import React, { SyntheticEvent, useState } from 'react';
import { AutocompleteValue } from '@mui/material';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

import Drawer from 'components/Drawer';
import ValidationMessage from 'components/ValidationMessage';
import { TextFieldPropsInterface } from 'components/TextField/TextField';
import { InputMessageType } from 'types';

import useStyles from '../styles';
import AutocompleteMobileContent from './AutocompletMobileContent';

export type AutocompleteChangeType = (event: SyntheticEvent, value: AutocompleteValue<any, any, any, any>) => void;

interface AutocompleteMobileTypeProps extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  inputProps?: TextFieldPropsInterface;
  message?: InputMessageType;
  autocompleteValue?: string;
  textField?: React.ReactNode;
  resetInputValue?: () => void;
}

const AutocompleteMobile: React.FunctionComponent<AutocompleteMobileTypeProps> = ({
  renderInput,
  inputProps,
  message,
  options,
  value,
  onChange,
  autocompleteValue,
  disabled,
  getOptionLabel,
  textField,
  resetInputValue,
  isOptionEqualToValue,
  ...restProps
}) => {
  const classes = useStyles();

  const [isOpen, setOpen] = useState<boolean>(false);

  const handleCloseClick = () => {
    setOpen(false);
    resetInputValue();
  };

  return (
    <div className={classes['autocomplete-mobile']}>
      <div onClick={() => !disabled && setOpen(true)}>
        <MuiAutocomplete
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          aria-autocomplete="none"
          PopperComponent={null}
          filterSelectedOptions
          readOnly
          renderInput={renderInput}
          options={options}
          value={value}
          {...restProps}
        />
      </div>
      <Drawer
        open={isOpen}
        hideClose
        onBack={handleCloseClick}
        title={
          <div className={classes['autocomplete-mobile__drawer-title']}>
            {textField}
            {!!message && <ValidationMessage message={message} show={!!message} />}
          </div>
        }
      >
        <div className={classes['autocomplete-mobile__results']}>
          <AutocompleteMobileContent
            disabled={disabled}
            options={options.slice(0, 20)}
            value={value}
            handleClose={handleCloseClick}
            onChange={onChange}
            multiple={restProps.multiple}
            autocompleteValue={autocompleteValue}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
          />
        </div>
      </Drawer>
      {!!message && <ValidationMessage message={message} show={!!message} />}
    </div>
  );
};

export default AutocompleteMobile;

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import differenceBy from 'lodash/differenceBy';

import Autocomplete from 'components/Autocomplete';
import { InputMessageType } from 'types';
import { commonApi } from 'api';
import { AutocompleteChangeType } from '../Autocomplete/Autocomplete';

interface PropsInterface {
  value?: any;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  onChange: AutocompleteChangeType;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  message?: InputMessageType;
  multiple?: boolean;
}

const LanguageAutocomplete: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { value, disabled, placeholder, label, onChange, onBlur, message, multiple = true } = props;

  const [autocomplete, setAutocomplete] = useState({
    options: [],
    inputValue: value || [],
    autocompleteValue: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value?.length < 3000) {
      setAutocomplete((prevState) => ({ ...prevState, autocompleteValue: value }));
    }
  };

  const handleCategoriesAutocompleteChange: AutocompleteChangeType = (event, value) => {
    setAutocomplete((prevState) => ({ ...prevState, inputValue: value }));
    onChange(event, value);
  };

  const handleLanguagesAutocompleteRequest = useCallback(
    async (query = '') => {
      const { data: languageResponse } = await commonApi.autocomplete.languages(query);
      setAutocomplete((prevState) => ({
        ...prevState,
        options: differenceBy(languageResponse),
      }));
    },
    [autocomplete.inputValue],
  );

  const handleLanguageAutocompleteFocus = () => {
    handleLanguagesAutocompleteRequest();
  };

  useEffect(() => {
    if (autocomplete.autocompleteValue) {
      handleLanguagesAutocompleteRequest(autocomplete.autocompleteValue);
    }
  }, [autocomplete.autocompleteValue]);

  useEffect(() => {
    setAutocomplete((prevState) => ({ ...prevState, inputValue: value }));
  }, [value]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      inputProps={{
        disabled,
        placeholder,
        label,
        name: 'languageAutocomplete',
        onChange: handleInputChange,
        onBlur,
      }}
      message={message}
      multiple={multiple}
      onBlur={onBlur}
      onChange={handleCategoriesAutocompleteChange}
      onFocus={handleLanguageAutocompleteFocus}
      options={autocomplete.options}
      value={autocomplete.inputValue}
    />
  );
};

export default LanguageAutocomplete;

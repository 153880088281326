import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';

const logError = (error: Error | AxiosError, data?: unknown): void => {
  if (import.meta.env.PROD) {
    if (
      error?.name === 'CanceledError' ||
      (axios.isAxiosError(error) &&
        (error?.code === 'ERR_NETWORK' || error.response?.status === 422 || error.response?.status === 401))
    ) {
      return;
    }

    Sentry.withScope((scope) => {
      // @ts-ignore
      const res = error?.response;
      // @ts-ignore
      const req = error?.request;
      // @ts-ignore
      const parsedRes = JSON.stringify(error?.response);
      // @ts-ignore
      const parsedReq = JSON.stringify(error?.request);

      scope.setExtras({
        res,
        req,
        data,
        parsedRes,
        parsedReq,
      });
      Sentry.captureException(error);
    });
  }
  console.error(error);
};

export default logError;

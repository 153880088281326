import React from 'react';
import { CircularProgress, Fade } from '@mui/material';
import useStyles from './styles';

interface PropsInterface {
  children: React.ReactNode;
  loading?: boolean;
  overlay?: number;
  size?: number;
  noContent?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
}

const LoaderWrapper: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { children, loading, overlay = 0.5, size = 30, noContent = false, color = 'secondary' } = props;
  const classes = useStyles({ overlay });

  const styles = loading && {
    opacity: overlay,
    pointerEvents: 'none',
  };

  return (
    <div className={classes['loader-wrapper']}>
      {loading && (
        <Fade in>
          <div className={classes['loader__animation']}>
            <CircularProgress size={size} color={color} />
          </div>
        </Fade>
      )}

      {(!noContent || !loading) && (
        <div className={classes['loader__content']} style={(styles || {}) as unknown}>
          {children}
        </div>
      )}
    </div>
  );
};

export default LoaderWrapper;

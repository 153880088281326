import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'doughnut-chart': {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '24px',
    alignItems: 'center',
    width: '100%',
  },
  'doughnut-chart--right': {
    gridTemplateColumns: '1fr',
  },
  'doughnut-chart__chart-wrapper': ({ size }: { size: number }) => ({
    flex: '1 1 auto',
    position: 'relative',
    width: size ?? 'auto',
    height: size ?? 'auto',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      height: 'auto',
    },
  }),
  'doughnut-chart__chart-wrapper--right': {
    order: 1,
  },
  'doughnut-chart__chart': {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
  },
  'doughnut-chart__background': {
    zIndex: 2,
    width: '100%',
    height: '100%',
  },
  'doughnut-chart__content': {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    flexDirection: 'column',
  },

  'doughnut-chart__legend': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  'doughnut-chart__legend--right': {
    order: 2,
  },

  'doughnut-chart__legend-item': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '8px',
    alignItems: 'center',
  },
  'doughnut-chart__legend-color': {
    width: 30,
    height: 4,
    borderRadius: 8,
  },
}));

export default useStyles;

import { DropzoneFileType } from 'components/Dropzone';
import { AttachmentType } from 'types';

const convertAttachmentToDropzone = (file: AttachmentType): DropzoneFileType => ({
  id: file.id || file.uuid,
  path: file.path,
  preview: file.path,
  lastModifiedDate: new Date(file.created_at),
  name: file.original_name || file.filename,
  size: null,
  type: file.mime_type,
});

export default convertAttachmentToDropzone;

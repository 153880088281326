import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dropdown__content: {
    backdropFilter: 'blur(12px)',
    borderRadius: 8,
    border: `solid 1px ${theme.palette.grey[600]}`,
  },
  dropdown__paper: {
    maxHeight: 500,
    overflow: 'auto',
  },
}));

export default useStyles;

const importLocale = (locale: string) => {
  switch (locale) {
    case 'en':
      return import('date-fns/locale/en-GB');
    case 'ru':
      return import('date-fns/locale/ru');
    case 'pl':
      return import('date-fns/locale/pl');
    case 'de':
      return import('date-fns/locale/de');
    case 'uk':
      return import('date-fns/locale/uk');
    case 'it':
      return import('date-fns/locale/it');
    case 'es':
      return import('date-fns/locale/es');
    case 'fr':
      return import('date-fns/locale/fr');
    default:
      return import('date-fns/locale/en-GB');
  }
};

export default importLocale;

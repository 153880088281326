import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'content-empty-placeholder': {
    color: 'inherit',
    margin: theme.spacing(6, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
    maxWidth: '100%',
  },
  'content-empty-placeholder__meta': ({ width }: { width: number }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxWidth: width || 'initial',
    textAlign: 'center',
    alignItems: 'center',
    margin: 'auto',
    color: 'inherit',
  }),
  'content-empty-placeholder__cta': {
    marginTop: theme.spacing(6),
  },
}));

export default useStyles;

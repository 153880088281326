import { PeriodTypeType } from 'types/date';

export const periodTypes: { [key: string]: PeriodTypeType } = {
  day: {
    key: 'day',
    duration: 1,
    switchable: true,
  },
  week: {
    key: 'week',
    duration: 7,
    switchable: true,
  },
  month: {
    key: 'month',
    duration: 31,
    switchable: true,
  },
  list: {
    key: 'list',
    duration: 1,
    switchable: true,
  },
};

export const periodTypesArray: PeriodTypeType[] = [
  periodTypes.list,
  periodTypes.day,
  periodTypes.week,
  periodTypes.month,
];

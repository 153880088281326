import { matchPath } from 'react-router-dom';

const isNavLinkActive = (href: string, routeHref: string, isExact?: boolean): boolean => {
  if (isExact) {
    const match = matchPath(href, { path: routeHref, exact: true });
    return match?.isExact;
  }

  const match = matchPath(href, { path: routeHref });
  return !!match;
};

export default isNavLinkActive;

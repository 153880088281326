import React from 'react';
import Paragraph from 'components/Paragraph';
import Logo from 'components/Logo';
import useStyles from './styles';

interface PropsInterface {
  children?: React.ReactNode;
  type?: 'fixed' | 'relative';
}

const INKsearchLoader: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { type = 'fixed', children } = props;
  const classes = useStyles({});

  return (
    <div className={type === 'fixed' ? classes['loader--fixed'] : ''}>
      <Logo />
      {children && (
        <Paragraph type="size-S-medium" color="secondary" margin={1}>
          {children}
        </Paragraph>
      )}
    </div>
  );
};

export default INKsearchLoader;

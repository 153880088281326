import React from 'react';
import useStyles from './styles';
import theme from '../../theme';

export type SkeletonVariantsType = 'image' | 'profile' | 'text' | 'notification' | 'card' | 'h1' | 'h2' | 'h3' | 'p';

export interface PropsInterface {
  width?: string | number;
  minHeight?: string | number;
  variant?: SkeletonVariantsType;
  children?: React.ReactNode;
  color?: string;
}

const Skeleton: React.FunctionComponent<PropsInterface> = ({
  variant = 'text',
  width,
  minHeight,
  color = theme.palette.grey[500],
  children,
}) => {
  const classes = useStyles({ variant, width, minHeight, color });

  return (
    <div className={classes['skeleton']}>
      {variant === 'h1' && <div className={classes['skeleton__h1']} />}
      {variant === 'h2' && <div className={classes['skeleton__h2']} />}
      {variant === 'h3' && <div className={classes['skeleton__h3']} />}
      {variant === 'p' && <div className={classes['skeleton__p']} />}
      {variant === 'text' && <div className={classes['skeleton__text']} />}
      {variant === 'image' && <div className={classes['skeleton__image']} />}
      {variant === 'profile' && (
        <div className={classes['skeleton__profile']}>
          <div className={classes['skeleton__profile-avatar']} />
          <div className={classes['skeleton__profile-meta']}>
            <div className={classes['skeleton__profile-title']} />
            <div className={classes['skeleton__profile-subtitle']} />
          </div>
        </div>
      )}
      {variant === 'notification' && (
        <div className={classes['skeleton__notification']}>
          <div />
        </div>
      )}
      {variant === 'card' && <div className={classes['skeleton__card']}>{children}</div>}
    </div>
  );
};

export default Skeleton;

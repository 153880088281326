import Palette from '../palette';

export default {
  root: {
    '& button': {
      background: 'transparent',
      border: 'none',
      '&:disabled': {
        color: Palette.black.light,
      },
    },
  },
};

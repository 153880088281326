import { AccountRoleType } from 'types';

type AdditionalAccountType = {
  avatar: string;
  name: string;
  role: AccountRoleType;
  token: string;
  uuid: string;
  color?: string;
};

export type AdditionalAccountListType = AdditionalAccountType[];

const getAccounts = (ingoreAccountUuid?: string): AdditionalAccountListType => {
  if (!window.localStorage) {
    return null;
  }

  const accounts = JSON.parse(window.localStorage.getItem('a_storage')) || [];

  if (ingoreAccountUuid && accounts.length > 0) {
    return accounts.filter(({ uuid }) => {
      const isLegit = ingoreAccountUuid !== uuid;
      removeAccount(ingoreAccountUuid);
      return isLegit;
    });
  }
  return accounts;
};

const removeAccount = (toRemoveUuid: string) => {
  const additionalAccounts = getAccounts();
  window.localStorage.setItem(
    'a_storage',
    JSON.stringify(additionalAccounts.filter(({ uuid }) => uuid !== toRemoveUuid)),
  );
};

const addAccount = (account: AdditionalAccountType) => {
  const additionalAccounts = getAccounts();
  window.localStorage.setItem('a_storage', JSON.stringify([...additionalAccounts, account]));
};

const additinalAccountHandler = {
  getAccounts,
  addAccount,
  switchAccount: (currentAccount?: AdditionalAccountType, newUuid?: string) => {
    const accounts = getAccounts();
    const newAccount = accounts.find(({ uuid }) => uuid === newUuid) || accounts?.[0];

    if (newAccount) {
      window.localStorage.setItem('biz_token', newAccount.token);

      if (newUuid) {
        removeAccount(newUuid);
      } else {
        removeAccount(newAccount.uuid);
      }

      if (currentAccount) {
        addAccount(currentAccount);
      }
    }
  },
  removeAccount,
  disableAccount: addAccount,
  clearAccounts: () => window.localStorage.setItem('a_storage', null),
};

export default additinalAccountHandler;

import client from '../client';
import authorize from '../authorize';
import { AccountApiInterface } from '../types/account';

const multipartRequest = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const account: AccountApiInterface = {
  me: {
    get: (token) => client.get('/inksearch/me', authorize(token)),
    updatev2: (params) => client.post('/inksearch/v2/user/update', params, { ...authorize(), ...multipartRequest }),
    changeRole: (params) => client.post('/me/change_role', params, authorize()),
    acceptStudioInvite: (params) => client.post('/inksearch/employee/accept_invitation', params, authorize()),
    acceptStudioProceed: (params) => client.post('/inksearch/v2/employee/invite/proceed', params),
    passwordChange: (params) => client.post('/me/change/password', params),
    getEmployeeAccess: () => client.get('/inksearch/v2/user/access/me', authorize()),
  },
  settings: {
    get: (token) => client.get('/inksearch/v2/user/settings/get', authorize(token)),
    update: (params) => client.post('/inksearch/v2/user/settings/update', params),
  },
  consents: {
    get: (token) => client.get('/inksearch/v2/user/consents/get', authorize(token)),
    update: (params) => client.post('/inksearch/v2/user/consents/update', params),
  },
  employee: {
    get: (uuid) => client.get(`/inksearch/user/get_user_data?employee_id=${uuid}`),
    updatev2: (params, uuid) => client.post(`/inksearch/v2/user/update/${uuid}`, params, multipartRequest),
    getList: () => client.get('/inksearch/me/get_employees'),
    remove: (params) => client.post('/inksearch/employee/remove_employee', params),
    getInvitationList: () => client.get('/inksearch/me/get_employees_invitations'),
    removeInvitation: (params) => client.post('/inksearch/me/delete_employees_invitations', params),
    setOrder: (params) => client.post('/inksearch/employee/set_order', params),

    clientCommunication: {
      getSettings: () => client.get('/me/notifications/outcoming/diff'),
      smsDisabled: (params) => client.get(`/me/notifications/outcoming/access?uuid=${params.uuid}`),
      restoreAll: () => client.get('/me/notifications/outcoming/employees/restore'),
    },

    invite: {
      check: (params) => client.post('/inksearch/v2/employee/invite/check', params),
      send: (params) => client.post('/inksearch/v2/employee/invite/send', params),
      get: (params) => client.post('/inksearch/v2/employee/invite/get', params),
    },

    access: {
      default: (params) => client.get(`/inksearch/v2/user/access/default?role=${params.role}`, authorize()),
      get: (params) => client.get(`/inksearch/v2/user/access/get?employee_id=${params.uuid}&nested=1`, authorize()),
    },
  },
  customSms: {
    getDiff: () => client.get('/sms/custom/employees/diff'),
    getDiffSingle: (params) => client.get(`/sms/custom/employees/diff/get?employee_id=${params.employee_id}`),
    restoreAll: () => client.get('/sms/custom/employees/restore_all'),
    restoreSingle: (params) => client.post('/sms/custom/employees/restore', params),
    directionalCodes: (params) =>
      client.get(`/sms/custom/regional_codes/available?type=${params.type}&employee_id=${params.employee_id}`),

    item: {
      chips: (params) => client.get(`/sms/custom/default/chips?type=${params.type}&employee_id=${params.employee_id}`),
      store: (params) => client.post('/sms/custom/store', params),
      update: (params) => client.post('/sms/custom/update', params),
      delete: (params) => client.post('/sms/custom/delete', params),
      list: (params) => client.get(`/sms/custom/list?type=${params.type}&employee_id=${params.employee_id}`),
      details: (params) => client.get(`/sms/custom/details?custom_sms_id=${params.custom_sms_id}`),
    },
  },
  ink: {
    edit: (params) => client.post('/inksearch/inks/update', params, multipartRequest),
    delete: (params) => client.post('/inksearch/inks/delete', params),
    add: (params) => client.post('/inksearch/inks/store', params, multipartRequest),
  },
  clientCommunicationSettings: {
    get: (uuid) => client.get(`/me/notifications/outcoming/get?employee_id=${uuid}`),
    default: (uuid) => client.get(`/me/notifications/outcoming/default?employee_id=${uuid}`),
  },
};

export default account;

import makeStyles from '@mui/styles/makeStyles';

import layoutValues from 'theme/constatns';

const useStyles = makeStyles((theme) => ({
  'sidebar-icon': {
    width: layoutValues.sidebar.menuItem.iconSize,
    height: layoutValues.sidebar.menuItem.iconSize,
  },
  'sidebar-icon--color': {
    color: theme.palette.white.light,
  },
}));

export default useStyles;

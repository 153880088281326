import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  select__root: {
    '& .MuiFilledInput-input': {
      margin: 0,
      letterSpacing: 0,
      fontSize: '14px',
      fontWeight: '500 !important',
      lineHeight: 1.715, // '24px',
      color: theme.palette.grey[100],
    },
  },

  select__paper: {
    padding: 0,
    maxHeight: 400,

    '&': {
      scrollbarColor: `${theme.palette.white.light} ${theme.palette.background.default}`,
      scrollbarWidth: 'thin',
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
      height: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 0,
      backgroundColor: theme.palette.white.light,
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
  },

  select__list: {
    padding: 0,
  },

  select__backdrop: {
    background: 'rgba(0,0,0,0)',
  },

  'select-mobile': {
    display: 'flex',
    gap: '4px',
  },
}));

export default useStyles;

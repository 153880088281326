import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: '700',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline-flex',
    alignItems: 'center',
    userSelect: 'none',
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    margin: ({ margin = 0 }: { margin: number }) => theme.spacing(margin, 0),
    borderRadius: '8px',
    background: theme.palette.background.card,
    boxShadow: '4px 8px 16px rgba(0, 0, 0, 0.4)',
    border: 'solid 1px',
    borderColor: theme.palette.background.card,
    transition: '200ms',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: 'calc(100% + 2px)',
      height: 'calc(100% + 2px)',
      boxShadow: 'inset 0px 0px 0px 5px transparent',
      borderRadius: '8px',
      transition: '200ms',
    },
  },

  'card--vertical': {
    '& $card__content': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  'card--no-padding': {
    '& $card__content': {
      padding: theme.spacing(0),
    },

    '&:not($card--active)': {
      '& $card__content': {
        padding: theme.spacing(0),
      },
    },
  },

  'card--slim': {
    '& $card__content': {
      padding: theme.spacing(0.5, 2),
    },
  },

  'card--large': {
    '& > $card__content': {
      padding: theme.spacing(6),
    },
  },

  'card--fill': {
    height: '100%',
    width: '100%',
  },

  'card--secondary': {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.light,

    '&$card--button': {
      '& button:hover': {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
  },

  'card--primary': {
    // background: theme.palette.primary.light,
    color: theme.palette.secondary.contrastText,
    background: 'linear-gradient(221.24deg, #8DD1AB 2.75%, #AF9FE3 99.49%)',
  },

  'card--lighten': {
    color: theme.palette.black.contrastText,
    background: theme.palette.background.default,
    borderColor: theme.palette.background.card,
  },

  'card--darken': {
    color: theme.palette.black.contrastText,
    background: theme.palette.background.drawer,
    borderColor: theme.palette.background.topbar,
  },

  'card--gradient-dark': {
    background: 'linear-gradient(142.17deg, rgba(34, 34, 34, 0.88) 0%, rgba(24, 24, 24, 0.72) 100%)',
    backdropFilter: 'blur(12px)',
    color: theme.palette.grey[100],
  },

  'card--inherit': {
    color: 'inherit',
    background: 'inherit',
  },

  'card--active': {
    // outline: 'solid 5px #FC5185',
    '&::before': {
      boxShadow: `inset 0px 0px 0px 5px ${theme.palette.primary.main}`,
    },
  },

  'card--button': {
    '& button:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },

  'card--flat': {
    boxShadow: 'none',
    border: 'none',
    borderRadius: '0',
  },

  card__content: {
    color: 'inherit',
    borderRadius: '8px',
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2, 4),
  },
}));

export default useStyles;

import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import useStyles from './styles';

type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

type HeaderVariantType = 'h1' | 'h2' | 'h3' | 'h4' | 'data-table';

export interface PropsInterface {
  children: React.ReactNode;
  type: HeaderType;
  as?: HeaderType;
  variant: HeaderVariantType;
  icon?: JSX.Element;
  alignment?: 'center' | 'left';
  margin?: number;
  color?: 'error' | 'primary' | 'secondary' | 'inherit';
}

const Header: FunctionComponent<PropsInterface> = ({
  children,
  type,
  as,
  icon,
  color = 'primary',
  margin,
  alignment,
  variant,
}) => {
  const classes = useStyles({ margin });

  const styles = {
    textAlign: alignment,
  };

  const Component = as || type;
  return (
    <Component
      style={styles}
      className={cx(classes.root, classes[variant ?? type], {
        [classes.withIcon]: icon,
        [classes[`header--${color}`]]: color,
      })}
    >
      {icon && <span className={classes.icon}>{icon}</span>}
      {children}
    </Component>
  );
};

export default Header;

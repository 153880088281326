import makeStyles from '@mui/styles/makeStyles';
import Palette from '../../theme/palette';

const useStyles = makeStyles((theme) => ({
  'phone-input-wrapper': {
    position: 'relative',
    backgroundColor: 'rgba(34, 34, 34, 0.6)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
  },

  'phone-input': {
    paddingTop: theme.spacing(1),
    '& .form-control': {
      background: 'transparent',
      border: 'none!important',
      color: 'inherit',
      width: '100%',
    },
    '& $phone-input__button': {
      backgroundColor: 'transparent',
      border: 'none',
      padding: theme.spacing(1),

      '&.flag-dropdown.open': {
        background: 'rgba(255,255,255,0.1)',
      },

      '&.flag-dropdown.open .selected-flag': {
        background: 'rgba(255,255,255,0.1)',
      },

      '& .selected-flag:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },

      '& .selected-flag:focus': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },

      '& .country-list': {
        letterSpacing: 0,
        fontSize: '14px',
        // fontSize: '0.8750em', // '14px',
        fontWeight: '500 !important',
        color: Palette.grey[100],
        fontFamily: '"Montserrat", sans-serif',

        '& .country-name': {
          color: Palette.grey[100],
        },

        '& .dial-code': {
          color: Palette.grey[300],
        },

        left: 0,
        top: '30px',
        background: 'linear-gradient(142.17deg, rgba(34, 34, 34, 0.88) 0%, rgba(24, 24, 24, 0.72) 100%)',
        backdropFilter: 'blur(12px)',
        boxShadow: '4px 4px 32px rgba(0, 0, 0, 0.32)',
        border: 'solid 1px rgba(255,255,255, 0.5)',
        borderRadius: 0,

        '&': {
          scrollbarColor: `${theme.palette.white.light} ${theme.palette.background.card}`,
          scrollbarWidth: 'thin',
        },

        '&::-webkit-scrollbar': {
          width: theme.spacing(1),
          height: theme.spacing(1),
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 0,
          backgroundColor: theme.palette.white.light,
        },
        '&::-webkit-scrollbar-button': {
          display: 'none',
        },

        '& .country.highlight': {
          backgroundColor: 'rgba(255,255,255,0.2)',
        },

        '& .country:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)',
        },
      },
    },
  },
  'phone-input__button': {},
  'phone-input__wrapper': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,

    letterSpacing: 0,
    fontSize: '14px',
    // fontSize: '0.8750em', // '14px',
    fontWeight: '500 !important',
    color: Palette.grey[100],
    fontFamily: '"Montserrat", sans-serif',

    '& input': {
      color: Palette.grey[100],
    },
    '& input::placeholder': {
      color: theme.palette.grey[300],
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 2,
      width: '100%',
    },
    '&.active': {
      '&:after': {
        borderBottom: `solid 1px ${theme.palette.grey[100]}`,
      },
    },
    '&:hover': {
      '&:after': {
        borderBottom: `solid 1px ${theme.palette.grey[100]}`,
      },
    },
  },

  'phone-input__wrapper--error': {
    borderBottomColor: theme.palette.error.main,
  },

  'phone-input__wrapper--disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    position: 'initial',
    pointerEvents: 'none',
  },
}));

export default useStyles;

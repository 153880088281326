import React from 'react';
import cx from 'classnames';
import { Icon } from '@mui/material';

import Paragraph from 'components/Paragraph';

import { InputMessageType } from './types';
import useStyles from './styles';

interface PropsInterface {
  message: InputMessageType;
  show: boolean;
}

const ValidationMessage: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { message = { message: null, type: null }, show } = props;
  const classes = useStyles();

  return (
    <div className={cx(classes['validation-message'], { [classes['validation-message--shown']]: !!show })}>
      <Paragraph type="size-XS-medium" color={message?.type || 'error'} icon={message?.message && <Icon>error</Icon>}>
        {message?.message}
      </Paragraph>
    </div>
  );
};

export default ValidationMessage;

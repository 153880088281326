import React from 'react';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

import openNewTab from 'helpers/openNewTab';
import generateDayId from 'helpers/date/generateDayId';
import Paragraph from 'components/Paragraph';
import { DropzoneFileClickType, DropzoneFileType } from 'components/Dropzone';
import useStyles from './styles';

interface PropsInterface {
  onRemove?: (file: DropzoneFileType) => void;
  onFileClick?: DropzoneFileClickType;
  defaultFileName?: string;
  file: DropzoneFileType;
  preventOpenFile?: boolean;
}

const FilePreview: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { onFileClick, onRemove, defaultFileName, file, preventOpenFile } = props;

  const classes = useStyles({});
  const fileName = file?.name || defaultFileName || 'File';
  const secondaryTitle = file?.lastModifiedDate ? generateDayId(file.lastModifiedDate) : '';
  const showPreview = file?.type?.includes('image');
  const isPdf = file?.type?.includes('pdf') ? 'picture_as_pdf' : 'attach_file';

  const handleFileClick = (file: DropzoneFileType) => {
    if (typeof onFileClick === 'function') {
      if (!preventOpenFile) {
        const isPdf = file?.type?.includes('pdf');
        if (isPdf) {
          openNewTab(URL.createObjectURL(file as File));
        }
      }
      onFileClick(file);
    }
  };

  return (
    <div className={classes['file-preview']} key={file.preview}>
      <div onClick={() => handleFileClick(file)} role="button" tabIndex={0}>
        <div className={classes['file-preview__preview-wrapper']}>
          {showPreview ? (
            <img alt={file.preview} className={classes['file-preview__preview']} src={file.preview} />
          ) : (
            <div className={classes['file-preview__preview']}>
              <Icon>{isPdf}</Icon>
            </div>
          )}
          {typeof onFileClick === 'function' && (
            <div className={classes['file-preview__preview-cover']}>
              <Icon>{showPreview || isPdf ? 'visibility' : 'download'}</Icon>
            </div>
          )}
        </div>
      </div>
      <div className={classes['file-preview__file-data']}>
        <Paragraph type="size-S-medium">{fileName}</Paragraph>
        <Paragraph type="size-S-medium">{secondaryTitle}</Paragraph>
      </div>
      {typeof onRemove === 'function' && (
        <Button color="error" onClick={() => onRemove(file)} size="small" variant="contained">
          <Icon color="secondary" fontSize="large">
            delete_outlined
          </Icon>
        </Button>
      )}
    </div>
  );
};

export default FilePreview;

import React, { VFC } from 'react';
import { Grid } from '@mui/material';
import Checkbox from 'components/Checkbox';
import DataTable from 'components/DataTable';

type Props = {
  checks: {
    [notification: string]: {
      sms: boolean;
      mail: boolean;
    };
  };
  onChange: (notification: string, type: 'sms' | 'mail') => (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ClientFormNotifications: VFC<Props> = ({ checks, onChange }) => {
  return (
    <Grid item xs={12}>
      <DataTable
        columns={[
          { label: 'type', width: '100%', id: 'type' },
          { label: 'sms', width: '0%', id: 'sms' },
          { label: 'mail', width: '0%', id: 'mail' },
        ]}
        rows={Object.entries(checks).map(([name, { sms, mail }]) => ({
          data: [
            <div key={`${name}-label`} style={{ textAlign: 'left' }}>
              {name}
            </div>,
            <Checkbox checked={sms} key={`${name}-sms-checkbox`} label="" onChange={onChange(name, 'sms')} />,
            <Checkbox checked={mail} key={`${name}-mail-checkbox`} label="" onChange={onChange(name, 'mail')} />,
          ],
          id: name,
        }))}
      />
    </Grid>
  );
};

export default ClientFormNotifications;

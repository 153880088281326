import React from 'react';
import { useTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';

import Paragraph from 'components/Paragraph';

import useStyles from './styles';

export interface PropsInterface {
  page: number;
  perPage: number;
  total: number;
  onChange(newPage: number, perPage: number): void;
}

const Pagination: React.FunctionComponent<PropsInterface> = ({ page, perPage, total, onChange }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChange(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onChange(1, parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      classes={{
        root: classes['pagination__root'],
        toolbar: classes['pagination__toolbar'],
        displayedRows: classes['pagination__displayed-rows'],
        input: classes['pagination__input'],
        spacer: classes['pagination__spacer'],
        select: classes['pagination__select'],
        selectRoot: classes['pagination__select-root'],
        selectLabel: classes['pagination__select-label'],
        selectIcon: classes['pagination__select-icon'],
        actions: classes['pagination__actions'],
      }}
      component="div"
      count={total}
      page={page - 1}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[10, 20, 50]}
      labelRowsPerPage={
        <Paragraph type="size-XS-regular" color="secondary">{`${t(
          'components.pagination.label-rows-per-page',
        )}:`}</Paragraph>
      }
      labelDisplayedRows={({ from, to, count, page }) => (
        <Paragraph type="size-XS-regular" color="secondary">
          {`${from}–${to} ${t('components.pagination.label-displayed-rows.of')} ${
            count !== -1 ? count : `more than ${to}`
          }`}
        </Paragraph>
      )}
    />
  );
};

export default Pagination;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'button-switcher': {
    color: '#fff',
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(1, 0),
    borderRadius: 4,
    backgroundColor: theme.palette.background.card,
  },

  'button-switcher--darken': {
    border: `1px solid ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[700],
  },

  'button-switcher__button': {
    padding: theme.spacing(1, 4),
    borderRadius: '8px',
    background: 'transparent !important',
    zIndex: 2,
    fontSize: 12,
    fontWeight: 700,
    transition: '300ms',

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center',
    display: 'block',
  },

  'button-switcher__button--active': {
    background: 'transparent !important',
    color: 'black',
  },

  'button-switcher__indicator': {
    position: 'absolute',
    height: 'calc(100% - 8px)',
    transition: 'all 300ms ease-in-out',
    backgroundColor: 'white',
    borderRadius: 4,
    top: 4,
    bottom: 4,
    zIndex: -1,
  },
}));

export default useStyles;

import React from 'react';

import MuiAvatar from '@mui/material/Avatar';

import getInitialsFromName from 'helpers/getInitialsFromName';
import Placeholder from 'assets/img/avatar_placeholder.png';
import Palette from 'theme/palette';

import useStyles from './styles';

export type AvatarProps = {
  src?: string;
  color?: string;
  avatarName?: string;
  size?: number;
  icon?: React.ReactNode;
};

const Avatar: React.FunctionComponent<AvatarProps> = (props: AvatarProps) => {
  const { size = 36, avatarName, src = avatarName ? undefined : Placeholder, color = Palette.grey[700], icon } = props;
  const classes = useStyles(props);

  return (
    <div className={classes['avatar__border']} style={{ backgroundColor: color, borderColor: color }}>
      <MuiAvatar
        alt={avatarName || 'Person'}
        className={classes['avatar']}
        src={icon ? undefined : src}
        style={{ backgroundColor: color }}
        sx={{ height: size, width: size }}
      >
        {icon || getInitialsFromName(avatarName || '')}
      </MuiAvatar>
    </div>
  );
};

export default Avatar;

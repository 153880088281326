import React from 'react';

import Header from 'components/Header';

export interface PropsInterface {
  children: React.ReactNode;
}

const WidgetTitle: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { children } = props;

  return (
    <Header type="h2" variant="h3">
      {children}
    </Header>
  );
};

export default WidgetTitle;

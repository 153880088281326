import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'voucher-form__payment-switch': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  'voucher-form__payment-switch-price': {
    marginTop: theme.spacing(-6),
  },
}));

export default useStyles;

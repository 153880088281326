import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  drawer__content: ({ minWidth, padding }: { minWidth: string; padding: string }) => ({
    padding: padding || theme.spacing(4, 4, 4),
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    minWidth: minWidth || 'auto',
    [theme.breakpoints.up('sm')]: {
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        backgroundColor: theme.palette.black.dark,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 0,
        backgroundColor: theme.palette.white.light,
      },
    },
  }),
  drawer__footer: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    borderTop: `solid 1px ${theme.palette.grey[600]}`,
    width: '100%',
  },

  drawer__validation: {
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  pagination__root: {
    display: 'grid',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',
    padding: '8px',
    maxWidth: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: '8px',
    },
  },
  pagination__toolbar: {
    width: 'fit-content',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto 1fr',
    alignItems: 'center',
    gap: '16px',
    padding: 0,
    margin: 0,
    '& .MuiTablePagination-actions': {
      margin: 0,
    },
  },
  pagination__spacer: {
    display: 'none',
  },
  pagination__input: {
    margin: 0,
    width: 'fit-content',
  },
  pagination__select: {
    margin: 0,
    fontSize: '14px',
    alignItems: 'center',
    lineHeight: '24px',
  },
  'pagination__select-icon': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: theme.palette.grey[300],
  },
  pagination__actions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '4px',
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
  },
}));

export default useStyles;

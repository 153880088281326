import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'test-stats': {
    position: 'fixed',
    top: 12,
    left: 100,
    zIndex: 999999,
    fontSize: '0.6em',
    padding: theme.spacing(4),
    background: 'rgba(0,0,0,0.2)',
    pointerEvents: 'none',
    border: 'solid 1px #FF0',
  },
  'test-stats__row': {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.5,
  },
  'test-stats__indicator': {
    width: 7,
    height: 7,
    borderRadius: '50%',
    marginLeft: 8,
  },
}));

export default useStyles;

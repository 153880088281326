import React from 'react';
import { Button, Collapse, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Checkbox, { CheckboxChangeType } from 'components/Checkbox';
import Divider from 'components/Divider';
import Dropzone, { DropzoneDropType, DropzoneRemoveType } from 'components/Dropzone';
import Paragraph from 'components/Paragraph';
import { useBooleanToggle } from 'helpers/hooks/useToggle';
import useAccount from 'helpers/hooks/useAccount';

import { ClientFormConsentsStateType } from '../../types';

type ConsentsProps = {
  consents: ClientFormConsentsStateType;
  onChange: CheckboxChangeType;
  onConsentFileDrop: DropzoneDropType;
  onConsentFileRemove: DropzoneRemoveType;
};

const ClientFormConsents: React.FunctionComponent<ConsentsProps> = ({
  consents,
  onChange,
  onConsentFileDrop,
  onConsentFileRemove,
}) => {
  const { state } = useAccount();

  const [moreExpanded, toggleMoreExpanded] = useBooleanToggle(false);

  const { t } = useTranslation('page-client');

  return (
    <>
      <Grid item xs={12}>
        <Divider title={t('consents.headers.consents')} />
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          checked={!!consents.general}
          label={
            <>
              <Paragraph type="size-S-medium">
                {t('consents.marketing.basic', { studio: state.data?.artist_data?.studio_name || state.data?.name })}
              </Paragraph>
              <Paragraph type="size-S-medium">
                {t('consents.marketing.basic*', { email: state.data?.artist_data?.studio_email || state.data?.email })}
              </Paragraph>
            </>
          }
          name="general"
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={12} marginLeft={6}>
        <Button color="secondary" onClick={() => toggleMoreExpanded()}>
          {moreExpanded ? t('clientEdit.details.hide') : t('clientEdit.details.show')} {t('clientEdit.details.more')}
        </Button>
        <Collapse in={moreExpanded}>
          <ol>
            <li>
              <Paragraph type="size-S-medium" color="secondary">
                {t('consents.marketing.extended.1')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type="size-S-medium" color="secondary">
                {t('consents.marketing.extended.2')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type="size-S-medium" color="secondary">
                {t('consents.marketing.extended.3')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type="size-S-medium" color="secondary">
                {t('consents.marketing.extended.4')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type="size-S-medium" color="secondary">
                {t('consents.marketing.extended.5')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type="size-S-medium" color="secondary">
                {t('consents.marketing.extended.6')}
              </Paragraph>
            </li>
            <li>
              <Paragraph type="size-S-medium" color="secondary">
                {t('consents.marketing.extended.7')}
              </Paragraph>
            </li>
          </ol>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Dropzone
          acceptFiles={{ 'image/png': ['.png'], 'image/jpeg': ['.jpeg'], 'application/pdf': ['.pdf'] }}
          defaultValue={consents.files}
          message={null}
          multiple
          onDrop={onConsentFileDrop}
          onRemove={onConsentFileRemove}
        />
      </Grid>
    </>
  );
};

export default React.memo(ClientFormConsents);

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'client-select': {
    position: 'relative',
  },

  'client-select--disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
    userSelect: 'none',
  },

  'client-select__client': {
    margin: theme.spacing(1, 0),
  },

  'client-select__client-warning': {
    display: 'flex',
    padding: theme.spacing(2, 0),
    gap: theme.spacing(2),
    color: theme.palette.warning.main,
  },

  'client-select__client-warning-icon': {
    fontSize: '1em',
    marginTop: theme.spacing(3),
  },

  'client-select__create': {
    lineHeight: 2.5,
  },

  'client-select__create-icon': {
    fontSize: '1.2em',
    lineHeight: 1.5,
  },

  'client-select__actions': {
    display: 'flex',
  },
}));

export default useStyles;

import React from 'react';
import map from 'lodash/map';

import FilePreview from 'components/FilePreview';
import { DropzoneFileClickType, DropzoneFileType } from 'components/Dropzone';
import openNewTab from 'helpers/openNewTab';
import useStyles from './styles';

interface PropsInterface {
  onRemove?: (file: DropzoneFileType) => void;
  onFileClick?: DropzoneFileClickType;
  fileNamePrefix?: string;
  files: DropzoneFileType[];
}

const DropzoneFileList: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { onFileClick, onRemove, fileNamePrefix, files } = props;

  const classes = useStyles({});

  const handleFileClick = (file: DropzoneFileType) => {
    const isPdf = file?.type?.includes('pdf');
    if (isPdf) {
      openNewTab(URL.createObjectURL(file as File));
    }
    onFileClick(file);
  };

  return (
    <aside className={classes['dropzone-files__wrapper']}>
      {map(files, (file: DropzoneFileType) => (
        <FilePreview
          defaultFileName={fileNamePrefix}
          file={file}
          key={file.name}
          onFileClick={handleFileClick}
          onRemove={onRemove}
        />
      ))}
    </aside>
  );
};

export default DropzoneFileList;

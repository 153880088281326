import format from 'date-fns/format';
import generateDateFromId from './generateDateFromId';

type FormatDateOptionsType = { dateFormat?: string; time?: boolean };
const initialOptions = { time: false, dateFormat: 'd MMMM yyyy' };

const formatDate = (date: Date | string, options?: FormatDateOptionsType): string => {
  if (!date || (typeof date !== 'string' && typeof date?.getDate !== 'function')) {
    return '';
  }

  const unifiedOptions: FormatDateOptionsType = { ...initialOptions, ...options };

  let formatStyle;
  if (unifiedOptions.time) {
    formatStyle = `${unifiedOptions.dateFormat}, HH:mm`;
  } else {
    formatStyle = unifiedOptions.dateFormat;
  }
  try {
    if (typeof date === 'string') {
      return format(generateDateFromId(date), formatStyle);
    }
    return format(date, formatStyle);
  } catch (e) {
    return '';
  }
};

export default formatDate;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOptionType } from 'components/ChipSelect/ChipSelect';
import logError from 'helpers/logError';
import { calendarApi } from 'api';

export type UseClientAutocompleteType = () => {
  isFetching: boolean;
  isError: boolean;
  paymentOptions: SelectOptionType<{ slug: string }>[];
};

const usePaymentOptions: UseClientAutocompleteType = () => {
  const { t } = useTranslation('common');

  const [isFetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [isError, setError] = useState(false);

  const getOptions = useCallback(() => {
    calendarApi
      .paymentMethods()
      .then(({ data: optionsResponse }) => {
        setOptions(
          Object.keys(optionsResponse || {}).map((key) => ({
            id: key.toString(),
            slug: optionsResponse[key],
            label: t(`common:paymentType.type.${optionsResponse[key]}`),
          })),
        );
        setError(false);
      })
      .catch((e) => {
        logError(e);
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [t]);

  useEffect(() => {
    setFetching(true);
    getOptions();
  }, []);

  return { isFetching, paymentOptions: options, isError } as const;
};

export default usePaymentOptions;

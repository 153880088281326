import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { commonApi } from 'api';
import { ReleaseNoteLatestResponseType } from 'api/types/common';
import Dialog from 'components/Dialog';
import LoaderWrapper from 'components/LoaderWrapper';
import { ReleaseDetails } from 'containers/ReleaseDetailsDrawer';
import isMobile from 'helpers/isMobile';
import logError from 'helpers/logError';

import useStyles from './styles';

type ReleaseNotesModalPropsType = {
  isOpen?: boolean;
  onClose(): void;
  onAccept(): void;
};

const ReleaseNotesModal: React.FunctionComponent<ReleaseNotesModalPropsType> = ({ isOpen, onClose, onAccept }) => {
  const { t } = useTranslation('common');
  const mobile = isMobile();

  const [isUpdateFetching, setUpdateFetching] = useState(true);
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNoteLatestResponseType>(null);

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const getLatestReleaseNote = async () => {
    setUpdateFetching(true);
    try {
      const { data } = await commonApi.releaseNotes.latest();
      setReleaseNotes(data);
    } catch (e) {
      logError(e);
    } finally {
      setUpdateFetching(false);
    }
  };

  useEffect(() => {
    getLatestReleaseNote();
  }, []);

  return (
    <Dialog
      hasBorders
      className={!mobile && classes['product-update-modal']}
      fullScreen={mobile}
      isOpen={isOpen}
      onClose={handleClose}
      title={t('release-modal.title')}
      actions={
        <>
          <Button color="secondary" onClick={handleClose} variant="text">
            {t('release-modal.buttons.not-now')}
          </Button>
          <Button color="primary" onClick={onAccept} variant="contained">
            {t('release-modal.buttons.check-it-out')}
          </Button>
        </>
      }
    >
      <LoaderWrapper loading={isUpdateFetching}>
        <ReleaseDetails releaseNotes={releaseNotes} isButtonVisible={false} />
      </LoaderWrapper>
    </Dialog>
  );
};

export default ReleaseNotesModal;

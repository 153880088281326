import Palette from '../palette';

export default {
  root: {
    zIndex: 1999,
  },
  paper: {
    minWidth: 250,
    borderRadius: 8,
    background: 'linear-gradient(142.17deg, rgba(34, 34, 34, 0.88) 0%, rgba(24, 24, 24, 0.72) 100%)',
    border: `1px solid ${Palette.background.topbar}`,
    backdropFilter: 'blur(8px)',
  },
};

import React from 'react';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { Backdrop } from '@mui/material';

import useStyles from './styles';
import DropdownLabel from './components/DropdownLabel';

interface PropsInterface {
  label?: React.ReactNode;
  children: React.ReactNode;
  rawLabel?: boolean;
  icon?: string;
  className?: string;
  isOpen?: boolean;
  onClose?(): void;
  onOpen?(): void;
  placement?: 'bottom' | 'bottom-start' | 'bottom-end';
  preventCloseOnClickInside?: boolean;
}

const Dropdown: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    label,
    icon,
    children,
    rawLabel = false,
    className,
    onClose,
    onOpen,
    isOpen,
    placement = 'bottom',
    preventCloseOnClickInside = false,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleMenuClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    if (typeof onOpen === 'function') {
      onOpen();
    }
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open || isOpen ? 'simple-popper' : undefined;

  return (
    <div style={{ position: 'relative' }} className={className}>
      <DropdownLabel rawLabel={rawLabel} label={label} handleMenuOpen={handleMenuOpen} icon={icon} />
      <Backdrop onClick={handleMenuClose} open={open} style={{ background: 'rgba(0,0,0,0)' }} />
      <Popper
        className={classes['dropdown__content']}
        anchorEl={anchorEl}
        id={id}
        open={open}
        style={{ zIndex: 99999 }}
        placement={placement}
      >
        <Paper
          onClick={preventCloseOnClickInside ? () => null : () => handleMenuClose()}
          className={classes['dropdown__paper']}
        >
          {children}
        </Paper>
      </Popper>
    </div>
  );
};

export default Dropdown;

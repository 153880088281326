import Palette from '../palette';

export default {
  colorPrimary: {
    backgroundColor: Palette.info.light,
  },
  barColorPrimary: {
    backgroundColor: Palette.info.main,
  },
};

import React from 'react';
import map from 'lodash/map';
import find from 'lodash/find';

import Select, { SelectChangeType } from 'components/Select';
import MenuItem from 'components/MenuItem';
import { CURRENCY } from 'constants/constants';
import { CurrencyType } from 'types';
import useStyles from './styles';

interface PropsInterface {
  value: CurrencyType;
  label?: string;
  disabled?: boolean;
  id: string;
  onChange(value: CurrencyType): void;
  error?: boolean;
  fullWidth?: boolean;
  name?: string;
  placeholder?: string;
  labelSingleLine?: boolean;
}

const CurrencySelect: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    value = { value: '' },
    label,
    onChange,
    disabled,
    error,
    id,
    fullWidth = false,
    name,
    placeholder,
    labelSingleLine = false,
  } = props;
  const classes = useStyles({});

  const handleChange: SelectChangeType = (e) => {
    const { value } = e.target;
    const newValue = find(CURRENCY, (currency) => value.toString() === currency.value?.toString());
    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <Select
      classes={{
        select: classes['currency-select__select'],
        icon: classes['currency-select__icon'],
        disabled: classes['currency-select__disabled'],
      }}
      disabled={disabled}
      displayEmpty
      error={!!error}
      fullWidth={fullWidth}
      label={label && { id, message: label }}
      labelId={id}
      labelSingleLine={labelSingleLine}
      name={name}
      onChange={handleChange}
      value={value?.value}
    >
      {placeholder && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {map(CURRENCY, (currency) => (
        <MenuItem selected={value?.value === currency.key} key={currency.key} value={currency.value}>
          {currency.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencySelect;

import client from '../client';
import { AuthApiInterface } from '../types/auth';
import authorize from '../authorize';

const auth: AuthApiInterface = {
  login: (params) => client.post('/auth/login', params),
  register: (params) => client.post('/auth/register', params),
  loginSocial: (social, url) => client.post(`/auth/login/${social}?redirect_url=${url || import.meta.env.BASE_URL}`),
  loginFacebook: (url) => client.post(`/auth/login/facebook?redirect_url=${url || import.meta.env.BASE_URL}`),
  loginGoogle: (url) => client.post(`/auth/login/google?redirect_url=${url || import.meta.env.BASE_URL}`),
  loginApple: (url) => client.post(`/auth/login/apple?redirect_url=${url || import.meta.env.BASE_URL}`),
  recoveryPassword: (params) => client.post('/auth/recovery/password', params),
  recoveryPasswordValidation: (params) => client.post('/auth/recovery/password/validate', params),
  sso: (token) => client.get('/auth/check_sso', authorize(token)),
  phone: {
    verifyNumber: (params) => client.post('/phone/send_verify', params, authorize()),
    verifyCode: (params) => client.post('/phone/verify', params, authorize()),
  },
};

export default auth;

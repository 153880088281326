import React from 'react';
import FormControl from '@mui/material/FormControl';

import CurrencySelect from 'components/CurrencySelect';
import TextField from 'components/TextField';
import ValidationMessage from 'components/ValidationMessage';
import { CURRENCY_LOCALE } from 'constants/constants';
import { CurrencyType, InputMessageType } from 'types';

import useStyles from './styles';

interface PropsInterface {
  value: string;
  currency: CurrencyType;
  label?: string;
  currencyLabel?: string;
  disabledCurrency?: boolean;
  id: string;
  onChangePrice(value: React.ChangeEvent<HTMLInputElement>): void;
  onBlurPrice?(event: React.FocusEvent<HTMLInputElement>): void;
  onChangeCurrency?(value: CurrencyType): void;
  message?: InputMessageType;
  fullWidth?: boolean;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
}

const PriceInput: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    value,
    currency = CURRENCY_LOCALE.pl,
    currencyLabel,
    label,
    onChangePrice,
    onBlurPrice,
    onChangeCurrency,
    disabledCurrency,
    message,
    id,
    fullWidth = false,
    placeholder,
    name = 'deposit',
    disabled = false,
  } = props;
  const classes = useStyles({});

  return (
    <FormControl className={classes['price-input']} error={!!message} fullWidth={fullWidth}>
      <div className={classes['price-input__input']}>
        {(!disabled || !disabledCurrency) && typeof onChangeCurrency === 'function' && (
          <div className={classes['price-input__currency']}>
            <CurrencySelect id={`${id}-currency`} label={currencyLabel} onChange={onChangeCurrency} value={currency} />
          </div>
        )}
        <div className={classes['price-input__price']}>
          <TextField
            key={currency?.key}
            disabled={disabled}
            InputProps={
              disabledCurrency && {
                startAdornment: <span className={classes['price-input__currency-icon']}>{currency?.label}</span>,
              }
            }
            error={!!message}
            fullWidth={fullWidth}
            id={id}
            label={label}
            name={name}
            onBlur={onBlurPrice}
            onChange={onChangePrice}
            placeholder={placeholder}
            type="number"
            value={value}
          />
        </div>
      </div>
      <ValidationMessage message={{ type: message?.type, message: message?.message }} show={!!message} />
    </FormControl>
  );
};

export default PriceInput;

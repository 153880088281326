const validationSchema = (translations) => ({
  content: {
    presence: { allowEmpty: false, message: translations.noEmpty },
    length: {
      minimum: 10,
      tooShort: translations.toShort,
      maximum: 918,
      tooLong: translations.toLong,
    },
  },
  sign: {
    presence: { allowEmpty: false, message: translations.noEmpty },
    length: {
      minimum: 3,
      tooShort: translations.toShort,
      maximum: 50,
      tooLong: translations.toLong,
    },
  },
});

export default validationSchema;

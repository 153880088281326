import React, { useContext, useRef } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

import { AccountContext } from 'context/account/accountContext';
import { setNotificationsOpenAction } from 'context/panel/panelActions';
import { PanelContext } from 'context/panel/panelContext';
import ProfileMenu from 'containers/ProfileMenu';
import isUserTester from 'helpers/isUserTester';
import UpgradeButton from 'components/UpgradeButton';
import ReleaseNotes from 'containers/ReleaseNotes';
import config from 'config';

import useStyles from './styles';

const Topbar: React.FunctionComponent = () => {
  const { state: accountContext } = useContext(AccountContext);
  const { dispatch } = useContext(PanelContext);
  const buttonRef = useRef(null);
  const isTester = isUserTester();

  const classes = useStyles({});

  const planName = accountContext?.data?.business_data?.active_plan.slug;
  const isTrial = accountContext?.data?.business_data?.trial_active;

  const handleNotificationsOpen = () => {
    setNotificationsOpenAction(dispatch, 'notifications');
  };

  return (
    <AppBar className={classes['topbar']} classes={{ root: classes['topbar__background'] }} position="relative">
      <Toolbar className={classes['topbar__toolbar']}>
        <div className={classes['topbar__content']} />
        {(planName === 'free' || planName === 'free-studio' || isTrial) && <UpgradeButton />}
        <ReleaseNotes />
        {(config.notifications.drawer.isActive || (isTester && config.notifications.drawer.isActiveDev)) && (
          <IconButton color="inherit" onClick={handleNotificationsOpen} ref={buttonRef} size="large">
            <Badge badgeContent={5} color="primary">
              <Icon>notifications</Icon>
            </Badge>
          </IconButton>
        )}
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;

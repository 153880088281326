import client from '../client';
import { DashboardApiInterface } from '../types/dashboard';

const dashboard: DashboardApiInterface = {
  settings: (params) => client.post('/dashboard/settings/get', params),
  getWidgetBySlug: (params) => client.post('/dashboard/get', params),
  getWidget: {
    earnings: (params) => client.post('/dashboard/get', { slug: 'earnings', ...params }),
    workTime: (params) => client.post('/dashboard/get', { slug: 'workTime', ...params }),
    linearChart: (params) => client.post('/dashboard/get', { slug: 'linearChart', ...params }),
    clientSource: (params) => client.post('/dashboard/get', { slug: 'clientSource', ...params }),
    events: (params) => client.post('/dashboard/get', { slug: 'events', ...params }),
  },
};

export default dashboard;

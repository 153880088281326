import { calendarApi } from 'api';
import logError from 'helpers/logError';
import mixpanel from 'helpers/deprecatedMixpanel';
import {
  EnqueueToastActionInterface,
  RemoveToastActionInterface,
  UpdateSchedulerActionInterface,
  SetMobileDeviceTypeActionInterface,
  SetSidebarOpenActionInterface,
  SetDialogOpenActionInterface,
  SetDialogCloseActionInterface,
  SetSurveyOpenActionInterface,
  PostSurveyActionInterface,
  SetElectronViewTypeActionInterface,
  SetNotificationsOpenActionInterface,
  PostEventFinalizationActionInterface,
  SetEventFinalizationOpenActionInterface,
  SetEventLogsOpenActionInterface,
  SetEventFormOpenActionInterface,
  SetEventFormCloseActionInterface,
  PanelAction,
  SetEventRemoveOpenParamsInterface,
  SetClientDetailsOpenParamsInterface,
  EventNotificationInterface,
  EventFormDoneParamsInterface,
  SetEventRemoveDoneParamsInterface,
  SetPhotoModalOpenParamsInterface,
  SetSchedulerViewTypeParamsInterface,
  SetLoginRedirectParamsInterface,
  SetEventDetailsOpenParamsInterface,
  ClearToastStoreActionInterface,
  SetInkPassportOpenActionInterface,
  SetReleaseDetailsOpenParamsInterface,
  BulkSmsFormOpenParamsInterface,
  BulkSmsDetailsOpenParamsInterface,
  SetVoucherFormOpenParamsInterface,
  SetVoucherPreviewOpenParamsInterface,
  SetClientMessageOpenParamsInterface,
} from './types';

export const setMobileDeviceTypeAction: SetMobileDeviceTypeActionInterface = (dispatch, isMobile): void => {
  dispatch({ type: 'SET_MOBILE_DEVICE_TYPE', payload: isMobile });
};

export const setElectronViewTypeAction: SetElectronViewTypeActionInterface = (dispatch, device): void => {
  dispatch({ type: 'SET_DESKTOP_DEVICE_TYPE', payload: device });
};

export const setSidebarOpenAction: SetSidebarOpenActionInterface = (dispatch, isOpen): void => {
  dispatch({ type: 'SET_SIDEBAR_OPEN', payload: isOpen });
};

export const enqueueToastAction: EnqueueToastActionInterface = (dispatch, toast): void => {
  const id = toast.options?.key ? toast.options.key : new Date().getTime() + Math.random();
  dispatch({ type: 'ENQUEUE_TOAST', payload: { ...toast, options: { ...toast.options, key: id } } });
};

export const clearToastStoreAction: ClearToastStoreActionInterface = (dispatch): void => {
  dispatch({ type: 'CLEAR_TOAST_STORE' });
};

export const removeToastAction: RemoveToastActionInterface = (dispatch, id): void => {
  dispatch({ type: 'REMOVE_TOAST', payload: id });
};

export const setClientDetailsOpenAction: PanelAction<SetClientDetailsOpenParamsInterface> = (
  dispatch,
  params,
): void => {
  dispatch({ type: 'SET_CLIENT_DETAILS_OPEN', payload: params });
};

export const setClientDetailsCloseAction: PanelAction = (dispatch): void => {
  dispatch({ type: 'SET_CLIENT_DETAILS_CLOSE' });
};

export const setSurveyOpenAction: SetSurveyOpenActionInterface = (dispatch, isOpen): void => {
  dispatch({ type: 'SET_SURVEY_OPEN', payload: isOpen });
};

export const updateSchedulerAction: UpdateSchedulerActionInterface = (dispatch, params): void => {
  dispatch({ type: 'UPDATE_SCHEDULER', payload: params });
};

export const postSurveyAction: PostSurveyActionInterface = (dispatch, params): void => {
  mixpanel.track('Feedback', params);
  dispatch({ type: 'SET_SURVEY_OPEN', payload: false });
};

export const setEventFinalizationOpenAction: SetEventFinalizationOpenActionInterface = (dispatch, params): void => {
  dispatch({ type: 'SET_EVENT_FINALIZATION_OPEN', payload: params });
};

export const postEventFinalizationAction: PostEventFinalizationActionInterface = async (
  dispatch,
  params,
  preventClose,
) => {
  const { data: finalizationResponse } = await calendarApi.finalize(params);
  dispatch({
    type: 'SET_EVENT_FINALIZATION_DONE',
    payload: { uuid: params.uuid, isCanceled: !!params.cancel_reason, preventClose },
  });
  mixpanel.track('DeprecatedEventRepresentation finalization', params);
  return finalizationResponse;
};

export const setInkPassportOpenAction: SetInkPassportOpenActionInterface = (
  dispatch,
  { uuid, onFailure, onSuccess },
) => {
  dispatch({
    type: 'SET_INKPASSPORT_OPEN',
    payload: { uuid, onFailure, onSuccess },
  });

  mixpanel.track('INKpassport:Open');
};

export const setInkPassportCloseAction: PanelAction = (dispatch) => {
  dispatch({
    type: 'SET_INKPASSPORT_CLOSE',
  });

  mixpanel.track('INKpassport:Open');
};

export const setDialogOpenAction: SetDialogOpenActionInterface = (dispatch, params) => {
  dispatch({ type: 'SET_DIALOG_OPEN', payload: params });
};

export const setDialogCloseAction: SetDialogCloseActionInterface = (dispatch) => {
  dispatch({ type: 'SET_DIALOG_CLOSE' });
};

export const setNotificationsOpenAction: SetNotificationsOpenActionInterface = (dispatch, type) => {
  dispatch({ type: 'SET_NOTIFICATION_OPEN', payload: type });
};

export const setEventLogsOpenAction: SetEventLogsOpenActionInterface = (dispatch, params) => {
  dispatch({
    type: 'SET_EVENT_HISTORY_OPEN',
    payload: { uuid: params.uuid },
  });
};

export const setEventLogsCloseAction: SetEventFormCloseActionInterface = (dispatch) => {
  dispatch({ type: 'SET_EVENT_HISTORY_CLOSE' });
};

export const setBulkSmsFormOpenAction: PanelAction<BulkSmsFormOpenParamsInterface> = (dispatch, params) => {
  dispatch({
    type: 'SET_BULK_SMS_FORM_OPEN',
    payload: { uuid: params?.uuid },
  });
};

export const setBulkSmsFormCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_BULK_SMS_FORM_CLOSE' });
};

export const setBulkSmsDetailsOpenAction: PanelAction<BulkSmsDetailsOpenParamsInterface> = (dispatch, params) => {
  dispatch({
    type: 'SET_BULK_SMS_DETAILS_OPEN',
    payload: { uuid: params?.uuid },
  });
};

export const setBulkSmsDetailsCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_BULK_SMS_DETAILS_CLOSE' });
};

export const setEventFormOpenAction: SetEventFormOpenActionInterface = (dispatch, params) => {
  dispatch({
    type: 'SET_EVENT_FORM_OPEN',
    payload: { field: params.field, event: params.event, isEdit: params.isEdit },
  });
};

export const setEventFormDoneAction: PanelAction<EventFormDoneParamsInterface> = (dispatch, params) => {
  dispatch({
    type: 'SET_EVENT_FORM_DONE',
    payload: { uuid: params.uuid },
  });
};

export const setEventFormCloseAction: SetEventFormCloseActionInterface = (dispatch) => {
  dispatch({ type: 'SET_EVENT_FORM_CLOSE' });
};

export const setEventRemoveOpenAction: PanelAction<SetEventRemoveOpenParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_EVENT_REMOVE_OPEN', payload: params });
};

export const setEventRemoveDoneAction: PanelAction<SetEventRemoveDoneParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_EVENT_REMOVE_DONE', payload: params });
};

export const setEventRemoveCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_EVENT_REMOVE_CLOSE' });
};

export const updateEventNotificationsAction: PanelAction<EventNotificationInterface[]> = (dispatch, notifications) => {
  dispatch({ type: 'UPDATE_EVENT_NOTIFICATION', payload: notifications });
};

export const setPhotoModalOpenAction: PanelAction<SetPhotoModalOpenParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_PHOTO_MODAL_OPEN', payload: params });
};

export const setPhotoModalCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_PHOTO_MODAL_CLOSE' });
};

export const setReleaseDetailsOpenAction: PanelAction<SetReleaseDetailsOpenParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_RELEASE_DETAILS_OPEN', payload: params });
};

export const setReleaseDetailsCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_RELEASE_DETAILS_CLOSE' });
};

export const setEventDetailsOpenAction: PanelAction<SetEventDetailsOpenParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_EVENT_DETAILS_OPEN', payload: params });
};

export const setClientMessageFormOpenAction: PanelAction<SetClientMessageOpenParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_CLIENT_MESSAGE_OPEN', payload: params });
};

export const setClientMessageFormCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_CLIENT_MESSAGE_CLOSE' });
};

export const setEventDetailsCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_EVENT_DETAILS_CLOSE' });
};

export const setVoucherFormOpenAction: PanelAction<SetVoucherFormOpenParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_VOUCHER_FORM_OPEN', payload: params });
};

export const setVoucherFormCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_VOUCHER_FORM_CLOSE' });
};

export const setVoucherPreviewOpenAction: PanelAction<SetVoucherPreviewOpenParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_VOUCHER_PREVIEW_OPEN', payload: params });
};

export const setVoucherPreviewCloseAction: PanelAction = (dispatch) => {
  dispatch({ type: 'SET_VOUCHER_PREVIEW_CLOSE' });
};

export const setSchedulerViewTypeAction: PanelAction<SetSchedulerViewTypeParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_SCHEDULER_VIEW_TYPE', payload: params });
};

export const setLoginRedirectAction: PanelAction<SetLoginRedirectParamsInterface> = (dispatch, params) => {
  dispatch({ type: 'SET_LOGIN_REDIRECT', payload: params });
};

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'sidebar-nav': {
    display: 'flex',
    flexDirection: 'column',
  },
  'sidebar-nav__link-group': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  'sidebar-nav--slim': {
    '& $sidebar-nav__text': {
      opacity: 0,
    },
  },
  'sidebar-nav__text': {
    transition: 'opacity 0.3s ease-in-out',
    whiteSpace: 'nowrap',
  },
  'sidebar-nav__item': {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  'sidebar-nav__item--tester': {
    color: '#ff0',
  },
  'sidebar-nav__button': {
    color: theme.palette.text.primary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightLight,
  },
  'sidebar-nav__nested-item': {
    width: '100%',
    padding: theme.spacing(4, 0),
  },
}));

export default useStyles;

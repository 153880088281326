import { VouchersApiInterface } from '../types/vouchers';
import client from '../client';

const vouchersApi: VouchersApiInterface = {
  get: (uuid) => client.get(`/vouchers/get?voucher_id=${uuid}`),
  list: (employee_id: string) => client.get(`/vouchers/list?employee_id=${employee_id}`),
  clientsList: (customer_id: string) => client.get(`/vouchers/customers?customer_id=${customer_id}`),
  search: (search) => client.get(`/vouchers/search?q=${search.query}&currency=${search.currency}`),
  generateCode: () => client.get('/vouchers/generate/code'),
  submit: (params) => client.post('/vouchers/store', params),
  update: (params) => client.post('/vouchers/update', params),
  delete: (uuid) => client.post('/v2/products/create', { uuid }),
  getDetails: (uuid) => client.get(`/vouchers/get?voucher_id=${uuid}`),
  setActive: (uuid) => client.get(`/vouchers/set/active?voucher_id=${uuid}`),
  setInactive: (uuid) => client.get(`/vouchers/set/inactive?voucher_id=${uuid}`),
  getUsage: (uuid) => client.get(`/vouchers/usage?voucher_id=${uuid}`),
};

export default vouchersApi;

import client from '../client';
import { ClientsApiInterface } from '../types/clients';

const multipartRequest = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const clients: ClientsApiInterface = {
  autocomplete: (query, page, perPage = 5) =>
    client.get(`/customers/autocomplete?q=${query}&page=${page}&per_page=${perPage}`),
  details: (uuid) => client.get(`/v2/customers/get?client_id=${uuid}`),
  history: (uuid) => client.get(`/customers/bookings/history?client_id=${uuid}`),
  create: (params) => client.post('/v2/customers/create', params),
  edit: (params) => client.post('/v2/customers/update', params),
  inspirations: (uuid) => client.get(`/v2/customers/history/images?customer_id=${uuid}`),
  list: (params) => client.post('/v2/customers/list', params),

  notes: {
    list: (uuid) => client.get(`/v2/customers/notes?customer_id=${uuid}`),
    add: (params) => client.post('/customers/notes/store', params),
  },

  tag: {
    autocomplete: (query, page = 1, perPage = 5) =>
      client.get(`/v2/customers/tags/autocomplete?q=${query}&page=${page}&per_page=${perPage}`),
  },

  consents: {
    store: (params) => client.post('/v2/customers/consents/store', params, multipartRequest),
    list: (uuid) => client.get(`/v2/customers/consents/list?customer_id=${uuid}`),
    getPhoto: (uuid) => client.get(`/v2/customers/consents/get?consent_id=${uuid}`, { responseType: 'blob' }),
    getFile: (uuid) =>
      client.get(`/v2/customers/consents/get?consent_id=${uuid}`, {
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
      }),
  },
};

export default clients;

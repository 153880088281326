import { alpha } from '@mui/material/styles';
import Palette from '../palette';

export default {
  underline: {
    backgroundColor: alpha('#222', 0.6),
    '&:before': {
      borderColor: Palette.grey[300],
    },
  },
};

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    whiteSpace: 'pre',
  },

  dropzone__dropbox: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    width: '100%',
    border: `dashed 1px ${theme.palette.black.light}`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(10),
    margin: theme.spacing(2, 0),
    borderRadius: 8,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-1px',
      left: 0,
      width: '0',
      height: '2px',
      backgroundColor: theme.palette.white.dark,
      transition: 'width 0.3s ease-in-out',
    },
    '&:hover:not(:disabled)': {
      '&:after': {
        width: '100%',
      },
    },
    '&:focus:not(:disabled), &:active:not(:disabled)': {
      '&:after': {
        width: '100%',
      },
    },
  },

  dropzone__extension: {
    paddingLeft: theme.spacing(1),

    '&:not(:last-of-type)': {
      '&:after': {
        content: '","',
      },
    },
  },

  dropzone__rejections: {
    marginTop: theme.spacing(10),
  },
}));

export default useStyles;

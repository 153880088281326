import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'drawer-actions': {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
  },
}));

export default useStyles;

import Palette from '../palette';

export default {
  input: {
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },

    '&:disabled': {
      borderBottomStyle: 'none',
      color: Palette.black.light,
      WebkitTextFillColor: Palette.black.light,
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&$disabled': {
      '&:before': {
        borderBottomStyle: 'none',
        color: Palette.black.light,
      },
    },
  },
};

export default {
  root: {
    fontSize: '12px',
    fontWeight: '400 !important',
    lineHeight: 1.33,
  },
  badge: {
    height: 16,
    minWidth: 16,
  },
  dot: {
    height: 8,
    minWidth: 8,
  },
};

const isElectron = () => {
  // Renderer process
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment

  // return !!import.meta.env.VITE_APP_IS_ELECTRON;

  if (window?.location?.href && !window.location.href.startsWith('file:')) {
    return false;
  }

  if (process?.env.VITE_APP_IS_ELECTRON) {
    return true;
  }

  // @ts-ignore
  if (typeof window !== 'undefined' && window?.process?.type === 'renderer') {
    return true;
  }

  if (
    typeof window?.process !== 'undefined' &&
    typeof window.process.versions === 'object' &&
    !!window.process.versions.electron
  ) {
    return true;
  }

  // PageLayout process
  // Detect the user agent when the `nodeIntegration` option is set to false
  return (
    typeof window.navigator === 'object' &&
    typeof window.navigator.userAgent === 'string' &&
    window.navigator.userAgent.indexOf('Electron') >= 0
  );
};

export default isElectron;

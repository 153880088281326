import React, { useContext, useRef } from 'react';
import clsx from 'classnames';

import BottomNavigation from 'components/BottomNavigation';
import { PanelContext } from 'context/panel/panelContext';
import { setSidebarOpenAction } from 'context/panel/panelActions';

import { Sidebar, Topbar } from './components';
import useStyles from './styles';

export interface PropsInterface {
  children?: React.ReactNode;
  slim?: boolean;
  fluid?: boolean;
  padding?: boolean;
}

const PanelPageLayout: React.FunctionComponent<PropsInterface> = (props) => {
  const { children, slim, fluid, padding } = props;
  const { state, dispatch } = useContext(PanelContext);
  const layoutRef = useRef(null);

  const classes = useStyles();

  const handleSidebarClose = () => {
    setSidebarOpenAction(dispatch, false);
  };

  return (
    <div
      className={clsx({
        [classes.layout]: true,
        [classes['layout--desktop']]: !state.isMobile,
        [classes['layout--mobile']]: state.isMobile,
        [classes['layout--fluid']]: fluid,
        [classes['layout--padding']]: padding,
        [classes['layout--shift-slim']]: slim && !state.isMobile,
      })}
      ref={layoutRef}
    >
      <Sidebar
        onClose={handleSidebarClose}
        open={state.isSidebarOpen}
        slim={slim && !state.isMobile}
        variant={!state.isMobile ? 'persistent' : 'temporary'}
      />
      <Topbar />
      <main className={classes['layout-content']}>
        {children}
        <BottomNavigation />
      </main>
    </div>
  );
};

export default PanelPageLayout;

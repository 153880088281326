import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Drawer } from '@mui/material';

import InkLogo from 'assets/img/logo-svg.svg?component';
import LanguageSwitcher from 'components/LanguageSwitcher';
import Paragraph from 'components/Paragraph';
import useAccount from 'helpers/hooks/useAccount';
import isElectron from 'helpers/electron/isElectron';
import layoutValues from 'theme/constatns';

import useStyles from './styles';
import SidebarNav from './components/SidebarNav';
import packageInfo from '../../../../../package.json';

interface PropsInterface {
  open: boolean;
  variant: 'permanent' | 'persistent' | 'temporary';
  onClose(): void;
  slim?: boolean;
}

const Sidebar: React.FunctionComponent<PropsInterface> = ({ open, variant, onClose, slim }) => {
  const { state: account } = useAccount();

  const classes = useStyles({});

  return (
    <Drawer
      anchor="left"
      classes={{ paper: `${classes.drawer} ${slim && classes['drawer--slim']}` }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={cx(classes.sidebar, {
          [classes['sidebar--electron']]: isElectron(),
        })}
      >
        <div className={cx(classes.sidebar__content, { [classes['sidebar__content--slim']]: slim })}>
          <div className={cx({ [classes['sidebar__logo']]: slim })}>
            <Link to="/">
              <InkLogo height={layoutValues.sidebar.logoSize} />
            </Link>
          </div>
          <SidebarNav slim={slim} profileRole={account?.data?.roles_check} />
        </div>
        <div
          className={cx(classes.sidebar__content, classes.sidebar__footer, {
            [classes['sidebar__content--slim']]: slim,
          })}
        >
          <div className={classes['sidebar__footer']}>
            <LanguageSwitcher />
            <Paragraph type="size-XS-regular" alignment="center">
              v{packageInfo.version}
            </Paragraph>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import differenceBy from 'lodash/differenceBy';

import Autocomplete from 'components/Autocomplete';
import { InputMessageType } from 'types';
import { clientsApi } from 'api';
import { AutocompleteChangeType } from '../Autocomplete/Autocomplete';

interface PropsInterface {
  value?: { uuid?: string; label: string }[];
  disabled?: boolean;
  placeholder?: string;
  label: string;
  onChange: AutocompleteChangeType;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  message?: InputMessageType;
  preventCreate?: boolean;
  helperText?: string;
}

const ClientTagsAutocomplete: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { value, disabled, placeholder, label, onChange, onBlur, message, preventCreate, helperText } = props;

  const [autocomplete, setAutocomplete] = useState({
    options: [],
    inputValue: value || [],
    autocompleteValue: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value?.length < 100) {
      setAutocomplete((prevState) => ({ ...prevState, autocompleteValue: value }));
    }
  };

  const handleTagAutocompleteChange: AutocompleteChangeType = (event, value) => {
    const newValue = typeof value === 'string' ? { label: value } : value;
    setAutocomplete((prevState) => ({ ...prevState, inputValue: newValue }));
    onChange(event, newValue);
  };

  const handleTagsAutocompleteRequest = useCallback(
    async (query = '') => {
      const { data: tagResponse } = await clientsApi.tag.autocomplete(query);
      setAutocomplete((prevState) => ({
        ...prevState,
        options: differenceBy(tagResponse, autocomplete.inputValue, 'uuid'),
      }));
    },
    [autocomplete.inputValue],
  );

  const handleTagsAutocompleteFocus = () => {
    handleTagsAutocompleteRequest();
  };

  useEffect(() => {
    if (autocomplete.autocompleteValue) {
      handleTagsAutocompleteRequest(autocomplete.autocompleteValue);
    }
  }, [autocomplete.autocompleteValue]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option?.label || option}
      disabled={disabled}
      inputProps={{
        disabled,
        placeholder,
        label,
        name: 'tagAutocomplete',
        onChange: handleInputChange,
        onBlur,
      }}
      helperText={helperText}
      message={message}
      multiple
      onBlur={onBlur}
      onChange={handleTagAutocompleteChange}
      onFocus={handleTagsAutocompleteFocus}
      options={[
        ...autocomplete.options,
        autocomplete.autocompleteValue?.length > 0 &&
          !preventCreate && { label: autocomplete.autocompleteValue, uuid: null },
      ].filter((value) => !!value)}
      value={autocomplete.inputValue}
      isOptionEqualToValue={(option, value) => option?.label === value?.label}
    />
  );
};

export default ClientTagsAutocomplete;

import getUserCustomLang from 'helpers/intl/getUserCustomLang';

const authorize = (token?: string) => ({
  headers: {
    Authorization: `Bearer ${token || window?.localStorage?.getItem('biz_token')}`,
    'accept-language': getUserCustomLang(),
  },
});

export default authorize;

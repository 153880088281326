import { USER_ROLES } from './constants/constants';
import SUBSCRIPTION_PLAN_TYPES from './constants/subscription';
import { AccessVariantsType } from './api/types/account';
import { AccountRoleType } from './types';
import VOUCHERS_FORM_FIELDS from './containers/Account/AccountVouchers/config';

export const availableLanguages = ['pl', 'de', 'en', 'uk', 'ru', 'es', 'it', 'fr'];

export type BizRouteType = {
  key: string;
  href: string;
  path?: string;
  isManagerAccess?: boolean;
  isPrivate?: boolean;
};

export const bizRoutes = {
  guide: {
    key: 'guide',
    href: '/guide',
  },
  dashboard: {
    guide: true,
    key: 'dashboard',
    href: '/dashboard',
  },
  calendar: {
    key: 'calendar',
    href: '/calendar',
    pages: {
      reception: {
        key: 'reception',
        href: '/calendar/r',
        path: '/r',
      },
      month: {
        key: 'month',
        href: '/calendar/m',
        path: '/m',
      },
      week: {
        key: 'week',
        href: '/calendar/w',
        path: '/w',
      },
      day: {
        key: 'day',
        href: '/calendar/d',
        path: '/d',
      },
      list: {
        key: 'list',
        href: '/calendar/l',
        path: '/l',
      },
    },
  },
  clients: {
    guide: true,
    key: 'clients',
    href: '/clients',
  },
  shop: {
    key: 'shop',
    href: '/shop',
    pages: {
      needles: {
        key: 'needles',
        href: '/shop/needles',
      },
      inks: {
        key: 'inks',
        href: '/shop/inks',
      },
      supplies: {
        key: 'supplies',
        href: '/shop/supplies',
      },
      product: {
        key: 'product',
        href: '/shop/product',
      },
      order: {
        key: 'order',
        href: '/shop/order',
      },
      orderFull: {
        key: 'order-full',
        href: '/shop/order/full',
      },
    },
  },
  summits: {
    key: 'summits',
    href: '/summits',
    pages: {
      list: {
        key: 'summits-list',
        href: '/summits',
      },
      product: {
        key: 'summits-event-details',
        href: '/summits/details',
      },
      convention: {
        key: 'convention',
        href: '/summits/convention',
      },
      training: {
        key: 'training',
        href: '/summits/training',
      },
      all: {
        key: 'all',
        href: '/summits/all',
      },
    },
  },
  assortment: {
    key: 'assortment',
    href: '/assortment',
  },
  jobBoard: {
    key: 'job-board',
    href: '/job-board',
    pages: {
      offer: {
        key: 'job-offer',
        href: '/job-board/offer',
      },
    },
  },
  shopCheckout: {
    key: 'shop-checkout',
    href: '/shop-checkout',
    pages: {
      address: {
        key: 'address',
        href: '/shop-checkout/address',
      },
      confirm: {
        key: 'confirm',
        href: '/shop-checkout/confirm',
      },
      done: {
        key: 'done',
        href: '/shop-checkout/done',
      },
      fail: {
        key: 'fail',
        href: '/shop-checkout/payment-fail',
      },
    },
  },
  scheduler: {
    guide: true,
    key: 'scheduler',
    href: '/scheduler',
  },
  chat: {
    hideMobile: true,
    guide: true,
    key: 'chat',
    href: '/chat',
  },
  employees: {
    guide: true,
    key: 'users',
    href: '/employees',
    submenu: true,
    pages: {
      menu: {
        key: 'employee-menu',
        href: '/employees/menu',
      },
      basic: {
        key: 'basic',
        href: '/employees/basic',
      },
      availability: {
        key: 'availability',
        href: '/employees/availability',
      },
      access: {
        key: 'access',
        href: '/employees/access',
        isManagerAccess: true,
      },
      services: {
        key: 'services',
        href: '/employees/services',
      },
      vouchers: {
        key: 'vouchers',
        href: '/employees/vouchers',
      },
      booking: {
        key: 'booking',
        href: '/employees/booking',
      },
      portfolio: {
        key: 'portfolio',
        href: '/employees/portfolio',
      },
      passwordChange: {
        key: 'passwordChange',
        href: '/employees/passwordChange',
        isPrivate: true,
      },
      studio: {
        key: 'studio',
        href: '/employees/studio',
      },
      sms: {
        key: 'sms',
        href: '/employees/sms',
        isManagerAccess: true,
      },
    },
  },
  bookingsGroup: {
    key: 'booking-list',
    href: '/marketplace-bookings',
  },
  settings: {
    submenu: true,
    pages: {
      basic: {
        key: 'basic',
        href: '/settings/basic',
      },
      public: {
        key: 'public',
        href: '/settings/public-profile',
      },
      currentPlan: {
        key: 'currentPlan',
        href: '/settings/current-plan',
      },
      subscription: {
        key: 'subscription',
        href: '/settings/subscription',
      },
      sms: {
        key: 'additional-sms',
        href: '/settings/additional-sms',
      },
      smsManagement: {
        key: 'sms',
        href: '/settings/sms',
      },
      bulkSms: {
        key: 'bulk',
        href: '/settings/bulk',
      },
      consents: {
        key: 'consents',
        href: '/settings/consents',
      },
      help: {
        key: 'help',
        href: '/settings/help',
      },
    },
    key: 'settings',
    href: '/settings',
  },
  auth: {
    key: 'auth',
    href: '/auth',
  },
  desktopAppRedirect: {
    key: 'desktop-app-redirect',
    href: '/desktop-app-redirect',
  },
  onboarding: {
    key: 'onboarding',
    href: '/onboarding',
    pages: {
      roleSelect: {
        key: 'role-select',
        href: '/onboarding/role-select',
      },
      phoneVerification: {
        key: 'phone-verification',
        href: '/onboarding/phone-verification',
      },
    },
  },
  profile: {
    submenu: true,
    pages: {
      basic: {
        key: 'basic',
        href: '/profile/basic',
      },
      availability: {
        key: 'availability',
        href: '/profile/availability',
      },
      access: {
        key: 'access',
        href: '/profile/access',
        isManagerAccess: true,
      },
      services: {
        key: 'services',
        href: '/profile/services',
      },
      vouchers: {
        key: 'vouchers',
        href: '/profile/vouchers',
      },
      booking: {
        key: 'booking',
        href: '/profile/booking',
      },
      portfolio: {
        key: 'portfolio',
        href: '/profile/portfolio',
      },
      passwordChange: {
        key: 'passwordChange',
        href: '/profile/passwordChange',
        isPrivate: true,
      },
      studio: {
        key: 'studio',
        href: '/profile/studio',
      },
      sms: {
        key: 'sms',
        href: '/profile/sms',
        isManagerAccess: true,
      },
    },
    key: 'profile',
    href: '/profile',
  },
  passwordRecovery: {
    key: 'password-recovery',
    href: '/password-recovery',
  },
  passwordRecoveryApproved: {
    key: 'password-recovery-approved',
    href: '/password-recovery-approved',
  },
  passwordRecoveryValidation: {
    key: 'password-recovery-validation',
    href: '/password-recovery-validation',
  },
  studioInvitation: {
    key: 'studio-invitation',
    href: '/studio-invitation',
    pages: {
      accept: {
        key: 'studio-invitation-accept',
        href: '/studio-invitation/accept',
      },
      proceed: {
        key: 'studio-invitation-proceed',
        href: '/studio-invitation/proceed',
      },
    },
  },
  storybook: {
    key: 'storybook',
    href: '/storybook',
  },
  partnership: {
    key: 'partnership',
    href: '/partnership',
  },
} as const;

export type NavigationConfigurationRouteType = {
  route: BizRouteType;
  roleAccess?: AccountRoleType[];
  grantedAccess?: {
    slug: AccessVariantsType;
    value: string | string[];
  };
  requiresUuid?: boolean;
};

export type NavigationConfigRouteGroupType = {
  [key: string]:
    | NavigationConfigurationRouteType
    | {
        route: NavigationConfigurationRouteType['route'];
        nestedRoutes: { [nestedRoutesKey: string]: NavigationConfigurationRouteType[] };
      };
};

type NavigationConfigType = {
  general: NavigationConfigRouteGroupType;
  ecommerce: NavigationConfigRouteGroupType;
  account: NavigationConfigRouteGroupType;
  other: NavigationConfigRouteGroupType;
};

export const navigationConfig: NavigationConfigType = {
  general: {
    dashboard: {
      route: bizRoutes.dashboard,
    },
    calendar: {
      route: bizRoutes.calendar,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
    clients: {
      route: bizRoutes.clients,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
    chat: {
      route: bizRoutes.chat,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
    users: {
      route: bizRoutes.employees,
      roleAccess: [USER_ROLES.reception, USER_ROLES.manager, USER_ROLES.artist, USER_ROLES.piercer],
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
      nestedRoutes: {
        profile: [
          {
            route: bizRoutes.employees.pages.basic,
            grantedAccess: { slug: 'employee_data_profile_category_access', value: 'full_access' },
            requiresUuid: true,
          },
          {
            route: bizRoutes.employees.pages.availability,
            grantedAccess: { slug: 'employee_data_profile_category_access', value: 'full_access' },
            requiresUuid: true,
          },
          {
            route: bizRoutes.employees.pages.booking,
            roleAccess: [USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'employee_data_profile_category_access', value: 'full_access' },
            requiresUuid: true,
          },
          {
            route: bizRoutes.employees.pages.portfolio,
            roleAccess: [USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'employee_data_portfolio_access', value: ['full_access', 'read_only'] },
            requiresUuid: true,
          },
        ],
        studio: [
          {
            route: bizRoutes.employees.pages.access,
            roleAccess: [USER_ROLES.reception, USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'employee_data_studio_category_access', value: 'full_access' },
            requiresUuid: true,
          },
          {
            route: bizRoutes.employees.pages.studio,
            roleAccess: [USER_ROLES.reception, USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'employee_data_studio_category_access', value: 'full_access' },
            requiresUuid: true,
          },
          {
            route: bizRoutes.employees.pages.sms,
            roleAccess: [USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'employee_data_studio_category_access', value: 'full_access' },
            requiresUuid: true,
          },
        ],
        sales: [
          {
            route: bizRoutes.employees.pages.services,
            roleAccess: [USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'employee_data_profile_category_access', value: 'full_access' },
            requiresUuid: true,
          },
          {
            route: bizRoutes.employees.pages.vouchers,
            roleAccess: [USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'employee_data_profile_category_access', value: 'full_access' },
            requiresUuid: true,
          },
        ],
      },
    },
    jobBoard: {
      route: bizRoutes.jobBoard,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
  },
  ecommerce: {
    shop: {
      route: bizRoutes.shop,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
    partners: {
      route: bizRoutes.partnership,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
    summits: {
      route: bizRoutes.summits,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
  },
  account: {
    profile: {
      route: bizRoutes.profile,
      nestedRoutes: {
        profile: [
          {
            route: bizRoutes.profile.pages.basic,
            requiresUuid: false,
          },
          {
            route: bizRoutes.profile.pages.availability,
            requiresUuid: false,
          },
          {
            route: bizRoutes.profile.pages.booking,
            roleAccess: [USER_ROLES.manager, USER_ROLES.artist, USER_ROLES.piercer],
            requiresUuid: false,
          },
          {
            route: bizRoutes.profile.pages.portfolio,
            roleAccess: [USER_ROLES.manager, USER_ROLES.artist, USER_ROLES.piercer],
            requiresUuid: false,
          },
          {
            route: bizRoutes.profile.pages.passwordChange,
            requiresUuid: false,
          },
        ],
        studio: [
          {
            route: bizRoutes.profile.pages.studio,
            roleAccess: [USER_ROLES.reception, USER_ROLES.artist, USER_ROLES.piercer],
            requiresUuid: false,
          },
        ],
        sales: [
          {
            route: bizRoutes.profile.pages.services,
            roleAccess: [USER_ROLES.manager, USER_ROLES.artist, USER_ROLES.piercer],
            requiresUuid: true,
          },
          {
            route: bizRoutes.profile.pages.vouchers,
            roleAccess: [USER_ROLES.manager, USER_ROLES.artist, USER_ROLES.piercer],
            requiresUuid: true,
          },
        ],
      },
    },
    settings: {
      route: bizRoutes.settings,
      nestedRoutes: {
        personalisation: [
          {
            route: bizRoutes.settings.pages.basic,
          },
          {
            route: bizRoutes.settings.pages.public,
            roleAccess: [USER_ROLES.manager, USER_ROLES.reception, USER_ROLES.artist],
          },
        ],
        subscriptions: [
          {
            route: bizRoutes.settings.pages.currentPlan,
          },
          {
            route: bizRoutes.settings.pages.subscription,
          },
          {
            route: bizRoutes.settings.pages.sms,
          },
        ],
        clientCommunication: [
          {
            route: bizRoutes.settings.pages.smsManagement,
            roleAccess: [USER_ROLES.manager, USER_ROLES.artist, USER_ROLES.piercer],
          },
          {
            route: bizRoutes.settings.pages.bulkSms,
            roleAccess: [USER_ROLES.manager, USER_ROLES.artist, USER_ROLES.piercer],
            grantedAccess: { slug: 'marketing_sms_access', value: ['full_access', 'read_only'] },
          },
        ],
        'ink-passport': [
          {
            route: bizRoutes.assortment,
            grantedAccess: {
              slug: 'employee_data_access',
              value: ['full_access', 'read_only'],
            },
          },
        ],
        other: [
          {
            route: bizRoutes.settings.pages.consents,
          },
          {
            route: bizRoutes.settings.pages.help,
          },
        ],
      },
    },
  },
  other: {
    reservations: {
      route: bizRoutes.bookingsGroup,
      grantedAccess: {
        slug: 'employee_data_access',
        value: ['full_access', 'read_only'],
      },
    },
  },
};

export const menuProfilePagesToDisplay = [bizRoutes.profile, bizRoutes.settings];

export const appBarOptions = [bizRoutes.dashboard, bizRoutes.calendar, bizRoutes.clients, bizRoutes.profile];

export const subscriptionSteps = {
  planSelect: { key: 'planSelect', isRequired: true },
  billingPeriod: { key: 'billingPeriod', isRequired: true },
  invoice: { key: 'invoice', isRequired: true },
  payment: { key: 'payment', isRequired: true },
};

export const onboardingSteps = {
  'role-select': {
    key: 'role-select',
    href: '/onboarding/role-select',
  },
  'phone-verification': {
    key: 'phone-verification',
    href: '/onboarding/phone-verification',
  },
  finale: {
    key: 'finale',
    href: '/onboarding/finale',
  },
};

const defaultAccessRoles = [USER_ROLES['artist'], USER_ROLES['manager'], USER_ROLES['piercer']];

//  Schema:
//    Feature Access Global: { [feature]: { isActive: boolean, isActiveDev: boolean } }
//    Feature Access Role: { [feature]: access: role[], ...customOptions }
//    Feature Access Steps Role: { access: role[], ...customOptions }[]

export type TablePreviewColumnType = {
  slug: string;
  span: number;
  justifyContent?: 'center';
};

export type TablePreviewColumnsType = {
  [key: string]: {
    slug: string;
    previewColumns: TablePreviewColumnType[];
  };
};

export const SERVICE_FORM_FIELDS: TablePreviewColumnsType = {
  description: {
    slug: 'description',
    previewColumns: [{ slug: 'description', span: 1 }],
  },
  duration: {
    slug: 'duration',
    previewColumns: [{ slug: 'duration', span: 1 }],
  },
  name: {
    slug: 'name',
    previewColumns: [{ slug: 'name', span: 2 }],
  },
  payment: {
    slug: 'payment',
    previewColumns: [
      { slug: 'price', span: 1 },
      { slug: 'deposit', span: 1 },
      { slug: 'currency', span: 2 },
    ],
  },
  color: {
    slug: 'color',
    previewColumns: [{ slug: 'color', span: 1 }],
  },
  isFullDay: {
    slug: 'isFullDay',
    previewColumns: [],
  },
};

//    Feature Settings:
export default {
  dashboard: {
    earnings: {
      access: defaultAccessRoles,
    },
    workTime: {
      access: defaultAccessRoles,
    },
    linearChart: {
      access: defaultAccessRoles,
    },
  },
  subscription: {
    steps: [subscriptionSteps.planSelect, subscriptionSteps.billingPeriod, subscriptionSteps.payment],
  },
  account: {
    isActive: true,
    fields: {
      aboutMe: {
        access: ['artist', 'manager'],
        isPrivate: false,
      },
      userLanguages: {
        access: defaultAccessRoles,
        isPrivate: false,
      },
      userCategories: {
        access: ['artist', 'manager'],
        isPrivate: false,
      },
      placeName: {
        access: ['artist', 'piercer'],
        isPrivate: false,
      },
    },
    tab: {
      studio: {
        employee: ['leave'],
        studiosEmployee: ['color', 'access', 'remove'],
      },
    },
  },
  notifications: {
    isActive: true,
    isActiveDev: true,
    drawer: {
      isActive: false,
      isActiveDev: true,
    },
  },
  stalking: {
    mixpanel: {
      isActive: true,
      isActiveDev: true,
    },
  },
  onboarding: {
    steps: [{ key: onboardingSteps['phone-verification'].key, access: defaultAccessRoles }],
  },
  calendar: {
    combineViewSwitch: {
      access: [USER_ROLES.artist, USER_ROLES.piercer, USER_ROLES.reception],
    },
    forceCombineView: {
      access: [USER_ROLES.manager],
    },
    view: {
      list: {
        access: [...defaultAccessRoles, USER_ROLES.reception],
      },
      month: {
        access: [...defaultAccessRoles, USER_ROLES.reception],
      },
      week: {
        access: [...defaultAccessRoles, USER_ROLES.reception],
      },
      reception: {
        access: [USER_ROLES.manager, USER_ROLES.reception],
      },
    },
  },
  employees: {
    access: ['manager', 'reception'],
  },
  clientCommunication: {
    bulk: {
      planAccess: [
        SUBSCRIPTION_PLAN_TYPES.premiumStudio,
        SUBSCRIPTION_PLAN_TYPES.smallStudio,
        SUBSCRIPTION_PLAN_TYPES.soloArtist,
      ],
    },
  },
  phoneDial: {
    allowed: ['pl', 'fr', 'uk', 'uk', 'tr', 'it'],
  },
  eventFrom: {
    version: 2,
    services: {
      searchMinItems: 10,
    },
  },
  services: {
    planLimit: {
      amount: {
        [SUBSCRIPTION_PLAN_TYPES.freeArtist]: 5,
        [SUBSCRIPTION_PLAN_TYPES.freeStudio]: 5,
        [SUBSCRIPTION_PLAN_TYPES.soloArtist]: 100,
        [SUBSCRIPTION_PLAN_TYPES.premiumStudio]: 100,
        [SUBSCRIPTION_PLAN_TYPES.smallStudio]: 100,
      },
    },
    categories: {
      session: {
        slug: 'session',
        formFields: {
          name: {
            slug: SERVICE_FORM_FIELDS.name.slug,
            isRequired: true,
            tableColumnSpan: 1,
          },
          payment: {
            slug: SERVICE_FORM_FIELDS.payment.slug,
            isRequired: true,
            tableColumnSpan: 2,
          },
          duration: {
            slug: SERVICE_FORM_FIELDS.duration.slug,
            isRequired: true,
            tableColumnSpan: 1,
          },
          color: {
            slug: SERVICE_FORM_FIELDS.color.slug,
            isRequired: false,
            tableColumnSpan: 1,
          },
        },
      },
      piercing: {
        slug: 'piercing',
        formFields: {
          name: {
            slug: SERVICE_FORM_FIELDS.name.slug,
            isRequired: true,
          },
          payment: {
            slug: SERVICE_FORM_FIELDS.payment.slug,
          },
          duration: {
            slug: SERVICE_FORM_FIELDS.duration.slug,
            isRequired: true,
          },
          color: {
            slug: SERVICE_FORM_FIELDS.color.slug,
            isRequired: false,
          },
        },
      },
      consult: {
        slug: 'consult',
        formFields: {
          name: {
            slug: SERVICE_FORM_FIELDS.name.slug,
            isRequired: true,
          },
          payment: {
            slug: SERVICE_FORM_FIELDS.payment.slug,
            isRequired: false,
          },
          duration: {
            slug: SERVICE_FORM_FIELDS.duration.slug,
            isRequired: true,
          },
          color: {
            slug: SERVICE_FORM_FIELDS.color.slug,
            isRequired: false,
          },
        },
      },
      other: {
        slug: 'other',
        formFields: {
          name: {
            slug: SERVICE_FORM_FIELDS.name.slug,
            isRequired: true,
          },
          duration: {
            slug: SERVICE_FORM_FIELDS.duration.slug,
            isRequired: true,
          },
          color: {
            slug: SERVICE_FORM_FIELDS.color.slug,
            isRequired: false,
          },
          isFullDay: {
            slug: SERVICE_FORM_FIELDS.isFullDay.slug,
            isRequired: false,
          },
        },
      },
    },
  },
  vouchers: {
    categories: {
      discount: {
        slug: 'discount',
        previewColumns: {
          name: VOUCHERS_FORM_FIELDS.name,
          promoCode: VOUCHERS_FORM_FIELDS.promoCode,
          value: VOUCHERS_FORM_FIELDS.value,
          expiration: VOUCHERS_FORM_FIELDS.expiration,
          quantity: VOUCHERS_FORM_FIELDS.quantity,
        },
      },
      voucher: {
        slug: 'voucher',
        previewColumns: {
          name: VOUCHERS_FORM_FIELDS.name,
          promoCode: VOUCHERS_FORM_FIELDS.promoCode,
          value: VOUCHERS_FORM_FIELDS.value,
          expiration: VOUCHERS_FORM_FIELDS.expiration,
        },
      },
    },
  },
  jobOffers: {
    categories: ['artist', 'piercer', 'manager', 'receptionist', 'apprentice', 'other'],
  },
};

export const dialIndicatorConfig = {
  size: 48,
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { AccountRoleType } from 'types';
import ConditionalWrapper from 'components/ConditionalWrapper';
import PhoneNumber from 'components/PhoneNumber';
import Paragraph from 'components/Paragraph';
import Avatar from 'components/Avatar';
import Card, { CardColorOptionsType } from 'components/Card';

import useStyles from './styles';

export type ClientRepresentationClientType = {
  color?: string;
  avatar?: string;
  avatarIcon?: React.ReactNode;
  role?: AccountRoleType;
  name: string;
  street?: string;
  postCode?: string;
  city?: string;
  country?: string;
  phone?: string;
  mail?: string;
  birth?: string;
};

interface PropsInterface {
  plain?: boolean;
  client: ClientRepresentationClientType;
  action?: React.ReactNode;
  onClick?(): void;
  color?: CardColorOptionsType;
}

const ClientRepresentation: React.FunctionComponent<PropsInterface> = ({ plain, client, action, onClick, color }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  if (!client) {
    return <div>empty...</div>;
  }

  return (
    <ConditionalWrapper condition={!plain} wrapper={(children) => Card({ children, slim: true, color, onClick })}>
      <div className={classes['client-representation']}>
        <div className={classes['client-representation__avatar']}>
          <Avatar icon={client.avatarIcon} src={client.avatar} avatarName={client.name} size={40} />
        </div>
        <div className={classes['client-representation__content']}>
          <Paragraph type="size-S-bold">
            <strong>{client.name}</strong>
          </Paragraph>
          {client.role && (
            <Paragraph type="size-S-medium" color="secondary">
              {client.role}
            </Paragraph>
          )}
          {(client.street || client.city || client.country) && (
            <Paragraph type="size-S-medium" color="secondary">
              {client.street && (
                <>
                  <span>{client.street}</span>
                  <br />
                </>
              )}
              {client.city && (
                <>
                  <span>
                    <span>{client.postCode}</span> <span>{client.city}</span>
                  </span>
                  <br />
                </>
              )}
              {client.country && <span>{client.country}</span>}
            </Paragraph>
          )}
          {(client.phone || client.mail) && (
            <Paragraph type="size-S-medium" color="secondary">
              {client.phone && (
                <>
                  <PhoneNumber>{client.phone}</PhoneNumber>
                  <br />
                </>
              )}
              {client.mail && <span>{client.mail}</span>}
            </Paragraph>
          )}
          {client.birth && (
            <Paragraph type="size-S-medium" color="secondary">
              <span>
                {t('components.clientRepresentation.birth')}: {client.birth}
              </span>
            </Paragraph>
          )}
        </div>
        {action && <div className={classes['client-representation__action']}>{action}</div>}
      </div>
    </ConditionalWrapper>
  );
};

export default ClientRepresentation;

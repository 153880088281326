import React, { ChangeEvent, useCallback, useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import validate from 'validate.js';

import useSmsCounter from 'helpers/apiHooks/useSmsCounter';
import { CheckChangeType } from 'types';

import { BulkSmsFormInitialState, BulkSmsInitialErrorState } from '../constatns';
import { BulkSmsErrorInterface, BulkSmsFormInitialStateInterface } from '../types';
import validationSchema from '../validationSchema';

const useClientMessageForm = (accountName: string) => {
  const { smsCounter, handleSmsCount } = useSmsCounter(accountName);

  const [formValue, setFormValue] = useState<BulkSmsFormInitialStateInterface>({
    ...BulkSmsFormInitialState,
    sign: accountName,
  });
  const [errors, setErrors] = useState<BulkSmsErrorInterface>(BulkSmsInitialErrorState);

  const { t } = useTranslation(['common', 'page-profile']);

  const validation = validationSchema({
    noEmpty: t('validators.common.notEmpty'),
    toLong: t('validators.common.toLong'),
    toShort: t('validators.common.toShort'),
    notValid: t('validators.common.notValid'),
  });

  const handleValidateSingle = useCallback(
    (value, name) => {
      if (name === 'phone') {
        const isValid = isValidPhoneNumber(value || '');
        if (!isValid) {
          setErrors((prevState) => ({ ...prevState, phone: t('validators.phone.notValid') }));
        } else {
          setErrors((prevState) => ({ ...prevState, phone: undefined }));
        }

        return !isValid;
      }
      if (name === 'date') {
        if (value instanceof Date) {
          setErrors((prevState) => ({ ...prevState, date: null }));
        } else {
          setErrors((prevState) => ({ ...prevState, date: t('validators.common.notEmpty') }));
        }

        return !(value instanceof Date);
      }
      if (name === 'time') {
        if (value?.length > 0) {
          setErrors((prevState) => ({ ...prevState, time: null }));
        } else {
          setErrors((prevState) => ({ ...prevState, time: t('validators.common.notEmpty') }));
        }

        return !(typeof value === 'string');
      }

      const validationResult = validate.single(value, validation[name])?.[0];

      setErrors((prevValue) => ({
        ...prevValue,
        [name]: validationResult,
      }));

      return !!validationResult;
    },
    [validation, t],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (name === 'content') {
        handleSmsCount(`${value} ${formValue.sign}`);
      }

      if (name === 'sign') {
        handleSmsCount(`${formValue.content} ${value}`);
      }

      setFormValue((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [formValue.content, formValue.sign],
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      handleValidateSingle(value, name);
    },
    [t],
  );

  const handleCheckChange: CheckChangeType = useCallback((event, checked) => {
    const { name } = event.target;

    if (name) {
      setFormValue((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  }, []);

  const handleTimeChange = useCallback((value: string) => {
    handleValidateSingle(value, 'time');
    setFormValue((prevState) => ({ ...prevState, time: value }));
  }, []);

  const handleSendDateChange = useCallback((date: Date) => {
    handleValidateSingle(date, 'date');
    setFormValue((prevState) => ({ ...prevState, date }));
  }, []);

  const handleValidateAll = useCallback(() => {
    const validateResult = validate(formValue, validation, { fullMessages: false });
    if (!validateResult) {
      return true;
    }

    setErrors((prevValue) => ({ ...prevValue, content: validateResult.content?.[0], sign: validateResult.sign?.[0] }));
    return !validateResult;
  }, [formValue, t]);

  const resetForm = () => {
    setFormValue({ ...BulkSmsFormInitialState, sign: accountName });
    setErrors(BulkSmsInitialErrorState);
  };

  const setForm = (form: Partial<BulkSmsFormInitialStateInterface>) => {
    setFormValue((prevState) => ({ ...prevState, ...form }));
  };

  return {
    formValue,
    smsCounter,
    errors,
    handleInputChange,
    handleInputBlur,
    handleTimeChange,
    handleSendDateChange,
    handleCheckChange,
    handleValidateSingle,
    handleValidateAll,
    resetForm,
    setForm,
  } as const;
};

export default useClientMessageForm;

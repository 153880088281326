import client from '../client';
import { MarketingApiInterface } from '../types/marketing';

const marketing: MarketingApiInterface = {
  campaign: {
    get: ({ uuid }) => client.get(`/user_marketing/sms_campaign/get?campaign_id=${uuid}`),
    create: (params) => client.post('/user_marketing/sms_campaign/create', params),
    delete: (params) => client.post('/user_marketing/sms_campaign/delete', params),
    recipients: (params) => client.post('/user_marketing/sms_campaign/recipients/count', params),
    recipientsList: (params) =>
      client.get(`/user_marketing/sms_campaign/recipients/list?campaign_id=${params.campaign_id}`),

    list: () => client.get('/user_marketing/sms_campaign/list'),
    submit: (params) => client.post('/user_marketing/sms_campaign/send', params),

    smsCounter: (params) => client.post('/user_marketing/sms_campaign/count', params),
    smsTest: (params) => client.post('/user_marketing/sms_campaign/test/send', params),
  },
};

export default marketing;

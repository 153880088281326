import { TablePreviewColumnsType } from 'config';

const VOUCHERS_FORM_FIELDS: TablePreviewColumnsType = {
  name: {
    slug: 'name',
    previewColumns: [{ slug: 'name', span: 2 }],
  },
  promoCode: {
    slug: 'promoCode',
    previewColumns: [{ slug: 'promoCode', span: 2 }],
  },
  value: {
    slug: 'value',
    previewColumns: [{ slug: 'value', span: 1 }],
  },
  expiration: {
    slug: 'expiration',
    previewColumns: [{ slug: 'expiration', span: 1, justifyContent: 'center' }],
  },
  quantity: {
    slug: 'quantity',
    previewColumns: [{ slug: 'quantity', span: 1, justifyContent: 'center' }],
  },
};

export default VOUCHERS_FORM_FIELDS;

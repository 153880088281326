import React, { useRef, useEffect } from 'react';
import { useRect } from 'helpers/hooks/useRect';
import { ToggleButton } from '@mui/material';

const ButtonSwitch = ({ children, setRect, isActive, ...rest }: any) => {
  const buttonRef = useRef(null);
  const rect = useRect(buttonRef, { observe: isActive });

  useEffect(() => {
    if (isActive) {
      setRect(rect);
    }
  }, [isActive, rect]);

  return (
    <ToggleButton size="large" disableRipple ref={buttonRef} {...rest}>
      {children}
    </ToggleButton>
  );
};

export default ButtonSwitch;

import React from 'react';
import { useTranslation } from 'react-i18next';

import usePaymentOptions from 'helpers/apiHooks/usePaymentOptions';
import { InputMessageType } from 'components/ValidationMessage';
import ChipSelect from 'components/ChipSelect';

export interface PropsInterface {
  label?: string;
  value: string[];
  onChange: (value: string) => void;
  message?: InputMessageType;
  exclude?: string[];
  disabled?: boolean;
}

const PaymentMethodSelect: React.FunctionComponent<PropsInterface> = ({
  value,
  onChange,
  label,
  message,
  exclude,
  disabled,
}) => {
  const { t } = useTranslation(['common']);

  const { paymentOptions } = usePaymentOptions();

  const options = exclude ? paymentOptions.filter(({ slug }) => !exclude?.includes(slug)) : paymentOptions;

  return (
    <ChipSelect
      label={label}
      onChange={(value) => onChange(value?.id)}
      options={options.map((type) => ({
        id: type.id,
        label: t(`common:paymentType.type.${type.slug}`),
      }))}
      disabled={disabled}
      value={value}
      preventEmpty
      error={message?.message}
    />
  );
};

export default PaymentMethodSelect;

import React from 'react';
import cx from 'classnames';
import useStyles from './styles';

export interface PropsInterface {
  children?: React.ReactNode;
  margin?: number;
  reverse?: boolean;
  component?: 'div' | 'span';
  color?: 'inherit' | 'warning' | 'error' | 'success' | 'info' | 'primary' | 'secondary' | 'black';
  alignment?: 'center' | 'left' | 'right';
  type: 'size-M-medium' | 'size-S-bold' | 'size-S-medium' | 'size-XS-medium' | 'size-XS-regular';
  icon?: React.ReactElement;
}

const Paragraph: React.FunctionComponent<PropsInterface> = ({
  children,
  margin,
  reverse = false,
  component,
  color = 'primary',
  alignment = 'left',
  type,
  icon,
}) => {
  const classes = useStyles({ margin });

  const RenderComponent = component || 'p';

  const styles = {
    textAlign: alignment,
  };

  return (
    <RenderComponent
      style={styles}
      className={cx(classes.paragraph, {
        [classes.withMargin]: typeof margin === 'number',
        [classes.reverse]: reverse,
        [classes[`paragraph--${color}`]]: color,
        [classes[`paragraph--${type}`]]: type,
        [classes['paragraph--icon']]: !!icon,
      })}
    >
      {icon && <span className={classes['paragraph__icon']}>{icon}</span>}
      {children}
    </RenderComponent>
  );
};

export default Paragraph;

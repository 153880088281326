import React from 'react';
import cx from 'classnames';

import ValidationMessage from 'components/ValidationMessage';
import Skeleton from 'components/Skeleton';
import { InputMessageType } from 'types';
import useStyles from './styles';

type PropsType = {
  message?: InputMessageType;
};

const EarningsWidgetSkeleton: React.FunctionComponent<PropsType> = ({ message }) => {
  const classes = useStyles({});

  return (
    <div
      className={cx(classes['earnings-widget-skeleton'], { [classes['earnings-widget-skeleton--message']]: !!message })}
    >
      {message && (
        <div className={classes['earnings-widget-skeleton__message']}>
          <ValidationMessage message={message} show={!!message} />
        </div>
      )}
      <div className={classes['earnings-widget-skeleton__header']}>
        <Skeleton variant="p" width={100} />
        <div className={classes['earnings-widget-skeleton__header-value']}>
          <Skeleton variant="h2" width={50} />
          <Skeleton variant="p" width={100} />
        </div>
      </div>
      <div className={classes['earnings-widget-skeleton__chart']}>
        <div>
          <Skeleton variant="p" width={90} />
          <Skeleton variant="p" width={100} />
          <Skeleton variant="p" width={70} />
        </div>
        <div className={classes['earnings-widget-skeleton__donaught']} />
      </div>
    </div>
  );
};

export default EarningsWidgetSkeleton;

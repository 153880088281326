import React, { useEffect, useState } from 'react';
import { Icon, IconButton, InputLabel } from '@mui/material';

import TextField from 'components/TextField';
import useStyles from './styles';
import Paragraph from '../Paragraph';

type ValueType = number;

interface PropsInterface<T = ValueType> {
  label?: string;
  onChange(value: T): void;
  minValue?: number;
  maxValue?: number;
  value?: T;
  defaultValue?: T;
  disabled?: boolean;
}

const NumberSelect: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { label, onChange, minValue = 1, maxValue = 9999, defaultValue = 1, value: propsValue, disabled } = props;
  const [value, setValue] = useState<ValueType>(propsValue ?? defaultValue);

  const classes = useStyles({});

  const handleChange = (newValue) => {
    const unifiedValue = newValue >= 0 ? newValue : null;
    if ((unifiedValue >= minValue && unifiedValue <= maxValue) || unifiedValue === null) {
      setValue(unifiedValue);
      onChange(unifiedValue);
    } else if (unifiedValue > maxValue) {
      setValue(maxValue);
      onChange(maxValue);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event?.target?.value;
    if (parseInt(newValue, 10) <= minValue ?? newValue === '') {
      handleChange(minValue);
    }
  };

  useEffect(() => {
    setValue(propsValue ?? defaultValue);
  }, [propsValue]);

  return (
    <div>
      {label && <Paragraph type="size-XS-medium">{label}</Paragraph>}
      <div className={classes['number-select__select']}>
        <IconButton size="small" onClick={() => handleChange(value - 1)} disabled={disabled || value <= minValue}>
          <Icon fontSize="inherit" className={classes['number-select__icon']}>
            remove
          </Icon>
        </IconButton>
        <div className={classes['number-select__input']}>
          <TextField
            disabled={disabled}
            value={value.toString()}
            onChange={({ target }) => handleChange(parseInt(target?.value || '0', 10))}
            hiddenLabel
            fullWidth={false}
            onBlur={handleBlur}
            size="small"
          />
        </div>
        <IconButton size="small" onClick={() => handleChange(value + 1)} disabled={disabled || value === maxValue}>
          <Icon fontSize="inherit" className={classes['number-select__icon']}>
            add
          </Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default NumberSelect;

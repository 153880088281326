import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';

import { ReleaseNoteDetailsType } from 'api/types/common';
import Paragraph from 'components/Paragraph';
import Header from 'components/Header';
import Chip from 'components/Chip';
import Card from 'components/Card';
import formatDate from 'helpers/date/formatDate';

import useStyles from './styles';
import packageInfo from '../../../../../package.json';

type ReleaseDetailsPropsType = {
  releaseNotes: ReleaseNoteDetailsType;
  isButtonVisible?: boolean;
};

const ReleaseDetails: React.FC<ReleaseDetailsPropsType> = ({ releaseNotes, isButtonVisible = true }) => {
  const { t } = useTranslation('common');

  const classes = useStyles();

  const productMinorVersion = packageInfo.version.split('.', 2).join('.');
  const isLatest = releaseNotes?.product_version === productMinorVersion;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} className={classes['release-details']}>
        <div className={classes['release-details__tags']}>
          <Chip size="small" label={formatDate(releaseNotes?.release_date_iso)} />
          <Chip size="small" label={`v${releaseNotes?.product_version}`} />
          {isLatest && <Chip color="warning" size="small" label={t('release-details.chip.new')} />}
        </div>
        <Header type="h3" variant="h2">
          {releaseNotes?.title}
        </Header>
        {releaseNotes?.content?.map((text) => (
          <Paragraph key={text} type="size-S-medium">
            {text}
          </Paragraph>
        ))}
        {releaseNotes?.photo_url && (
          <img className={classes['release-details__cover']} src={releaseNotes?.photo_url} alt={releaseNotes?.title} />
        )}
      </Grid>
      {isButtonVisible && releaseNotes?.url && (
        <Grid item xs={12}>
          <Button component="a" href={releaseNotes.url} target="_self" fullWidth variant="contained">
            {t('release-details.cta')}
          </Button>
        </Grid>
      )}
      {releaseNotes?.survey_url && (
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Header type="h2" variant="h4">
                  {t('release-details.survey.header')}
                </Header>
              </Grid>
              <Grid item xs={12}>
                <Paragraph type="size-S-medium">{t('release-details.survey.description')}</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="outlined"
                  component="a"
                  href={releaseNotes.survey_url}
                  target="_blank"
                >
                  {t('release-details.survey.cta')}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default ReleaseDetails;

const getCookieDomain = () => (import.meta.env.PROD ? '.inksearch.co' : window.location.hostname);

export const getCookie = (cookieName, cookies = null) => {
  const documentObject = typeof document === 'object' ? document.cookie : '';
  const cookie = cookies || documentObject;
  const cookiesArray = cookie.split(';');
  for (let i = 0; i < cookiesArray.length; i += 1) {
    const name = cookiesArray[i]
      .split(' ')
      .join('')
      .split('=')[0];
    const value = cookiesArray[i].split('=')[1];
    if (name === cookieName) {
      return value;
    }
  }
  return undefined;
};

export const setCookie = (name, value, days = 999) => {
  const currDate = new Date();
  const expDate = new Date(currDate.getTime() + 24 * 3600 * 1000 * days);

  document.cookie = `${name}=${value}; expires=${expDate.toUTCString()}; Path=/; domain=${getCookieDomain()}`;
};

export const deleteCookie = (name): void => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${getCookieDomain()}`;
};

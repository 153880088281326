import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'release-notes-update-representation': {
    display: 'flex',
    alignItems: 'stretch',
    gap: '16px',
  },
  'release-notes-update-representation__image': {
    cursor: 'pointer',
    borderRadius: 8,
    width: 100,
    maxHeight: 150,
    objectFit: 'cover',
  },

  'release-notes-update-representation__content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  'release-notes-update-representation__content-tags': {
    display: 'flex',
    gap: '4px',
  },
  'release-notes-update-representation__content-text': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  'release-notes-update-representation__content-text-title': {
    cursor: 'pointer',
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'language-switcher__list': {
    // transform: 'translateX(50%)',
    position: 'relative',
  },
  'language-switcher__list-item': {
    // width: 150,
    borderRadius: 2,
    overflow: 'hidden',
  },
  'language-switcher__label-icon': {
    width: 24,
    height: 24,
  },
}));

export default useStyles;

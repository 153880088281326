import makeStyles from '@mui/styles/makeStyles';
import Palette from '../../theme/palette';

const useStyles = makeStyles((theme) => ({
  'text-field__wrapper': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  'text-field': {
    position: 'relative',
    width: '100%',
  },

  'text-field__helper-text': {
    paddingTop: '4px',
  },

  'text-field--full-width': {
    width: '100%',
  },

  'text-field__input--multiline': {
    '& textarea': {
      minHeight: '50px',
      letterSpacing: 0,
      fontSize: '14px',
      // fontSize: '0.8750em', // '14px',
      fontWeight: '500 !important',
      lineHeight: 1.715, // '24px',
      color: theme.palette.grey[100],
      '&::placeholder': {
        color: Palette.grey[300],
        opacity: 1,
      },
    },
  },

  'text-field__counter': {
    position: 'absolute',
    right: 0,
    bottom: 'calc(-1em - 6px)',
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'checkbox--not-centered': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '8px',
    justifyContent: 'start',
    alignItems: 'start',
    '& .MuiFormControlLabel-label': {
      paddingTop: '12px',
    },
  },
}));

export default useStyles;

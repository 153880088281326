import Palette from '../palette';

export default {
  root: {
    '& .MuiFilledInput-underline': {
      '&:before': {
        borderBottomColor: Palette.grey[300],
      },
      '&:after': {
        borderBottomColor: Palette.grey[300],
      },
    },
    '& label': {
      span: {
        color: Palette.error.main,
      },
    },
    '& label.Mui-focused': {
      color: Palette.grey[100],
      span: {
        color: Palette.error.main,
      },
    },
    '& label.Mui-error': {
      color: Palette.grey[100],
      span: {
        color: Palette.error.main,
      },
    },
    '& .Mui-focused': {
      '&:before': {
        borderBottomColor: Palette.grey[100],
      },
      '&:after': {
        borderBottomColor: Palette.grey[100],
      },
    },
    '& .MuiFilledInput-underline:hover': {
      '&:before': {
        borderBottomColor: Palette.grey[100],
      },
    },
    '& .MuiInputAdornment-root': {
      span: {
        fontSize: '16px',
        color: Palette.grey[100],
      },
    },
    '& input': {
      letterSpacing: 0,
      fontSize: '14px',
      // fontSize: '0.8750em', // '14px',
      fontWeight: '500 !important',
      color: Palette.grey[100],
      '&::placeholder': {
        color: Palette.grey[300],
        opacity: 1,
      },
    },
  },
};

import { AccountActionType, AccountState } from './types';

export const initialState: AccountState = {
  token: null,
  data: null,
  consents: null,
  settings: null,
  access: {
    notifications: {
      allow: true,
      events: true,
    },
  },
  employeesData: {},
  progression: 0,
  isFetching: false,
  isFetched: false,
  isLogged: false,
  // usage when user will logout
  blockSso: false,
  error: null,
};

export function reducer(state: AccountState, action: AccountActionType): AccountState {
  switch (action.type) {
    case 'FETCH_ACCOUNT_REQUEST':
      return { ...state, isFetched: false, isFetching: true };
    case 'FETCH_ACCOUNT_DONE':
      return {
        ...state,
        data: action.payload.data,
        consents: action.payload.consents,
        settings: action.payload.settings,
        isFetched: true,
        isFetching: false,
        error: false,
      };
    case 'FETCH_ACCOUNT_FAIL':
      return { ...state, data: null, isFetched: false, isFetching: false, error: 'Fetch fail' };
    case 'UPDATE_ACCOUNT_REQUEST':
      return { ...state, isFetched: false, isFetching: true };
    case 'UPDATE_ACCOUNT_DONE':
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetched: true,
        isFetching: false,
        error: false,
      };
    case 'UPDATE_ACCOUNT_FAIL':
      return { ...state, isFetched: false, isFetching: false, error: 'Update fail' };
    case 'CLEAR_ACCOUNT_DONE':
      return { ...state, data: null, error: false };
    case 'SET_ACCOUNT_IS_LOGGED':
      return {
        ...state,
        isLogged: true,
      };
    case 'SET_ACCOUNT_IS_LOGGED_OUT':
      return {
        ...state,
        isLogged: false,
        blockSso: true,
      };
    case 'ACCOUNT_PROFILE_PROGRESSION':
      return { ...state, progression: action.payload };
    default:
      return state;
  }
}

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { io } from 'socket.io-client';
import { Button } from '@mui/material';

import { EventNotificationInterface } from 'context/panel/types';
import { PanelContext } from 'context/panel/panelContext';
import useAccount from 'helpers/hooks/useAccount';
import isUserTester from 'helpers/isUserTester';
import useToast from 'helpers/hooks/useToast';
import TestStats from 'components/TestStats';
import config from 'config';

import NotificationsDrawer from './components/NotificationsDrawer';

let socket;

const Notification: React.FunctionComponent = () => {
  const { state: accountState } = useAccount();
  const { state: panelState, setNotificationsOpen, updateEventNotifications } = useContext(PanelContext);
  const { handleToastDisplay } = useToast();
  const { t } = useTranslation('common');

  const [hasMore, setHasMore] = useState(false);

  const [notificationSocket, setNotificaitonSocket] = useState({
    eventsCounter: 0,
    connectionTime: 0,
  });

  const isMenuOpen = panelState.notifications.type === 'notifications';

  const handleNextPage = async () => {
    const data = {
      items: [],
    };
    setHasMore(true);
    updateEventNotifications(data.items);
  };

  const handleNotificationsClose = () => {
    setNotificationsOpen(null);
  };

  useEffect(() => {
    const startTime = performance.now();
    socket = io(import.meta.env.VITE_SOCKET_URL);
    socket.on('connect', () => {
      const endTime = performance.now();
      setNotificaitonSocket((prevState) => ({
        ...prevState,
        isActive: true,
        connectionTime: Math.round(endTime - startTime),
      }));
      socket.emit('subscribe', {
        channel: accountState.data?.uuid,
        auth: {},
      });

      socket.on('profile_updated', () => {
        handleToastDisplay(t('messages.profile-update.message'), 'info', {
          action: <Button onClick={() => window.location?.reload()}>{t('messages.profile-update.cta')}</Button>,
          autoHideDuration: null,
        });
      });

      if (accountState.access.notifications.events) {
        socket.on('scheduler_update', (data: string, moreData: EventNotificationInterface) => {
          setNotificaitonSocket((prevState) => ({ ...prevState, eventsCounter: prevState.eventsCounter + 1 }));
          updateEventNotifications([moreData]);
        });

        socket.on('disconnect', () => {
          setNotificaitonSocket((prevState) => ({ ...prevState, isActive: false }));
        });
      }
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('scheduler_update');
      socket.off('profile_updated');
    };
  }, []);

  if (config.notifications.drawer.isActive || (config.notifications.drawer.isActiveDev && isUserTester())) {
    return (
      <>
        {isUserTester() && (
          <TestStats
            eventNotification={{
              isActive: socket?.connected,
              counter: notificationSocket.eventsCounter,
              connectTime: notificationSocket.connectionTime,
            }}
          />
        )}

        <NotificationsDrawer
          hasMore={hasMore}
          isOpen={isMenuOpen}
          notifications={panelState.notifications}
          onClose={handleNotificationsClose}
          onNextPage={handleNextPage}
        />
      </>
    );
  }

  return <div />;
};

export default Notification;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowX: 'hidden',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(6),

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 4),
    position: 'relative',
  },

  'layout__quote-background': {
    top: 24,
    left: 24,
    position: 'absolute',
    borderRadius: 24,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: 'calc(100% - 48px)',
    height: 'calc(100% - 48px)',
    zIndex: -1,
    border: `1px solid ${theme.palette.grey[600]}`,
  },

  'layout__quote-content': {
    padding: theme.spacing(12),
  },

  'layout__quote-header': {
    fontSize: 48,
    letterSpacing: 1,
    fontWeight: 800,
    margin: theme.spacing(8, 0),

    [theme.breakpoints.down('xl')]: {
      fontSize: 42,
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: 32,
    },
  },

  'layout__quote-header2': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1em',
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.9em',
    },
  },

  'layout__quote-header-gradient': {
    background: 'linear-gradient(221.24deg, #8DD1AB 2.75%, #AF9FE3 99.49%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
}));

export default useStyles;

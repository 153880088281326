import Palette from '../palette';

export default {
  root: {
    color: Palette.icon,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },

    '&:disabled': {
      color: Palette.black.main,
    },
  },
};

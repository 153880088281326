import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'number-select__select': {
    display: 'flex',
    alignItems: 'center',
  },

  'number-select__icon': {},

  'number-select__input': {
    width: 80,
    '& input': {
      textAlign: 'center',
    },
  },
}));

export default useStyles;

import cx from 'classnames';
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MuiBottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MuiAppBar from '@mui/material/AppBar';

import isNavLinkActive from 'helpers/navigation/isNavLinkActive';
import mixpanel from 'helpers/mixpanel';
import usePanel from 'context/panel/hooks/usePanel';
import { setSidebarOpenAction } from 'context/panel/panelActions';
import { appBarOptions } from 'config';
import SidebarIcon from 'layouts/PanelPageLayout/components/Sidebar/components/SidebarIcon';

import useStyles from './styles';

const BottomNavigation: React.FunctionComponent = () => {
  const {
    state: { isMobile, isSidebarOpen },
    dispatch,
  } = usePanel();
  const location = useLocation();
  const classes = useStyles();

  const handleSidebarOpen = () => {
    setSidebarOpenAction(dispatch, !isSidebarOpen);
  };

  const handleChange = (event: React.ChangeEvent, newValue: string) => {
    mixpanel.track('BottomNavigationButton:PageClick', { page: newValue });
  };

  useEffect(() => {
    if (isMobile) {
      setSidebarOpenAction(dispatch, false);
    }
  }, [location.key, dispatch, isMobile]);

  if (!isMobile) {
    return null;
  }

  return (
    <MuiAppBar className={classes['bottom-navigation-wrapper']}>
      <MuiBottomNavigation onChange={handleChange} className={classes['bottom-navigation']}>
        {appBarOptions.map(({ key, href }) => (
          <BottomNavigationAction
            icon={
              <div
                className={cx(classes['bottom-navigation-button__icon-wrapper'], {
                  [classes['bottom-navigation-button__icon-wrapper--active']]: isNavLinkActive(location.pathname, href),
                })}
              >
                <SidebarIcon type={key} isActive={isNavLinkActive(location.pathname, href)} />
              </div>
            }
            component={NavLink}
            value={key}
            key={key}
            to={href}
            classes={{ root: classes['bottom-navigation-button__bottom-navigation-action'] }}
          />
        ))}
        <BottomNavigationAction
          icon={<SidebarIcon type="menu" />}
          onClick={handleSidebarOpen}
          value="menu"
          classes={{ root: classes['bottom-navigation-button__bottom-navigation-action'] }}
        />
      </MuiBottomNavigation>
    </MuiAppBar>
  );
};

export default BottomNavigation;

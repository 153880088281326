import { Dispatch } from 'react';
import * as Sentry from '@sentry/react';

import { authApi, inksearchApi } from 'api';
import logError from 'helpers/logError';
import identifyMixUser from 'helpers/identifyMixUser';
import objectToFormData from 'helpers/objectToFormData';
import getProfileProgression from 'helpers/getProfileProgression';
import mixpanel from 'helpers/deprecatedMixpanel';

import { deleteCookie } from 'helpers/cookie';
import {
  AuthorizeUserActionInterface,
  RegisterUserActionInterface,
  AccountActionType,
  LogoutActionInterface,
  UpdateUserProfileProgressionActionInterface,
  UpdateAccountActionInterface,
  UpdateSettingsActionInterface,
  UpdateConsentsActionInterface,
} from './types';
import { CURRENCY, CURRENCY_LOCALE, USER_ROLES } from '../../constants/constants';
import { periodTypes } from '../../constants/date';

export const updateUserProfileProgression: UpdateUserProfileProgressionActionInterface = (
  dispatch: Dispatch<AccountActionType>,
  data,
) => {
  dispatch({
    type: 'ACCOUNT_PROFILE_PROGRESSION',
    payload: getProfileProgression(data),
  });
};

export const authorizeUser: AuthorizeUserActionInterface = async (
  dispatch,
  token = window?.localStorage?.getItem('biz_token'),
  accountSource = null,
  skipContextUpdate = false,
) => {
  dispatch({ type: 'FETCH_ACCOUNT_REQUEST' });
  try {
    const { data: user } = await inksearchApi.me.get(token);
    const { data: settings } = await inksearchApi.settings.get(token);
    const { data: consents } = await inksearchApi.consents.get(token);

    const allowEventNotifications = user.roles_check === USER_ROLES.manager || user.artist_data?.studio_exists;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      pageType: 'Home',
      userId: user.uuid,
      userStatus: 'Loged-in',
    });

    window?.localStorage?.setItem('u_t', user.is_tester ? '1' : '0');
    window?.localStorage?.setItem('customLang', user.interface_language);

    if (!skipContextUpdate) {
      dispatch({
        type: 'FETCH_ACCOUNT_DONE',
        payload: {
          data: { ...user, additional: { freshRegister: accountSource === 'register' } },
          access: {
            notifications: {
              allow: !!allowEventNotifications,
              events: !!allowEventNotifications,
            },
          },
          consents: {
            marketing: !!consents.marketing_consent,
            bookings: !!consents.booking_request_agreement,
            visibility: !!consents.inksearch_profile_visibility,
          },
          settings: {
            currency:
              CURRENCY.find((currency) => currency.value === settings.currency_id) ||
              CURRENCY_LOCALE[user.interface_language] ||
              CURRENCY_LOCALE.pl,
            dashboardPeriod: periodTypes[settings.dashboard_default_range],
            schedulerPeriod: periodTypes[settings.scheduler_default_range],
            schedulerPeriodMobile: periodTypes[settings.scheduler_mobile_default_range],
          },
        },
      });
      updateUserProfileProgression(dispatch, user);
    }
    identifyMixUser(user.email, user.uuid, user.name, accountSource, {
      role: user.roles_check,
      studio: user.artist_data?.studio_name,
      plan:
        !user.business_data?.trial_active && user.business_data?.active_plan?.slug
          ? user.business_data?.active_plan?.slug
          : 'none',
      trial_ends: user.business_data?.trial_ends_at,
      access_type: user.access,
      lang: user.interface_language,
    });
    if (import.meta.env.PROD) {
      Sentry.setUser({
        email: user.email,
        name: user.name,
        role: user.roles_check,
        uuid: user.uuid,
        token,
        data: {
          details: JSON.stringify(user),
        },
      });
    }
    return user;
  } catch (error) {
    logError(error);
    dispatch({ type: 'FETCH_ACCOUNT_FAIL', payload: error });
    throw error;
  }
};

export const registerUser: RegisterUserActionInterface = async (dispatch, params) => {
  try {
    const { data } = await authApi.register({
      name: params.name,
      email: params.email,
      password: params.password,
      password_confirmation: params.passwordAgain,
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'register',
      pagePath: window.location.pathname,
      pageTitle: window.document.title,
    });
    window.localStorage.setItem('biz_token', data.access_token);
    return await authorizeUser(dispatch, data.access_token, 'register');
  } catch (error) {
    logError(error);
    dispatch({ type: 'UPDATE_ACCOUNT_FAIL', payload: error });
    throw error;
  }
};

export const logout: LogoutActionInterface = (dispatch, ignoreToken) => {
  mixpanel.track('PanelMenu:Logout');

  if (!ignoreToken) {
    window.localStorage?.removeItem('biz_token');
  }
  deleteCookie('sso_token');

  window.location.reload();

  dispatch({
    type: 'SET_ACCOUNT_IS_LOGGED_OUT',
  });
};

export const switchAccountAction: LogoutActionInterface = (dispatch) => {
  mixpanel.track('PanelMenu:AccountSwtich');
  window.localStorage?.removeItem('biz_token');
  deleteCookie('sso_token');

  window.location.reload();

  dispatch({
    type: 'SET_ACCOUNT_IS_LOGGED_OUT',
  });
};

export const setConfirmLogin = (dispatch) => {
  dispatch({
    type: 'SET_ACCOUNT_IS_LOGGED',
  });
};

export const updateAccountAction: UpdateAccountActionInterface = async (dispatch, data) => {
  dispatch({ type: 'UPDATE_ACCOUNT_REQUEST' });
  try {
    const scope = Object.keys(data);
    const { data: user } = await inksearchApi.me.updatev2(objectToFormData({ scope, ...data }));
    mixpanel.track('Account update (self)', { uuid: user.uuid, name: user.name });
    updateUserProfileProgression(dispatch, user);
    dispatch({ type: 'UPDATE_ACCOUNT_DONE', payload: user });
    return user;
  } catch (error) {
    logError(error);
    dispatch({ type: 'UPDATE_ACCOUNT_FAIL', payload: error });
    throw error;
  }
};

export const updateSettingsAction: UpdateSettingsActionInterface = async (dispatch, data) => {
  // dispatch({ type: 'UPDATE_ACCOUNT_REQUEST' });
  try {
    const { data: settings } = await inksearchApi.settings.update(data);
    mixpanel.track('Settings update');
    // dispatch({ type: 'UPDATE_ACCOUNT_DONE', payload: user });
    return settings;
  } catch (error) {
    logError(error);
    // dispatch({ type: 'UPDATE_ACCOUNT_FAIL', payload: error });
    throw error;
  }
};

export const updateConsentsAction: UpdateConsentsActionInterface = async (dispatch, data) => {
  // dispatch({ type: 'UPDATE_ACCOUNT_REQUEST' });
  try {
    const { data: consents } = await inksearchApi.consents.update(data);
    mixpanel.track('Consents update');
    // dispatch({ type: 'UPDATE_ACCOUNT_DONE', payload: user });
    return consents;
  } catch (error) {
    logError(error);
    // dispatch({ type: 'UPDATE_ACCOUNT_FAIL', payload: error });
    throw error;
  }
};

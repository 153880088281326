import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'data-table': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },

  'data-table__pagination': {
    display: 'flex',
    justifyContent: 'center',
  },

  'data-table__headers': {
    display: 'flex',
    gap: '16px',
    textTransform: 'uppercase',
    padding: '0 16px', // adjust to items cards border radius
    fontWeight: 600,
  },
  'data-table__header': {
    display: 'block',
    flex: '0 0 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // padding: theme.spacing(2),
    alignItems: 'center',

    color: theme.palette.grey[100],
    margin: 0,
    fontSize: '12px',
    // fontSize: '0.75em', // '12px',
    fontWeight: '500 !important',
    lineHeight: 2, // '24px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  'data-table__content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  'data-table__separator': {
    margin: theme.spacing(6, 0, 2),
  },
  'data-table__row': {
    display: 'flex',
    width: '100%',
    gap: '16px',
  },
  'data-table__cell': {
    zIndex: 1,
    display: 'flex',
    flex: '0 0 auto',
    minHeight: '40px',
    alignItems: 'center',
    overflow: 'hidden',
  },
  'data-table__bottom-indicator': {
    position: 'absolute',
    bottom: 0,
  },
}));

export default useStyles;

import mixpanel from 'helpers/deprecatedMixpanel';
import formatDate from './date/formatDate';

interface IdentifyMixUserInterface {
  (
    email: string,
    uuid: string,
    name: string,
    accountSource?: 'register' | 'social' | 'login' | null,
    other?: { [key: string]: string },
  ): void;
}

const identifyMixUser: IdentifyMixUserInterface = (email, uuid, name, accountSource = null, other = null) => {
  mixpanel.identify(name);
  mixpanel.people.set({
    last_login: formatDate(new Date(), { time: true }),
  });
  mixpanel.people.set({
    $email: email, // only reserved properties need the $
    USER_ID: uuid, // use human-readable names
    name, // ...or numbers
    ...other,
  });
};

export default identifyMixUser;

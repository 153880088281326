import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import { Icon } from '@mui/material';

import additinalAccountHandler, { AdditionalAccountListType } from 'helpers/additionalAccountHandler';
import SidebarNavLink from 'layouts/PanelPageLayout/components/Sidebar/components/SidebarNavLink';
import { AccountContext } from 'context/account/accountContext';
import { logout, switchAccountAction } from 'context/account/accountActions';
import { menuProfilePagesToDisplay } from 'config';
import Paragraph from 'components/Paragraph';
import Dropdown from 'components/Dropdown';
import Profile from 'components/Profile';
import Divider from 'components/Divider';
import Header from 'components/Header';
import Alert from 'components/Alert';
import mixpanel from 'helpers/mixpanel';
import SidebarIcon from '../../layouts/PanelPageLayout/components/Sidebar/components/SidebarIcon';

import useStyles from './styles';

const ProfileMenu: React.FunctionComponent = () => {
  const { state: accountContext, dispatch } = useContext(AccountContext);
  const [accounts, setAccounts] = useState<AdditionalAccountListType>([]);
  const isLogged = accountContext.data;

  const classes = useStyles({});
  const { t } = useTranslation('common');

  const handleNavClick = (key) => {
    mixpanel.track('ProfileMenu:PageClick', { page: key });
  };

  const handleAccountAdd = () => {
    if (!accountContext.data) {
      return;
    }

    additinalAccountHandler.disableAccount({
      avatar: accountContext?.data?.user_data?.avatar_paths.min,
      name: accountContext.data?.name,
      role: accountContext.data?.roles_check,
      token: window.localStorage?.getItem('biz_token'),
      uuid: accountContext.data?.uuid,
      color: accountContext.data?.settings?.color,
    });

    switchAccountAction(dispatch);
  };

  const handleAccountSwitch = (uuid?: string) => {
    additinalAccountHandler.switchAccount(
      isLogged
        ? {
            avatar: accountContext?.data?.user_data?.avatar_paths.min,
            name: accountContext.data?.name,
            role: accountContext.data?.roles_check,
            token: window.localStorage?.getItem('biz_token'),
            uuid: accountContext.data?.uuid,
            color: accountContext.data?.settings?.color,
          }
        : null,
      uuid,
    );

    window.location.reload();
  };

  const handleLogout = () => {
    if (additinalAccountHandler.getAccounts().length > 0) {
      additinalAccountHandler.switchAccount();
      logout(dispatch, true);
    } else {
      logout(dispatch);
    }
  };

  useEffect(() => {
    setAccounts(additinalAccountHandler.getAccounts(accountContext.data?.uuid));
  }, []);

  return (
    <Dropdown
      placement="bottom-start"
      label={
        <Profile
          avatar={accountContext.data?.user_data?.avatar_paths.min}
          name={accountContext.data?.name || t('menu-profile.missing-user-title')}
          size={36}
          slim
        />
      }
    >
      {isLogged && (
        <>
          <div className={classes['profile-menu__profile']}>
            <Profile
              name={accountContext.data?.name}
              avatar={accountContext.data?.user_data?.avatar_paths.min}
              size={64}
              type="vertical"
            />
            <Paragraph type="size-S-medium" alignment="center" color="secondary">
              {accountContext.data?.email}
            </Paragraph>
          </div>
          {accountContext.data?.artist_data?.studio_exists ? (
            <div className={classes['profile-menu__alert']}>
              <Alert
                type="info"
                message={
                  <span>
                    {t('menu-profile.account-assign')} <strong>{accountContext.data?.artist_data?.studio_name}</strong>
                  </span>
                }
                sharp
              />
            </div>
          ) : (
            <Divider />
          )}
        </>
      )}
      {accountContext.data?.onboarding_complete && (
        <div>
          <div className={classes['profile-menu__navigation-title']}>
            <Header type="h5" variant="data-table">
              {t('menu-profile.navigation-list.header')}
            </Header>
          </div>
          <div>
            {menuProfilePagesToDisplay.map((page) => (
              <SidebarNavLink
                key={page.key}
                onClick={() => handleNavClick(page.key)}
                title={t(`navigation.${page?.key}`)}
                icon={<SidebarIcon type={page?.key} />}
                href={page.href}
              />
            ))}
          </div>
          <Divider />
        </div>
      )}
      <div className={classes['profile-menu__additinal-account']}>
        <div>
          {accounts.map((account) => (
            <div className={classes['profile-menu__additinal-account-item']} key={account.uuid}>
              <Profile
                onClick={() => handleAccountSwitch(account.uuid)}
                name={account.name}
                subtitle={account.role}
                avatar={account.avatar}
                avatarName={account.name}
                hideChevron
                fullWidth
                color={account.color}
              />
            </div>
          ))}
        </div>
        {isLogged && (
          <Button
            onClick={handleAccountAdd}
            className={classes['profile-menu__additinal-account-add']}
            color="secondary"
            startIcon={<Icon>add</Icon>}
          >
            {t('menu-profile.add-account')}
          </Button>
        )}
      </div>
      {isLogged && (
        <>
          <Divider />
          <div className={classes['profile-menu__log-out']}>
            <Button onClick={handleLogout} variant="outlined" color="secondary">
              {t('navigation.logout')}
            </Button>
          </div>
        </>
      )}
    </Dropdown>
  );
};
export default React.memo(ProfileMenu);

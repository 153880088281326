import { PaginationType } from 'api/types';

import { CurrencyType, CalendarPermissionType, AccountRoleType, ProfileAccessRoleType } from '../types';

export const EVENT_PAYMENT_METHODS = {
  FREE: {
    id: '4',
    slug: 'free',
  },
};

export const BASE_URL = {
  marketplace: 'https://inksearch.co',
  business: 'https://panel.inksearch.co',
};

export const USER_ROLES: Record<AccountRoleType, AccountRoleType> = {
  user: 'user',
  artist: 'artist',
  manager: 'manager',
  piercer: 'piercer',
  reception: 'reception',
};

export const PROFILE_ACCESS_ROLE: Record<ProfileAccessRoleType, ProfileAccessRoleType> = {
  studiosEmployee: 'studiosEmployee',
  owner: 'owner',
  employee: 'employee',
};

export const INVITABLE_USER_ROLES = [USER_ROLES.artist, USER_ROLES.piercer, USER_ROLES.reception];

export const CURRENCY_LOCALE: { [key: string]: CurrencyType } = {
  pl: { label: 'PLN', value: 0, key: 'PLN' },
  en: { label: 'EUR', value: 2, key: 'EUR' },
  de: { label: 'EUR', value: 2, key: 'EUR' },
  ru: { label: 'EUR', value: 2, key: 'EUR' },
  uk: { label: 'UAH', value: 4, key: 'UAH' },
  ua: { label: 'UAH', value: 4, key: 'UAH' },
  gb: { label: 'GBP', value: 3, key: 'GBP' },
  it: { label: 'EUR', value: 3, key: 'EUR' },
  es: { label: 'EUR', value: 3, key: 'EUR' },
  fr: { label: 'EUR', value: 3, key: 'EUR' },
};

export const CURRENCY: CurrencyType[] = [
  { label: 'PLN', value: 0, key: 'PLN' },
  { label: 'USD', value: 1, key: 'USD' },
  { label: 'EUR', value: 2, key: 'EUR' },
  { label: 'GBP', value: 3, key: 'GBP' },
  { label: 'UAH', value: 4, key: 'UAH' },
  { label: 'NOK', value: 5, key: 'NOK' },
  { label: 'CHF', value: 6, key: 'CHF' },
  { label: 'TRY', value: 7, key: 'TRY' },
  { label: 'DKK', value: 8, key: 'DKK' },
];

export const USER_COLORS = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#808080',
];

export const ACCESS_TYPE = {
  fullAccess: { type: 'fullAccess', slug: 'full-access' },
  readAccess: { type: 'readAccess', slug: 'readonly-access' },
  writeAccess: { type: 'writeAccess', slug: 'write-access' },
};

export const PERMISSIONS_TYPE: { [node: string]: { type: string; slug: CalendarPermissionType } } = {
  createEvent: { type: 'createEvent', slug: 'create-event' },
  deleteEvent: { type: 'deleteEvent', slug: 'delete-event' },
  modifyEvent: { type: 'modifyEvent', slug: 'modify-event' },
  showEvent: { type: 'showEvent', slug: 'show-event' },
  finalizeEvent: { type: 'finalizeEvent', slug: 'finalize-event' },
};

export const CONTACT_SOURCES = {
  0: {
    id: 0,
    type: 'inksearch',
    icon: 'INKsearch',
    nickname: false,
    url: 'https://www.inksearch.co/user/',
  },
  1: {
    id: 1,
    type: 'facebook',
    icon: 'Messenger',
    nickname: true,
    url: 'https://www.facebook.com/profile.php?id=',
  },
  2: {
    id: 2,
    type: 'instagram',
    icon: 'Instagram',
    nickname: true,
    url: 'https://www.instagram.com/',
  },
  3: {
    id: 3,
    type: 'email',
    icon: 'Email',
    nickname: false,
  },
  4: {
    id: 4,
    type: 'whatsapp',
    icon: 'WhatsApp',
    nickname: true,
  },
  5: {
    id: 5,
    type: 'phone',
    icon: 'Phone',
    nickname: false,
  },
  6: {
    id: 6,
    type: 'not_found',
    icon: 'close',
    nickname: false,
    preventSelect: true,
  },
  7: {
    id: 7,
    type: 'tiktok',
    icon: 'TikTok',
    nickname: true,
  },
  8: {
    id: 8,
    type: 'twitter',
    icon: 'Twitter',
    nickname: true,
  },
  9: {
    id: 9,
    type: 'telegram',
    icon: 'Telegram',
    nickname: true,
  },
};

export const BOOKING_SOURCES = {
  1: {
    id: 1,
    type: 'facebook',
    icon: 'Messenger',
    url: 'https://www.facebook.com/profile.php?id=',
  },
  2: {
    id: 2,
    type: 'instagram',
    icon: 'Instagram',
    url: 'https://www.instagram.com/',
  },
  7: {
    id: 7,
    type: 'tiktok',
    icon: 'TikTok',
  },
  8: {
    id: 8,
    type: 'twitter',
    icon: 'Twitter',
  },

  10: {
    id: 10,
    type: 'walk-in',
    icon: '',
  },
  11: {
    id: 11,
    type: 'contest',
    icon: '',
  },
  12: {
    id: 12,
    type: 'studio',
    icon: '',
  },
  13: {
    id: 13,
    type: 'artist',
    icon: '',
  },
  14: {
    id: 14,
    type: 'street',
    icon: '',
  },
  15: {
    id: 15,
    type: 'convent',
    icon: '',
  },
  16: {
    id: 16,
    type: 'other',
    icon: '',
  },
};

export const GENDER_OPTIONS = [1, 2, 3];

export const INITIAL_PAGINATION_STATE: PaginationType = {
  last_page: 1,
  current_page: 1,
  total: 0,
  per_page: 50,
};

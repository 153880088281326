import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'earnings-widget-skeleton': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      display: 'initial',
    },
  },

  'earnings-widget-skeleton--message': {
    '& $earnings-widget-skeleton__header': {
      opacity: 0.2,
    },

    '& $earnings-widget-skeleton__chart': {
      opacity: 0.2,
    },
  },

  'earnings-widget-skeleton__message': {
    position: 'absolute',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },

  'earnings-widget-skeleton__header': {
    display: 'block',

    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      width: '100%',
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },
  },

  'earnings-widget-skeleton__header-value': {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-end',
    },
  },

  'earnings-widget-skeleton__chart': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 300,

    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
      margin: 'auto',
    },
  },

  'earnings-widget-skeleton__donaught': {
    width: 120,
    height: 120,
    border: '25px solid rgba(255,255,255, 0.3)',
    marginLeft: theme.spacing(6),
    borderRadius: 100,
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0),
    },
  },
}));

export default useStyles;

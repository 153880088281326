import React from 'react';
import cx from 'classnames';
import { ButtonBase } from '@mui/material';

import useStyles from './styles';

export type CardColorOptionsType = 'primary' | 'secondary' | 'lighten' | 'inherit' | 'darken' | 'gradient-dark';

export interface PropsInterface {
  children: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
  color?: CardColorOptionsType;
  active?: boolean;
  onClick?(): void;
  margin?: number;
  slim?: boolean;
  padding?: 'default' | 'slim' | 'none' | 'large';
  fill?: boolean;
  flat?: boolean;
}

const Card: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    children,
    onClick,
    active,
    color,
    slim,
    padding = 'default',
    direction = 'vertical',
    fill,
    flat,
    margin,
  } = props;
  const classes = useStyles({ margin });

  const Content =
    typeof onClick !== 'function' ? (
      <div className={classes['card__content']}>{children}</div>
    ) : (
      <ButtonBase className={classes['card__content']} onClick={onClick}>
        {children}
      </ButtonBase>
    );

  return (
    <div
      className={cx({
        [classes.card]: true,
        [classes['card--primary']]: color === 'primary',
        [classes['card--secondary']]: color === 'secondary',
        [classes['card--lighten']]: color === 'lighten',
        [classes['card--darken']]: color === 'darken',
        [classes['card--gradient-dark']]: color === 'gradient-dark',
        [classes['card--inherit']]: color === 'inherit',
        [classes['card--button']]: typeof onClick === 'function',
        [classes['card--active']]: active,
        [classes['card--large']]: padding === 'large',
        [classes['card--slim']]: slim || padding === 'slim',
        [classes['card--no-padding']]: padding === 'none',
        [classes['card--fill']]: fill,
        [classes['card--vertical']]: direction === 'vertical',
        [classes['card--flat']]: flat,
      })}
    >
      {Content}
    </div>
  );
};

export default Card;

import Palette from '../palette';

export default {
  root: {
    fontSize: '14px',
    lineHeight: '24px',
    margin: 0,
    padding: '8px 16px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  text: {
    '&:disabled': {
      color: Palette.primary.dark,
    },
  },
  textSecondary: {
    color: '#DFDFE1',
    '&:disabled': {
      color: Palette.black.light,
    },
  },
  contained: {
    '&:disabled': {
      color: Palette.white.light,
      backgroundColor: Palette.primary.main,
      opacity: 0.4,
    },
  },
  containedSecondary: {
    '&:disabled': {
      color: Palette.black.dark,
      backgroundColor: Palette.black.main,
    },
  },
  outlined: {
    color: Palette.white.light,
    border: `solid 1px ${Palette.white.light}`,
    '&:hover': {
      backgroundColor: Palette.white.light,
      color: Palette.primary.main,
    },
    '&:disabled': {
      color: Palette.primary.dark,
      border: `solid 2px ${Palette.primary.dark}`,
    },
  },
  outlinedSecondary: {
    color: Palette.white.light,
    border: `solid 1px ${Palette.white.light}`,
    '&:hover': {
      backgroundColor: Palette.white.light,
      color: Palette.primary.main,
    },
    '&:disabled': {
      color: Palette.black.main,
      border: `solid 2px ${Palette.black.main}`,
    },
  },
};

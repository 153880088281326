import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import PI from 'react-phone-input-2';
import { InputLabel } from '@mui/material';

import ValidationMessage from '../ValidationMessage';
import useStyles from './styles';

export type PhoneInputChangeType = (
  event: React.ChangeEvent<HTMLInputElement>,
  number: string,
  dialCode: string,
) => void;
export type PhoneInputBlurType = (event: React.FocusEvent<HTMLInputElement>, number: string) => void;

interface PropsInterface {
  defaultValue?: string;
  value?: string;
  onChange: PhoneInputChangeType;
  onBlur?: PhoneInputBlurType;
  translations: {
    phoneLabel?: string;
    phonePlaceholder?: string;
  };
  endEmbodiment?: React.ReactNode;
  error?: string;
  disabled?: boolean;
  dialCodes?: string[];
}

const initialCountryReformat = (country) => {
  if (country === 'en') {
    return 'gb';
  }

  if (country === 'uk') {
    return 'ua';
  }

  return country;
};

const PhoneInput: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    defaultValue = '',
    value,
    onChange,
    onBlur = () => {},
    error,
    translations,
    endEmbodiment,
    disabled,
    dialCodes,
  } = props;

  const [phoneValue, setPhoneValue] = useState(defaultValue);
  const [status, setStatus] = useState({ active: false });

  const { i18n } = useTranslation();

  const initialCountry = initialCountryReformat(i18n.language);
  const classes = useStyles({});
  // @ts-ignore
  const ReactPhoneInput = PI.default ? PI.default : PI;
  const handleOnChange = (
    value: string,
    country: { countryCode: string; dialCode: string },
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange(event, `+${value}`, country.dialCode);
    setPhoneValue(value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur(event, `+${phoneValue}`);
    setStatus({ active: false });
  };

  const handleFocus = () => {
    setStatus({ active: true });
  };

  useEffect(() => {
    if (value) {
      setPhoneValue(value);
    }
  }, [value]);

  return (
    <div
      className={cx(classes['phone-input-wrapper'], {
        [classes['phone-input__wrapper--disabled']]: disabled,
      })}
    >
      {translations.phoneLabel && (
        <InputLabel
          className={cx({
            active: status.active,
          })}
          disabled={disabled}
          color="secondary"
          error={!!error}
        >
          {translations.phoneLabel}
        </InputLabel>
      )}
      <div
        className={cx(classes['phone-input__wrapper'], {
          active: status.active,
          [classes['phone-input__wrapper--error']]: !!error,
        })}
      >
        <ReactPhoneInput
          onlyCountries={dialCodes}
          buttonClass={classes['phone-input__button']}
          placeholder={translations?.phonePlaceholder}
          containerClass={classes['phone-input']}
          onChange={handleOnChange}
          country={initialCountry}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={phoneValue}
          error={!!error}
        />
        <div>{endEmbodiment && endEmbodiment}</div>
      </div>

      {error && <ValidationMessage message={{ type: 'error', message: error }} show={!!error} />}
    </div>
  );
};

export default React.memo(PhoneInput);

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'file-preview': {
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
  },

  'file-preview__preview': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  'file-preview__preview-cover': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: 0,
    opacity: 0,
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.2s ease-in-out',
    zIndex: 1,
  },

  'file-preview__preview-wrapper': {
    position: 'relative',
    width: theme.spacing(12),
    height: theme.spacing(12),

    '&:hover': {
      '& $file-preview__preview-cover': {
        opacity: 1,
        cursor: 'pointer',
      },
    },
  },

  'file-preview__file-data': {
    flex: '1 0 auto',
    width: 'min-content',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 6),

    '& > *:nth-child(2)': {
      flex: '0 0 95px',
      textAlign: 'right',
    },
  },
}));

export default useStyles;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from 'components/Drawer';
import ButtonSwitcher from 'components/ButtonSwitcher';
import Paragraph from 'components/Paragraph';
import { ClientDetailsType } from 'api/types/clients';
import convertAttachmentToDropzone from 'helpers/convert/convertAttachmentToDropzone';
import logError from 'helpers/logError';
import { clientsApi } from 'api';
import usePanel from 'context/panel/hooks/usePanel';

import ClientCreateFormActions from './components/ClientFormActions';
import ClientFormNotifications from './components/ClientFormNotifications';
import ClientFormConsents from './components/ClientFormConsents';
import ClientFormUserData from './components/ClientFormUserData';
import useClientForm from './useClientForm';

type TabType = 'general' | 'consent' | 'notification';

export type ClientFormSuccessType = (client: ClientDetailsType) => void;

interface PropsInterface {
  clientDetails?: ClientDetailsType;
  onClose(): void;
  onSuccess?: ClientFormSuccessType;
  isOpen: boolean;
}

export const NOTIFICATION_CHECKS = ['notification'] as const;
export type NotificationCheck = (typeof NOTIFICATION_CHECKS)[number];

const tabs = ['general', 'consent'] as const;

const ClientForm: React.FunctionComponent<PropsInterface> = ({ clientDetails, onClose, onSuccess, isOpen }) => {
  const { setDialogOpen, setDialogClose } = usePanel();
  const { t } = useTranslation(['page-client', 'common']);

  const [activeTab, setActiveTab] = useState<TabType>('general');
  const {
    formValue,
    errors,
    status,
    handleInputChange,
    handleInputBlur,
    handleDateChange,
    handlePhoneChange,
    handleTagSelect,
    handleClientSourceSelect,
    handleContactSourceChange,
    handleContactSourceSelect,
    handleLanguageChange,
    handleConsentsChange,
    handleConsentFileDrop,
    handleConsentFileRemove,
    handleFormSubmit,
  } = useClientForm(clientDetails, isOpen, onSuccess);

  const rerender = useRef(0);
  const [files, setFiles] = useState([]);

  const handleNotificationCheck = () => () => {};

  const handleDrawerApproveClose = () => {
    onClose();
    setDialogClose();
  };

  const handleDrawerClose = () => {
    setDialogOpen({
      title: t('common:dialog.discard.title'),
      onClose: setDialogClose,
      actions: (
        <div>
          <Button color="secondary" onClick={setDialogClose}>
            {t('common:dialog.discard.return')}
          </Button>
          <Button onClick={handleDrawerApproveClose} variant="contained">
            {t('common:dialog.discard.cta')}
          </Button>
        </div>
      ),
      content: <Paragraph type="size-S-medium">{t('common:dialog.discard.content')}</Paragraph>,
    });
  };

  useEffect(() => {
    if (isOpen && clientDetails?.uuid) {
      clientsApi.consents
        .list(clientDetails?.uuid)
        .then(({ data: filesResponse }) => setFiles(filesResponse?.map((file) => convertAttachmentToDropzone(file))))
        .catch((e) => logError(e));
    }

    if (isOpen) {
      rerender.current += 1;
      setActiveTab('general');
    }
  }, [isOpen]);

  return (
    <Drawer
      footer={
        <ClientCreateFormActions isFetching={status.isFetching || status.isSending} onSubmit={handleFormSubmit} />
      }
      onClose={handleDrawerClose}
      open={isOpen}
      title={t(`clientEdit.title.${clientDetails ? 'edit' : 'create'}`)}
    >
      <form key={rerender.current}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <ButtonSwitcher
              defaultValue={activeTab}
              onChange={(value) => setActiveTab(value as TabType)}
              options={tabs.map((tab) => ({ value: tab, label: t(`drawer.tabs.${tab}`) }))}
              stretch
            />
          </Grid>
          {activeTab === 'general' && (
            <ClientFormUserData
              errors={errors}
              formValue={formValue}
              onClientSourceSelect={handleClientSourceSelect}
              onContactSelectChange={handleContactSourceChange}
              onContactSourceSelect={handleContactSourceSelect}
              onDateChange={handleDateChange}
              onInputBlur={handleInputBlur}
              onInputChange={handleInputChange}
              onLanguageChange={handleLanguageChange}
              onPhoneChange={handlePhoneChange}
              onTagSelect={handleTagSelect}
            />
          )}
          {activeTab === 'consent' && (
            <ClientFormConsents
              consents={{ ...formValue.consents, files }}
              onChange={handleConsentsChange}
              onConsentFileDrop={handleConsentFileDrop}
              onConsentFileRemove={handleConsentFileRemove}
            />
          )}
          {activeTab === 'notification' && (
            <ClientFormNotifications checks={{ one: { sms: true, mail: false } }} onChange={handleNotificationCheck} />
          )}
        </Grid>
      </form>
    </Drawer>
  );
};

export default ClientForm;

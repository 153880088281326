import { useState } from 'react';

import { CheckChangeType, CurrencyChangeType, InputBlurType, InputChangeType } from 'types';
import { ClientType } from 'api/types/clients';
import { ButtonSwitchChangeType } from 'components/ButtonSwitcher';
import mixpanel from 'helpers/mixpanel';

import { VoucherFormValueInterface, VoucherFormValueStateType } from '../types';
import { VoucherErrorValueState, VoucherFormValueState } from '../constatns';
import validateVoucherForm from '../validateVoucherForm';

const useVoucherForm = (initialState = VoucherFormValueState) => {
  const [formValue, setFormValue] = useState(initialState);
  const [errors, setErrors] = useState(VoucherErrorValueState);

  const handleAllValidation = (): boolean => {
    const validationResult = validateVoucherForm.all(formValue);
    setErrors(validationResult);

    if (!Object.values(validationResult).find((result) => result)) {
      return true;
    }

    mixpanel.track('VoucherForm:ValidationFail', { validationResult });
    return false;
  };

  const handleInputChange: InputChangeType = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleInputBlur: InputBlurType = (event) => {
    const { name, value } = event.target;

    const validationResult = validateVoucherForm.single(name, value);
    setErrors((prevState) => ({ ...prevState, [name]: validationResult }));
  };

  const handleCurrencyChange: CurrencyChangeType = (currency) => {
    setFormValue((prevState) => ({ ...prevState, currency }));
  };

  const handlePaymentMethodChange = (paymentMethod) => {
    setFormValue((prevState) => ({ ...prevState, paymentMethod }));
  };

  const handlePaymentTypeChange = (paymentType?: 'value' | 'percent') => {
    setFormValue((prevState) => {
      const newPaymentType = prevState.paymentType === 'value' ? 'percent' : 'value';
      return { ...prevState, paymentType: paymentType || newPaymentType };
    });
  };

  const handleVoucherTypeChange: ButtonSwitchChangeType<VoucherFormValueStateType> = (value) => {
    setFormValue((prevState) => {
      const newPaymentType = value === 'voucher' ? 'value' : prevState.paymentType;
      return { ...prevState, type: value, paymentType: newPaymentType };
    });
  };

  const handleCheckChange: CheckChangeType = (event) => {
    const { name, checked } = event.target;
    setFormValue((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleExpirationDateChange = (date: Date) => {
    setFormValue((prevState) => ({ ...prevState, expirationDate: date }));
  };

  const handlePaymentDateChange = (date: Date) => {
    setFormValue((prevState) => ({ ...prevState, paymentDate: date }));
  };

  const handleNumberInputChange = (name: 'quantity' | 'price' | 'percent', value: number) => {
    setFormValue((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClientChange = (client: ClientType, type: 'buyer' | 'client') => {
    setFormValue((prevState) => ({ ...prevState, [type]: client }));
  };

  const resetForm = () => {
    setFormValue((prevState) => ({ ...VoucherFormValueState, currency: prevState.currency }));
    setErrors(VoucherErrorValueState);
  };

  const setVoucher = (voucher: Partial<VoucherFormValueInterface>, isNew?: boolean) => {
    setFormValue((prevState) => ({ ...(isNew ? VoucherFormValueState : prevState), ...voucher }));
  };

  return {
    errors,
    formValue,
    onInputBlur: handleInputBlur,
    onInputChange: handleInputChange,
    onVoucherTypeChange: handleVoucherTypeChange,
    onExpirationDateChange: handleExpirationDateChange,
    onPaymentMethodChange: handlePaymentMethodChange,
    onNumberInputChange: handleNumberInputChange,
    onPaymentTypeChange: handlePaymentTypeChange,
    onPaymentDateChange: handlePaymentDateChange,
    onCurrencyChange: handleCurrencyChange,
    onAllValidation: handleAllValidation,
    onClientChange: handleClientChange,
    onCheckChange: handleCheckChange,
    setVoucher,
    resetForm,
  } as const;
};

export default useVoucherForm;

import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { marketingApi } from 'api';

const useSmsCounter = (content: string) => {
  const [smsCounter, setSmsCounter] = useState({
    characters: 0,
    charactersRemaining: 160,
    sms: 0,
  });

  const getSmsLengthRequest = debounce(
    (value: string) => {
      marketingApi.campaign.smsCounter({ message: value }).then(({ data: smsCounterResponse }) => {
        setSmsCounter({
          sms: smsCounterResponse.values?.messages,
          characters: smsCounterResponse.values?.length,
          charactersRemaining: smsCounterResponse.values?.remaining,
        });
      });
    },
    [300],
  );

  useEffect(() => {
    if (typeof content === 'string') {
      getSmsLengthRequest(content);
    }
  }, [content]);

  return { smsCounter, handleSmsCount: getSmsLengthRequest } as const;
};

export default useSmsCounter;

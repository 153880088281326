import get from 'lodash/get';
import reduce from 'lodash/reduce';
import { AccountInterface } from 'context/account/types';

const nodeWeightByRole = {
  artist: [
    { key: 'categories', value: 4 },
    { key: 'languages', value: 1 },
    { key: 'city', value: 2 },
    { key: 'country', value: 2 },
    { key: 'phone_verified', value: 5 },
    { key: 'user_data.about_user', value: 3 },
    { key: 'user_data.avatar', value: 4 },
    { key: 'payments.account_holder_name', value: 0 },
    { key: 'payments.account_number', value: 0 },
    { key: 'payments.deposit_large', value: 3 },
    { key: 'payments.deposit_medium', value: 3 },
    { key: 'payments.deposit_small', value: 3 },
    { key: 'booking.large_session_cash', value: 2 },
    { key: 'booking.mid_session_cash', value: 2 },
    { key: 'booking.small_session_cash', value: 2 },
  ],
  manager: [
    { key: 'categories', value: 1 },
    { key: 'languages', value: 1 },
    { key: 'city', value: 2 },
    { key: 'country', value: 2 },
    { key: 'phone_verified', value: 5 },
    { key: 'user_data.about_user', value: 3 },
    { key: 'user_data.avatar', value: 4 },
  ],
  reception: [
    { key: 'city', value: 2 },
    { key: 'country', value: 2 },
    { key: 'phone_verified', value: 5 },
    { key: 'user_data.avatar', value: 4 },
  ],
  piercer: [
    { key: 'languages', value: 1 },
    { key: 'city', value: 2 },
    { key: 'country', value: 2 },
    { key: 'phone_verified', value: 5 },
    { key: 'user_data.avatar', value: 4 },
  ],
};

const getProfileProgression = (account: AccountInterface): number => {
  const nodeWeight = nodeWeightByRole[account.roles_check];
  const nodesWeight = reduce(nodeWeight, (state, item) => state + item.value, 0);
  const unitWeightPercent = 100 / nodesWeight;
  const score = reduce(nodeWeight, (state, node) => state + (get(account, node.key, false) ? node.value : 0), 0);

  return Math.round(score * unitWeightPercent);
};

export default getProfileProgression;

const Palette = {
  primary: {
    main: '#506FDC',
    dark: '#3052C9',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FFFFFF',
    main: '#EAEAEA',
    dark: '#9C9C9C',
  },
  black: {
    light: '#5B5B5B',
    main: '#333333',
    dark: '#000000',
  },
  white: {
    light: '#FFFFFF',
    main: '#FAFAFA',
    dark: '#adadad',
  },
  blue: {
    light: '#272741',
    main: '#191731',
    dark: '#0A051D',
  },
  error: {
    light: '#ff9c9c',
    main: '#FF6B6B',
    dark: '#C20000',
  },
  success: {
    light: '#9effd3',
    main: '#2EFFA9',
    dark: '#188c5d',
    contrastText: '#000',
  },
  warning: {
    light: '#fcbf9c',
    main: '#FFF492',
    dark: '#FFB400',
    contrastText: '#000',
  },
  info: {
    light: '#7c74ff',
    main: '#4138DF',
    dark: '#29238a',
  },
  text: {
    primary: '#f5f5f5',
    secondary: '#e0e0e0',
    link: '#eeeeee',
    disabled: '#757575',
  },
  action: {
    disabled: '#757575',
  },
  background: {
    default: '#181818',
    topbar: '#222',
    toolbar: '#2C2F33',
    drawer: '#111',
    card: '#37393E',
    paper: '#111',
  },
  grey: {
    100: '#DFDFE1',
    200: '#ADADB4',
    300: '#94949E',
    400: '#706c6c',
    600: '#2C2F33',
    700: '#222',
    800: '#181818',
    900: '#111',
  },
  icon: '#b0bec5',
  divider: '#616161',
};

export default Palette;

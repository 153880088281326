import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteValue } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete';

import AutocompleteMobileOption from './AutocompletMobileOption';
import ContentEmptyPlaceholder from '../../ContentEmptyPlaceholder';

export type AutocompleteChangeType = (
  event: SyntheticEvent,
  value: AutocompleteValue<any, any, any, any>,
  handleClose: () => void,
) => void;

type AutocompleteMobileContentTypeProps = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & {
  multiple: boolean;
  autocompleteValue: string;
  handleClose?: () => void;
};

const AutocompleteMobileContent: React.FC<AutocompleteMobileContentTypeProps> = ({
  disabled,
  options,
  multiple,
  value,
  autocompleteValue,
  onChange,
  handleClose,
  getOptionLabel,
  isOptionEqualToValue,
}) => {
  const { t } = useTranslation('common');

  const isInputValueEmpty = () => {
    return !autocompleteValue || autocompleteValue?.length === 0;
  };

  const areValuesSelected = () => {
    return multiple ? value && value?.length > 0 : !!value;
  };

  const valueLabel = getOptionLabel(value);

  if (isInputValueEmpty()) {
    if (areValuesSelected()) {
      if (multiple) {
        return value?.map((v, index) => (
          <AutocompleteMobileOption
            disabled={disabled}
            key={`${getOptionLabel(v)}-${index}`}
            value={v}
            values={value}
            checked
            multiple={multiple}
            onChange={onChange}
            autocompleteValue={autocompleteValue}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
          />
        ));
      }
      return (
        <AutocompleteMobileOption
          disabled={disabled}
          key={valueLabel}
          value={value}
          values={value}
          checked
          multiple={multiple}
          onChange={onChange}
          autocompleteValue={autocompleteValue}
          handleClose={handleClose}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
        />
      );
    }
    return (
      <ContentEmptyPlaceholder
        title={t('components.autocomplete.empty-state.title')}
        subtitle={t('components.autocomplete.empty-state.subtitle')}
      />
    );
  }
  return options?.length > 0 ? (
    options?.map((option, index) => {
      return (
        <AutocompleteMobileOption
          key={`${getOptionLabel(option)}-${index}`}
          value={option}
          values={value}
          checked={
            multiple ? !!value.find((v) => isOptionEqualToValue(v, option)) : isOptionEqualToValue(value, option)
          }
          multiple={multiple}
          onChange={onChange}
          autocompleteValue={autocompleteValue}
          handleClose={() => !multiple && handleClose()}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
        />
      );
    })
  ) : (
    <ContentEmptyPlaceholder
      title={t('components.autocomplete.no-results.title')}
      subtitle={t('components.autocomplete.no-results.subtitle')}
    />
  );
};

export default AutocompleteMobileContent;

import { CalendarApiInterface } from 'api/types/calendar';
import client from 'api/client';
import authorize from '../authorize';

const multipartRequest = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const calendar: CalendarApiInterface = {
  addEvent: (params) => client.post('/scheduler/create_event', params, multipartRequest),
  eventCheckOverlap: (params) => client.post('/scheduler/check_overlap', params),
  removeEvent: (params) => client.post('/scheduler/event_remove', params),
  eventUpdate: (uuid, params) => client.post(`/scheduler/event_update?uuid=${uuid}`, params, multipartRequest),
  eventsList: (params) => client.post('/scheduler/get_events', params),
  eventsListv2: (params, config) => client.post('/scheduler/v2/get_events', params, config),
  eventLogs: (uuid) => client.get(`/system/logs/scheduler?log_id=${uuid}`),
  eventDetails: (uuid) => client.get(`/scheduler/get_event_details?id=${uuid}`),
  addOtherEvent: (params) => client.post('/scheduler/create_other', params, multipartRequest),
  eventOtherUpdate: (uuid, params) => client.post(`/scheduler/update_other?uuid=${uuid}`, params, multipartRequest),
  finalize: (params) => client.post('/scheduler/finalize', params),
  cancelReasons: () => client.get('/scheduler/get_cancel_reasons', authorize()),
  paymentMethods: () => client.get('/scheduler/get_payment_methods', authorize()),
  messageClient: (params) => client.post('/scheduler/v2/sms/send', params),
};

export default calendar;

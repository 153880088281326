import React from 'react';
import cx from 'classnames';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

import useStyles from './styles';

export type CheckboxChangeType = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

interface PropsInterface extends CheckboxProps {
  label: React.ReactElement | string;
  checked: boolean;
  centered?: boolean;
}

const Checkbox: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { label, checked, centered = true, ...restProps } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      control={<MuiCheckbox {...restProps} checked={checked} />}
      label={label}
      className={cx({ [classes['checkbox--not-centered']]: !centered })}
    />
  );
};

export default Checkbox;

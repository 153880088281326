import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'doughnut-widget': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '24px',
    },
  },
  'doughnut-widget--no-text': {
    display: 'grid',
    gridTemplateColumns: 'auto',
  },
  'doughnut-widget__header': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  'doughnut-widget__title': {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  'doughnut-widget__summary': {
    display: 'flex',
    flexDirection: 'column',
  },
  'doughnut-widget__chart': {
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'doughnut-widget__doughnut--no-text': {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';
import layoutValues from 'theme/constatns';

const useStyles = makeStyles((theme) => ({
  topbar: {
    height: layoutValues.topbar.height.sm,
    boxShadow: 'none',
    zIndex: 1001,
    [theme.breakpoints.up('sm')]: {
      height: layoutValues.topbar.height.md,
    },
  },

  topbar__background: {
    backdropFilter: 'initial',
  },

  topbar__toolbar: {
    gap: theme.spacing(2),
  },

  topbar__content: {
    flexGrow: 1,
  },
}));

export default useStyles;

import { NavigationConfigurationRouteType } from 'config';
import { AccountRoleType } from 'types';

const validateRoleAccessToNavigationConfigRoute = ({
  nestedRoute,
  profileRole,
}: {
  nestedRoute: NavigationConfigurationRouteType;
  profileRole: AccountRoleType;
}) => (nestedRoute?.roleAccess ? nestedRoute.roleAccess?.includes(profileRole) : true);

export default validateRoleAccessToNavigationConfigRoute;

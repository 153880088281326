import Palette from '../palette';

export default {
  root: {
    margin: 0,
    padding: 0,
    letterSpacing: 0.2,
    fontSize: '12px',
    // fontSize: '0.75em', // '12px',
    fontWeight: '500 !important',
    lineHeight: 1.33, // '16px',
    color: Palette.grey[300],
  },
};

import React from 'react';
import { Grid } from '@mui/material';

import Divider from 'components/Divider';

import { ReleaseNoteType, ReleaseNotesUpdateRepresentation } from '../ReleaseNotesUpdateRepresentation';

type ReleaseNotesUpdateListPropsType = {
  updates: ReleaseNoteType[];
  onClick(uuid: string): void;
};

const ReleaseNotesUpdateList: React.FC<ReleaseNotesUpdateListPropsType> = ({ updates, onClick }) => {
  const latest = updates?.slice(0, 1)[0] || null;
  const otherReleases = updates?.slice(1, updates?.length) || [];

  return (
    <Grid container spacing={6}>
      {latest && (
        <Grid item xs={12}>
          <Divider title="Latest updates" />
          <ReleaseNotesUpdateRepresentation
            uuid={latest.uuid}
            key={latest.uuid}
            date={latest.date}
            title={latest.title}
            productVersion={latest.productVersion}
            description={latest.description}
            cover={latest.cover}
            onClick={() => onClick(latest.uuid)}
          />
        </Grid>
      )}
      {otherReleases?.length > 0 && (
        <Grid item xs={12}>
          <Divider title="Previous updates" />
          {otherReleases?.map((otherRelease, index) => (
            <>
              <ReleaseNotesUpdateRepresentation
                uuid={otherRelease.uuid}
                key={otherRelease.uuid}
                date={otherRelease.date}
                title={otherRelease.title}
                productVersion={otherRelease.productVersion}
                description={otherRelease.description}
                cover={otherRelease.cover}
                onClick={() => onClick(latest.uuid)}
              />
              {index !== otherReleases.length - 1 && <Divider />}
            </>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ReleaseNotesUpdateList;

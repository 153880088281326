import React from 'react';
import cx from 'classnames';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import useStyles from './styles';

interface PropsInterface {
  isOpen: boolean;
  onClose(): void;
  icon?: React.ReactElement;
  title: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactElement;
  children?: React.ReactNode;
  className?: string;
  fullScreen?: boolean;
  hasBorders?: boolean;
}

const Dialog: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { isOpen, onClose, title, content, actions, children, className, fullScreen, icon, hasBorders = false } = props;
  const classes = useStyles({});

  return (
    <MuiDialog
      classes={{ root: classes['dialog__root'], paper: className }}
      fullScreen={fullScreen}
      onClose={onClose}
      open={!!isOpen}
    >
      <DialogTitle
        classes={{ root: cx(classes['dialog__title'], { [classes['dialog__title--has-borders']]: hasBorders }) }}
      >
        {icon && <span className={classes['dialog__icon']}>{icon}</span>}
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: cx({ [classes['dialog__content--has-borders']]: hasBorders }) }}>
        {content || children}
      </DialogContent>
      {actions && (
        <DialogActions classes={{ root: cx({ [classes['dialog__actions--has-borders']]: hasBorders }) }}>
          {Array.isArray(actions) ? actions.map((action) => action) : actions}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;

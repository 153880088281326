import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'loader-wrapper': {
    position: 'relative',
    width: '100%',
    minHeight: 24,
  },
  loader__animation: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader__content: {
    transition: 'all 0.2s ease-in-out',
  },
}));

export default useStyles;

import Palette from '../palette';

export default {
  root: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
    display: 'block',
    maxWidth: 'calc(100% - 24px)',

    margin: 0,
    padding: 0,
    fontSize: '12px',
    fontWeight: '400 !important',
    lineHeight: 1.33, // '16px',
    color: Palette.grey[100],
    transform: 'translate(12px, 7px)',
    '&.Mui-disabled': {
      color: Palette.black.light,
    },
  },
};

import React, { SyntheticEvent } from 'react';
import { useAutocomplete } from '@mui/base';
import { AutocompleteValue, StandardTextFieldProps } from '@mui/material';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

import TextField from 'components/TextField';
import isMobile from 'helpers/isMobile';
import { InputMessageType } from 'types';

import { TextFieldPropsInterface } from '../TextField/TextField';
import AutocompleteMobile from './components/AutocompletMobile';

export type AutocompleteChangeType = (event: SyntheticEvent, value: AutocompleteValue<any, any, any, any>) => void;

interface PropsInterface extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'isOptionEqualToValue'> {
  isOptionEqualToValue: (option, value) => boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  inputProps?: TextFieldPropsInterface;
  message?: InputMessageType;
  helperText?: string;
}

const Autocomplete: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { inputProps, options, message, helperText, value, isOptionEqualToValue, ...restProps } = props;

  const [inputValue, setInputValue] = React.useState('');
  const cachedOptions = React.useRef([]);

  const onInputChange = (event, value, reason) => {
    if (reason === 'reset' || reason === 'clear') return;

    if (event && event.type === 'blur') {
      setInputValue('');
      return;
    }
    setInputValue(value);
    if (inputProps?.onChange) {
      inputProps?.onChange(event);
    }
  };

  const handleOnChange = (event, value, reason) => {
    if (reason === 'reset' || reason === 'clear') return;
    restProps?.onChange(event, value, reason);
  };

  const { groupedOptions, getInputProps } = useAutocomplete({
    ...restProps,
    options,
    value,
    componentName: 'BaseAutocompleteIntroduction',
    onInputChange,
    inputValue,
    blurOnSelect: false,
    clearOnBlur: false,
    onChange: (event, value, reason) => handleOnChange(event, value, reason),
  });

  cachedOptions.current = groupedOptions?.length > 0 ? groupedOptions : cachedOptions.current;

  const mobile = isMobile();

  if (mobile)
    return (
      <AutocompleteMobile
        {...restProps}
        message={message}
        options={cachedOptions.current}
        autocompleteValue={inputValue}
        value={value}
        resetInputValue={() => setInputValue('')}
        onChange={(event, value, reason) => handleOnChange(event, value, reason)}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField aria-autocomplete="none" autoComplete="off" {...params} {...(inputProps || {})} />
        )}
        textField={
          <TextField
            autoFocus
            aria-autocomplete="none"
            autoComplete="off"
            {...(inputProps || {})}
            {...(getInputProps() as unknown as StandardTextFieldProps)}
            value={inputValue}
            hiddenLabel
            label={null}
            fullWidth
            message={message}
            helperText={helperText}
          />
        }
      />
    );

  return (
    <div>
      <MuiAutocomplete
        {...restProps}
        value={value}
        aria-autocomplete="none"
        autoComplete={false}
        options={options}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            aria-autocomplete="none"
            autoComplete="off"
            message={message}
            helperText={helperText}
            {...params}
            {...(inputProps || {})}
          />
        )}
        {...restProps}
      />
    </div>
  );
};

export default Autocomplete;

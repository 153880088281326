import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import cx from 'classnames';

import InputLabel from '@mui/material/InputLabel';
import ValidationMessage from 'components/ValidationMessage';
import Chip from 'components/Chip';

import useStyles from './styles';
import Paragraph from '../Paragraph';

export type SelectOptionType<T = unknown> = {
  id: string;
  label: string;
  slug?: string;
  disabled?: boolean;
} & T;

export interface PropsInterface<T = unknown> {
  onChange?(value: SelectOptionType<T>, multiValue?: SelectOptionType<T>[]): void;
  defaultOption?: string[];
  options: SelectOptionType<T>[];
  label?: string;
  multiselect?: boolean;
  preventEmpty?: boolean;
  preventRemove?: boolean;
  error?: string;
  value?: string[];
  disabled?: boolean;
}

const ChipSelect: React.FunctionComponent<PropsInterface> = <T,>(props: PropsInterface<T>) => {
  const {
    onChange = () => {},
    multiselect,
    preventEmpty,
    preventRemove,
    defaultOption = [],
    options,
    label,
    error = null,
    value,
    disabled,
  } = props;
  const classes = useStyles({});

  const [active, setActive] = useState<SelectOptionType<T>>(null);
  const [activeItems, setActiveItems] = useState<string[]>(value || defaultOption);

  const handleItemsChange = (item: SelectOptionType<T>, values: string[] = []) => {
    setActive(item);
    setActiveItems(values);

    onChange(
      item,
      values.map((value) => options.find(({ id }) => id === value)).filter((value) => value),
    );
  };

  const handleChange = (value: SelectOptionType<T>) => {
    if (multiselect) {
      const elementIndex = activeItems.indexOf(value.id);
      if (elementIndex >= 0) {
        if (preventRemove) {
          return;
        }
        // remove selected item
        if (activeItems.length === 1 && preventEmpty) {
          // prevent remove last item
          return;
        }
        const newValue = [...activeItems.slice(0, elementIndex), ...activeItems.slice(elementIndex + 1)];
        handleItemsChange(value, newValue);
      } else {
        // add selected item
        const newValue = [...activeItems, value.id];
        handleItemsChange(value, newValue);
      }
    } else if (active?.id === value.id && !preventEmpty) {
      handleItemsChange(null, []);
    } else {
      handleItemsChange(value, [value.id]);
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      setActiveItems(value || []);
    }
  }, [value]);

  return (
    <div className={classes['chip-select']}>
      {label && <Paragraph type="size-XS-medium">{label}</Paragraph>}
      <div>
        {map(options, (value: SelectOptionType<T>) => (
          <Chip
            className={classes['chip-select__option']}
            color="secondary"
            key={value.id}
            label={value.label}
            onClick={() => handleChange(value)}
            variant={activeItems.includes(value.id) ? 'filled' : 'outlined'}
            disabled={value.disabled}
          />
        ))}
      </div>
      <ValidationMessage message={{ type: 'error', message: error }} show={!!error} />
    </div>
  );
};

export default ChipSelect;

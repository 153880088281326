import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { bizRoutes } from 'config';
import isMobile from 'helpers/isMobile';

import useStyles from './styles';

const UpgradeButton = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  if (!isMobile()) {
    return (
      <Button
        className={classes['upgrade-button']}
        component={Link}
        to={bizRoutes.settings.pages.subscription.href}
        variant="outlined"
      >
        {t('upgradeButton.cta')}
      </Button>
    );
  }
  return <div />;
};

export default UpgradeButton;

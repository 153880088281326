import React from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export interface PropsInterface {
  children: string;
}

const PhoneNumber: React.FunctionComponent<PropsInterface> = ({ children }) => {
  if (!children) {
    return <span />;
  }
  const startsWithPlus = children?.[0] === '+';
  const phoneWithPlus = startsWithPlus ? children : `+${children}`;

  if (!isValidPhoneNumber(phoneWithPlus)) {
    return <span>{children}</span>;
  }

  const unifiedPhone = parsePhoneNumber(phoneWithPlus);

  return <span>{unifiedPhone?.formatInternational()}</span>;
};

export default PhoneNumber;

import React from 'react';
import { Route } from 'react-router-dom';
import { PropsInterface as LayoutPropsInterface } from 'layouts/PanelPageLayout/PanelPageLayout';

interface PropsInterface extends LayoutPropsInterface {
  children?: React.ReactNode;
  component: React.FunctionComponent<LayoutPropsInterface>;
  exact: boolean;
  layout: React.FunctionComponent<LayoutPropsInterface | void> | React.FunctionComponent;
  path: string;
  padding?: boolean;
}

const RouteWithLayout: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { layout: Layout, component: Component, slim = false, fluid = false, padding = false, path } = props;

  return (
    <Route
      path={path}
      render={(matchProps) => (
        <Layout fluid={fluid} padding={padding} slim={slim}>
          {/* @ts-ignore */}
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default React.memo(RouteWithLayout);

import React from 'react';
import error from 'assets/img/error.png';

const Error500: React.FunctionComponent = () => {
  return (
    <div
      style={{
        borderRadius: 8,
        background: '#181818',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        position: 'fixed',
        padding: 16,
      }}
    >
      <div style={{ maxWidth: 800, display: 'flex' }}>
        <img
          style={{
            width: 200,
          }}
          src={error}
          alt="broken"
        />
        <div
          style={{
            padding: 16,
          }}
        >
          <div style={{ fontSize: 26 }}>Internal Server Error</div>
          <div style={{ fontSize: 14, padding: '12px 0' }}>
            We are very sorry, it seems there is a problem with our servers. Our developers are already working hard to
            repair it.
          </div>
          <button style={{ padding: 6 }} type="button">
            <a href="/">Home page</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Error500;

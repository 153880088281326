import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import useSmsInformation from 'helpers/hooks/useSmsInformation';
import Alert from 'components/Alert';
import { bizRoutes } from 'config';

type PropsType = {
  minimumSmsAmount?: number;
};

const SmsAlertNotification: React.FunctionComponent<PropsType> = ({ minimumSmsAmount = 0 }) => {
  const { t } = useTranslation('common');
  const { smsAmount, isDisabledByPlan } = useSmsInformation();

  if (isDisabledByPlan) {
    return (
      <Alert
        type="warning"
        message={t('bulk-sms.stepper.summary.card.plan-block.message')}
        action={
          <Button variant="outlined" color="secondary" component={Link} to={bizRoutes.settings.pages.subscription.href}>
            {t('bulk-sms.stepper.summary.card.plan-block.cta')}
          </Button>
        }
      />
    );
  }

  if (smsAmount < minimumSmsAmount && !isDisabledByPlan) {
    return (
      <Alert
        type="warning"
        message={t('bulk-sms.stepper.summary.card.no-sms.message')}
        action={
          <Button variant="outlined" color="secondary" component={Link} to={bizRoutes.settings.pages.sms.href}>
            {t('bulk-sms.stepper.summary.card.no-sms.cta')}
          </Button>
        }
      />
    );
  }

  return <div />;
};

export default SmsAlertNotification;

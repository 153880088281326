import { VoucherType } from 'helpers/api/getVouchers';

import { VoucherFormValueInterface } from '../types';

const convertVoucherToForm = (voucherDetails: Partial<VoucherType>): Partial<VoucherFormValueInterface> => {
  return {
    uuid: voucherDetails.uuid,
    code: voucherDetails.code,
    price: voucherDetails?.discountValue?.toString(),
    percent: voucherDetails?.discountValue?.toString(),
    type: voucherDetails.type,
    quantity: voucherDetails.quantity,
    expirationDate: voucherDetails.expirationDate ? new Date(voucherDetails.expirationDate) : new Date(),
    currency: voucherDetails.currency,
    paymentType: voucherDetails.isPercent ? 'percent' : 'value',
    paymentMethod: voucherDetails.paymentMethod,
    client: voucherDetails.client,
    buyer: voucherDetails.buyer,
    title: voucherDetails.name,
    isLimitedQuantity: !!voucherDetails.quantity && voucherDetails.quantity !== 55555,
    isLimitedDate: !!voucherDetails.expirationDate,
    isBuyerSame: voucherDetails?.buyer?.uuid === voucherDetails?.client?.uuid,
    isDifferentPaymentDate: !!voucherDetails.paidDate,
    paymentDate: voucherDetails.paidDate ? new Date(voucherDetails.paidDate) : new Date(),
  };
};

export default convertVoucherToForm;

import { CalendarLayoutVariantsType } from 'context/calendarLayout/types';

export const MIN_TIME_LENGTH = 5;

export const CALENDAR_LAYOUT_VARIANTS: { [key: string]: { key: CalendarLayoutVariantsType } } = {
  weekly: { key: 'week' },
  reception: { key: 'reception' },
  monthly: { key: 'month' },
};

export const CALENDAR_BOUNDS = {
  minDate: new Date(2000, 0, 1),
  maxDate: new Date(2100, 11, 31),
  birthdayMaxDate: new Date(),
};

const electronTitleBar = 0;

const layoutValues = {
  spacing: 4,
  topbar: {
    height: {
      md: 64,
      sm: 56,
    },
  },
  toolbar: {
    height: 60,
  },
  appBar: {
    height: {
      default: 56,
    },
  },
  sidebar: {
    width: {
      slim: 112,
      default: 240,
    },
    logoSize: 32,
    menuItem: {
      iconSize: 24,
    },
  },
  borderRadius: 8,
  electron: {
    titleBar: electronTitleBar,
  },
  speedDial: {
    cta: {
      size: 48,
    },
  },
  defaultStickyTopPosition: import.meta.env.VITE_APP_IS_ELECTRON ? electronTitleBar : 0,
  content: {
    form: {
      maxWidth: 420,
    },
  },
};

export default layoutValues;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import LoaderWrapper from 'components/LoaderWrapper';

interface PropsInterface {
  onSubmit(e): void;
  isFetching: boolean;
}

const ClientCreateFormActions: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { onSubmit, isFetching } = props;

  const { t } = useTranslation('page-client');
  const translations = {
    save: t('clientEdit.actions.save'),
  };

  return (
    <Button color="primary" onClick={onSubmit} variant="contained" fullWidth disabled={isFetching}>
      <LoaderWrapper loading={isFetching} overlay={0}>
        {translations.save}
      </LoaderWrapper>
    </Button>
  );
};

export default ClientCreateFormActions;

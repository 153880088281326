import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'tester-mode': {
    zIndex: 9999999,
    background: '#FF0',
    color: '#00F',
    position: 'fixed',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    margin: 'auto',
    padding: theme.spacing(0, 4),
  },
}));
export default useStyles;

import React, { Suspense, useCallback, useEffect, useReducer, useState } from 'react';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import find from 'lodash/find';

import { Icon, IconButton } from '@mui/material';

import INKsearchLoader from 'components/INKsearchLoader/INKsearchLoader';
import ErrorBoundary from 'components/ErrorBoundary';
import Dialog from 'components/Dialog';
import Notifications from 'containers/Notifications/Notifications';
import VoucherFormDrawer from 'containers/VoucherFormDrawer';
import useWindowDimensions from 'helpers/hooks/useWindowDimensions';
import getUserCustomLang from 'helpers/intl/getUserCustomLang';
import importLocale from 'helpers/intl/importLocale';
import isElectron from 'helpers/electron/isElectron';
import useAccount from 'helpers/hooks/useAccount';
import isMobileCheck from 'helpers/isMobile';
import lazyLoad from 'helpers/lazyLoad';
import { EventFinalizationParamsType } from 'api/types/calendar';
import config, { availableLanguages } from 'config';
import { ToastType } from 'types';

import { CURRENCY_LOCALE } from '../../constants/constants';
import ClientMessageForm from '../../containers/ClientMessageForm';
import { initialState, reducer } from './panelReducer';
import {
  removeToastAction,
  setClientDetailsCloseAction,
  setClientDetailsOpenAction,
  setDialogCloseAction,
  setDialogOpenAction,
  setElectronViewTypeAction,
  setEventDetailsCloseAction,
  setEventDetailsOpenAction,
  setEventFinalizationOpenAction,
  setEventLogsOpenAction,
  setEventFormDoneAction,
  setEventFormOpenAction,
  setEventRemoveCloseAction,
  setEventRemoveDoneAction,
  setEventRemoveOpenAction,
  setLoginRedirectAction,
  setMobileDeviceTypeAction,
  setNotificationsOpenAction,
  setPhotoModalCloseAction,
  setPhotoModalOpenAction,
  setSchedulerViewTypeAction,
  setSidebarOpenAction,
  updateEventNotificationsAction,
  setInkPassportOpenAction,
  setInkPassportCloseAction,
  postEventFinalizationAction,
  setReleaseDetailsOpenAction,
  setReleaseDetailsCloseAction,
  setBulkSmsFormCloseAction,
  setBulkSmsFormOpenAction,
  setBulkSmsDetailsOpenAction,
  setBulkSmsDetailsCloseAction,
  setVoucherFormOpenAction,
  setVoucherFormCloseAction,
  setVoucherPreviewOpenAction,
  setVoucherPreviewCloseAction,
  setClientMessageFormCloseAction,
  setClientMessageFormOpenAction,
} from './panelActions';
import {
  BulkSmsDetailsOpenParamsInterface,
  BulkSmsFormOpenParamsInterface,
  EventFinalizationOpenParamsInterface,
  EventFormDoneParamsInterface,
  EventFormOpenParamsInterface,
  EventFormVoidInterface,
  EventLogsOpenParamsInterface,
  InkPassportOpenParamsInterface,
  PanelContextValue,
  SetClientDetailsOpenParamsInterface,
  SetClientMessageOpenParamsInterface,
  SetDialogOpenParamsInterface,
  SetEventDetailsOpenParamsInterface,
  SetEventRemoveDoneParamsInterface,
  SetEventRemoveOpenParamsInterface,
  SetLoginRedirectParamsInterface,
  SetPhotoModalOpenParamsInterface,
  SetReleaseDetailsOpenParamsInterface,
  SetSchedulerViewTypeParamsInterface,
  SetVoucherFormOpenParamsInterface,
  SetVoucherPreviewOpenParamsInterface,
} from './types';

const EventLogsDrawer = lazyLoad(() => import('containers/EventLogsDrawer/EventLogsDrawer'));
const BulkSmsDetails = lazyLoad(() => import('containers/BulkSmsDetails/BulkSmsDetails'));
const EventDetailsDrawer = lazyLoad(() => import('containers/EventDetailsDrawer'));
const EventFinalization = lazyLoad(() => import('containers/EventFinalization'));
const ReleaseDetails = lazyLoad(() => import('containers/ReleaseDetailsDrawer'));
const ClientDetails = lazyLoad(() => import('containers/ClientDetails'));
const EventRemove = lazyLoad(() => import('containers/EventRemove'));
const InkPassport = lazyLoad(() => import('containers/InkPassport'));
const BulkSmsForm = lazyLoad(() => import('containers/BulkSmsForm'));
const PhotoModal = lazyLoad(() => import('components/PhotoModal'));
const EventForm = lazyLoad(() => import('containers/EventForm'));
const EventFormV2 = lazyLoad(() => import('containers/EventFormV2'));
const VoucherPreview = lazyLoad(() => import('containers/VoucherPreview/VoucherPreview'));

export const PanelContext = React.createContext<PanelContextValue>(null);

interface PropsInterface {
  children: React.ReactNode;
}

let toastDisplayed = [];

const PanelProvider: React.FunctionComponent<PropsInterface> = ({ children }) => {
  const { t, i18n } = useTranslation('common');

  const { state: account } = useAccount();
  const { width } = useWindowDimensions();
  const location = useLocation<{ redirect: string }>();
  const isMobile = isMobileCheck() || width < 765;

  const defaultViewType = isMobile
    ? account.settings?.schedulerPeriodMobile?.key
    : account.settings?.schedulerPeriod?.key;

  const initialCurrency =
    find(CURRENCY_LOCALE, (currency) => currency.value === account.data?.payments?.currency) ||
    CURRENCY_LOCALE[i18n.language];

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isAuth: account.isLogged,
    isMobile,
    currency: initialCurrency,
    scheduler: {
      ...initialState.scheduler,
      viewType: defaultViewType || initialState.scheduler.viewType,
    },
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(true);

  const toastStore = (id) => {
    toastDisplayed = [...toastDisplayed, id];
  };

  const toastRemove = (id) => {
    toastDisplayed = [...toastDisplayed.filter((key) => id !== key)];
  };

  const handleClientEdit = () => {
    setClientDetailsOpenAction(dispatch, null);
  };

  const handleClientDetailsOpen = (params: SetClientDetailsOpenParamsInterface) =>
    setClientDetailsOpenAction(dispatch, params);

  const handleClientDetailsClose = () => {
    setClientDetailsCloseAction(dispatch);
  };

  const handleDialogClose = () => setDialogCloseAction(dispatch);

  const handleEventLogsOpen = useCallback((params: EventLogsOpenParamsInterface) => {
    setEventLogsOpenAction(dispatch, params);
  }, []);

  const handleBulkSmsFormOpen = useCallback((params?: BulkSmsFormOpenParamsInterface) => {
    setBulkSmsFormOpenAction(dispatch, params);
  }, []);

  const handleBulkSmsFormClose = useCallback(() => {
    setBulkSmsFormCloseAction(dispatch);
  }, []);

  const handleBulkSmsDetailsOpen = useCallback((params?: BulkSmsDetailsOpenParamsInterface) => {
    setBulkSmsDetailsOpenAction(dispatch, params);
  }, []);

  const handleBulkSmsDetailsClose = useCallback(() => {
    setBulkSmsDetailsCloseAction(dispatch);
  }, []);

  const handleEventLogsClose: EventFormVoidInterface = useCallback(() => {
    dispatch({ type: 'SET_EVENT_HISTORY_CLOSE' });
  }, []);

  const handleEventFormOpen = useCallback((params: EventFormOpenParamsInterface) => {
    setEventFormOpenAction(dispatch, params);
  }, []);

  const handleEventFormDone = (params: EventFormDoneParamsInterface) => {
    setEventFormDoneAction(dispatch, params);
  };

  const handleEventFormClose: EventFormVoidInterface = useCallback(() => {
    dispatch({ type: 'SET_EVENT_FORM_CLOSE' });
  }, []);

  const handleEventFinalizationOpen = useCallback((params: EventFinalizationOpenParamsInterface) => {
    setEventFinalizationOpenAction(dispatch, params);
  }, []);

  const handleEventFinalizationSubmit = async (params: EventFinalizationParamsType, preventClose) => {
    await postEventFinalizationAction(dispatch, params, preventClose);
  };

  const handleInkPassportOpen = useCallback((params: InkPassportOpenParamsInterface) => {
    setInkPassportOpenAction(dispatch, params);
  }, []);

  const handleInkPassportClose = useCallback(() => {
    setInkPassportCloseAction(dispatch);
  }, []);

  const handleDialogOpen = (params: SetDialogOpenParamsInterface) => {
    setDialogOpenAction(dispatch, params);
  };

  const handleEventRemoveOpen = (params: SetEventRemoveOpenParamsInterface) => {
    setEventRemoveOpenAction(dispatch, params);
  };

  const handleEventRemoveDone = (params: SetEventRemoveDoneParamsInterface) => {
    setEventRemoveDoneAction(dispatch, params);
  };

  const handleEventRemoveClose = () => {
    setEventRemoveCloseAction(dispatch);
  };

  const handlePhotoModalOpen = (params: SetPhotoModalOpenParamsInterface) => {
    setPhotoModalOpenAction(dispatch, params);
  };

  const handlePhotoModalClose = () => {
    setPhotoModalCloseAction(dispatch);
  };

  const handleReleaseDetailsOpen = (params?: SetReleaseDetailsOpenParamsInterface) => {
    setReleaseDetailsOpenAction(dispatch, params);
  };

  const handleReleaseDetailsClose = () => {
    setReleaseDetailsCloseAction(dispatch);
  };

  const handleNotificationsOpen = (params) => {
    setNotificationsOpenAction(dispatch, params);
  };

  const handleEventNotificationsUpdate = (params) => {
    updateEventNotificationsAction(dispatch, params);
  };

  const handleSchedulerViewTypeChange = (params: SetSchedulerViewTypeParamsInterface) => {
    setSchedulerViewTypeAction(dispatch, params);
  };

  const setLoginRedirect = (params: SetLoginRedirectParamsInterface) => {
    setLoginRedirectAction(dispatch, params);
  };

  const setEventDetailsOpen = (params: SetEventDetailsOpenParamsInterface) => {
    setEventDetailsOpenAction(dispatch, params);
  };

  const setEventDetailsClose = () => {
    setEventDetailsCloseAction(dispatch);
  };

  const handleVoucherFormOpen = (params: SetVoucherFormOpenParamsInterface) => {
    setVoucherFormOpenAction(dispatch, params);
  };

  const handleVoucherFormClose = () => {
    setVoucherFormCloseAction(dispatch);
  };

  const handleVoucherPreviewOpen = (params: SetVoucherPreviewOpenParamsInterface) => {
    setVoucherPreviewOpenAction(dispatch, params);
  };

  const handleVoucherPreviewClose = () => {
    setVoucherPreviewCloseAction(dispatch);
  };

  const handleClientMessageFormOpen = (params: SetClientMessageOpenParamsInterface) => {
    setClientMessageFormOpenAction(dispatch, params);
  };

  const handleClientMessageFormClose = () => {
    setClientMessageFormCloseAction(dispatch);
  };

  useEffect(() => {
    setMobileDeviceTypeAction(dispatch, isMobile);

    const customLang = window.localStorage.getItem('customLang');
    if (customLang && availableLanguages.includes(customLang)) {
      i18n.changeLanguage(customLang);
    }

    if (isMobile) {
      setSidebarOpenAction(dispatch, false);
    } else {
      setSidebarOpenAction(dispatch, true);
    }

    if (isElectron()) {
      setElectronViewTypeAction(dispatch, 'windows');
    }

    setLoading(false);
  }, [isMobile]);

  useEffect(() => {
    state.toasts.list.map(({ message, options }: ToastType) => {
      if (toastDisplayed.includes(options?.key)) {
        return null;
      }
      enqueueSnackbar(message, {
        key: options?.key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options?.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, key) => {
          toastRemove(key);
          removeToastAction(dispatch, key);
        },
        action: (
          <>
            {options?.action && options.action}
            <IconButton onClick={() => closeSnackbar(options?.key)}>
              <Icon>close</Icon>
            </IconButton>
          </>
        ),
      });
      toastStore(options?.key);
      return null;
    });
  }, [state.toasts, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    window.addEventListener('online', () => closeSnackbar());
    window.addEventListener('offline', () =>
      enqueueSnackbar(t('messages.no-internet-connection'), { variant: 'error', autoHideDuration: null }),
    );
  }, []);

  useEffect(() => {
    if (location.state?.redirect?.length > 1) {
      setLoginRedirect({ url: location.state.redirect });
    }
  }, [location.state?.redirect]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const userLang = getUserCustomLang();
      const locale = (await importLocale(userLang)).default;
      setDefaultOptions({ locale });
      setLoading(false);
    })();
  }, [i18n.language]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const returnContext: PanelContextValue = {
    state,
    dispatch,
    setLoginRedirect,
    setEventLogsOpen: handleEventLogsOpen,
    setEventLogsClose: handleEventLogsClose,
    setEventFormOpen: handleEventFormOpen,
    setEventFormDone: handleEventFormDone,
    setEventFormClose: handleEventFormClose,
    setEventFinalizationOpen: handleEventFinalizationOpen,
    eventFinalizationSubmit: handleEventFinalizationSubmit,
    setInkPassportOpen: handleInkPassportOpen,
    setInkPassportClose: handleInkPassportClose,
    setDialogOpen: handleDialogOpen,
    setDialogClose: handleDialogClose,
    setEventRemoveOpen: handleEventRemoveOpen,
    setEventRemoveDone: handleEventRemoveDone,
    setEventRemoveClose: handleEventRemoveClose,
    setClientDetailsOpen: handleClientDetailsOpen,
    setClientDetailsClose: handleClientDetailsClose,
    setNotificationsOpen: handleNotificationsOpen,
    updateEventNotifications: handleEventNotificationsUpdate,
    setPhotoModalOpen: handlePhotoModalOpen,
    setPhotoModalClose: handlePhotoModalClose,
    setReleaseDetailsOpen: handleReleaseDetailsOpen,
    setReleaseDetailsClose: handleReleaseDetailsClose,
    setSchedulerViewType: handleSchedulerViewTypeChange,
    setEventDetailsOpen,
    setEventDetailsClose,
    setClientMessageFormOpen: handleClientMessageFormOpen,
    setClientMessageFormClose: handleClientMessageFormClose,
    setBulkSmsFormClose: handleBulkSmsFormClose,
    setBulkSmsFormOpen: handleBulkSmsFormOpen,
    setBulkSmsDetailsClose: handleBulkSmsDetailsClose,
    setBulkSmsDetailsOpen: handleBulkSmsDetailsOpen,
    setVoucherFormOpen: handleVoucherFormOpen,
    setVoucherFormClose: handleVoucherFormClose,
    setVoucherPreviewOpen: handleVoucherPreviewOpen,
    setVoucherPreviewClose: handleVoucherPreviewClose,
  };

  if (isLoading) {
    return <INKsearchLoader>Initializing panel...</INKsearchLoader>;
  }

  return (
    <PanelContext.Provider value={returnContext}>
      {children}
      <ErrorBoundary reload>
        <Dialog {...state.dialog} onClose={handleDialogClose} />
      </ErrorBoundary>

      {state.isAuth && (
        <>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.clientDetails.rendered && (
                <ClientDetails
                  clientUuid={state.clientDetails.uuid}
                  isOpen={state.clientDetails.isOpen}
                  onClose={handleClientDetailsClose}
                  onEdit={handleClientEdit}
                  onInkClick={handlePhotoModalOpen}
                />
              )}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {(config.notifications.isActive || config.notifications.isActiveDev) && <Notifications />}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.eventFinalization.rendered && account.data?.access_permissions?.includes('finalize-event') && (
                <EventFinalization />
              )}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.inkPassport.rendered && account.data?.access_permissions?.includes('finalize-event') && (
                <InkPassport />
              )}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.eventForm.rendered &&
                config.eventFrom.version === 1 &&
                (account.data?.access_permissions?.includes('create-event') ||
                  account.data?.access_permissions?.includes('modify-event')) && <EventForm />}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.eventForm.rendered &&
                config.eventFrom.version === 2 &&
                (account.data?.access_permissions?.includes('create-event') ||
                  account.data?.access_permissions?.includes('modify-event')) && <EventFormV2 />}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.photoModal.rendered && (
                <PhotoModal
                  isOpen={state.photoModal.isOpen}
                  src={state.photoModal.src}
                  onClose={handlePhotoModalClose}
                />
              )}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.releaseDetails.rendered && (
                <ReleaseDetails
                  isOpen={state.releaseDetails.isOpen}
                  uuid={state.releaseDetails.uuid}
                  onClose={handleReleaseDetailsClose}
                />
              )}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>{state.bulkSmsForm.rendered && <BulkSmsForm />}</Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>{state.bulkSmsDetails.rendered && <BulkSmsDetails />}</Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              {state.eventRemove.rendered && account.data?.access_permissions?.includes('delete-event') && (
                <EventRemove />
              )}
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              <EventDetailsDrawer />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              <EventLogsDrawer />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              <VoucherFormDrawer />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              <VoucherPreview />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary reload>
            <Suspense fallback={null}>
              <ClientMessageForm />
            </Suspense>
          </ErrorBoundary>
        </>
      )}
    </PanelContext.Provider>
  );
};

export default PanelProvider;

import Palette from '../palette';

export default {
  root: {
    '& ol': {
      flexWrap: 'nowrap',

      '& > *': {
        flex: '0 1 auto',
        color: Palette.secondary.dark,

        '&:last-of-type': {
          flex: '1 9 auto',
          width: 10,
        },
      },
    },
  },
};

import { VouchersListResponseType, VouchersResponseType, VoucherTypeType } from 'api/types/vouchers';
import { ClientType } from 'api/types/clients';
import { vouchersApi } from 'api';
import convertKeyToCurrency from 'helpers/money/convertKeyToCurrency';
import { CurrencyType } from 'types';

export type VoucherType = {
  uuid: string;
  status: string;
  name: string;
  code: string;
  isPercent: boolean;
  discountValue: number;
  isPaid: boolean;
  description: string;
  quantity: number;
  expirationDate: string;
  userId: number;
  currency: CurrencyType;
  type: VoucherTypeType;
  usageCount: number;
  client: ClientType;
  buyer: ClientType;
  paymentMethod: string;
  inActive: boolean;
  paidDate?: string;
};

export type VoucherListType = {
  discount?: VoucherType[];
  voucher?: VoucherType[];
};

export const convertVoucherResponse = (vouchersResponse: VouchersResponseType): VoucherType => {
  if (!vouchersResponse) {
    return null;
  }

  const usageCount = vouchersResponse.usage_count ?? 0;
  const quantity = vouchersResponse.quantity === 55555 ? null : vouchersResponse.quantity;

  let status = 'active';
  if (vouchersResponse.type === 'discount' && usageCount && quantity && usageCount >= quantity) status = 'used';
  if (vouchersResponse.inactive) status = 'deactivated';

  return {
    uuid: vouchersResponse.uuid,
    status,
    name: vouchersResponse.name,
    code: vouchersResponse.code,
    isPercent: !!vouchersResponse.is_percent,
    discountValue: vouchersResponse.discount_value,
    isPaid: !!vouchersResponse.is_paid,
    description: vouchersResponse.description,
    quantity,
    expirationDate: vouchersResponse.exp_date,
    userId: vouchersResponse.user_id,
    currency: convertKeyToCurrency(vouchersResponse.currency),
    type: vouchersResponse.type,
    usageCount,
    client: vouchersResponse.client,
    buyer: vouchersResponse.buyer,
    paymentMethod: vouchersResponse.payment_method,
    inActive: !!vouchersResponse.inactive,
    paidDate: vouchersResponse.paid_date,
  };
};

export const convertVouchersListResponse = (vouchersListResponse: VouchersListResponseType): VoucherListType => ({
  discount: vouchersListResponse?.discount?.map((voucher) => convertVoucherResponse(voucher)),
  voucher: vouchersListResponse?.voucher?.map((voucher) => convertVoucherResponse(voucher)),
});

export const getVoucher = async (voucherId: string) => {
  if (voucherId) {
    const { data: voucherResponse } = await vouchersApi.getDetails(voucherId);
    return convertVoucherResponse(voucherResponse);
  }
  return null;
};

export const getVouchers = async (employeeId = ''): Promise<VoucherListType> => {
  const { data: vouchersResponse } = await vouchersApi.list(employeeId);

  return convertVouchersListResponse(vouchersResponse);
};

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialog__root: {
    zIndex: '1300',
  },
  dialog__title: {
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 700,
    fontSize: '1.1em',
    letterSpacing: '2px',
    lineHeight: 1.4,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  'dialog__title--has-borders': {
    borderBottom: `1px solid ${theme.palette.grey[600]}`,
  },
  'dialog__content--has-borders': {
    '& > *': {
      paddingTop: theme.spacing(6),
    },
  },
  'dialog__actions--has-borders': {
    borderTop: `1px solid ${theme.palette.grey[600]}`,
  },

  dialog__icon: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;

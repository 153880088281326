import { v4 } from 'uuid';
import { CURRENCY_LOCALE } from 'constants/constants';
import { PanelActionType, PanelStateInterface } from './types';

export const initialState: PanelStateInterface = {
  isAuth: false,
  isMobile: true,
  loginRedirect: '',
  electronEnvironment: null,
  isSidebarOpen: true,
  currency: CURRENCY_LOCALE.pl,
  scheduler: {
    date: new Date(),
    viewType: 'week',
    eventId: null,
  },
  clientDetails: {
    isOpen: false,
    uuid: null,
    rendered: false,
  },
  dialog: {
    isOpen: false,
    title: null,
    content: null,
    actions: null,
  },
  notifications: {
    type: null,
    events: [],
    hash: null,
  },
  toasts: {
    list: [],
    max: 3,
    timeout: 3000,
  },
  alerts: {
    list: [],
  },
  survey: {
    isOpen: false,
    rendered: false,
  },
  eventFinalization: {
    isOpen: false,
    uuid: null,
    result: null,
    onSuccess: null,
    onFailure: null,
    rendered: false,
  },
  inkPassport: {
    isOpen: false,
    eventUuid: null,
    rendered: false,
  },
  eventLogs: {
    isOpen: false,
    rendered: false,
    eventUuid: null,
  },
  eventForm: {
    isOpen: false,
    isClone: false,
    isEdit: false,
    event: null,
    workHours: null,
    field: {
      dayId: null,
      dayToId: null,
      timeId: null,
      timeToId: null,
      resource: null,
    },
    rendered: false,
    result: {
      uuid: null,
      succeed: false,
      fail: false,
    },
    onClose: null,
  },
  eventRemove: {
    isOpen: false,
    uuid: null,
    rendered: false,
    result: {
      uuid: null,
      succeed: false,
      fail: false,
    },
  },
  eventDetails: {
    isOpen: false,
    rendered: false,
    eventId: null,
  },
  photoModal: {
    isOpen: false,
    rendered: false,
    src: null,
  },
  releaseDetails: {
    isOpen: false,
    rendered: false,
    uuid: null,
  },
  bulkSmsForm: {
    isOpen: false,
    rendered: false,
    uuid: null,
  },
  bulkSmsDetails: {
    isOpen: false,
    rendered: false,
    uuid: null,
  },
  clientMessageForm: {
    isOpen: false,
    client: null,
    rendered: false,
    uuid: null,
  },
  voucherForm: {
    isOpen: false,
    type: 'voucher',
    rendered: false,
    uuid: null,
  },
  voucherPreview: {
    isOpen: false,
    rendered: false,
    uuid: null,
  },
};

export function reducer(state: PanelStateInterface, action: PanelActionType): PanelStateInterface {
  switch (action.type) {
    case 'SET_LOGIN_REDIRECT':
      return { ...state, loginRedirect: action.payload.url };
    case 'SET_MOBILE_DEVICE_TYPE':
      return { ...state, isMobile: action.payload };
    case 'SET_DESKTOP_DEVICE_TYPE':
      return { ...state, electronEnvironment: { device: action.payload } };
    case 'SET_SIDEBAR_OPEN':
      return { ...state, isSidebarOpen: action.payload };
    case 'UPDATE_SCHEDULER':
      return { ...state, scheduler: { ...state.scheduler, ...action.payload } };
    case 'ENQUEUE_TOAST':
      return {
        ...state,
        toasts: { ...state.toasts, list: [...state.toasts.list, action.payload] },
      };
    case 'CLEAR_TOAST_STORE':
      return {
        ...state,
        toasts: { ...state.toasts, list: [] },
      };
    case 'REMOVE_TOAST':
      return {
        ...state,
        toasts: {
          ...state.toasts,
          list: [...state.toasts.list.filter(({ options }) => options?.key !== action.payload)],
        },
      };
    case 'SET_CLIENT_DETAILS_OPEN':
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          isOpen: !!action.payload,
          uuid: action.payload.uuid,
          rendered: true,
          onEdit: action.payload.onEdit,
        },
      };
    case 'SET_CLIENT_DETAILS_CLOSE':
      return {
        ...state,
        clientDetails: { ...state.clientDetails, isOpen: false },
      };
    case 'SET_SURVEY_OPEN': {
      return { ...state, survey: { ...state.survey, isOpen: action.payload, rendered: true } };
    }
    case 'SET_EVENT_FINALIZATION_OPEN': {
      return {
        ...state,
        eventFinalization: {
          ...state.eventFinalization,
          isOpen: !!action.payload.uuid,
          result: !action.payload ? state.eventFinalization.result : null,
          forceCancel: !!action.payload?.forceCancel,
          rendered: true,
          uuid: null,
          ...action.payload,
        },
      };
    }
    case 'SET_EVENT_FINALIZATION_DONE': {
      return {
        ...state,
        eventFinalization: {
          ...state.eventFinalization,
          isOpen: action.payload.preventClose,
          // isOpen: false,
          forceCancel: false,
          uuid: action.payload.preventClose ? null : state.eventFinalization.uuid,
          // uuid: null,
          result: { uuid: action.payload.uuid, isCanceled: action.payload.isCanceled },
          rendered: true,
        },
      };
    }
    case 'SET_INKPASSPORT_OPEN': {
      return {
        ...state,
        inkPassport: {
          isOpen: true,
          eventUuid: action.payload.uuid,
          onFailure: action.payload.onFailure,
          onSuccess: action.payload.onSuccess,
          rendered: true,
        },
      };
    }
    case 'SET_INKPASSPORT_DONE': {
      return {
        ...state,
        inkPassport: {
          ...state.inkPassport,
          isOpen: false,
          eventUuid: null,
        },
      };
    }
    case 'SET_INKPASSPORT_CLOSE': {
      return {
        ...state,
        inkPassport: {
          ...state.inkPassport,
          isOpen: false,
          eventUuid: null,
        },
      };
    }
    case 'SET_DIALOG_CLOSE':
      return { ...state, dialog: { ...state.dialog, isOpen: false, actions: null, content: null, title: null } };
    case 'SET_DIALOG_OPEN':
      return { ...state, dialog: { ...state.dialog, isOpen: true, ...action.payload } };
    case 'SET_NOTIFICATION_OPEN':
      return { ...state, notifications: { ...state.notifications, type: action.payload } };
    case 'SET_BULK_SMS_FORM_OPEN':
      return {
        ...state,
        bulkSmsForm: { ...state.bulkSmsForm, uuid: action.payload.uuid, isOpen: true, rendered: true },
      };
    case 'SET_BULK_SMS_FORM_CLOSE':
      return {
        ...state,
        bulkSmsForm: { ...initialState.bulkSmsForm, isOpen: false, uuid: null, rendered: true },
      };
    case 'SET_BULK_SMS_DETAILS_OPEN':
      return {
        ...state,
        bulkSmsDetails: { ...state.bulkSmsDetails, uuid: action.payload.uuid, isOpen: true, rendered: true },
      };
    case 'SET_BULK_SMS_DETAILS_CLOSE':
      return {
        ...state,
        bulkSmsDetails: { ...initialState.bulkSmsDetails, isOpen: false, uuid: null, rendered: true },
      };
    case 'SET_EVENT_HISTORY_OPEN':
      return {
        ...state,
        eventLogs: {
          ...state.eventLogs,
          isOpen: true,
          eventUuid: action.payload.uuid,
          rendered: true,
        },
      };
    case 'SET_EVENT_HISTORY_CLOSE':
      return {
        ...state,
        eventLogs: { ...initialState.eventLogs, isOpen: false },
      };
    case 'SET_EVENT_FORM_OPEN':
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          isOpen: true,
          field: { ...state.eventForm.field, ...action.payload.field },
          event: action.payload.event,
          isEdit: action.payload.isEdit,
          rendered: true,
          result: null,
        },
      };
    case 'SET_EVENT_FORM_CLOSE':
      return {
        ...state,
        eventForm: { ...initialState.eventForm, rendered: true, result: null },
      };
    case 'SET_EVENT_FORM_DONE':
      return {
        ...state,
        eventForm: {
          ...state.eventForm,
          isOpen: false,
          result: { uuid: action.payload.uuid, succeed: true, fail: false },
        },
      };
    case 'SET_EVENT_REMOVE_OPEN':
      return {
        ...state,
        eventRemove: {
          isOpen: true,
          uuid: action.payload.uuid,
          rendered: true,
        },
      };
    case 'SET_EVENT_REMOVE_DONE':
      return {
        ...state,
        eventRemove: {
          ...initialState.eventRemove,
          rendered: true,
          result: { uuid: action.payload.uuid, succeed: true, fail: false },
        },
      };
    case 'SET_EVENT_REMOVE_CLOSE':
      return {
        ...state,
        eventRemove: { ...initialState.eventRemove, rendered: true },
      };
    case 'SET_PHOTO_MODAL_OPEN':
      return {
        ...state,
        photoModal: { isOpen: true, src: action.payload.src, rendered: true },
      };
    case 'SET_PHOTO_MODAL_CLOSE':
      return {
        ...state,
        photoModal: { ...state.photoModal, isOpen: false },
      };
    case 'UPDATE_EVENT_NOTIFICATION':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          events: [...state.notifications.events, ...action.payload],
          hash: action.payload?.length > 0 ? v4() : null,
        },
      };
    case 'SET_SCHEDULER_VIEW_TYPE':
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          viewType: action.payload.viewTypeKey,
        },
      };
    case 'SET_EVENT_DETAILS_OPEN':
      return {
        ...state,
        eventDetails: {
          ...state.eventDetails,
          isOpen: true,
          rendered: true,
          eventId: action.payload.id,
        },
      };
    case 'SET_EVENT_DETAILS_CLOSE':
      return {
        ...state,
        eventDetails: {
          ...state.eventDetails,
          isOpen: false,
        },
      };

    case 'SET_CLIENT_MESSAGE_OPEN':
      return {
        ...state,
        clientMessageForm: {
          isOpen: true,
          rendered: true,
          client: action.payload.client,
          uuid: action.payload.uuid,
        },
      };
    case 'SET_CLIENT_MESSAGE_CLOSE':
      return {
        ...state,
        clientMessageForm: {
          ...state.clientMessageForm,
          client: null,
          uuid: null,
          isOpen: false,
        },
      };

    case 'SET_RELEASE_DETAILS_OPEN':
      return {
        ...state,
        releaseDetails: { isOpen: true, uuid: action.payload.uuid, rendered: true },
      };
    case 'SET_RELEASE_DETAILS_CLOSE':
      return {
        ...state,
        releaseDetails: { ...state.releaseDetails, isOpen: false },
      };

    case 'SET_VOUCHER_FORM_OPEN':
      return {
        ...state,
        voucherForm: {
          isOpen: true,
          type: action.payload?.type || 'voucher',
          uuid: action.payload?.uuid,
          voucher: action.payload?.voucher,
          onSuccess: action.payload?.onSuccess,
          rendered: true,
        },
      };
    case 'SET_VOUCHER_FORM_CLOSE':
      return {
        ...state,
        voucherForm: { ...state.releaseDetails, type: 'voucher', voucher: null, isOpen: false },
      };
    case 'SET_VOUCHER_PREVIEW_OPEN':
      return {
        ...state,
        voucherPreview: {
          isOpen: true,
          uuid: action.payload?.uuid,
          onActivationSuccess: action.payload?.onActivationSuccess,
          rendered: true,
        },
      };
    case 'SET_VOUCHER_PREVIEW_CLOSE':
      return {
        ...state,
        voucherPreview: { ...state.releaseDetails, isOpen: false },
      };

    default:
      return state;
  }
}

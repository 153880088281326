import { AxiosResponse } from 'axios';

import { ClientDetailsType, ClientEditParamsType } from 'api/types/clients';
import { clientsApi } from 'api';
import objectToFormData from 'helpers/objectToFormData';
import mixpanel from 'helpers/mixpanel';

const postClient = async (params: ClientEditParamsType): Promise<AxiosResponse<ClientDetailsType>> => {
  if (params.uuid) {
    mixpanel.track('Client:Edit');
    return clientsApi.edit(objectToFormData({ ...params, client_id: params.uuid }));
  }
  mixpanel.track('Client:Add');
  return clientsApi.create(objectToFormData(params));
};

export default postClient;

export default {
  root: {
    minWidth: '120px!important',
    fontSize: '12px',
    // fontSize: '0.75em', // '12px',
    fontWeight: '500',
    lineHeight: 2, // '24px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '&$selected': {
      fontWeight: 600,
    },
  },
};

import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@mui/material';

interface PropsInterface {
  rawLabel?: boolean;
  label: string | React.ReactNode;
  handleMenuOpen(event): void;
  icon?: string;
}

const DropdownLabel: React.FunctionComponent<PropsInterface> = ({ rawLabel, label, handleMenuOpen, icon }) => {
  if (rawLabel) {
    return (
      <div onClick={handleMenuOpen} role="button" tabIndex={0}>
        {label}
      </div>
    );
  }
  if (icon)
    return (
      <IconButton onClick={handleMenuOpen} size="large" style={{ backgroundColor: 'transparent' }}>
        <Icon>{icon}</Icon>
      </IconButton>
    );

  return (
    <Button color="secondary" onClick={handleMenuOpen} size="small" fullWidth>
      {label}
    </Button>
  );
};

export default DropdownLabel;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import map from 'lodash/map';

import { ButtonBase, InputLabel, InputAdornment, Grid } from '@mui/material';
import TextField from 'components/TextField';
import * as Icons from 'assets/icons';

import { ClientSourceType } from 'types/client';
import { CONTACT_SOURCES } from 'constants/constants';
import useStyles from './styles';
import Paragraph from '../Paragraph';

export type ContactSourceSelectType = (source: number[]) => void;
export type ContactSourceChangeType = (event: React.ChangeEvent<HTMLInputElement>) => void;

interface PropsInterface {
  onSourceChange: ContactSourceSelectType;
  onInputChange?: ContactSourceChangeType;
  selectedSources?: number[];
  defaultSourceValue?: { [key: string]: string };
  sources?: { [key: string]: (typeof CONTACT_SOURCES)[0] };
  multi?: boolean;
}

const ContactSourceSelect: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    onInputChange,
    onSourceChange,
    selectedSources,
    defaultSourceValue,
    sources = CONTACT_SOURCES,
    multi = false,
  } = props;
  const { t } = useTranslation('common');
  const classes = useStyles({});

  const [value, setValue] = useState<ClientSourceType['id'][]>(selectedSources);

  const handleSourceChange = (source: number) => {
    let newValue = [source];
    if (multi) {
      if (!value?.includes(source)) {
        newValue = [...(value || []), source];
      } else {
        newValue = value.filter((item) => item !== source);
      }
    }

    setValue(newValue);
    onSourceChange(newValue);
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Paragraph type="size-XS-regular">{t('components.clientSource.label')}</Paragraph>
        <div className={classes['client-source-select__list']}>
          {map(sources, (bookingSource: ClientSourceType) => {
            if (bookingSource.preventSelect) {
              return null;
            }
            const Icon = Icons[`${bookingSource.icon}Icon`];

            return (
              <ButtonBase
                className={cx(classes['client-source-select__source'], {
                  [classes['client-source-select--selected']]: value?.includes(bookingSource.id),
                })}
                key={bookingSource.id}
                onClick={() => handleSourceChange(bookingSource.id)}
              >
                <Icon className={classes['client-source-select__source-icon']} />
              </ButtonBase>
            );
          })}
        </div>
      </Grid>
      {typeof onInputChange === 'function' &&
        value?.length > 0 &&
        value.map((item) => {
          const Icon = sources[item] ? Icons[`${sources[item].icon}Icon`] : <div />;
          return (
            sources[item]?.nickname && (
              <Grid className={classes['client-source-select__input']} item xs={12}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className={classes['client-source-select__source-icon']} />
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={defaultSourceValue?.[item]}
                  label={sources[item]?.type}
                  name={(item || 1).toString()}
                  onChange={onInputChange}
                  placeholder={t('components.clientSource.inputPlaceholder')}
                />
              </Grid>
            )
          );
        })}
    </Grid>
  );
};

export default ContactSourceSelect;

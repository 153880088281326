import { BulkSmsErrorInterface, BulkSmsFormInitialStateInterface, BulkSmsStatusInterface } from './types';

export const BulkSmsFormInitialState: BulkSmsFormInitialStateInterface = {
  content: '',
  sign: '',
  diffDate: false,
  date: null,
  time: '',
};

export const BulkSmsInitialStatusState: BulkSmsStatusInterface = {
  isSending: false,
  isFetching: false,
};

export const BulkSmsInitialErrorState: BulkSmsErrorInterface = {
  general: null,
  content: null,
  sign: null,
  date: null,
  time: null,
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import Drawer, { DrawerActions } from 'components/Drawer';
import LoaderWrapper from 'components/LoaderWrapper';
import EarningsSummary from 'containers/EarningsSummary';
import usePanel from 'context/panel/hooks/usePanel';
import generateDayId from 'helpers/date/generateDayId';
import useVoucher from 'helpers/apiHooks/useVoucher';
import useAccount from 'helpers/hooks/useAccount';
import useToast from 'helpers/hooks/useToast';
import logError from 'helpers/logError';
import mixpanel from 'helpers/mixpanel';

import { VoucherFormValueState } from './constatns';
import convertVoucherToForm from './helpers/covertVoucherToFom';
import useVoucherForm from './hooks/useVoucherForm';
import VoucherFormForm from './components/VoucherFormForm';

const VoucherFormDrawer: React.FunctionComponent = () => {
  const {
    state: { voucherForm },
    setVoucherFormClose,
  } = usePanel();
  const {
    state: {
      settings: { currency },
    },
  } = useAccount();

  const { voucherDetails, voucherStatus, handleVoucherSubmit } = useVoucher({
    uuid: voucherForm.uuid,
  });

  const {
    formValue,
    errors,
    onVoucherTypeChange,
    onExpirationDateChange,
    onPaymentMethodChange,
    onInputBlur,
    onNumberInputChange,
    onCheckChange,
    onCurrencyChange,
    onPaymentDateChange,
    onInputChange,
    onClientChange,
    onPaymentTypeChange,
    setVoucher,
    resetForm,
    onAllValidation,
  } = useVoucherForm({
    ...VoucherFormValueState,
    type: voucherForm.type,
    currency,
  });

  const { handleToastDisplay } = useToast();
  const { t } = useTranslation(['voucher']);

  const [viewType, setViewType] = useState<'form' | 'done'>('form');

  const handleVoucherFormDrawerClose = () => {
    setVoucherFormClose();
    setViewType('form');
  };

  const handleVoucherSubmitCommit = async () => {
    if (onAllValidation()) {
      try {
        const voucherResponse = await handleVoucherSubmit(formValue);
        if (typeof voucherForm.onSuccess === 'function') {
          voucherForm.onSuccess(voucherResponse);
        }

        if (formValue.type === 'voucher') {
          setViewType('done');
        } else {
          setVoucherFormClose();
        }
        handleToastDisplay(t('form.messages.submit-success'), 'success');
        if (voucherDetails) {
          mixpanel.track('VoucherFormDrawer:EditVoucherSuccess', { formValue });
        } else {
          mixpanel.track('VoucherFormDrawer:AddVoucherSuccess', { formValue });
        }
      } catch (e) {
        logError(e);

        if (e?.response?.data?.general?.includes('code_exists')) {
          handleToastDisplay(t('form.messages.code-exists'), 'error');
        } else {
          handleToastDisplay(t('form.messages.submit-error'), 'error');
        }
        if (voucherDetails) {
          mixpanel.track('VoucherFormDrawer:EditVoucherError', { formValue });
        } else {
          mixpanel.track('VoucherFormDrawer:AddVoucherError', { formValue });
        }
      }
    }
  };

  useEffect(() => {
    if (voucherForm.isOpen) {
      if (voucherDetails) {
        setVoucher(convertVoucherToForm(voucherDetails));
      } else if (voucherForm.voucher) {
        setVoucher(convertVoucherToForm({ ...voucherForm.voucher, expirationDate: generateDayId(new Date()) }));
      } else if (voucherForm.type) {
        setVoucher({ ...formValue, type: voucherForm.type });
      }
    } else {
      resetForm();
    }
  }, [voucherForm, voucherDetails]);

  return (
    <Drawer
      onClose={handleVoucherFormDrawerClose}
      open={voucherForm.isOpen}
      title={voucherDetails ? t('form.title.edit') : t('form.title.create')}
      footer={
        viewType === 'form' && (
          <DrawerActions>
            <Button
              disabled={voucherStatus.isFetching || voucherStatus.isSending}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleVoucherSubmitCommit}
            >
              <LoaderWrapper loading={voucherStatus.isSending}>
                {voucherDetails ? t('form.footer.cta.edit') : t('form.footer.cta.create')}
              </LoaderWrapper>
            </Button>
          </DrawerActions>
        )
      }
    >
      {viewType === 'form' && (
        <VoucherFormForm
          formValue={formValue}
          errors={errors}
          onVoucherTypeChange={onVoucherTypeChange}
          onExpirationDateChange={onExpirationDateChange}
          onPaymentDateChange={onPaymentDateChange}
          onPaymentMethodChange={onPaymentMethodChange}
          onInputBlur={onInputBlur}
          onNumberInputChange={onNumberInputChange}
          onCheckChange={onCheckChange}
          onCurrencyChange={onCurrencyChange}
          onInputChange={onInputChange}
          onClientChange={onClientChange}
          onPaymentTypeChange={onPaymentTypeChange}
          setVoucher={setVoucher}
        />
      )}
      {viewType === 'done' && !voucherDetails && (
        <EarningsSummary date={generateDayId(new Date())} currency={formValue.currency} />
      )}
    </Drawer>
  );
};

export default VoucherFormDrawer;

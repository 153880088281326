import React, { useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import INKsearchLoader from 'components/INKsearchLoader';
import { deleteCookie, getCookie } from 'helpers/cookie';
import getParamFromURL from 'helpers/url/getParamFromURL';
import useAccessStore from 'store/access/access.store';
import useEmployees from 'store/employees/employees.store';
import { authApi } from 'api';

import { authorizeUser, setConfirmLogin } from './accountActions';
import { AccountState, AccountActionType } from './types';
import { initialState, reducer } from './accountReducer';

export interface AccountContextValue {
  state: AccountState;
  dispatch: React.Dispatch<AccountActionType>;
}

interface PropsInterface {
  children: React.ReactNode;
}

export const AccountContext = React.createContext<AccountContextValue>(null);

const AccountProvider: React.FunctionComponent<PropsInterface> = ({ children }) => {
  const employeesStore = useEmployees((state) => ({
    getEmployeesList: state.getEmployeesList,
    setAllowEmployees: state.setAllow,
  }));
  const accessStore = useAccessStore((state) => ({ access: state.employeeAccess, getAccess: state.getEmployeeAccess }));
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  const handleAuthCallback = async () => {
    const access = await accessStore.getAccess();
    if (
      access.employee_data_access !== 'no_access' ||
      access.calendar_other_employees_access !== 'no_access' ||
      access.financial_data_widget_employees_income !== 'no_access' ||
      access.financial_data_widget_employees_work_time !== 'no_access'
    ) {
      employeesStore.setAllowEmployees(true);
      employeesStore.getEmployeesList();
    }
  };

  const handleAuth = async () => {
    setLoading(true);
    const token = window?.localStorage?.getItem('biz_token');
    const ssoToken = getCookie('sso_token');
    const newAccount = getParamFromURL('fresh') as 'social' | 'login';
    if (token) {
      authorizeUser(dispatch, token, newAccount)
        .then(() =>
          handleAuthCallback().then(() => {
            setConfirmLogin(dispatch);
          }),
        )
        .finally(() => {
          setLoading(false);
        });
    } else if (ssoToken) {
      authApi
        .sso(ssoToken)
        .then(({ data }) => {
          const sso = data?.access_token;
          window.localStorage?.setItem('biz_token', sso);

          authorizeUser(dispatch, sso, data?.new_user)
            .then(async () => {
              await handleAuthCallback();
            })
            .finally(() => {
              setLoading(false);
              if (!state.blockSso) {
                history.push('/sso');
              }
            });
        })
        .catch((error) => {
          const status = error.response?.status;

          if (status && status >= 400 && status < 500) {
            deleteCookie('sso_token');
          }

          setLoading(false);
          history.push('/');
        });
    } else {
      dispatch({ type: 'UPDATE_ACCOUNT_FAIL', payload: 'token.missing' });
      setLoading(false);
    }
  };

  useEffect(() => {
    // check if user is tester and setup a variable
    const tokenParam = getParamFromURL('access_token') ? getParamFromURL('access_token') : null;

    if (tokenParam) {
      window.localStorage?.setItem('biz_token', tokenParam);

      if (getParamFromURL('new_user') === '1') {
        setTimeout(function () {
          window.location.href = `${window.location.href.split('?')[0]}?fresh=social`;
        }, 1000);
      } else {
        setTimeout(function () {
          // eslint-disable-next-line prefer-destructuring
          window.location.href = window.location.href.split('?')[0];
        }, 1000);
      }
    } else {
      handleAuth();
    }
  }, []);

  if (isLoading) {
    return <INKsearchLoader>Authorizing user...</INKsearchLoader>;
  }

  return <AccountContext.Provider value={{ state, dispatch }}>{children}</AccountContext.Provider>;
};

export default AccountProvider;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Paragraph from 'components/Paragraph';
import Header from 'components/Header';
import Chip from 'components/Chip';
import PLACEHOLDER_IMAGE from 'assets/img/release-note-cover-placeholder.png';

import useStyles from './styles';
import packageInfo from '../../../../../package.json';

export type ReleaseNoteType = {
  uuid: string;
  cover?: string;
  date: string;
  productVersion: string;
  title: string;
  description: string;
};

type ReleaseNotesUpdateRepresentationPropsType = {
  onClick(uuid: string): void;
} & ReleaseNoteType;

export const ReleaseNotesUpdateRepresentation: React.FC<ReleaseNotesUpdateRepresentationPropsType> = ({
  cover,
  productVersion,
  title,
  date,
  description,
  uuid,
  onClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const handleClick = () => {
    onClick(uuid);
  };

  const productMinorVersion = packageInfo.version.split('.', 2).join('.');
  const isLatest = productVersion === productMinorVersion;

  return (
    <div className={classes['release-notes-update-representation']}>
      <img
        className={classes['release-notes-update-representation__image']}
        src={cover || PLACEHOLDER_IMAGE}
        alt={title}
        onClick={handleClick}
      />
      <div className={classes['release-notes-update-representation__content']}>
        <div className={classes['release-notes-update-representation__content-tags']}>
          <Chip label={date} variant="filled" size="small" />
          <Chip label={`v${productVersion}`} variant="filled" size="small" />
          {isLatest && <Chip color="warning" size="small" label={t('release-details.chip.new')} />}
        </div>
        <div className={classes['release-notes-update-representation__content-text']}>
          <div
            className={classes['release-notes-update-representation__content-text-title']}
            role="button"
            tabIndex={0}
            onClick={handleClick}
          >
            <Header type="h4" variant="h4">
              {title}
            </Header>
          </div>
          <div className={classes['release-notes-update-representation__content-text-description']}>
            <Paragraph type="size-S-medium" color="primary">
              {description}&nbsp;
            </Paragraph>
            <Paragraph type="size-S-medium" color="primary" component="span">
              <a onClick={handleClick}>{t('release-notes.item.more')}</a>
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

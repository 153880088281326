import React from 'react';
import { Icon } from '@mui/material';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { createStyles } from '@mui/styles';

import Header from 'components/Header';
import Paragraph from 'components/Paragraph';
import logError from 'helpers/logError';

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed-error-boundary';

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false',
  ) as boolean;

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'true');
    return window.location.reload();
  }
  window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
  return null;
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  reload?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: 'text' | 'illustration';
  // eslint-disable-next-line react/no-unused-prop-types
  alignment?: 'center' | 'flex-start';
}

const styles = (theme) =>
  createStyles({
    'error-boundary': {
      padding: theme.spacing(2, 0),
      width: '100%',
      display: 'flex',
    },

    'error-boundary__content': (props: ErrorBoundaryProps) => ({
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      justifyContent: props.alignment || 'flex-start',
      alignItems: props.alignment || 'flex-start',
    }),

    'error-boundary__image': {
      width: 150,
      marginRight: theme.spacing(4),
    },

    'error-boundary__icon': {
      fontSize: '2em',
      marginRight: theme.spacing(4),
    },
  });

interface ErrorBoundaryPropsWithStyles extends ErrorBoundaryProps, WithStyles<typeof styles> {}

class ErrorBoundary extends React.Component<ErrorBoundaryPropsWithStyles, { hasError: boolean }> {
  constructor(props: Readonly<ErrorBoundaryPropsWithStyles>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    logError(error as Error, { source: 'ErrorBoundary' });
    return { hasError: true };
  }

  componentDidCatch() {
    const { reload } = this.props;

    if (reload) {
      retryPageLoading();
    }
  }

  render() {
    const { classes, children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className={classes['error-boundary']}>
          <div>
            {/* <img className={classes['error-boundary__image']} src={errorImage} alt="image" /> */}
            <Icon className={classes['error-boundary__icon']} color="error" fontSize="inherit">
              heart_broken
            </Icon>
          </div>
          <div className={classes['error-boundary__content']}>
            <Header type="h3" variant="h3">
              Ups... error
            </Header>
            <div className={classes['error-boundary__description']}>
              <Paragraph type="size-S-medium">
                We are very sorry, it seems there is a problem with this part of page. Please try again
              </Paragraph>
              <Paragraph type="size-S-medium" color="secondary">
                A message about this issue have been sent automatically to our development team. If you notice the
                problem persists, please report it!
              </Paragraph>
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary);

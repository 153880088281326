import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'currency-select': {},
  'currency-select__select': {
    display: 'flex',
    minWidth: 50,
    alignItems: 'center',
    paddingRight: `${theme.spacing(2)}!important`,
  },
  'currency-select__icon': {
    display: 'none',
  },
  'currency-select__disabled': {
    color: '#fff!important',
  },
}));

export default useStyles;

import React from 'react';

import { PaginationType } from 'api/types';
import Pagination from 'components/Pagination';
import Card, { CardColorOptionsType } from 'components/Card';

import useStyles from './styles';
import DataTableSkeleton from './components/DataTableSkeleton';
import DataTableEmpty from './components/DataTableEmpty';
import Paragraph from '../Paragraph';

export type DataTableColumnType = {
  id: string | number;
  label?: React.ReactNode;
  width: string | number;
  justifyContent?: 'center' | 'left' | 'right';
};

export type DataTableEmptyType = {
  header?: string;
  paragraph?: string | React.ReactNode;
  cta?: string;
  href?: string;
  ctaClick?: () => void;
  placeholderRows?: number;
  alert?: {
    title?: string;
    message?: string;
    variant?: 'error' | 'info' | 'warning';
  };
};

interface PropsInterface {
  columns: DataTableColumnType[];
  rows: {
    data: React.ReactNode[];
    id: string | number;
    name?: string;
    color?: CardColorOptionsType;
    onClick?: () => void;
  }[];
  columnAlign?: 'center' | 'flex-start' | 'flex-end';
  cellAlign?: 'center' | 'flex-start' | 'flex-end';
  pagination?: PaginationType;
  onPaginationChange?(page: number, perPage: number): void;
  emptyState?: DataTableEmptyType;
  isLoading?: boolean;
  config?: {
    cellPadding: number;
  };
}

const DataTable: React.FunctionComponent<PropsInterface> = ({
  columns,
  rows,
  columnAlign = 'flex-start',
  cellAlign = 'center',
  pagination,
  onPaginationChange,
  emptyState,
  isLoading,
}) => {
  const classes = useStyles();
  let separatorLetter = '';

  return (
    <div className={classes['data-table']}>
      <div className={classes['data-table__headers']} style={{ justifyContent: columnAlign }}>
        {rows?.length > 0 &&
          columns.map(({ label, width, id, justifyContent }, index) => (
            <div
              className={classes['data-table__header']}
              key={id}
              style={{
                flexBasis: index === columns.length - 1 ? width : `calc(${width} - 16px)`,
                textAlign: justifyContent,
              }}
            >
              {label}
            </div>
          ))}
      </div>
      <div className={classes['data-table__content']}>
        {isLoading ? (
          <DataTableSkeleton rows={3} animate isLoading />
        ) : (
          <>
            {rows?.length > 0 &&
              rows.map((row) => {
                let separator = false;
                if (typeof row.name === 'string' && separatorLetter?.toUpperCase() !== row.name[0]?.toUpperCase()) {
                  separator = true;
                  [separatorLetter] = row.name?.toUpperCase() || '';
                }

                return (
                  <React.Fragment key={row.id}>
                    {separator && (
                      <div className={classes['data-table__separator']}>
                        <Paragraph type="size-XS-medium" color="secondary">
                          {separatorLetter}
                        </Paragraph>
                      </div>
                    )}
                    <Card key={row.id} padding="default" color={row?.color} onClick={row?.onClick}>
                      <div className={classes['data-table__row']}>
                        {row.data.map((cell, index) => (
                          <div
                            className={classes['data-table__cell']}
                            key={`${row.id}-${index}`}
                            style={{
                              flexBasis:
                                index === columns.length - 1
                                  ? columns[index]?.width
                                  : `calc(${columns[index]?.width} - 16px)`,
                              // flexBasis: columns[index]?.width,
                              alignItems: cellAlign,
                              justifyContent: columns[index]?.justifyContent || columnAlign,
                            }}
                          >
                            {cell}
                          </div>
                        ))}
                      </div>
                    </Card>
                  </React.Fragment>
                );
              })}
            {(!rows || rows?.length === 0) && <DataTableEmpty {...emptyState} />}
          </>
        )}
      </div>

      {!isLoading && pagination && pagination.total !== 0 && (
        <div className={classes['data-table__pagination']}>
          <Pagination
            perPage={pagination.per_page}
            page={pagination.current_page}
            total={pagination.total}
            onChange={onPaginationChange}
          />
        </div>
      )}
    </div>
  );
};

export default DataTable;

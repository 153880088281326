import { create } from 'zustand';
import omit from 'lodash/omit';

import getProfileProgression from 'helpers/getProfileProgression';
import objectToFormData from 'helpers/objectToFormData';
import logError from 'helpers/logError';
import mixpanel from 'helpers/mixpanel';
import { inksearchApi } from 'api';

import { EmployeesStoreInterface } from './types';

const useEmployees = create<EmployeesStoreInterface>()((set, get) => ({
  allow: false,
  employees: { list: {}, isFetching: true, isFetched: false },
  employeesInvites: { list: {}, isFetching: true, isFetched: false },
  employeeDetails: { data: null, progression: 0, isFetching: false },

  employeeRemove: async ({ id }) => {
    try {
      const response = await inksearchApi.employee.remove({ employee_id: id });
      set((prevState) => ({
        employees: { list: omit(prevState.employees.list, id), isFetching: false, isFetched: true },
      }));
      mixpanel.track('employee:remove', { employee_id: id });
      return response;
    } catch (e) {
      logError(e);
      return e;
    }
  },
  employeeRemoveInvitation: async ({ id }) => {
    try {
      const { data: response } = await inksearchApi.employee.removeInvitation({ invitation_id: id });
      set((prevState) => ({
        employeesInvites: { list: omit(prevState.employeesInvites.list, id), isFetching: false, isFetched: true },
      }));
      mixpanel.track('employee:invite-remove', { invitation_id: id });
      return response;
    } catch (e) {
      logError(e);
      return e;
    }
  },
  employeeUpdate: async ({ data, id }) => {
    try {
      const scope = Object.keys(data);
      const { data: employee } = await inksearchApi.employee.updatev2(objectToFormData({ scope, ...data }), id);
      set({ employeeDetails: { data: employee, progression: getProfileProgression(employee), isFetching: false } });
      mixpanel.track('employee:update', { employeeUuid: employee.uuid, employeeName: employee.name });
      return employee;
    } catch (e) {
      logError(e);
      return e;
    }
  },

  getEmployeeDetails: async (uuid) => {
    try {
      const { data: employee } = await inksearchApi.employee.get(uuid);
      set({ employeeDetails: { data: employee, progression: getProfileProgression(employee), isFetching: false } });
      return true;
    } catch (e) {
      logError(e);
      return e;
    }
  },
  getEmployeesList: async () => {
    if (!get().allow) {
      return null;
    }
    try {
      const { data: employeesResponse } = await inksearchApi.employee.getList();
      set({
        employees: {
          list: employeesResponse?.reduce((state, item) => ({ ...state, [item.uuid]: item }), {}),
          isFetching: false,
          isFetched: true,
        },
      });
      return employeesResponse;
    } catch (e) {
      logError(e);
      return e;
    }
  },
  getEmployeesInvitesList: async () => {
    try {
      const { data: employeeInvitesResponse } = await inksearchApi.employee.getInvitationList();
      set({
        employeesInvites: {
          list: employeeInvitesResponse?.reduce((state, item) => ({ ...state, [item.id || item.uuid]: item }), {}),
          isFetching: false,
          isFetched: true,
        },
      });
      return employeeInvitesResponse;
    } catch (e) {
      logError(e);
      return e;
    }
  },

  listType: 'inhouse',
  setListType: (type) => {
    set({
      listType: type,
    });
  },
  setAllow: (allow: boolean) => set({ allow }),
}));

export default useEmployees;

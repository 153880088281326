import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { ChartOptions, DoughnutControllerChartOptions } from 'chart.js';
import Chart from 'chart.js/auto';

import Header from 'components/Header';
import Paragraph from 'components/Paragraph';

import useStyles from './styles';

export interface PropsInterface {
  parseValueFunction?: (value: number) => string;
  data: { value: number; label: string; color: string }[];
  value?: number | string;
  valueLabel?: string;
  size?: number;
  children?: React.ReactNode;
  options?: Partial<DoughnutControllerChartOptions>;
  showLegend?: boolean;
  legendOnRight?: boolean;
}

function adjust(color, amount) {
  return `#${color
    .replace(/^#/, '')
    .replace(/../g, (color) =>
      `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2),
    )}`;
}

const DoughnutChart: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    parseValueFunction,
    options,
    value,
    valueLabel,
    children,
    data,
    size,
    showLegend = true,
    legendOnRight,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const chartDOMRef = useRef(null);
  const chartDOMRefBackground = useRef(null);
  const chartRef = useRef<Chart | null>(null);
  const classes = useStyles({ size });

  const [chartData, colors, labels]: [number[], string[], string[]] = data.reduce(
    (state, value) => [
      [...state[0], value.value],
      [...state[1], value.color],
      [...state[2], value.label],
    ],
    [[], [], []],
  );

  const hoverColors = colors.map((color) => adjust(color, 20));

  useEffect(() => {
    if (chartDOMRefBackground.current && chartDOMRef.current) {
      new Chart(chartDOMRefBackground.current, {
        type: 'doughnut',
        data: {
          datasets: [{ data: [100], backgroundColor: 'rgba(255, 255, 255, 0.16)' }],
        },
        options: {
          responsive: true,
          borderWidth: 0,
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
          ...options,
        } as ChartOptions,
      });

      chartRef.current = new Chart(chartDOMRef.current, {
        type: 'doughnut',
        data: {
          labels: labels.map((label) => label),
          datasets: [
            {
              data: chartData,
              backgroundColor: colors,
              hoverOffset: 0,
              hoverBackgroundColor: hoverColors,
            },
          ],
        },

        // @ts-ignore
        options: {
          layout: {
            padding: 0,
          },
          clip: 20,
          rotation: 90,
          responsive: true,
          borderWidth: 0,
          spacing: 0.5,
          borderRadius: 0,
          plugins: {
            legend: {
              display: false,
            },

            tooltip: {
              titleFont: '"Montserrat", sans-serif',
              fontSize: 10,
              padding: 4,
              multiKeyBackground: '#00000000',
              callbacks: {
                labelColor(tooltipItem) {
                  return {
                    borderWidth: 0,
                    borderColor: 'rgba(0,0,0,0)',
                    backgroundColor: tooltipItem.dataset.backgroundColor[tooltipItem.dataIndex],
                  };
                },
                label(tooltipItem) {
                  if (parseValueFunction && typeof tooltipItem.dataset.data[tooltipItem.dataIndex] === 'number') {
                    // @ts-ignore
                    return parseValueFunction(tooltipItem.dataset.data[tooltipItem.dataIndex]);
                  }
                  return ` ${tooltipItem.dataset.data[tooltipItem.dataIndex]}`;
                },
              },
            },
          },
          ...options,
        } as ChartOptions,
      });
      setLoading(false);
    }

    return () => {
      chartRef.current = null;
    };
  }, [chartDOMRefBackground, chartDOMRef, chartRef]);

  useEffect(() => {
    if (!isLoading && chartRef.current) {
      chartRef.current.data.datasets[0].data = data.map(({ value }) => value);
      chartRef.current.update();
    }
  }, [data]);

  return (
    <div className={cx(classes['doughnut-chart'], { [classes['doughnut-chart--right']]: legendOnRight })}>
      {showLegend && (
        <div
          className={cx(classes['doughnut-chart__legend'], {
            [classes['doughnut-chart__legend--right']]: legendOnRight,
          })}
        >
          {labels.map((label, index) => (
            <div className={classes['doughnut-chart__legend-item']} key={label}>
              <div className={classes['doughnut-chart__legend-color']} style={{ backgroundColor: colors[index] }} />
              <Paragraph type="size-XS-regular" color="secondary">
                {label}
              </Paragraph>
            </div>
          ))}
        </div>
      )}
      <div
        className={cx(classes['doughnut-chart__chart-wrapper'], {
          [classes['doughnut-chart__chart-wrapper--right']]: legendOnRight,
        })}
      >
        <canvas className={classes['doughnut-chart__chart']} ref={chartDOMRef} />
        <canvas className={classes['doughnut-chart__background']} ref={chartDOMRefBackground} />
        {!!value && (
          <div className={classes['doughnut-chart__content']}>
            <Header type="h2" variant="h2">
              {value}
            </Header>
            {valueLabel && (
              <Paragraph type="size-S-medium" color="secondary">
                {valueLabel}
              </Paragraph>
            )}
            {!!children && (
              <Paragraph type="size-XS-regular" color="secondary">
                {children}
              </Paragraph>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DoughnutChart;

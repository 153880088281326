import React from 'react';

import MaterialDrawer from '@mui/material/Drawer';
import withStyles from '@mui/styles/withStyles';

import ErrorBoundary from 'components/ErrorBoundary';
import isElectron from 'helpers/electron/isElectron';
import isMobile from 'helpers/isMobile';
import layoutValues from 'theme/constatns';
import theme from 'theme';

import Alert from 'components/Alert';
import { DrawerHeader } from './index';
import useStyles from './styles';

const StyledDrawer = withStyles({
  paper: ({ slim, position }) => {
    const top = isElectron() ? layoutValues.electron.titleBar : 0;
    const mobile = isMobile();
    return {
      overflow: 'hidden',
      top: !position?.bottom && (position?.top ?? slim ? '60%' : top),
      bottom: position?.bottom,
      left: !position?.right && position?.left,
      right: position?.right,
      background: 'linear-gradient(142.17deg, rgba(34, 34, 34, 0.88) 0%, rgba(24, 24, 24, 0.72) 100%)',
      backdropFilter: 'blur(16px)',
      zIndex: 2,
      padding: 0,
      margin: mobile ? 'auto' : 'initial',
      borderTopLeftRadius: mobile && 16,
      borderTopRightRadius: mobile && 16,
      width: '550px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    };
  },
})(MaterialDrawer);

export interface PropsInterface {
  children: React.ReactNode;
  anchor?: 'right' | 'left' | 'top' | 'bottom';
  footer?: React.ReactNode;
  footerAdditional?: React.ReactNode;
  validation?: string;
  headerActions?: React.ReactNode;
  title?: React.ReactNode;
  titleAdditional?: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?(variant?: 'x' | 'button'): void;
  onBack?(): void;
  minWidth?: string;
  open?: boolean;
  hideClose?: boolean;
  className?: string;
  slim?: boolean;
  padding?: string;
  position?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
}

const Drawer: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    children,
    footer,
    footerAdditional,
    validation,
    anchor = 'right',
    title,
    titleAdditional,
    subtitle,
    open = false,
    onClose = () => {},
    onBack,
    className,
    slim = false,
    minWidth,
    hideClose,
    headerActions,
    padding = '',
    position,
  } = props;

  const classes = useStyles({ minWidth, padding });
  const mobile = isMobile();

  return (
    <StyledDrawer
      position={position}
      slim={slim && mobile ? slim.toString() : undefined}
      anchor={anchor}
      classes={{ paper: className }}
      onClose={() => onClose('button')}
      open={open}
    >
      {(title || !hideClose) && (
        <DrawerHeader
          headerActions={headerActions}
          hideClose={hideClose}
          onBack={onBack}
          onClose={() => onClose('x')}
          subtitle={subtitle}
          title={title}
          titleAdditional={titleAdditional}
        />
      )}
      <ErrorBoundary>
        <div className={classes.drawer__content}>{children}</div>
        {footer && (
          <div className={classes.drawer__footer}>
            {footerAdditional && <div>{footerAdditional}</div>}
            {validation && <Alert type="error" message={validation} className={classes['drawer__validation']} />}
            <div>{footer}</div>
          </div>
        )}
      </ErrorBoundary>
    </StyledDrawer>
  );
};

export default Drawer;

import injectStyledScrollbar from '../helpers/injectStyledScrollbar';

export default {
  root: {
    background: 'linear-gradient(142.17deg, rgba(34, 34, 34, 0.88) 0%, rgba(24, 24, 24, 0.72) 100%)',
    backdropFilter: 'blur(12px)',
    boxShadow: '4px 4px 32px rgba(0, 0, 0, 0.32)',

    ...injectStyledScrollbar(),
  },
  rounded: {
    borderRadius: '0',
  },
  elevation1: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
  },
};

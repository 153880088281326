import cx from 'classnames';
import React from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Checkbox from 'components/Checkbox';

import useStyles from '../styles';

type AutocompleteMobileOptionTypeProps = Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'options'> & {
  checked?: boolean;
  multiple: boolean;
  autocompleteValue: string;
  handleClose?: () => void;
  values?: AutocompleteProps<any, any, any, any>['value'];
};

const AutocompleteMobileOption: React.FC<AutocompleteMobileOptionTypeProps> = ({
  disabled,
  values,
  value,
  checked,
  multiple,
  onChange,
  autocompleteValue,
  handleClose,
  getOptionLabel,
  isOptionEqualToValue,
}) => {
  const classes = useStyles();

  const onClick = (e, v) => {
    if (disabled) return;

    if (multiple) {
      if (checked) {
        const newValues = values.filter((val) => !isOptionEqualToValue(val, v));
        onChange(e, newValues, 'removeOption');
      } else onChange(e, [...values, v], 'selectOption');
    } else {
      if (checked) {
        onChange(e, null, 'selectOption');
        return;
      }
      onChange(e, v, 'selectOption');
      handleClose();
    }
  };

  const makeBold = (item) => {
    let newValue = item;
    for (let i = 0; i < autocompleteValue?.length; i++) {
      const re = new RegExp(`((?<!<)(?<!<\\/))${autocompleteValue.charAt(i)}(?!r\\/>)`, 'gi');
      newValue = newValue?.replace(re, '<b>$&</b>');
    }
    return newValue;
  };

  const LabelComponent = (
    <div
      key={`option-${getOptionLabel(value)}`}
      dangerouslySetInnerHTML={{ __html: makeBold(getOptionLabel(value)) }}
      className={classes['autocomplete-mobile--font']}
    />
  );

  return (
    <div
      className={cx(classes['autocomplete-mobile__result'], classes['autocomplete-mobile--font'], {
        [classes['autocomplete-mobile__result--disabled']]: disabled,
      })}
      onClick={(e) => onClick(e, value)}
    >
      {multiple ? (
        <>
          <Checkbox disabled={disabled} color="secondary" label={null} checked={checked} onClick={null} />
          {LabelComponent}
        </>
      ) : (
        <FormControlLabel
          control={<Radio checked={checked} color="secondary" />}
          key={value}
          label={LabelComponent}
          value={value}
        />
      )}
    </div>
  );
};

export default AutocompleteMobileOption;

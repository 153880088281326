import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Icon, IconButton } from '@mui/material';

import useSmsInformation from 'helpers/hooks/useSmsInformation';
import useAccount from 'helpers/hooks/useAccount';
import useToast from 'helpers/hooks/useToast';
import logError from 'helpers/logError';
import ClientRepresentation from 'components/ClientRepresentation';
import ValidationMessage from 'components/ValidationMessage';
import Drawer, { DrawerActions } from 'components/Drawer';
import LoaderWrapper from 'components/LoaderWrapper';
import Paragraph from 'components/Paragraph';
import TextField from 'components/TextField';
import usePanel from 'context/panel/hooks/usePanel';
import { calendarApi } from 'api';

import SmsAlertNotification from '../SmsAlertNotification';
import useClientMessageForm from './helpers/useClientMessageForm';
import useStyles from './styles';

const ClientMessageForm: React.FC = () => {
  const { t } = useTranslation('common');
  const {
    state: { data },
  } = useAccount();
  const {
    state: { clientMessageForm },
    setClientMessageFormClose,
    setClientDetailsOpen,
  } = usePanel();
  const classes = useStyles();
  const { handleToastDisplay } = useToast();
  const { smsAmount, isDisabledByPlan } = useSmsInformation();
  const { formValue, errors, smsCounter, handleInputBlur, handleInputChange, handleValidateAll, resetForm } =
    useClientMessageForm(data?.artist_data?.studio_name || data?.name);

  const [isLoading, setLoading] = useState(false);
  const [clientPhone, setClientPhone] = useState(clientMessageForm.client?.phone || null);

  const clientPhoneResult = clientPhone || clientMessageForm.client?.phone;

  const handleSubmit = async () => {
    if (!isLoading && handleValidateAll()) {
      try {
        setLoading(true);
        const params = {
          event_id: clientMessageForm.uuid,
          message: formValue.content,
          signature: formValue.sign,
        };
        await calendarApi.messageClient(params);
        setClientMessageFormClose();
        handleToastDisplay(t('client-message-form.message.send-success'), 'success');
      } catch (e) {
        logError(e);
        handleToastDisplay(t('client-message-form.message.send-error'), 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (clientMessageForm.isOpen) {
      resetForm();
    }
  }, [clientMessageForm.isOpen]);

  return (
    <Drawer
      onClose={setClientMessageFormClose}
      title={t('client-message-form.title')}
      open={clientMessageForm.isOpen}
      footer={
        <DrawerActions>
          <Button
            fullWidth
            variant="contained"
            disabled={smsAmount === 0 || isDisabledByPlan || !clientPhoneResult}
            onClick={handleSubmit}
          >
            <LoaderWrapper loading={isLoading}>{t('client-message-form.cta')}</LoaderWrapper>
          </Button>
        </DrawerActions>
      }
    >
      <Grid container spacing={8}>
        {clientMessageForm.client && (
          <Grid item xs={12} className={classes['client-message-form__client-representation']}>
            <ClientRepresentation
              client={{
                name: clientMessageForm.client.name,
                mail: clientMessageForm.client.email,
                phone: clientPhoneResult,
              }}
              action={
                <IconButton
                  onClick={() =>
                    setClientDetailsOpen({
                      uuid: clientMessageForm.client.uuid,
                      onEdit: ({ phone }) => setClientPhone(phone),
                    })
                  }
                >
                  <Icon>visibility</Icon>
                </IconButton>
              }
            />
            {!clientPhoneResult && (
              <ValidationMessage
                message={{ message: t('client-message-form.message.phone-missing'), type: 'error' }}
                show
              />
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Paragraph type="size-S-medium">{t('client-message-form.content-alert')}</Paragraph>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('client-message-form.fields.content.label')}
            placeholder={t('client-message-form.fields.content.placeholder')}
            multiline
            name="content"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            value={formValue.content}
            error={!!errors.content}
            message={{ type: 'error', message: errors.content }}
          />
          <br />
          <TextField
            label={t('client-message-form.fields.sign.label')}
            name="sign"
            helperText={`${smsCounter.sms} SMS, ${smsCounter.charactersRemaining} ${t(
              'client-message-form.fields.content.character-counter',
            )}`}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={!!errors.sign}
            message={{ type: 'error', message: errors.sign }}
            value={formValue.sign}
          />
        </Grid>
        <Grid item xs={12}>
          <SmsAlertNotification minimumSmsAmount={smsCounter.sms} />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default ClientMessageForm;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'validation-message': {
    position: 'absolute',
    bottom: '-24px',
    display: 'block',
    opacity: 0,
    marginTop: theme.spacing(1),
    transition: '200ms',
    padding: '0',

    '& span': {
      opacity: 0,
    },

    '& p:empty:before': {
      content: '""',
      display: 'inline-block',
    },
    '& p:empty': {
      paddingBottom: '5px',
    },
  },

  'validation-message--shown': {
    opacity: 1,
    '& span': {
      opacity: 1,
    },
  },
}));

export default useStyles;

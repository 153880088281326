import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import IconButton from '@mui/material/IconButton';
import { Grid, Icon } from '@mui/material';

import usePanel from 'context/panel/hooks/usePanel';
import { ClientType } from 'api/types/clients';
import useClientAutocomplete from 'helpers/apiHooks/useClientAutocomplete';
import mixpanel from 'helpers/mixpanel';
import ClientForm, { ClientFormSuccessType } from 'containers/ClientForm';
import ClientRepresentation from 'components/ClientRepresentation';
import ValidationMessage, { InputMessageType } from 'components/ValidationMessage';
import { DataTableEmpty } from 'components/DataTable';
import LoaderWrapper from 'components/LoaderWrapper';
import TextField from 'components/TextField';
import Profile from 'components/Profile';
import Drawer from 'components/Drawer';
import Card from 'components/Card';
import Link from 'components/Link';

import { ClientChangeType } from '../../types';
import useStyles from './styles';

export interface PropsInterface {
  error?: InputMessageType;
  client?: ClientType;
  onClientChange?: ClientChangeType;
  disabled?: boolean;
}

const ClientSelect: React.FunctionComponent<PropsInterface> = ({ error, onClientChange, client, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation(['event-form', 'common']);

  const { setClientDetailsOpen } = usePanel();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isClientFormOpen, setClientFormOpen] = useState(false);

  const { clientList, isError, isFetching } = useClientAutocomplete(searchValue);

  const handleClientAutocomplete = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setSearchValue('');
  };

  const handleClientChange: ClientChangeType = (client) => {
    mixpanel.track('ClientSelect:SelectClient');
    onClientChange(client);
    setDrawerOpen(false);
  };

  const handleClientCreate: ClientFormSuccessType = (newClient) => {
    mixpanel.track('ClientSelect:CreateClient');
    onClientChange({
      uuid: newClient.uuid,
      name: newClient.name,
      phone: newClient.phone,
      email: newClient.email,
    });
    setClientFormOpen(false);
  };

  const handleClientRemove = () => {
    onClientChange(null);
  };

  const handleClientCreateClick = () => {
    mixpanel.track('ClientSelect:CreateClientCtaClick');
    setClientFormOpen(true);
  };

  return (
    <>
      <div className={cx(classes['client-select'], { [classes['client-select--disabled']]: disabled })}>
        {client?.name || client?.uuid ? (
          <ClientRepresentation
            color="gradient-dark"
            client={{ name: client.name, avatar: client.name, mail: client.email, phone: client.phone }}
            action={
              <div className={classes['client-select__actions']}>
                <IconButton onClick={() => setClientDetailsOpen({ uuid: client.uuid, onEdit: handleClientCreate })}>
                  <Icon baseClassName="material-icons-outlined">remove_red_eye</Icon>
                </IconButton>
                <IconButton onClick={handleClientRemove}>
                  <Icon>close</Icon>
                </IconButton>
              </div>
            }
          />
        ) : (
          <>
            <Card onClick={handleDrawerOpen} color="gradient-dark" slim direction="horizontal">
              <Profile name={t('client.add')} />
              <Icon>add</Icon>
            </Card>
            <span className={classes['client-select__create']}>
              {t('client.or')}&nbsp;
              <Link onClick={handleClientCreateClick}>{t('client.create')}</Link>&nbsp;
              <Icon className={classes['client-select__create-icon']}>person_add_alt</Icon>
            </span>
          </>
        )}
        {error && <ValidationMessage message={error} show />}
      </div>
      <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)} title={t('client.drawer.title')}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TextField
              autoFocus={isDrawerOpen}
              onChange={handleClientAutocomplete}
              placeholder={t('client.drawer.search.placeholder')}
              hiddenLabel
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper loading={isFetching}>
              {clientList.length > 0 ? (
                clientList.map((client) => (
                  <div key={client.uuid} className={classes['client-select__client']}>
                    <ClientRepresentation
                      onClick={() => handleClientChange(client)}
                      client={{ name: client.name, avatar: client.name, mail: client.email, phone: client.phone }}
                      action={<Icon>keyboard_arrow_right</Icon>}
                    />
                  </div>
                ))
              ) : (
                <DataTableEmpty
                  placeholderRows={4}
                  header={t('client.drawer.empty.title')}
                  paragraph={
                    <span>
                      {t('client.drawer.empty.subtitle')}&nbsp;
                      <Link onClick={handleClientCreateClick}>{t('client.create')}</Link>&nbsp;
                      <Icon className={classes['client-select__create-icon']}>person_add_alt</Icon>
                    </span>
                  }
                />
              )}
            </LoaderWrapper>
          </Grid>
        </Grid>
      </Drawer>
      <ClientForm onClose={() => setClientFormOpen(false)} isOpen={isClientFormOpen} onSuccess={handleClientCreate} />
    </>
  );
};

export default ClientSelect;

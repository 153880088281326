import makeStyles from '@mui/styles/makeStyles';

import layoutValues from 'theme/constatns';

const useStyles = makeStyles((theme) => ({
  'bottom-navigation-button__icon-wrapper': {
    padding: '6px',
    height: '40px',
    borderRadius: '8px',
    border: '2px solid transparent',
    transition: '200ms',
  },
  'bottom-navigation-button__bottom-navigation-action': {
    minWidth: 'auto',
    [theme.breakpoints.up('md')]: {
      minWidth: 'default',
    },
  },
  'bottom-navigation-button__icon-wrapper--active': {
    backgroundColor: 'rgba(255, 255, 255, 0.88)',
    borderColor: 'white',
    '& svg': {
      color: 'black',
    },
  },
  'bottom-navigation-wrapper': {
    height: layoutValues.appBar.height.default,
    top: 'auto',
    bottom: 0,
  },
  'bottom-navigation': {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(5, 1fr)',
    borderTop: '1px solid #37393E',
    background: 'linear-gradient(135deg, rgba(34, 34, 34, 0.88) 0%, rgba(24, 24, 24, 0.72) 100%)',
  },
}));

export default useStyles;

import { useContext } from 'react';
import { PanelContext } from 'context/panel/panelContext';
import { clearToastStoreAction, enqueueToastAction } from 'context/panel/panelActions';
import { OptionsObject } from 'notistack';

interface UseToastInterface {
  (): {
    handleToastDisplay(
      text: string | any,
      variant?: 'success' | 'warning' | 'error' | 'info',
      options?: OptionsObject,
    ): void;
    handleToastClear(): void;
  };
}

const useToast: UseToastInterface = () => {
  const { dispatch } = useContext(PanelContext);

  return {
    handleToastDisplay: (text, variant = 'success', options = {}) => {
      enqueueToastAction(dispatch, {
        message: text,
        options: { ...options, variant },
      });
    },
    handleToastClear: () => {
      clearToastStoreAction(dispatch);
    },
  };
};

export default useToast;

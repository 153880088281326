import React from 'react';
import cx from 'classnames';
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { FormControlLabel, Radio } from '@mui/material';

import { CheckmarkIcon } from 'assets/icons';
import isMobile from 'helpers/isMobile';

import useStyles from './styles';

const MenuItem: React.FunctionComponent<MenuItemProps> = (props: MenuItemProps) => {
  const { selected, disabled, key, value, children, ...restProps } = props;
  const classes = useStyles({});
  const mobile = isMobile();

  const component = React.useMemo(() => {
    return (
      <div
        className={cx(classes['menu-item__content-container'], {
          [classes['menu-item__content-container__selected']]: selected,
        })}
      >
        <div className={classes['menu-item__content-label']}>{children}</div>
        {selected && <CheckmarkIcon height={24} width={24} />}
      </div>
    );
  }, [selected, children]);

  if (mobile)
    return (
      <FormControlLabel
        className={cx(classes['menu-item__mobile'], {
          [classes['menu-item__mobile__disabled']]: disabled,
        })}
        key={key}
        value={value}
        label={component}
        control={<Radio autoFocus={selected} className={classes['menu-item__radio__mobile']} />}
      />
    );

  return (
    <MuiMenuItem
      autoFocus={selected}
      className={classes['menu-item__desktop']}
      disabled={disabled}
      key={key}
      value={value}
      {...restProps}
    >
      {component}
    </MuiMenuItem>
  );
};

export default MenuItem;

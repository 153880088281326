import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import upperCase from 'lodash/upperCase';

import { MenuItem, MenuList } from '@mui/material';
import { updateAccountAction } from 'context/account/accountActions';
import { AccountContext } from 'context/account/accountContext';
import Dropdown from 'components/Dropdown';
import * as Icons from 'assets/icons/flags';

import { availableLanguages } from 'config';
import useStyles from './styles';

const LanguageSwitcher: React.FunctionComponent = () => {
  const { dispatch } = useContext(AccountContext);
  const classes = useStyles();
  const { i18n } = useTranslation();
  const DefaultFlagIcon = Icons[`${upperCase(i18n.language)}FlagIcon`];

  const handleLanguageChange = async (lang) => {
    window.localStorage.setItem('customLang', lang);
    await i18n.changeLanguage(lang);
    await updateAccountAction(dispatch, { interface_language: lang });
  };

  return (
    <Dropdown key={i18n.language} label={<DefaultFlagIcon className={classes['language-switcher__label-icon']} />}>
      <MenuList className={classes['language-switcher__list']}>
        {availableLanguages
          .filter((lang) => lang !== i18n.language)
          .map((lang) => {
            const FlagIcon = Icons[`${upperCase(lang)}FlagIcon`];
            return (
              <MenuItem
                className={classes['language-switcher__list-item']}
                key={lang}
                onClick={() => handleLanguageChange(lang)}
                value={lang}
              >
                <FlagIcon className={classes['language-switcher__label-icon']} />
              </MenuItem>
            );
          })}
      </MenuList>
    </Dropdown>
  );
};

export default LanguageSwitcher;

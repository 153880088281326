import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'drawer__title-wrapper': {
    position: 'relative',
    padding: theme.spacing(2, 4),
    borderBottom: `solid 1px ${theme.palette.grey[600]}`,
  },

  'drawer__title-wrapper--darker': {
    backgroundColor: 'rgba(1,1,1,0.3)',
  },

  'drawer__title-wrapper--back': {
    padding: theme.spacing(2),
  },

  drawer__title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  'drawer__title--close-only': {
    justifyContent: 'end',
  },

  'drawer__title-text': {
    flexGrow: 2,
  },

  'drawer__title-close': {
    marginRight: theme.spacing(-2),
  },

  'drawer__title-additional': {
    padding: theme.spacing(2, 0),
  },
}));

export default useStyles;

const calculatePercentDifference = (value: number, previous: number): string => {
  if (previous === 0 && value === 0) {
    return '0';
  }
  if (previous === 0) {
    return '+100';
  }
  if (value === previous) {
    return '0';
  }
  if (value > previous) {
    return `+${Math.round(((value - previous) / previous) * 100)}`;
  }
  if (value < previous) {
    return `${Math.round(((value - previous) / previous) * 100)}`;
  }

  return '';
};

export default calculatePercentDifference;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormControlLabel, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';

import { CheckChangeType, CurrencyChangeType, InputBlurType, InputChangeType } from 'types';
import useVoucher from 'helpers/apiHooks/useVoucher';
import logError from 'helpers/logError';
import usePanel from 'context/panel/hooks/usePanel';
import PaymentMethodSelect from 'components/PaymentMethodSelect';
import ButtonSwitcher, { ButtonSwitchChangeType } from 'components/ButtonSwitcher';
import NumberSelect from 'components/NumberSelect';
import ClientSelect from 'components/ClientSelect';
import DatePicker from 'components/DatePicker';
import Paragraph from 'components/Paragraph';
import PriceInput from 'components/PriceInput';
import TextField from 'components/TextField';
import Divider from 'components/Divider';
import Link from 'components/Link';

import { ClientType } from 'api/types/clients';
import { vouchersApi } from 'api';

import { VoucherFormErrorInterface, VoucherFormValueInterface, VoucherFormValueStateType } from '../../types';
import useStyles from './styles';

const formFields = {
  voucher: ['is_paid', 'buyer', 'client', 'payment_method'],
  discount: ['is_percent', 'quantity', 'payment_type'],
};

type VoucherFormPropsType = {
  formValue: VoucherFormValueInterface;
  errors: VoucherFormErrorInterface;
  onVoucherTypeChange: ButtonSwitchChangeType<VoucherFormValueStateType>;
  onExpirationDateChange: (date: Date) => void;
  onPaymentDateChange: (date: Date) => void;
  onPaymentMethodChange: (paymentMethod: any) => void;
  onInputBlur: InputBlurType;
  onNumberInputChange: (name: 'quantity' | 'percent' | 'price', value: number) => void;
  onCheckChange: CheckChangeType;
  onCurrencyChange: CurrencyChangeType;
  onInputChange: InputChangeType;
  onClientChange: (client: ClientType, type: 'buyer' | 'client') => void;
  onPaymentTypeChange: (paymentType?: 'value' | 'percent') => void;
  setVoucher: (voucher: Partial<VoucherFormValueInterface>, isNew?: boolean) => void;
};

const VoucherFormForm: React.FunctionComponent<VoucherFormPropsType> = ({
  formValue,
  errors,
  onVoucherTypeChange,
  onExpirationDateChange,
  onPaymentDateChange,
  onPaymentMethodChange,
  onInputBlur,
  onNumberInputChange,
  onCheckChange,
  onCurrencyChange,
  onInputChange,
  onClientChange,
  onPaymentTypeChange,
  setVoucher,
}) => {
  const {
    state: { voucherForm },
  } = usePanel();

  const { voucherDetails } = useVoucher({
    uuid: voucherForm.uuid,
  });

  const isEdit = !!voucherForm.uuid;

  const classes = useStyles();
  const { t } = useTranslation(['voucher']);

  const handleGenerateCodeClick = async () => {
    try {
      const {
        data: { code: codeResponse },
      } = await vouchersApi.generateCode();

      setVoucher({ code: codeResponse });
    } catch (e) {
      logError(e);
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ButtonSwitcher
          disabled={isEdit}
          stretch
          options={[
            { value: 'voucher', label: t('form.fields.type.voucher') },
            { value: 'discount', label: t('form.fields.type.discount') },
          ]}
          onChange={onVoucherTypeChange}
          value={formValue.type}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider title={t('form.section.basic')} />
        <TextField
          onChange={onInputChange}
          onBlur={onInputBlur}
          name="title"
          message={!!errors.title && { message: t(errors.title), type: 'error' }}
          value={formValue.title}
          label={t('form.fields.title.label')}
          placeholder={t('form.fields.title.placeholder')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={onInputChange}
          onBlur={onInputBlur}
          name="code"
          disabled={isEdit}
          message={!!errors.code && { message: t(errors.code), type: 'error' }}
          value={formValue.code}
          label={t('form.fields.code.label')}
          placeholder={t('form.fields.code.placeholder')}
          InputProps={{
            endAdornment: (
              <Button variant="text" color="secondary" disabled={isEdit} onClick={handleGenerateCodeClick}>
                {t('form.fields.code.generate')}
              </Button>
            ),
          }}
        />
      </Grid>

      {formValue.paymentType === 'value' && (
        <>
          <Grid container spacing={6} item xs={12} justifyContent="space-between" alignItems="center">
            <Grid item flexGrow={3}>
              <PriceInput
                value={formValue.price}
                currency={formValue.currency}
                label={t('form.fields.price.label')}
                placeholder={t('form.fields.price.placeholder')}
                currencyLabel={t('form.fields.price.currency')}
                message={!!errors.price && { message: t(errors.price), type: 'error' }}
                id="price"
                name="price"
                disabled={isEdit && voucherDetails?.type === 'voucher'}
                disabledCurrency={isEdit && voucherDetails?.type === 'voucher'}
                fullWidth
                onChangePrice={onInputChange}
                onBlurPrice={onInputBlur}
                onChangeCurrency={onCurrencyChange}
              />
            </Grid>
            {formValue.type === 'voucher' && (
              <Grid item>
                <FormControlLabel
                  disabled
                  control={<Checkbox checked color="secondary" />}
                  label={t('form.fields.paid.label')}
                />
              </Grid>
            )}
          </Grid>
          {formFields[formValue.type]?.includes('payment_type') && (
            <Grid item xs={12} className={classes['voucher-form__payment-switch-price']}>
              <span className={classes['voucher-form__payment-switch']}>
                {t('form.fields.paymentType.or')}&nbsp;
                <Link onClick={() => onPaymentTypeChange()}>
                  {t(`form.fields.paymentType.${formValue.paymentType}`)}
                </Link>
              </span>
            </Grid>
          )}
          {formFields[formValue.type]?.includes('payment_method') && (
            <Grid item xs={12}>
              <PaymentMethodSelect
                value={[formValue.paymentMethod]}
                onChange={onPaymentMethodChange}
                disabled={isEdit}
                label={t('form.fields.paymentMethod.label')}
                exclude={['free']}
                message={!!errors.paymentMethod && { message: t(errors.paymentMethod), type: 'error' }}
              />
            </Grid>
          )}
        </>
      )}

      {formValue.paymentType === 'percent' && (
        <Grid item xs={12}>
          <TextField
            value={formValue.percent}
            name="percent"
            label={t('form.fields.percent.label')}
            placeholder={t('form.fields.percent.placeholder')}
            message={!!errors.percent && { message: t(errors.percent), type: 'error' }}
            type="number"
            startIcon="percent"
            onChange={onInputChange}
            onBlur={onInputBlur}
          />
          <span className={classes['voucher-form__payment-switch']}>
            {t('form.fields.paymentType.or')}&nbsp;
            <Link onClick={() => onPaymentTypeChange()}>{t(`form.fields.paymentType.${formValue.paymentType}`)}</Link>
          </span>
        </Grid>
      )}

      {formValue.type === 'voucher' && (
        <Grid item xs={12}>
          <Divider title={t('form.section.details')} />
          <DatePicker
            onChange={onPaymentDateChange}
            value={formValue.paymentDate}
            label={t('form.fields.paidDate.label')}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValue.isLimitedDate}
                color="secondary"
                name="isLimitedDate"
                onChange={onCheckChange}
              />
            }
            label={t('form.fields.expirationDate.isLimited')}
          />
        </div>
        {formValue.isLimitedDate && (
          <DatePicker onChange={onExpirationDateChange} value={formValue.expirationDate} minDate={new Date()} />
        )}
      </Grid>

      {formFields[formValue.type]?.includes('quantity') && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValue.isLimitedQuantity}
                color="secondary"
                name="isLimitedQuantity"
                onChange={onCheckChange}
              />
            }
            label={t('form.fields.quantity.isLimited')}
          />
          {formValue.isLimitedQuantity && (
            <NumberSelect onChange={(value) => onNumberInputChange('quantity', value)} value={formValue.quantity} />
          )}
        </Grid>
      )}

      {formFields[formValue.type]?.includes('client') && (
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <Paragraph type="size-XS-medium">{t('form.fields.client.label')}</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <ClientSelect onClientChange={(client) => onClientChange(client, 'client')} client={formValue.client} />
          </Grid>
        </Grid>
      )}

      {formFields[formValue.type]?.includes('buyer') && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox checked={formValue.isBuyerSame} color="secondary" name="isBuyerSame" onChange={onCheckChange} />
            }
            label={t('form.fields.buyer.isBuyer')}
          />
          {!formValue.isBuyerSame && (
            <div>
              <InputLabel shrink>{t('form.fields.buyer.label')}</InputLabel>
              <ClientSelect onClientChange={(client) => onClientChange(client, 'buyer')} client={formValue.buyer} />
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default VoucherFormForm;

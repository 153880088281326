import React from 'react';

import Header from 'components/Header';
import theme from 'theme';

import useStyles from './styles';

export interface PropsInterface {
  title?: React.ReactNode;
  margin?: number;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit' | 'darken' | '';
  padding?: number;
}

const Divider: React.FunctionComponent<PropsInterface> = ({ title, margin = 4, color, padding = 0 }) => {
  const classes = useStyles({ margin, padding });

  const styles =
    color === 'darken'
      ? { borderColor: theme.palette.background.drawer }
      : {
          borderColor: color ? theme.palette[color].main : theme.palette.grey[600],
        };

  return (
    <div className={classes.divider}>
      {title && (
        <div className={classes['divider__title']}>
          <Header type="h4" variant="h4" color="secondary">
            {title}
          </Header>
        </div>
      )}
      <hr className={classes['divider__hr']} style={styles} />
    </div>
  );
};

export default Divider;

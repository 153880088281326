import { DashboardFilterInterface } from 'views/Dashboard/types';
import generateDateFromId from 'helpers/date/generateDateFromId';
import parseDateToString from 'helpers/date/parseDateToString';
import { PeriodTypeKeyType } from 'types/date';

const convertFiltersToParams = (
  filters: DashboardFilterInterface,
): {
  period: PeriodTypeKeyType;
  employee_id: string;
  currency: number;
  date_start: string;
} => {
  return {
    period: filters.periodType.key,
    employee_id: filters.employee,
    currency: filters.currency?.value,
    date_start: parseDateToString(generateDateFromId(filters.date)),
  };
};

export default convertFiltersToParams;

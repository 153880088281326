/* eslint-disable */
const objectToFormData = (obj, ignoreList = null) => {
  if (obj instanceof FormData) {
    return obj;
  }
  const formData = new FormData();

  function appendFormData(data, root = '') {
    if (!ignore(root)) {
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            appendFormData(data[i], `${root}[${i}]`);
          }
        } else {
          return formData.append(`${root}[0]`, '');
        }
      } else if (typeof data === 'object' && data) {
        for (const key in data) {
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], `${root}[${key}]`);
            }
          }
        }
      } else if (data !== null && typeof data !== 'undefined') {
        formData.append(root, data);
      }
    }
  }

  function ignore(root) {
    return (
      Array.isArray(ignoreList) &&
      ignoreList.some(function (x) {
        return x === root;
      })
    );
  }

  appendFormData(obj);

  return formData;
};

export default objectToFormData;

import { VoucherFormErrorInterface, VoucherFormValueInterface } from './types';

export const VoucherFormValueState: VoucherFormValueInterface = {
  client: null,
  buyer: null,
  isBuyerSame: true,
  code: '',
  title: '',
  price: '',
  isPaid: true,
  paymentMethod: null,
  paymentType: 'value',
  currency: null,
  percent: '',
  quantity: null,
  isLimitedQuantity: false,
  isLimitedDate: false,
  isDifferentPaymentDate: false,
  paymentDate: new Date(),
  type: 'voucher',
  expirationDate: new Date(),
};

export const VoucherErrorValueState: VoucherFormErrorInterface = {
  general: null,
  title: null,
  code: null,
  price: null,
  percent: null,
  paymentMethod: null,
};

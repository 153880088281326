import React, { useEffect } from 'react';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { EventNotificationInterface } from 'context/panel/types';
import Drawer from 'components/Drawer';
import useInfiniteScroll from 'helpers/hooks/useInfiniteScroll';

import useStyles from './styles';

interface PropsInterface {
  isOpen: boolean;
  onClose(): void;
  onNextPage(): void;
  notifications: {
    events?: EventNotificationInterface[];
  };
  hasMore?: boolean;
}

const NotificationsDrawer: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { isOpen, onClose, notifications, hasMore, onNextPage } = props;

  const [page, loaderRef, scrollerRef] = useInfiniteScroll({ hasMore });

  const classes = useStyles({});

  useEffect(() => {
    onNextPage();
  }, [page]);

  return (
    <Drawer onClose={onClose} open={isOpen} title="Notifications">
      <div ref={scrollerRef}>
        <MenuList autoFocusItem={isOpen}>
          {notifications.events?.map(({ type }) => (
            <MenuItem key={Math.random()}>{type}</MenuItem>
          ))}
        </MenuList>
      </div>
      <div className={classes['notifications__scroll-indicator']} ref={loaderRef} />
    </Drawer>
  );
};

export default NotificationsDrawer;

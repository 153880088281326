import React, { useState, useRef, useEffect } from 'react';
import map from 'lodash/map';
import cx from 'classnames';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useRect } from 'helpers/hooks/useRect';

import ButtonSwitch from './ButtonSwitch';
import useStyles from './styles';

type ValueType = string | number | null;

export type ButtonSwitchChangeType<T = ValueType> = (value: T) => void;

export type ButtonSwitcherOptionType = {
  value: ValueType;
  label: React.ReactNode;
};

interface PropsInterface {
  defaultValue?: ValueType;
  value?: ValueType;
  options: ButtonSwitcherOptionType[];
  onChange: ButtonSwitchChangeType;
  stretch?: boolean;
  disabled?: boolean;
  color?: 'default' | 'lighten' | 'darken';
}

const ButtonSwitcher: React.FunctionComponent<PropsInterface> = ({
  defaultValue = null,
  options,
  onChange = () => {},
  stretch = false,
  disabled,
  value,
  color,
}) => {
  const [activeButton, setActiveButton] = useState<ValueType>(value || defaultValue || null);
  const [activeRect, setActiveRect] = useState({
    bottom: 0,
    left: 0,
    width: 0,
  });

  const containerRef = useRef(null);
  const rect = useRect(containerRef);

  const classes = useStyles({});

  const handleClick = (_, value: ValueType) => {
    setActiveButton(value);
    onChange(value);
  };

  const setItemRect = (itemRect) => {
    setActiveRect(itemRect);
  };

  useEffect(() => {
    if (value) {
      setActiveButton(value);
    }
  }, [value]);

  return (
    <ToggleButtonGroup
      className={cx(classes['button-switcher'], { [classes['button-switcher--darken']]: color === 'darken' })}
      exclusive
      fullWidth={stretch}
      onChange={handleClick}
      ref={containerRef}
      disabled={disabled}
    >
      {map(options, (option: ButtonSwitcherOptionType) => (
        <ButtonSwitch
          disabled={disabled}
          className={cx(classes['button-switcher__button'], {
            [classes['button-switcher__button--active']]: option.value === activeButton,
          })}
          isActive={option.value === activeButton}
          key={option.value}
          setRect={setItemRect}
          value={option.value}
        >
          {option.label}
        </ButtonSwitch>
      ))}
      <div
        className={classes['button-switcher__indicator']}
        style={{
          left: activeRect.left > 0 && rect ? activeRect.left - (rect ? rect.left : 0) + 4 : 0,
          width: activeRect && activeRect.width - 4 * 2,
        }}
      />
    </ToggleButtonGroup>
  );
};

export default ButtonSwitcher;

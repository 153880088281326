import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { createHashHistory, createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import Routes from 'routes';

import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import 'react-phone-input-2/lib/high-res.css';

import INKsearchLoader from 'components/INKsearchLoader/INKsearchLoader';
import PanelProvider from 'context/panel/panelContext';
import ToastProvider from 'components/ToastProvider';
import isElectron from 'helpers/electron/isElectron';
import mixpanel from 'helpers/deprecatedMixpanel';
import AccountProvider from 'context/account';
import GlobalStyles from 'theme/globalStyles';
import 'assets/scss/index.scss';
import theme from 'theme';

import packageInfo from '../package.json';
import isUserTester from './helpers/isUserTester';
import TesterMode from './components/TesterMode';
import Error500 from './containers/500';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const browserHistory = isElectron() ? createHashHistory() : createBrowserHistory();

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://9f665f58ff6e4ec0b81f9be638a9447c@o560383.ingest.sentry.io/5695902',
    tracesSampleRate: 0.5,
    environment: import.meta.env.NODE_ENV,
    release: `INKbusiness@${packageInfo.version}`,
  });

  browserHistory.listen((location) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      pagePath: location,
      pageTitle: window.document.title,
    });
  });
}

mixpanel.init();

const App: React.FunctionComponent = () => {
  const isTesterMode = isUserTester();

  return (
    <Sentry.ErrorBoundary fallback={<Error500 />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<INKsearchLoader>Reloading...</INKsearchLoader>}>
            {isTesterMode && <TesterMode />}
            <GlobalStyles />
            <Router history={browserHistory}>
              <ToastProvider>
                <AccountProvider>
                  <PanelProvider>
                    <Routes />
                  </PanelProvider>
                </AccountProvider>
              </ToastProvider>
            </Router>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;

const isValidDate = (dateObject: Date): boolean => {
  return dateObject?.toString() !== 'Invalid Date';
};

// returns string format yyyy-mm-dd eg: 2020-11-20
const generateDayId = (date: Date): string => {
  if (date && isValidDate(date) && typeof date.getMonth === 'function') {
    const unifiedMonth = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const unifiedDay = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    return `${date.getFullYear()}-${unifiedMonth}-${unifiedDay}`;
  }
  return null;
};

export default generateDayId;

import makeStyles from '@mui/styles/makeStyles';
import layoutValues from 'theme/constatns';

const useStyles = makeStyles((theme) => ({
  'sidebar-nav-link': {
    display: 'flex',
    color: 'inherit',
    alignItems: 'left',
    padding: theme.spacing(2, 4, 2, 2),
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontSize: '14px',
    lineHeight: 1.715,
    fontWeight: 500,
    transition: '200ms',
    '&:focus, &:active': {
      color: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.88)',
      borderRadius: layoutValues.borderRadius,
    },
  },

  'sidebar-nav-link__label': {
    transition: 'opacity 0.3s ease-in-out',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },

  'sidebar-nav-link__label--fade': {
    opacity: 0,
  },

  'sidebar-nav-link__icon': {
    width: layoutValues.sidebar.menuItem.iconSize,
    height: layoutValues.sidebar.menuItem.iconSize,
    display: 'flex',
    alignItems: 'center',
    marginLeft: (layoutValues.sidebar.width.slim - layoutValues.sidebar.menuItem.iconSize) / 2 - 32,
    marginRight: theme.spacing(4),
    color: 'inherit',
  },

  'sidebar-nav-link--active': {
    color: 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.88)',
    borderRadius: layoutValues.borderRadius,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },

  'sidebar-nav-link--not-clickable': {
    pointerEvents: 'none',
  },

  'sidebar-nav-link--clickable': {
    pointerEvents: 'auto',
    cursor: 'pointer',
  },

  'sidebar-nav-link--disabled': {
    pointerEvents: 'auto',
  },
}));

export default useStyles;

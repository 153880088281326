import React from 'react';

import MuiChip, { ChipProps } from '@mui/material/Chip';

export interface PropsInterface extends ChipProps {
  test?: string;
}

const Chip: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { ...restProps } = props;

  return <MuiChip {...restProps} />;
};

export default Chip;

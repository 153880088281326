import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Grid, IconButton, Icon } from '@mui/material';

import { commonApi } from 'api';
import GuideIcon from 'assets/icons/guide.svg?component';
import Hero from 'assets/img/drawer_hero.png';
import Card from 'components/Card';
import Drawer from 'components/Drawer';
import Header from 'components/Header';
import LoaderWrapper from 'components/LoaderWrapper';
import Paragraph from 'components/Paragraph';
import ReleaseNotesModal from 'containers/ReleaseNotesModal';
import usePanel from 'context/panel/hooks/usePanel';
import { getCookie, setCookie } from 'helpers/cookie';
import formatDate from 'helpers/date/formatDate';
import logError from 'helpers/logError';

import useStyles from './styles';
import { ReleaseNoteType } from './components/ReleaseNotesUpdateRepresentation';
import ReleaseNotesUpdateList from './components/ReleaseNotesUpdateList';
import packageInfo from '../../../package.json';

const ReleaseNotes = () => {
  const { setReleaseDetailsOpen } = usePanel();

  const [isBadgeVisible, setBadgeVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExtendedList, setExtenderList] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNoteType[]>(null);
  const [isFetching, setFetching] = useState(true);
  const [isOpen, setOpen] = useState(false);

  const classes = useStyles();
  const { t, i18n } = useTranslation('common');

  const productMinorVersion = packageInfo.version.split('.', 2).join('.');

  const handleIconClick = () => {
    setOpen((p) => !p);
    setExtenderList(false);
    setBadgeVisible(false);
    setCookie('release-version', productMinorVersion);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateClick = (uuid) => {
    setReleaseDetailsOpen({ uuid });
  };

  useEffect(() => {
    if (isOpen) {
      commonApi.releaseNotes
        .list({ lang: i18n.language, limit: isExtendedList ? 9999 : 5 })
        .then(({ data }) => {
          setReleaseNotes(
            data?.map((note) => ({
              cover: note.photo_url,
              date: formatDate(new Date(note.release_date_iso)),
              description: note.short_description,
              uuid: note.uuid,
              title: note.title,
              productVersion: note?.product_version,
            })),
          );
        })
        .catch((e) => {
          logError(e);
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }, [isOpen, isExtendedList]);

  useEffect(() => {
    if (productMinorVersion !== getCookie('release-version')) {
      setIsModalOpen(true);
      setBadgeVisible(true);
    }
  }, []);

  return (
    <>
      <IconButton color="inherit" onClick={handleIconClick} size="large">
        <Badge color="primary" invisible={!isBadgeVisible} badgeContent="New">
          <GuideIcon />
        </Badge>
      </IconButton>

      <Drawer onClose={handleClose} open={isOpen} title={t('release-notes.title')}>
        {!isExtendedList ? (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div className={classes['guide-menu__hero']}>
                <img alt="hero" className={classes['guide-menu__hero-image']} src={Hero} />
                <div className={classes['guide-menu__hero-title']}>
                  <Header type="h1" variant="h1">
                    {t('release-notes.hero.title')}
                  </Header>
                  <Paragraph type="size-S-medium">{t('release-notes.hero.subtitle')}</Paragraph>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper loading={isFetching} noContent>
                <ReleaseNotesUpdateList updates={releaseNotes} onClick={handleUpdateClick} />
              </LoaderWrapper>
            </Grid>
            <Grid item xs={12}>
              <Card color="gradient-dark" direction="horizontal" margin={6} onClick={() => setExtenderList(true)}>
                <div>{t('release-notes.more.title')}</div>
                <Icon>chevron_right</Icon>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card fill>
                <div className={classes['release-notes__help-card']}>
                  <Header type="h2" variant="h4">
                    {t('release-notes.help.title')}
                  </Header>
                  <Paragraph type="size-S-medium">{t('release-notes.help.paragraph')}</Paragraph>
                  <Button color="secondary" href="mailto:contact@inkbusiness.co" variant="outlined">
                    {t('release-notes.help.cta')}
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <LoaderWrapper loading={isFetching} noContent>
            <ReleaseNotesUpdateList updates={releaseNotes} onClick={handleUpdateClick} />
          </LoaderWrapper>
        )}
      </Drawer>
      <ReleaseNotesModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setBadgeVisible(true);
          setCookie('release-version', productMinorVersion);
        }}
        onAccept={() => {
          setIsModalOpen(false);
          setCookie('release-version', productMinorVersion);
        }}
      />
    </>
  );
};

export default ReleaseNotes;

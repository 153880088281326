import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'autocomplete-mobile': {
    position: 'relative',
  },

  'autocomplete-mobile__text-field': {
    position: 'relative',
  },

  'autocomplete-mobile__text-field-badge': {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },

  'autocomplete-mobile__drawer-title': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  'autocomplete-mobile__results': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  'autocomplete-mobile__result': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    transition: '200ms',
    '&:focus, &:hover': {
      textDecoration: 'underline',
    },
  },
  'autocomplete-mobile--font': {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  'autocomplete-mobile__result--disabled': {
    cursor: 'default',
    pointerEvents: 'none',
  },
}));

export default useStyles;

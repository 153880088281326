import React from 'react';

import ContentEmptyPlaceholder from 'components/ContentEmptyPlaceholder';
import { DataTableEmptyType } from 'components/DataTable/DataTable';
import Alert from 'components/Alert';

import DataTableSkeleton from '../DataTableSkeleton';
import useStyles from './styles';

const DataTableEmpty: React.FunctionComponent<DataTableEmptyType> = ({
  header,
  paragraph,
  cta,
  href,
  ctaClick,
  alert,
  placeholderRows = 6,
}) => {
  const classes = useStyles();

  return (
    <div className={classes['data-table__empty']}>
      <div className={classes['data-table__empty-skeleton']}>
        <DataTableSkeleton animate={false} rows={placeholderRows} />
      </div>
      <div className={classes['data-table__empty-content-wrapper']}>
        <div className={classes['data-table__empty-content']}>
          <ContentEmptyPlaceholder title={header} subtitle={paragraph} cta={{ label: cta, onClick: ctaClick, href }} />
          {alert && (
            <div className={classes['data-table__alert']}>
              <Alert message={alert.message} title={alert.title} type={alert.variant || 'info'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataTableEmpty;

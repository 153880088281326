import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validate from 'validate.js';

import generateDayId from 'helpers/date/generateDayId';
import useToast from 'helpers/hooks/useToast';
import postClient from 'helpers/api/postClient';
import { ClientDetailsType, ClientEditParamsType } from 'api/types/clients';
import { CONTACT_SOURCES } from 'constants/constants';

import { clientsApi } from 'api';
import objectToFormData from 'helpers/objectToFormData';
import { PhoneInputChangeType } from 'components/PhoneInput';
import { AutocompleteChangeType } from 'components/Autocomplete/Autocomplete';
import { SelectChangeType } from 'components/Select';
import { ContactSourceChangeType, ContactSourceSelectType } from 'components/ContactSourceSelect';
import { CheckboxChangeType } from 'components/Checkbox';
import { DropzoneDropType, DropzoneRemoveType } from 'components/Dropzone';

import { ClientFormInitialError, ClientFormInitialStateInterface, ClientFormInitialStatus } from './constants';
import { ClientFormErrorsType, ClientFormStateType, ClientFormStatusType } from './types';

const useClientForm = (
  clientDetails: ClientDetailsType,
  isOpen: boolean,
  onSuccess?: (client: ClientDetailsType) => void,
) => {
  const { t, i18n } = useTranslation(['common', 'page-client']);
  const { handleToastDisplay } = useToast();

  const [formValue, setFormValue] = useState<ClientFormStateType>({
    ...ClientFormInitialStateInterface,
    language: {
      code: i18n.language,
      name: i18n.language,
      native: i18n.language,
    },
  });
  const [errors, setErrors] = useState<ClientFormErrorsType>(ClientFormInitialError);
  const [status, setStatus] = useState<ClientFormStatusType>(ClientFormInitialStatus);

  const validation = useMemo(
    () => ({
      name: {
        presence: { allowEmpty: false, message: t('common:validators.common.notEmpty') },
        length: {
          maximum: 50,
          message: t('common:validators.common.toLong'),
        },
      },
      email: {
        presence: false,
        email: { message: t('common:validators.common.notValid') },
        length: {
          maximum: 200,
          message: t('common:validators.common.toLong'),
        },
      },
    }),
    [t],
  );

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value?.length < 1000) {
      setFormValue((prevState) => ({ ...prevState, [name]: value }));
    }
  }, []);

  const handleDateChange = useCallback((date: Date) => {
    setFormValue((prevState) => ({ ...prevState, birthday: date }));
  }, []);

  const handleInputBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name && validation[name]) {
      setErrors((prevValue) => ({
        ...prevValue,
        [name]: validate.single(value || null, validation[name]),
      }));
    }
  }, []);

  const handlePhoneChange: PhoneInputChangeType = useCallback((event, number) => {
    setFormValue((prevState) => ({ ...prevState, phoneNumber: number }));
  }, []);

  const handleLanguageChange: AutocompleteChangeType = useCallback((event, value) => {
    setFormValue((prevState) => ({ ...prevState, language: value }));
  }, []);

  const handleTagSelect: AutocompleteChangeType = useCallback((event, value) => {
    setFormValue((prevState) => ({
      ...prevState,
      tags: value?.map((tag) => (typeof tag === 'string' ? { uuid: '', label: tag } : tag)),
    }));
  }, []);

  const handleClientSourceSelect: SelectChangeType = useCallback((event) => {
    setFormValue((prevState) => ({ ...prevState, clientSource: parseInt(event.target.value || '0', 10) }));
  }, []);

  const handleContactSourceSelect: ContactSourceSelectType = useCallback((value) => {
    setFormValue((prevState) => ({
      ...prevState,
      contactSources: value.map((item) => ({
        label: CONTACT_SOURCES[item].type,
        type: item,
        id: clientDetails?.client_providers?.find(({ type }) => type === item)?.id,
      })),
    }));
  }, []);

  const handleContactSourceChange: ContactSourceChangeType = useCallback((event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => ({
      ...prevState,
      contactSourcesValue: { ...prevState.contactSourcesValue, [name]: value },
    }));
  }, []);

  const handleConsentsChange: CheckboxChangeType = useCallback((event, checked) => {
    const { name } = event.target;
    setFormValue((prevState) => ({ ...prevState, consents: { ...prevState.consents, [name]: checked } }));
  }, []);

  const handleConsentFileDrop: DropzoneDropType = useCallback((files) => {
    setFormValue((prevState) => ({
      ...prevState,
      consents: { ...prevState.consents, files: [...(prevState.consents.files as File[]), ...files] },
    }));
  }, []);

  const handleConsentFileRemove: DropzoneRemoveType = useCallback(
    (id) => {
      setFormValue((prevState) => ({
        ...prevState,
        consents: {
          ...prevState.consents,
          filesToRemove: id ? [...formValue.consents.filesToRemove, id] : formValue.consents.filesToRemove,
          files: formValue.consents.files?.filter((file) => file.id !== id),
        },
      }));
    },
    [formValue.consents.files, formValue.consents.filesToRemove],
  );

  const handleValidate = useCallback(() => {
    const validateResult = validate(formValue, validation, { fullMessages: false });
    if (!validateResult) {
      return true;
    }
    setErrors(validateResult);
    return false;
  }, [formValue]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (status.isSending) {
      return;
    }

    if (handleValidate()) {
      setStatus((prevState) => ({ ...prevState, isSending: true }));

      const params: ClientEditParamsType = {
        uuid: clientDetails?.uuid,
        name: formValue.name,
        gender: formValue.gender,
        phone: formValue.phoneNumber,
        email: formValue.email,
        client_source: formValue.clientSource,
        birthday: generateDayId(formValue.birthday),
        user_language: formValue.language?.code,
        marketing_consent: formValue.consents.general ? 1 : 0,
        user_tags: formValue.tags || null,
        client_providers:
          formValue.contactSources?.length > 0
            ? formValue.contactSources?.map((value) => ({
                type: value.type,
                id: value.id,
                value:
                  value.type === 4
                    ? formValue.contactSourcesValue?.[value.type] || formValue.phoneNumber
                    : formValue.contactSourcesValue?.[value.type],
              }))
            : null,
        personal_data: {
          home_address: formValue.street,
          home_number: formValue.homeNumber,
          post_code: formValue.postCode,
          city_name: formValue.city,
          country_name: formValue.country,
          vat_number: formValue.vat,
          other_info: formValue.additional,
        },
      };

      const consentParams = {
        customer_id: clientDetails?.uuid,
        documents: formValue.consents.files?.length > 0 ? formValue.consents.files : undefined,
        deleted_files: formValue.consents.filesToRemove?.length > 0 ? formValue.consents.filesToRemove : undefined,
      };

      try {
        const { data: clientResponse } = await postClient(params);
        await clientsApi.consents.store(objectToFormData({ ...consentParams, customer_id: clientResponse.uuid }));
        if (typeof onSuccess === 'function') {
          onSuccess(clientResponse);
          handleToastDisplay(t('page-client:clientEdit.messages.editSuccess'), 'success');
        }
      } catch (e) {
        handleToastDisplay(t('page-client:clientEdit.messages.editError'), 'error');
      }
    }

    setStatus((prevState) => ({ ...prevState, isSending: false }));
  };

  useEffect(() => {
    if (isOpen) {
      setErrors(ClientFormInitialError);
      if (clientDetails?.uuid) {
        setFormValue({
          ...ClientFormInitialStateInterface,
          name: clientDetails.name,
          email: clientDetails.email,
          phoneNumber: clientDetails.phone,
          language: {
            code: clientDetails.user_language,
            native: clientDetails.user_language_label,
            name: clientDetails.user_language_label,
          },
          birthday: clientDetails.birthday ? new Date(clientDetails.birthday) : null,
          gender: clientDetails.gender || null,
          street: clientDetails.personal_data?.home_address,
          homeNumber: clientDetails.personal_data?.home_number,
          postCode: clientDetails.personal_data?.post_code,
          city: clientDetails.personal_data?.city_name,
          country: clientDetails.personal_data?.country_name,
          vat: clientDetails.personal_data?.vat_number,
          additional: clientDetails.personal_data?.other_info,
          contactSourcesValue: clientDetails.client_providers?.reduce(
            (state, { value, type, id }) => ({ ...state, [type]: value, id }),
            {},
          ),
          contactSources: clientDetails.client_providers?.map(({ id, type }) => ({
            id,
            label: CONTACT_SOURCES[type]?.type,
            type,
          })),
          clientSource: clientDetails.client_source || -1,
          tags:
            clientDetails.user_tags?.length > 0
              ? clientDetails.user_tags?.map(({ uuid, label }) => ({ uuid, label }))
              : null,
          consents: {
            general: clientDetails.marketing_consent ? 1 : 0,
            files: [],
            filesToRemove: [],
          },
        });
      } else {
        setFormValue(ClientFormInitialStateInterface);
      }
    }
  }, [isOpen, clientDetails?.uuid]);

  return {
    formValue,
    errors,
    status,
    handleInputChange,
    handleDateChange,
    handleInputBlur,
    handlePhoneChange,
    handleTagSelect,
    handleClientSourceSelect,
    handleContactSourceChange,
    handleContactSourceSelect,
    handleLanguageChange,
    handleConsentsChange,
    handleConsentFileDrop,
    handleConsentFileRemove,
    handleFormSubmit,
  };
};

export default useClientForm;

import React, { useContext, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { PanelPageLayout, Authentication as AuthenticationLayout } from 'layouts';
import { AccountContext } from 'context/account/accountContext';
import INKsearchLoader from 'components/INKsearchLoader';
import RouteWithLayout from 'components/RouteWithLayout';
import lazyLoad from 'helpers/lazyLoad';
import { bizRoutes } from 'config';

const StorybookView = lazyLoad(() => import('../views/Storybook'));
const DesktopAppRedirectView = lazyLoad(() => import('../views/DesktopAppRedirect'));
const StudioInvitationView = lazyLoad(() => import('../views/StudioInvitation'));
const LimboRoutes = lazyLoad(() => import('./LimboRoutes'));
const PublicRoutes = lazyLoad(() => import('./PublicRoutes'));
const PanelRoutes = lazyLoad(() => import('./PanelRoutes'));

const Routes: React.FunctionComponent = () => {
  const { state: accountContext } = useContext(AccountContext);
  const isAuthUser = accountContext.data;
  const isUserRole = accountContext.data?.roles_check === 'user';
  const isOnboardingComplete = accountContext.data?.onboarding_complete;
  const isMissingPhoneVerification = !accountContext.data?.phone_verified;
  const isNotLoggedIn = !isAuthUser || !accountContext.isLogged;

  return (
    <Suspense fallback={<INKsearchLoader>Getting routes...</INKsearchLoader>}>
      <Switch>
        <RouteWithLayout
          component={StorybookView}
          exact={false}
          layout={PanelPageLayout}
          path={bizRoutes.storybook.href}
          slim
        />
        <RouteWithLayout
          component={DesktopAppRedirectView}
          exact
          layout={AuthenticationLayout}
          path={bizRoutes.desktopAppRedirect.href}
        />
        <RouteWithLayout
          component={StudioInvitationView}
          exact={false}
          layout={AuthenticationLayout}
          path={bizRoutes.studioInvitation.href}
        />
        {isNotLoggedIn && <PublicRoutes />}
        {((!isNotLoggedIn && !isOnboardingComplete) || isUserRole || isMissingPhoneVerification) && <LimboRoutes />}
        {!isNotLoggedIn && isOnboardingComplete && <PanelRoutes />}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default Routes;

import makeStyles from '@mui/styles/makeStyles';
import layoutValues from 'theme/constatns';
import theme from '../../theme';

const useStyles = makeStyles(() => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '&': {
      scrollbarColor: `${theme.palette.white.light} ${theme.palette.black.dark}`,
      scrollbarWidth: 'thin',
    },

    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
      height: theme.spacing(1),
      backgroundColor: theme.palette.black.dark,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 0,
      backgroundColor: theme.palette.white.light,
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  'layout--desktop': {
    paddingLeft: layoutValues.sidebar.width.default,
  },
  'layout--mobile': {
    '& $layout-content': {
      paddingBottom: '3em',
    },
  },
  'layout--shift-slim': {
    paddingLeft: layoutValues.sidebar.width.slim,
  },
  'layout--fluid': {
    height: '100%',
    width: '100%',
  },
  'layout--padding': {
    '& $layout-content': {
      padding: theme.spacing(6),
    },
  },
  'layout-content': {
    flex: '1 1 auto',
    position: 'relative',
  },
  'layout-content-animation': {
    display: 'none',
  },
  'layout-content-animation--enter': {
    display: 'block',
    opacity: '0',
    zIndex: 10,
  },
  'layout-content-animation--enter-active': {
    display: 'block',
    opacity: '1',
    transition: 'opacity 1050ms',
  },
  'layout-content-animation--exit': {
    display: 'none',
  },
  'layout-content-animation--exit-active': {
    display: 'none',
  },
  'layout-content-animation--done': {},
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'upgrade-button': {
    whiteSpace: 'break-spaces',
    border: 'solid 1px #DFDFE1',
    color: ' #DFDFE1',
    '&:hover': {
      border: 'solid 1px #fff',
      color: '#fff',
      background: '#4e5054',
    },
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'profile-menu__profile': {
    padding: theme.spacing(4, 4, 0, 4),
  },

  'profile-menu__alert': {
    margin: theme.spacing(4, 0),
  },

  'profile-menu__navigation-title': {
    padding: theme.spacing(0, 4, 2, 4),
  },

  'profile-menu__menu-icon': {
    marginRight: theme.spacing(4),
  },

  'profile-menu__additinal-account': {
    padding: theme.spacing(0, 4),
  },

  'profile-menu__additinal-account-item': {
    padding: theme.spacing(1, 0),
  },

  'profile-menu__additinal-account-add': {
    marginTop: theme.spacing(2),
    textTransform: 'initial',
  },

  'profile-menu__log-out': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 4, 4),
  },
}));

export default useStyles;

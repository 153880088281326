const generateDateFromId = (id: string): Date => {
  if (id?.includes('-')) {
    const [year, month, day] = id.split('-').map((item) => parseInt(item, 10));
    return new Date(year, month - 1, day);
  }

  return null;
};

export default generateDateFromId;

import { useContext } from 'react';

import { AccountContext } from 'context/account/accountContext';
import config from 'config';

const useSmsInformation = () => {
  const { state: accountContext } = useContext(AccountContext);

  const isDisabledByPlan = !config.clientCommunication.bulk.planAccess.includes(
    // @ts-ignore
    accountContext?.data.business_data?.active_plan?.slug,
  );

  const smsAmount =
    (accountContext?.data.business_data?.sms_amount?.sms_current_amount || 0) +
    (accountContext?.data.business_data?.sms_amount?.sms_current_amount_additional || 0);

  return {
    smsAmount,
    isDisabledByPlan,
  } as const;
};

export default useSmsInformation;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'product-update-modal': {
    width: '80vw',
    maxWidth: 680,
  },
}));

export default useStyles;

import React from 'react';
import { SnackbarProvider } from 'notistack';

import isMobile from 'helpers/isMobile';
import { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon } from 'assets/icons/status';
import useStyles from './styles';

interface PropsInterface {
  children: React.ReactNode;
}

const ToastProvider: React.FunctionComponent<PropsInterface> = ({ children }) => {
  const classes = useStyles();
  const mobile = isMobile();

  return (
    <SnackbarProvider
      autoHideDuration={6000}
      onClose={() => {}}
      anchorOrigin={
        mobile
          ? {
              vertical: 'top',
              horizontal: 'center',
            }
          : {
              vertical: 'bottom',
              horizontal: 'left',
            }
      }
      classes={{
        root: classes['toast'],
        variantSuccess: classes['toast--success'],
        variantError: classes['toast--error'],
        variantWarning: classes['toast--warning'],
        variantInfo: classes['toast--info'],
      }}
      iconVariant={{
        success: <SuccessIcon className={classes['toast__icon']} />,
        error: <ErrorIcon className={classes['toast__icon']} />,
        warning: <WarningIcon className={classes['toast__icon']} />,
        info: <InfoIcon className={classes['toast__icon']} />,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;

import i18n from 'i18next';
import { availableLanguages } from '../../config';

const getUserCustomLang = (): string => {
  const customLang = i18n.language ?? window.localStorage.getItem('customLang')?.substring(0, 2);
  const langHandled = availableLanguages.includes(customLang);

  return langHandled ? customLang : window.navigator.language.substring(0, 2);
};

export default getUserCustomLang;

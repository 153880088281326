import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  notifications__anchor: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  'notifications__scroll-indicator': {
    height: 40,
    width: 300,
    backgroundColor: 'red',
  },
}));

export default useStyles;

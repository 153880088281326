import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import Button from '@mui/material/Button';

import useStyles from './styles';

export interface PropsInterface {
  href?: string;
  onClick?(): void;
  title: string;
  icon?: React.ReactNode;
  fadeTitle?: boolean;
  isActive?: boolean;
  isNested?: boolean;
}

const SidebarNavLink: React.FunctionComponent<PropsInterface> = ({
  href,
  onClick,
  icon,
  title,
  fadeTitle,
  isActive = false,
  isNested = false,
}) => {
  const classes = useStyles();

  const customProps = !href
    ? {}
    : {
        component: NavLink,
        to: href,
      };

  return (
    <Button
      {...customProps}
      onClick={onClick}
      className={cx(classes['sidebar-nav-link'], {
        [classes['sidebar-nav-link--active']]: isActive,
        [classes['sidebar-nav-link--not-clickable']]: isActive && !isNested,
        [classes['sidebar-nav-link--clickable']]: isActive && isNested,
      })}
      color="secondary"
    >
      {icon && <span className={classes['sidebar-nav-link__icon']}>{icon}</span>}
      <span
        className={cx(classes['sidebar-nav-link__label'], {
          [classes['sidebar-nav-link__label--fade']]: fadeTitle,
        })}
      >
        {title}
      </span>
    </Button>
  );
};

export default SidebarNavLink;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'release-details': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  'release-details__tags': {
    display: 'flex',
    gap: '4px',
  },

  'release-details__cover': {
    width: '100%',
    maxHeight: 400,
    objectFit: 'cover',
  },
}));

export default useStyles;

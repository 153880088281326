/* eslint-disable */
import React, { useLayoutEffect } from 'react';

const props: (keyof DOMRect)[] = ['bottom', 'height', 'left', 'right', 'top', 'width'];

const rectChanged = (a: DOMRect = {} as DOMRect, b: DOMRect = {} as DOMRect) =>
  props.some((prop) => a[prop] !== b[prop]);

const observedNodes = new Map<Element, RectProps>();
let rafId: number;

const run = () => {
  const changedStates: RectProps[] = [];
  observedNodes.forEach((state, node) => {
    const newRect = node.getBoundingClientRect();
    if (rectChanged(newRect, state.rect)) {
      state.rect = newRect;
      changedStates.push(state);
    }
  });

  changedStates.forEach((state) => {
    state.callbacks.forEach((cb) => cb(state.rect));
  });

  rafId = window.requestAnimationFrame(run);
};

function observeRect(node: Element, cb: (rect: DOMRect) => void) {
  return {
    observe() {
      const wasEmpty = observedNodes.size === 0;
      if (observedNodes.has(node)) {
        observedNodes.get(node)!.callbacks.push(cb);
      } else {
        observedNodes.set(node, {
          rect: undefined,
          hasRectChanged: false,
          callbacks: [cb],
        });
      }
      if (wasEmpty) run();
    },

    unobserve() {
      const state = observedNodes.get(node);
      if (state) {
        // Remove the callback
        const index = state.callbacks.indexOf(cb);
        if (index >= 0) state.callbacks.splice(index, 1);

        // Remove the node reference
        if (!state.callbacks.length) observedNodes.delete(node);

        // Stop the loop
        if (!observedNodes.size) cancelAnimationFrame(rafId);
      }
    },
  };
}

type RectProps = {
  rect: DOMRect | undefined;
  hasRectChanged: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  callbacks: Function[];
};

export function useRect<T extends Element = HTMLElement>(
  nodeRef: React.RefObject<T | undefined | null>,
  observeOrOptions: boolean | UseRectOptions = true,
): null | DOMRect {
  let observe: boolean;
  let onChange: UseRectOptions['onChange'];

  if (typeof observeOrOptions === 'boolean') {
    observe = observeOrOptions;
  } else {
    // @ts-ignore
    observe = observeOrOptions?.observe ?? true;
    onChange = observeOrOptions?.onChange;
  }

  const [element, setElement] = React.useState(nodeRef.current);
  const initialRectIsSet = React.useRef(false);
  const initialRefIsSet = React.useRef(false);
  const [rect, setRect] = React.useState<DOMRect | null>(null);
  const onChangeRef = React.useRef(onChange);

  useLayoutEffect(() => {
    onChangeRef.current = onChange;
    if (nodeRef.current !== element) {
      setElement(nodeRef.current);
    }
  });

  useLayoutEffect(() => {
    if (element && !initialRectIsSet.current) {
      initialRectIsSet.current = true;
      setRect(element.getBoundingClientRect());
    }
  }, [element]);

  useLayoutEffect(() => {
    if (!observe) {
      return;
    }

    let elem = element;
    // State initializes before refs are placed, meaning the element state will
    // be undefined on the first render. We still want the rect on the first
    // render, so initially we'll use the nodeRef that was passed instead of
    // state for our measurements.
    if (!initialRefIsSet.current) {
      initialRefIsSet.current = true;
      elem = nodeRef.current;
    }

    if (!elem) {
      return;
    }

    const observer = observeRect(elem, (rect) => {
      onChangeRef.current?.(rect);
      setRect(rect);
    });
    observer.observe();
    return () => {
      observer.unobserve();
    };
  }, [observe, element, nodeRef]);

  return rect;
}

type UseRectOptions = {
  observe?: boolean;
  onChange?: (rect: PRect) => void;
};

type PRect = Partial<DOMRect> & {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
};

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { FormHelperText, Icon, InputAdornment, StandardTextFieldProps, TextField as MuiTextField } from '@mui/material';

import ValidationMessage from 'components/ValidationMessage';
import { InputMessageType } from 'types';

import useStyles from './styles';

export interface TextFieldPropsInterface extends StandardTextFieldProps {
  value?: string;
  maxLength?: number;
  maxLengthError?: string;
  fullWidth?: boolean;
  message?: InputMessageType;
  multiline?: boolean;
  startIcon?: string;
  helperText?: string;
}

const TextField: React.FunctionComponent<TextFieldPropsInterface> = React.forwardRef(
  (props: TextFieldPropsInterface, ref) => {
    const {
      maxLength,
      onChange = () => {},
      fullWidth = true,
      message = null,
      multiline,
      type = 'text',
      startIcon,
      helperText,
      ...restProps
    } = props;
    const [counter, setCounter] = useState(restProps.value?.length || 0);
    const [error, setError] = useState(message?.message);

    const handleOnChange = (e) => {
      onChange(e);
    };

    const classes = useStyles({});

    useEffect(() => {
      setCounter(restProps.value?.length || 0);
    }, [restProps.value]);

    useEffect(() => {
      setError(message?.message || null);
    }, [message]);

    return (
      <div className={cx(classes['text-field__wrapper'], { [classes['text-field--max-length']]: maxLength > 0 })}>
        <div
          className={cx(classes['text-field'], {
            [classes['text-field--full-width']]: fullWidth,
          })}
        >
          <MuiTextField
            className={cx({ [classes['text-field__input--multiline']]: multiline })}
            error={!!error}
            ref={ref}
            {...restProps}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...restProps?.InputProps,
              startAdornment: startIcon ? (
                <InputAdornment position="start">
                  <Icon>{startIcon}</Icon>
                </InputAdornment>
              ) : (
                restProps?.InputProps?.startAdornment
              ),
            }}
            fullWidth={fullWidth}
            multiline={multiline}
            onChange={handleOnChange}
            onWheel={() => {
              // @ts-ignore
              document.activeElement.blur();
            }}
            type={type}
          />
          {maxLength > 0 && (
            <div className={classes['text-field__counter']}>
              <FormHelperText>{`${counter} / ${maxLength}`}</FormHelperText>
            </div>
          )}
        </div>
        {helperText && <FormHelperText className={classes['text-field__helper-text']}>{helperText}</FormHelperText>}
        <ValidationMessage key={restProps.key} message={{ type: 'error', message: error }} show />
      </div>
    );
  },
);

export default TextField;

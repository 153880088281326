import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'guide-menu__hero': {
    position: 'relative',
  },

  'guide-menu__hero-image': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 8,
    position: 'absolute',
    zIndex: -1,
  },

  'guide-menu__hero-title': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: theme.spacing(6),
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'profile-menu': {
    color: 'inherit',
    // display: 'inline-flex',
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gridTemplateColumns: '0fr 1fr',
  },

  'profile-menu--slim': {
    '& $profile-menu__avatar': {
      marginRight: theme.spacing(2),
    },
  },
  'profile-menu--vertical': {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    gridTemplateColumns: '1fr',

    '& $profile-menu__avatar': {
      margin: 'auto',
      marginBottom: theme.spacing(2),
    },

    '& $profile-menu__meta': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  'profile-menu--horizontal': {
    '& $profile-menu__indicator': {},
  },

  'profile-menu--text-overflow': {
    '& p': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    '& $profile-menu__meta': {
      overflow: 'hidden',
    },
  },

  'profile-menu--clickable': {
    pointerEvents: 'initial',
    transition: 'background 0.1s ease-in-out',

    '&:hover': {
      background: 'rgba(255,255,255, 0.05)',
    },
  },

  'profile-menu--no-padding': {
    padding: 0,
  },

  'profile-menu--full-width': {
    width: '100%',
  },

  'profile-menu__indicator': {},

  'profile-menu__avatar': {
    marginRight: theme.spacing(4),
  },

  'profile-menu__meta-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  'profile-menu__meta': {
    color: 'inherit',

    alignItems: 'center',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  'profile-menu__meta-role': {
    color: 'inherit',
    alignItems: 'center',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}));

export default useStyles;

import React, { ReactFragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import useStyles from './styles';

interface PropsInterface {
  href?: string;
  children: React.ReactNode;
  external?: boolean;
  download?: string;
  onClick?(): void;
}

const Link: React.FunctionComponent<PropsInterface> = ({ href, children, external, download, onClick }) => {
  const classes = useStyles();

  if (onClick) {
    return (
      <span role="button" tabIndex={-1} onClick={onClick} className={classes['link']}>
        {children}
      </span>
    );
  }

  if (external) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={href} className={classes['link']}>
      {children}
    </RouterLink>
  );
};

export default Link;

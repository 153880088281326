import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';
import Grid from '@mui/material/Grid';

import additinalAccountHandler from 'helpers/additionalAccountHandler';
import { AccountContext } from 'context/account/accountContext';
import BackgroundImage from 'assets/img/working-3.png';
import ProfileMenu from 'containers/ProfileMenu';
import Header from 'components/Header';
import Logo from 'components/Logo';

import useStyles from './styles';

type PropsType = {
  children: JSX.Element;
};

const PageAuthenticationLayout: React.FunctionComponent<PropsType> = ({ children }) => {
  const { state: accountContenxt } = useContext(AccountContext);
  const { t } = useTranslation('authorization');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={6} md={7} xs={12}>
          <div className={classes.contentBody}>
            {/* wtf layout with route????? */}

            {(additinalAccountHandler.getAccounts().length > 0 || accountContenxt.data) && (
              <div style={{ position: 'absolute', right: 24, top: 24 }}>
                <ProfileMenu />
              </div>
            )}

            {children}
          </div>
        </Grid>
        <Grid className={classes.quoteContainer} item container lg={6} md={5} alignItems="flex-end">
          <Grid item className={classes['layout__quote-content']}>
            <Logo />
            <h1 className={classes['layout__quote-header']}>
              {t('quote.header.part1')}
              <span className={classes['layout__quote-header-gradient']}>{t('quote.header.effect')}</span>
              {t('quote.header.part2')}
            </h1>
            <div className={classes['layout__quote-header2']}>
              <Header type="h2" variant="h3" margin={4} icon={<Icon>arrow_forward</Icon>}>
                {t('quote.advantages.1')}
              </Header>
              <br />
              <Header type="h2" variant="h3" margin={4} icon={<Icon>arrow_forward</Icon>}>
                {t('quote.advantages.2')}
              </Header>
              <br />
              <Header type="h2" variant="h3" margin={4} icon={<Icon>arrow_forward</Icon>}>
                {t('quote.advantages.3')}
              </Header>
            </div>
          </Grid>
          <div className={classes['layout__quote-background']} style={{ backgroundImage: `url(${BackgroundImage})` }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PageAuthenticationLayout;

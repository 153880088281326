import React, { useState } from 'react';
import { RadioGroup } from '@mui/material';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ConditionalWrapper from 'components/ConditionalWrapper';
import Drawer from 'components/Drawer';
import isMobile from 'helpers/isMobile';

import useStyles from './styles';

export type SelectChangeType<T = unknown> = (event: React.ChangeEvent<HTMLInputElement>, newValue: T) => void;

interface PropsInterface<T = unknown> extends Omit<SelectProps, 'label' | 'onChange'> {
  label?: {
    id: string;
    message: string;
  };
  error?: boolean;
  fullWidth?: boolean;
  labelSingleLine?: boolean;
  title?: string;
  onChange: SelectChangeType<T>;
}

const Select: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    label,
    error,
    fullWidth = true,
    labelSingleLine = true,
    title,
    children,
    value,
    onChange,
    name,
    disabled,
    multiple,
    renderValue,
    ...restProps
  } = props;
  const classes = useStyles({});
  const mobile = isMobile();

  const styles = {
    whiteSpace: labelSingleLine ? 'pre' : '',
  } as React.CSSProperties;

  const [isOpen, setOpen] = useState(false);

  const handleOpen = (open) => {
    setOpen(open);
  };

  return (
    <ConditionalWrapper
      condition={!!label}
      wrapper={(children) => (
        <FormControl error={error} fullWidth={fullWidth} disabled={disabled}>
          <InputLabel error={error} id={label.id} style={styles}>
            {label.message}
          </InputLabel>
          {children}
        </FormControl>
      )}
    >
      <>
        <MuiSelect
          {...restProps}
          disabled={disabled}
          onChange={(e) => {
            onChange(e as unknown as React.ChangeEvent<HTMLInputElement>, e.target.value);
          }}
          value={value}
          multiple={multiple}
          name={name}
          classes={{ root: classes['select__root'] }}
          open={mobile ? false : isOpen}
          onOpen={() => handleOpen(true)}
          onClose={() => handleOpen(false)}
          // @ts-ignore
          hiddenLabel={!label}
          MenuProps={{
            ...restProps.MenuProps,
            BackdropProps: { className: classes['select__backdrop'] },
            classes: {
              ...restProps.MenuProps?.classes,
              paper: classes['select__paper'],
              list: classes['select__list'],
            },
          }}
          error={error}
          renderValue={renderValue}
        >
          {children}
        </MuiSelect>
        {mobile && (
          <Drawer
            {...restProps}
            title={label?.message ? label?.message : title}
            open={isOpen}
            anchor="bottom"
            slim
            onClose={() => setOpen(false)}
          >
            <RadioGroup
              className={classes['select-mobile']}
              aria-labelledby={name}
              name={name}
              onChange={(e, item) => {
                if (multiple) {
                  if (value instanceof Array) {
                    const isRemove = value.includes(item);
                    if (isRemove) {
                      onChange(
                        e,
                        value.filter((a) => a !== item),
                      );
                    } else {
                      onChange(e, [...value, item]);
                    }
                  } else {
                    onChange(e, [item]);
                  }
                } else {
                  const isRemove = value === item;
                  if (isRemove) {
                    onChange(e, '');
                  } else {
                    onChange(e, item);
                  }
                  setOpen(false);
                }
              }}
              value={value}
            >
              {children}
            </RadioGroup>
          </Drawer>
        )}
      </>
    </ConditionalWrapper>
  );
};

export default Select;

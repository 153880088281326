import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import isElectron from './helpers/electron/isElectron';
import packageInfo from '../package.json';
import { availableLanguages } from './config';

const options = {
  // order and from where user language should be detected
  order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
};

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: options,
    backend: {
      loadPath: () => {
        if (isElectron()) {
          return `${`${window.location.origin}${window.location.pathname}`.replace(
            'index.html',
            '',
          )}/locales/{{lng}}/{{ns}}.json?v=${packageInfo.version}`;
        }
        return `/locales/{{lng}}/{{ns}}.json?v=${packageInfo.version}`;
      },
      // requestOptions: {
      //   cache: 'no-store',
      // },
    },
    ns: [
      'assortment',
      'authorization',
      'booking-details',
      'common',
      'dashboard',
      'ecommerce',
      'event-details',
      'event-form',
      'event-logs',
      'event-remove',
      'ink-passport',
      'onboarding',
      'page-booking-list',
      'page-chat',
      'page-client',
      'page-employees',
      'page-partnership',
      'page-product',
      'page-profile',
      'page-request-list',
      'page-settings',
      'page-summits',
      'scheduler',
      'studio-invitation',
      'subscription',
      'summit-checkout',
      'vouchers',
    ],
    defaultNS: 'common',
    supportedLngs: availableLanguages,
    fallbackLng: 'en', // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import React from 'react';
import cx from 'classnames';

import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Header from 'components/Header';
import Paragraph from 'components/Paragraph';
import useStyles from './styles';

export interface PropsInterface {
  headerActions?: React.ReactNode;
  title?: React.ReactNode;
  titleAdditional?: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?(): void;
  onBack?(): void;
  hideClose?: boolean;
  color?: 'darker';
}

const DrawerHeader: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { title, headerActions, onBack, subtitle, onClose, hideClose, color, titleAdditional } = props;
  const classes = useStyles({});

  return (
    <div
      className={cx(classes['drawer__title-wrapper'], {
        [classes['drawer__title-wrapper--darker']]: color === 'darker',
        [classes['drawer__title-wrapper--back']]: typeof onBack === 'function',
      })}
    >
      <div
        className={cx(classes.drawer__title, {
          [classes['drawer__title--close-only']]: !hideClose && !title && !headerActions && !onBack,
        })}
      >
        {typeof onBack === 'function' && (
          <IconButton aria-label="close" onClick={onBack} size="large">
            <Icon>chevron_left</Icon>
          </IconButton>
        )}
        {title && typeof title === 'string' ? (
          <div className={classes['drawer__title-text']}>
            <Header type="h3" variant="h3">
              {title}
            </Header>
          </div>
        ) : (
          title
        )}
        {!hideClose && (
          <IconButton className={classes['drawer__title-close']} aria-label="close" onClick={onClose} size="large">
            <Icon>close</Icon>
          </IconButton>
        )}
        {headerActions && headerActions}
      </div>
      {subtitle && <Paragraph type="size-XS-regular">{subtitle}</Paragraph>}
      {titleAdditional && <div className={classes['drawer__title-additional']}>{titleAdditional}</div>}
    </div>
  );
};

export default DrawerHeader;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'client-representation': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& p:nth-child(n+3)': {
      marginTop: theme.spacing(2),
    },
  },

  'client-representation__avatar': {
    marginRight: theme.spacing(4),
  },

  'client-representation__content': {
    flex: '1 1 100%',
  },

  'client-representation__action': {
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;

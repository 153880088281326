import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';

import { dashboardApi } from 'api';
import DoughnutChart from 'components/Chart/DoughnutChart';
import ErrorBoundary from 'components/ErrorBoundary';
import calculatePercentDifference from 'helpers/calculatePercentDifference';
import generateDateFromId from 'helpers/date/generateDateFromId';
import logError from 'helpers/logError';
import { DashboardFilterInterface } from 'views/Dashboard/types';

import EarningsWidgetSkeleton from './components/EarningsWidgetSkeleton';
import DoughnutWidget from '../components/DoughnutWidget';
import convertFiltersToParams from '../helpers/convertFiltersToParams';

const colors = ['#181818E0', '#266c69', '#12CBC4', '#FDA7DF'];
const chartConfig = { cutout: '55%' };

const EarningsWidget: React.FunctionComponent<DashboardFilterInterface> = (props: DashboardFilterInterface) => {
  const { currency, periodType, date } = props;
  const currentDate = generateDateFromId(date);
  const previousDate = generateDateFromId(date);
  previousDate.setMonth(currentDate.getMonth() - 1);

  const [chartData, setChartData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const { t, i18n } = useTranslation(['dashboard', 'common']);

  const previousPeriodLabel = useMemo(
    () =>
      periodType.key === 'month'
        ? previousDate.toLocaleDateString(i18n.language, { month: 'long' })
        : t('previousPeriod'),
    [date, periodType, i18n],
  );

  useEffect(() => {
    dashboardApi.getWidget
      .earnings(convertFiltersToParams(props))
      .then(({ data }) => {
        setChartData({
          data: Object.keys(data).map((key, index) => ({
            value: data[key].value,
            key: camelCase(key),
            label: t(`earnings.legend.label.${camelCase(key)}`),
            color: colors[index],
          })),
          currency: currency.key,
          previous: data.finalized.previous_value,
          value: data.finalized.value + data.vouchers.value,
        });
        setMessage(null);
      })
      .catch((e) => {
        setMessage({
          type: 'error',
          message: t('common:messages.generalError'),
        });
        logError(e);
      })
      .finally(() => setLoading(false));
  }, [props]);

  if (isLoading || message) {
    return (
      <div style={{ width: '100%' }}>
        <EarningsWidgetSkeleton message={message} />
      </div>
    );
  }

  if (!chartData) {
    return null;
  }

  const sum = chartData?.data
    ?.map((a) => a.value)
    .reduce(function (a, b) {
      return a + b;
    });

  return (
    <ErrorBoundary>
      <DoughnutWidget
        title={t(`earnings.title.${periodType.key}`)}
        highlightedText={{
          color: colors[2],
          title: chartData.value.toLocaleString(undefined, {
            style: 'currency',
            currency: currency.key,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
          description: `${t('planned')}: ${sum.toLocaleString(undefined, {
            style: 'currency',
            currency: currency.key,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })} (${t('whole-chart')})`,
        }}
        description={`${calculatePercentDifference(chartData.value, chartData.previous)}% (${previousPeriodLabel})`}
      >
        <DoughnutChart
          data={chartData.data}
          parseValueFunction={(value: number) =>
            value.toLocaleString(undefined, {
              style: 'currency',
              currency: currency.key,
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })
          }
          options={chartConfig}
          size={120}
        />
      </DoughnutWidget>
    </ErrorBoundary>
  );
};

export default React.memo(EarningsWidget);

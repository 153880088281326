import { clientsApi } from 'api';
import { DeprecatedClientType } from 'api/types/clients';

const getClientAutocomplete = async (query: string, count = 0, perPage = 30): Promise<DeprecatedClientType[]> => {
  const { data: clientsResponse } = await clientsApi.autocomplete(query, count);
  let clients = [];

  if (clientsResponse.clients?.data.length) {
    clients = [...clients, ...clientsResponse.clients.data.slice(0, perPage / 2)];
  }

  return clients;
};

export default getClientAutocomplete;

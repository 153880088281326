import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: ({ margin, padding }: { margin: number; padding: number }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(margin, 0),
    padding: theme.spacing(0, padding),
  }),

  divider__title: {
    flex: '0 0 auto',
    marginRight: theme.spacing(6),
    color: theme.palette.grey[200],
  },

  divider__hr: {
    flex: '1 1 auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
  },
}));

export default useStyles;

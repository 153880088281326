import { AccessStoreInterface } from 'store/access/types';
import { NavigationConfigurationRouteType } from 'config';

const validateGrantedAccessToNavigationConfigRoute = ({
  accessStore,
  nestedRoute,
}: {
  accessStore: Omit<AccessStoreInterface, 'getEmployeeAccess'>;
  nestedRoute: NavigationConfigurationRouteType;
}) => {
  if (!nestedRoute?.grantedAccess) return true;
  if (nestedRoute.grantedAccess.value?.length)
    return nestedRoute.grantedAccess.value.includes(
      accessStore?.employeeAccess?.data?.[nestedRoute.grantedAccess.slug],
    );
  return accessStore?.employeeAccess?.data?.[nestedRoute.grantedAccess.slug] === nestedRoute.grantedAccess.value;
};

export default validateGrantedAccessToNavigationConfigRoute;

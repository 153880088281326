import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'bulk-sms__footer-additional': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(4),
  },
  'bulk-sms__clients': { display: 'flex', width: '100%', justifyContent: 'space-between' },

  'client-message-form__client-representation': {
    position: 'relative',
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'date-picker': {
    position: 'relative',
  },

  'date-picker__dialog-paper': {
    zIndex: 999999,
    backgroundColor: `${theme.palette.background.paper}!important`,
  },
  'date-picker__input': {
    '& > input': {
      // textAlign: 'center',
    },
  },
  'date-picker__input-clear-icon': {
    opacity: 0,
    transition: '200ms',
  },
  'date-picker__input-clear-icon--visible': {
    opacity: 1,
  },
  'date-picker__label': {
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;

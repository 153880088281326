import React from 'react';

export interface PropsInterface {
  condition: boolean;
  wrapper: React.FunctionComponent<React.ReactElement>;
  children: React.ReactElement;
}

const ConditionalWrapper: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { condition, wrapper, children } = props;
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;

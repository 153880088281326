import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  alert: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 4),
    lineHeight: 1.8,
    fontWeight: 300,
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  alert__message: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flex: '1 1 calc(100% - 40px)',
    },
  },

  alert__action: {
    marginRight: 0,
  },

  'alert--success': {
    color: '#fff',
    border: ' 1px solid #28AB77',
    background: 'linear-gradient(90deg, rgba(23, 128, 84, 0.8) 0%, rgba(34, 34, 34, 0.88) 100%)',
  },
  'alert--info': {
    color: '#fff',
    border: '1px solid #879DFF',
    background: 'linear-gradient(90deg, rgba(68, 79, 128, 0.8) 0%, rgba(34, 34, 34, 0.88) 100%)',
  },
  'alert--warning': {
    color: '#fff',
    border: '1px solid #FFF492',
    background: 'linear-gradient(90deg, rgba(128, 122, 73, 0.8) 0%, rgba(34, 34, 34, 0.88) 100%)',
  },
  'alert--error': {
    color: '#fff',
    border: '1px solid #FF6666',
    background: 'linear-gradient(90deg, rgba(128, 51, 51, 0.8) 0%, rgba(34, 34, 34, 0.88) 100%)',
  },

  'alert--full-width': { width: '100%' },

  'alert--sharp': {
    borderRadius: 0,
    border: 'initial',
  },

  'alert__plan-block': {
    width: 50,
    height: 50,
  },
}));

export default useStyles;

import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { VoucherFormValueInterface } from 'containers/VoucherFormDrawer/types';
import generateDayId from 'helpers/date/generateDayId';
import { VoucherSubmitParamsType } from 'api/types/vouchers';
import { vouchersApi } from 'api';
import logError from 'helpers/logError';
import { VoucherStatusType } from 'types/vouchers';

import { convertVoucherResponse, getVoucher, VoucherType } from '../api/getVouchers';

const useVoucher = ({ uuid }: { uuid: string }) => {
  const [details, setDetails] = useState<VoucherType>(null);
  const [status, setStatus] = useState<VoucherStatusType>({
    isFetching: true,
    isSending: false,
    error: null,
  });

  const getVoucherDetails = async (voucherId: string) => {
    const voucherDetails = await getVoucher(voucherId);
    setDetails(voucherDetails);
    setStatus({ isFetching: false, error: null, isSending: false });
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleVoucherStatusChangeToActive = async (voucherId: string) => {
    if (voucherId) {
      return vouchersApi.setActive(voucherId);
    }
    return null;
  };

  const handleVoucherStatusChangeToInactive = async (voucherId: string) => {
    if (voucherId) {
      return vouchersApi.setInactive(voucherId);
    }
    return null;
  };

  const handleVoucherSubmit = async (voucher: VoucherFormValueInterface): Promise<VoucherType> => {
    try {
      const discount =
        voucher.paymentType === 'percent'
          ? voucher.percent && parseInt(voucher.percent, 10)
          : voucher.price && parseInt(voucher.price, 10);
      const params: VoucherSubmitParamsType = {
        name: voucher.title,
        code: voucher.code,
        type: voucher.type,
        client_id: voucher.client?.uuid,
        buyer_id: voucher.buyer?.uuid,
        is_percent: voucher.paymentType === 'percent' ? 1 : 0,
        discount_value: discount,
        quantity: voucher.quantity,
        exp_date: voucher.isLimitedDate ? generateDayId(voucher.expirationDate) : null,
        currency: voucher.currency?.key,
        payment_method: voucher.paymentMethod,
        is_paid: voucher.type === 'discount' ? 0 : 1,
        paid_date: voucher.isDifferentPaymentDate ? generateDayId(voucher.paymentDate) : null,
      };

      if (voucher.paymentType === 'percent') {
        delete params.currency;
      }

      if (voucher.type === 'discount') {
        delete params.is_paid;
        delete params.client_id;
        delete params.buyer_id;
        delete params.payment_method;
      }

      if (!voucher.isLimitedDate) {
        delete params.exp_date;
      }

      if (voucher.type === 'voucher') {
        delete params.quantity;
      }

      if (voucher.isLimitedQuantity && !voucher.quantity && voucher.type === 'discount') {
        params.quantity = 1;
      }

      if (!voucher.isLimitedQuantity && voucher.type === 'discount') {
        params.quantity = 55555;
      }

      setStatus({ isFetching: false, error: null, isSending: true });
      const { data: voucherResponse } = voucher.uuid
        ? await vouchersApi.update({ ...params, voucher_id: voucher.uuid })
        : await vouchersApi.submit(params);
      setStatus({ isFetching: false, error: null, isSending: false });
      return convertVoucherResponse(voucherResponse);
    } catch (e) {
      logError(e);
      setStatus({ isFetching: false, error: e, isSending: false });
      throw e;
    }
  };

  const handleVoucherDeactivate = async (voucherId = uuid): Promise<AxiosResponse<void>> => {
    try {
      setStatus({ isFetching: false, error: null, isSending: true });
      return await vouchersApi.delete({ uuid: voucherId });
    } catch (e) {
      logError(e);
      setStatus({ isFetching: false, error: e, isSending: false });
      throw e;
    }
  };

  const handleSetVoucher = (voucher: VoucherType) => {
    setDetails(voucher);
  };

  useEffect(() => {
    try {
      setStatus({ isFetching: true, error: null, isSending: false });
      getVoucherDetails(uuid);
    } catch (e) {
      logError(e);
      setStatus({ isFetching: false, error: e, isSending: false });
    }
  }, [uuid]);

  return {
    voucherDetails: details,
    voucherStatus: status,
    handleVoucherSubmit,
    handleVoucherDeactivate,
    handleStatusChange,
    handleVoucherStatusChangeToActive,
    handleVoucherStatusChangeToInactive,
    handleSetVoucher,
  } as const;
};

export default useVoucher;

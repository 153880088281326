import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Divider from 'components/Divider';
import { bizRoutes, navigationConfig } from 'config';
import isNavLinkActive from 'helpers/navigation/isNavLinkActive';
import isUserTester from 'helpers/isUserTester';
import mixpanel from 'helpers/mixpanel';
import useAccessStore from 'store/access';
import { AccountRoleType } from 'types';

import useStyles from './styles';
import SidebarNavLink from '../SidebarNavLink';
import SidebarIcon from '../SidebarIcon';

import validateGrantedAccessToNavigationConfigRoute from '../../validators/validateGrantedAccessToNavigationConfigRoute';
import validateRoleAccessToNavigationConfigRoute from '../../validators/validateRoleAccessToNavigationConfigRoute';

export interface PropsInterface {
  slim?: boolean;
  profileRole: AccountRoleType;
}

const SidebarNav: React.FunctionComponent<PropsInterface> = ({ slim, profileRole }) => {
  const accessStore = useAccessStore();
  const classes = useStyles();
  const { pathname } = useLocation();
  const { t } = useTranslation('common');

  const isTester = isUserTester();

  const handleNavClick = (key) => {
    mixpanel.track('Navbar:PageClick', { page: key });
  };

  const navigationConfigurationKeys = Object.keys(navigationConfig);
  console.log(t(`common:navigation.guide`));
  return (
    <div className={cx(classes['sidebar-nav'], { [classes['sidebar-nav--slim']]: slim })}>
      {navigationConfigurationKeys.map((navigationConfigurationKey) => {
        return (
          <React.Fragment key={navigationConfigurationKey}>
            <div className={classes['sidebar-nav__link-group']}>
              {Object.keys(navigationConfig?.[navigationConfigurationKey])?.map((itemKey) => {
                const page = navigationConfig[navigationConfigurationKey][itemKey].route;
                const isActive = isNavLinkActive(pathname, page?.href);

                if (
                  !validateRoleAccessToNavigationConfigRoute({
                    nestedRoute: navigationConfig[navigationConfigurationKey][itemKey],
                    profileRole,
                  })
                )
                  return null;
                if (
                  page.key === bizRoutes.employees.key &&
                  !validateGrantedAccessToNavigationConfigRoute({
                    accessStore,
                    nestedRoute: navigationConfig[navigationConfigurationKey][itemKey],
                  })
                )
                  return null;

                return (
                  <div
                    className={cx(classes['sidebar-nav__item'], {
                      [classes['sidebar-nav__item--tester']]: page?.isDev && isTester,
                    })}
                    key={`sidebar-nav__item-${itemKey}`}
                  >
                    <SidebarNavLink
                      fadeTitle={slim}
                      href={page?.href}
                      icon={<SidebarIcon type={page?.key} isActive={isActive} />}
                      title={t(`navigation.${page?.key}`)}
                      onClick={() => handleNavClick(page?.key)}
                      isActive={isActive}
                      isNested={page.submenu}
                    />
                  </div>
                );
              })}
            </div>
            <Divider margin={2} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SidebarNav;

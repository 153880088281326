import React from 'react';
import cx from 'classnames';
import { ButtonBase, Icon, Tooltip } from '@mui/material';

import Placeholder from 'assets/img/avatar_placeholder.png';
import Paragraph from 'components/Paragraph';
import Avatar from 'components/Avatar';
import Palette from 'theme/palette';

import useStyles from './styles';

interface PropsInterface {
  name: string;
  avatar?: string;
  avatarName?: string;
  icon?: React.ReactNode;
  color?: string;
  slim?: boolean;
  type?: 'vertical' | 'horizontal';
  size?: number;
  textOverflow?: boolean;
  noPadding?: boolean;
  avatarOnly?: boolean;
  fullWidth?: boolean;
  subtitle?: React.ReactNode;
  onClick?(): void;
  tooltip?: string;
  hideChevron?: boolean;
}

const Profile: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    name,
    avatarName,
    avatar = avatarName ? null : Placeholder,
    icon,
    color = Palette.grey[700],
    slim,
    onClick = null,
    type = 'horizontal',
    size,
    textOverflow = false,
    noPadding = false,
    fullWidth = false,
    subtitle = '',
    tooltip,
    hideChevron,
  } = props;

  const classes = useStyles();
  const Component = (args) => React.useMemo(() => (onClick ? <ButtonBase {...args} /> : <div {...args} />), [args]);

  const unifiedName = name?.length > 40 ? `${name.substr(0, 30)}...` : name;
  const unifiedSize = size || 40;
  const unifiedSlimSize = slim && !size ? 24 : unifiedSize;

  return (
    <Component
      className={cx(classes['profile-menu'], {
        [classes['profile-menu--slim']]: slim,
        [classes['profile-menu--vertical']]: type === 'vertical',
        [classes['profile-menu--horizontal']]: type === 'horizontal',
        [classes['profile-menu--text-overflow']]: textOverflow,
        [classes['profile-menu--clickable']]: !!onClick,
        [classes['profile-menu--no-padding']]: noPadding,
        [classes['profile-menu--full-width']]: fullWidth,
      })}
      onClick={onClick}
    >
      <div className={classes['profile-menu__avatar']}>
        <Avatar avatarName={avatarName} color={color} size={unifiedSlimSize} src={avatar} icon={icon} />
      </div>
      <div className={classes['profile-menu__meta-wrapper']}>
        <div className={classes['profile-menu__meta']}>
          {tooltip ? (
            <Tooltip title={tooltip}>
              <div>
                <Paragraph type="size-S-bold" color="primary">
                  {unifiedName}
                </Paragraph>
              </div>
            </Tooltip>
          ) : (
            <Paragraph type="size-S-bold" color="primary">
              {unifiedName}
            </Paragraph>
          )}
        </div>
        {subtitle && (
          <div className={classes['profile-menu__meta-role']}>
            {typeof subtitle === 'string' ? (
              <Paragraph type="size-XS-medium" color="secondary" alignment={type === 'vertical' ? 'center' : 'left'}>
                {subtitle}
              </Paragraph>
            ) : (
              subtitle
            )}
          </div>
        )}
      </div>
      {onClick && !hideChevron && <Icon className={classes['profile-menu__indicator']}>keyboard_arrow_down</Icon>}
    </Component>
  );
};
export default Profile;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    fontWeight: 300,
    fontSize: '1em',
    letterSpacing: '0.5px',
    lineHeight: 1.6,
    color: theme.palette.white.light,
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    maxWidth: '60em',
    verticalAlign: 'center',
  },

  type1: {
    lineHeight: 1.5,
  },
  type2: {
    margin: 0,
    color: theme.palette.white.dark,
    letterSpacing: 0,
  },
  type3: {
    fontWeight: 500,
    fontSize: '1em',
    lineHeight: 1.3,
  },
  subtitle1: {
    marginTop: 0,
    color: theme.palette.white.dark,
  },
  withMargin: ({ margin }: { margin: number }) => ({
    margin: theme.spacing(typeof margin === 'number' ? margin : 2, 0),
  }),
  reverse: {
    color: theme.palette.black.dark,
    fontWeight: 400,
  },

  'paragraph--icon': {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
  },

  paragraph__icon: {
    height: '1.4em',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      fontSize: '16px',
    },
  },

  'paragraph--inherit': {
    color: 'inherit',
  },

  'paragraph--info': {
    color: theme.palette.info.light,
  },

  'paragraph--warning': {
    color: theme.palette.warning.main,
  },

  'paragraph--success': {
    color: theme.palette.success.main,
  },

  'paragraph--error': {
    color: theme.palette.error.main,
  },

  'paragraph--primary': {
    color: theme.palette.grey[100],
  },

  'paragraph--secondary': {
    color: theme.palette.grey[300],
  },
  'paragraph--black': {
    color: '#000',
  },

  'paragraph--size-M-medium': {
    margin: 0,
    padding: 0,
    letterSpacing: 0,
    fontSize: '16px',
    // fontSize: '1em', // '16px',
    fontWeight: '500 !important',
    lineHeight: '24px',
    a: {
      fontWeight: '700 !important',
      textDecoration: 'underline',
    },
  },
  'paragraph--size-S-bold': {
    margin: 0,
    padding: 0,
    letterSpacing: 0,
    fontSize: '14px',
    // fontSize: '0.8750em', // '14px',
    fontWeight: '700 !important',
    lineHeight: 1.715, // '24px',
    a: {
      fontWeight: '700 !important',
      textDecoration: 'underline',
    },
  },
  'paragraph--size-S-medium': {
    margin: 0,
    padding: 0,
    letterSpacing: 0,
    fontSize: '14px',
    // fontSize: '0.8750em', // '14px',
    fontWeight: '500 !important',
    lineHeight: 1.715, // '24px',
    '& > a': {
      color: 'inherit',
      fontWeight: '700 !important',
      textDecoration: 'underline !important',
    },
  },
  'paragraph--size-XS-medium': {
    margin: 0,
    padding: 0,
    letterSpacing: 0.2,
    fontSize: '12px',
    // fontSize: '0.75em', // '12px',
    fontWeight: '500 !important',
    lineHeight: 1.33, // '16px',
  },
  'paragraph--size-XS-regular': {
    margin: 0,
    padding: 0,
    letterSpacing: 0,
    fontSize: '12px',
    // fontSize: '0.75em', // '12px',
    fontWeight: '400 !important',
    lineHeight: 1.33, // '16px',
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';
import { PropsInterface } from './Skeleton';

const useStyles = makeStyles((theme) => ({
  skeleton: ({ width = '100%' }: PropsInterface) => ({
    display: 'flex',
    alignItems: 'center',
    width,
    height: 'fit-content',
  }),

  skeleton__profile: {
    display: 'flex',
    alignItems: 'center',
  },

  'skeleton__profile-avatar': ({ color }: PropsInterface) => ({
    width: 50,
    height: 50,
    marginRight: theme.spacing(4),
    borderRadius: '50%',
    backgroundColor: color,
    animation: '$loading 1s ease-in-out infinite alternate-reverse ',
  }),

  'skeleton__profile-meta': {
    display: 'flex',
    flexDirection: 'column',
  },

  'skeleton__profile-title': ({ color }: PropsInterface) => ({
    width: 200,
    height: '1em',
    marginBottom: theme.spacing(2),
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
  }),

  'skeleton__profile-subtitle': ({ color }: PropsInterface) => ({
    width: 150,
    height: '0.875em',
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
  }),

  skeleton__text: ({ color }: PropsInterface) => ({
    width: '100%',
    height: '0.875em',
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
    margin: theme.spacing(1, 0),
  }),

  skeleton__image: ({ width = 160, minHeight = 160, color = theme.palette.grey[500] }: PropsInterface) => ({
    width,
    minHeight,
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
  }),

  skeleton__h1: ({ color }: PropsInterface) => ({
    width: '100%',
    height: '2.1em',
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
    margin: theme.spacing(2, 0),
  }),

  skeleton__h2: ({ color }: PropsInterface) => ({
    width: '100%',
    height: '1.8em',
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
    margin: theme.spacing(2, 0),
  }),

  skeleton__h3: ({ color }: PropsInterface) => ({
    width: '100%',
    height: '1.5em',
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
    margin: theme.spacing(2, 0),
  }),

  skeleton__p: ({ color }: PropsInterface) => ({
    width: '100%',
    height: '1em',
    backgroundColor: color,
    animation: '1s ease-in-out infinite alternate-reverse $loading',
    borderRadius: '8px',
    margin: theme.spacing(1, 0),
  }),

  skeleton__card: {
    width: '100%',
    padding: '8px',
    backgroundColor: 'rgba(107,107,107, 0.5)',
    borderRadius: '8px',
    margin: theme.spacing(1, 0),
  },

  '@keyframes loading': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.5,
    },
  },
}));

export default useStyles;

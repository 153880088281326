import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { DeprecatedClientType } from 'api/types/clients';
import getClientAutocomplete from 'helpers/api/getClientAutocomplete';
import logError from 'helpers/logError';

export type UseClientAutocompleteType = (
  value: string,
  options?: { debounceDelay: number },
) => {
  isFetching: boolean;
  isError: boolean;
  clientList: DeprecatedClientType[];
};

const defaultOptions = { debounceDelay: 300 };

const useClientAutocomplete: UseClientAutocompleteType = (value, options) => {
  const unifiedOptions = { ...defaultOptions, ...options };

  const [isFetching, setFetching] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [isError, setError] = useState(false);

  const getClients = useCallback(
    debounce(
      (value) => {
        getClientAutocomplete(value)
          .then((clientList) => {
            setClientList(clientList);
            setError(false);
          })
          .catch((e) => {
            logError(e);
            setError(true);
          })
          .finally(() => {
            setFetching(false);
          });
      },
      [unifiedOptions.debounceDelay],
    ),
    [unifiedOptions.debounceDelay],
  );

  useEffect(() => {
    if (value) {
      setFetching(true);
      getClients(value);
    } else {
      setClientList([]);
    }
  }, [value]);

  return { isFetching, clientList, isError };
};

export default useClientAutocomplete;

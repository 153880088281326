import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'client-source-select__source': {
    padding: theme.spacing(2, 4),
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    transition: 'all 0.3s ease-in-out',
    margin: theme.spacing(1),

    '&:focus': {
      filter: 'invert(20%)',
    },
  },
  'client-source-select--selected': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  'client-source-select__list': {
    margin: theme.spacing(0, -1, 0, -1),
  },
  'client-source-select__source-icon': {
    width: 20,
    height: 20,
  },

  'client-source-select__input': {
    textTransform: 'capitalize',
  },

  'client-source-select__input:fist-of-type': {
    marginTop: theme.spacing(-4),
  },
}));

export default useStyles;

import { create } from 'zustand';

import { inksearchApi } from 'api';
import { AccessStoreInterface } from './types';

const useAccessStore = create<AccessStoreInterface>((set) => ({
  employeeAccess: {
    data: null,
    isFetching: false,
  },
  getEmployeeAccess: async () => {
    set((prevState) => ({ ...prevState, employeeAccess: { ...prevState.employeeAccess, isFetching: true } }));
    const { data } = await inksearchApi.me.getEmployeeAccess();
    set((prevState) => ({ ...prevState, employeeAccess: { data, isFetching: false } }));
    return data;
  },
}));

export default useAccessStore;

import Palette from '../palette';

export default {
  root: {
    fontWeight: 600,

    disabled: {},
    '&$disabled': {
      color: Palette.black.light,
    },
  },
};

import Palette from '../palette';

export default {
  input: {
    fontSize: '16px',
    '&$disabled': {
      color: Palette.black.light,
    },
  },
};

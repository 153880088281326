import { SetStateAction, useState } from 'react';

// reference
// https://mantine.dev/hooks/use-toggle/

export const useToggle = <T>(initialValue: T, options: [T, T]): [T, (value?: SetStateAction<T>) => void] => {
  const [state, setState] = useState(initialValue);

  const toggle = (value?: React.SetStateAction<T>) => {
    if (typeof value !== 'undefined') {
      setState(value);
    } else {
      setState((current) => {
        if (current === options[0]) {
          return options[1];
        }

        return options[0];
      });
    }
  };

  return [state, toggle];
};

export const useBooleanToggle = (initialValue = false) => {
  return useToggle(initialValue, [true, false]);
};

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: ({ margin = 0 }: { margin: number }) => ({ margin: theme.spacing(margin, 0) }),
  withIcon: {
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
  icon: {
    display: 'flex',
    paddingRight: theme.spacing(2),
  },

  h1: {
    fontSize: '24px',
    // fontSize: '2em', // '32px',
    fontWeight: 800,
    lineHeight: 1.5, // '48px',
    letterSpacing: '1px',
    '& > a': {
      textDecoration: 'underline !important',
    },
  },
  h2: {
    fontSize: '18px',
    // fontSize: '1.125em', // '18px',
    fontWeight: 700,
    lineHeight: 1.33, // '24px',
    letterSpacing: '1.60px',
    '& > a': {
      textDecoration: 'underline !important',
    },
  },
  h3: {
    fontSize: '16px',
    // fontSize: '1em', // '16px',
    fontWeight: 700,
    lineHeight: 1.5, // '24 px',
    letterSpacing: '2px',
    '& > a': {
      textDecoration: 'underline !important',
    },
  },
  h4: {
    fontSize: '14px',
    // fontSize: '1em', // '16px',
    fontWeight: 700,
    lineHeight: 1.715, // '24 px',
    letterSpacing: '1px',
    '& > a': {
      textDecoration: 'underline !important',
    },
  },
  'data-table': {
    color: theme.palette.grey[100],
    margin: 0,
    fontSize: '12px',
    // fontSize: '0.75em', // '12px',
    fontWeight: '500 !important',
    lineHeight: 2, // '24px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  'header--primary': {
    color: theme.palette.grey[100],
  },
  'header--secondary': {
    color: theme.palette.grey[300],
  },
  'header--error': {
    color: theme.palette.error.main,
  },
  'header--inherit': {
    color: 'inherit',
  },
}));

export default useStyles;

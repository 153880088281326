import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Paragraph from 'components/Paragraph';

import useStyles from './styles';

interface PropsInterface {
  options: { label: React.ReactElement | string; value: string | number }[];
  label?: React.ReactNode;
  value?: string | number;
  name: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  row?: boolean;
  fullWidth?: boolean;
}

const RadioButtonsGroup: React.FunctionComponent<PropsInterface> = (props) => {
  const { options, label, value, name, onChange, row = false, fullWidth } = props;
  const classes = useStyles();
  return (
    <FormControl fullWidth={fullWidth}>
      {label && <Paragraph type="size-XS-regular">{label}</Paragraph>}
      <RadioGroup name={name} onChange={onChange} row={row} value={value}>
        {options?.map(({ label, value }) => (
          <FormControlLabel
            control={<Radio color="secondary" />}
            key={value}
            label={label}
            value={value}
            classes={{ label: fullWidth && classes['radio-buttons-group__label'] }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
export default RadioButtonsGroup;

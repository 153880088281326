import { useState, useRef, useLayoutEffect, RefObject } from 'react';
import useVisibility from './useVisibility';

const useInfiniteScroll = ({
  hasMore,
  initialPage = 0,
  distance = 150,
}): [number, RefObject<HTMLDivElement>, RefObject<HTMLDivElement>] => {
  const scrollContainerRef = useRef();
  const [page, setPage] = useState(initialPage);
  const [isVisible, loaderRef] = useVisibility(distance);

  useLayoutEffect(() => {
    if (isVisible) {
      setPage(page + 1);
    }
  }, [hasMore, distance, isVisible]);

  return [page, loaderRef, scrollContainerRef];
};

export default useInfiniteScroll;

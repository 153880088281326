import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Icon, InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import getUserCustomLang from 'helpers/intl/getUserCustomLang';
import importLocale from 'helpers/intl/importLocale';
import logError from 'helpers/logError';
import ValidationMessage from 'components/ValidationMessage';
import ErrorBoundary from 'components/ErrorBoundary';
import { InputMessageType } from 'types';

import useStyles from './styles';

interface PropsInterface extends Omit<DatePickerProps<Date, Date>, 'renderInput' | 'open'> {
  renderInput?(...args: any): JSX.Element;
  inputLabel?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  clear?: boolean;
  message?: InputMessageType;
  size?: 'small' | 'medium';
}

const DatePicker: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    inputFormat = 'yyyy-MM-dd',
    label,
    placeholder,
    size = 'medium',
    renderInput,
    disabled,
    fullWidth,
    clear,
    message,
    ...restProps
  } = props;
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [locale, setLocale] = useState(null);
  const classes = useStyles({});
  const { t } = useTranslation('common');

  const allowOpen = disabled ? false : isOpen;
  const buttonInput = typeof renderInput === 'function';

  const handleDatepickerOpen = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    (async () => {
      try {
        const userLang = getUserCustomLang();
        setLocale((await importLocale(userLang)).default);
      } catch (e) {
        logError(e, { source: 'DatePicker' });
      }
    })();
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (locale?.options) {
      // @ts-ignore
      locale.options.weekStartsOn = 1;
    }
    if (locale) {
      setLoading(false);
    }
  }, [locale]);

  if (isLoading) {
    return <div />;
  }

  return (
    <ErrorBoundary>
      <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
        <div className={classes['date-picker']}>
          <MuiDatePicker
            {...restProps}
            DialogProps={{
              ...restProps.DialogProps,
              PaperProps: {
                className: classes['date-picker__dialog-paper'],
              },
            }}
            InputProps={{
              ...restProps.InputProps,
              className: classes['date-picker__input'],
            }}
            inputFormat={inputFormat}
            disabled={disabled}
            onError={(em, a) => {
              setError(em && t(`validators.date.${em}`));
            }}
            onClose={handleDatepickerOpen}
            open={buttonInput ? allowOpen : undefined}
            renderInput={(params) =>
              buttonInput ? (
                <div
                  onClick={handleDatepickerOpen}
                  ref={params.inputRef}
                  role="button"
                  tabIndex={0}
                  style={{ width: fullWidth ? '100%' : 'inherit' }}
                  className={classes['date-picker__label']}
                >
                  {renderInput()}
                </div>
              ) : (
                <TextField
                  {...params}
                  error={error || !!message}
                  InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                  fullWidth={fullWidth}
                  label={label}
                  placeholder={placeholder}
                  hiddenLabel={!label}
                  size={size}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        {clear && restProps.value && (
                          <IconButton
                            onClick={() => {
                              restProps.onChange(null);
                            }}
                            size="small"
                            className={cx(classes['date-picker__input-clear-icon'], {
                              [classes['date-picker__input-clear-icon--visible']]: restProps.value,
                            })}
                          >
                            <Icon>close</Icon>
                          </IconButton>
                        )}
                        {params.InputProps?.endAdornment}
                      </InputAdornment>
                    ),
                  }}
                />
              )
            }
          />
          {(message || error) && (
            <ValidationMessage
              message={{ type: message?.type || 'error', message: error || message?.message }}
              show={!!error || !!message}
            />
          )}
        </div>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export default DatePicker;

import React from 'react';
import cx from 'classnames';

import Header from 'components/Header';
import Paragraph from 'components/Paragraph';

import WidgetTitle from '../../WidgetTitle';
import useStyles from './styles';

export interface PropsInterface {
  title?: string;
  highlightedText?: {
    title?: string;
    color?: string;
    description?: string;
  };
  description?: string;
  children?: React.ReactNode;
}

const DoughnutWidget: React.FunctionComponent<PropsInterface> = ({ title, highlightedText, description, children }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes['doughnut-widget'], {
        [classes['doughnut-widget--no-text']]: !highlightedText && !description,
      })}
    >
      <div className={classes['doughnut-widget__header']}>
        {title && <WidgetTitle>{title}</WidgetTitle>}
        {highlightedText && (
          <div className={classes['doughnut-widget__summary']} style={{ color: highlightedText.color }}>
            <Header type="h2" variant="h1" color="inherit">
              {highlightedText.title}
            </Header>
            <Paragraph type="size-XS-regular" color="secondary">
              {highlightedText.description}
            </Paragraph>
          </div>
        )}
        {description && (
          <Paragraph type="size-S-medium" color="secondary">
            {description}
          </Paragraph>
        )}
      </div>
      <div
        className={cx(classes['doughnut-widget__chart'], {
          [classes['doughnut-widget__doughnut--no-text']]: !highlightedText && !description,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default DoughnutWidget;

import Palette from '../palette';

export default {
  root: {
    '&:hover': {
      backgroundColor: `${Palette.blue.light}!important`,
      color: '#FFF!important',
    },
    '&$selected': {
      backgroundColor: 'rgba(255,255,255,1)',
      color: '#000',
    },
  },
};

import mixpanelApi from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import config from '../config';

type LocationOrFeatureNameType = string;
type ActionNameType = string;

interface MixpanelInterface {
  init(): void;
  identify(name: string): void;
  track(id: `${LocationOrFeatureNameType}:${ActionNameType}`, params?: unknown): void;
  people: {
    set(params: {
      last_login?: string;
      $email?: string;
      USER_ID?: string;
      name?: string;
      entrance_by?: 'register' | 'social' | 'login' | null;
    }): void;
  };
}

const shouldMixPanelBeAvailable = () => {
  if (
    (import.meta.env.PROD && config.stalking.mixpanel.isActive) ||
    (import.meta.env.DEV && config.stalking.mixpanel.isActiveDev)
  ) {
    window?.localStorage?.setItem('allowMixPanel', 'allow');
  } else {
    window?.localStorage?.removeItem('allowMixPanel');
  }
};
const isMixPanelAvailable = () => window?.localStorage?.getItem('allowMixPanel') === 'allow';

const mixpanel: MixpanelInterface = {
  init: () => {
    shouldMixPanelBeAvailable();
    if (isMixPanelAvailable()) {
      try {
        mixpanelApi.init(
          import.meta.env.PROD ? '264cd8036ae90cd5890c0e9ec0f2180b' : '1327e0eb24b1c822d0e91a51a17ff727',
        );
      } catch (e) {
        window?.localStorage?.removeItem('allowMixPanel');
        Sentry.captureException(new Error('Mix not loaded'), {
          extra: {
            error: e.message,
          },
        });
      }
    }
  },
  identify: (name) => {
    if (isMixPanelAvailable()) {
      mixpanelApi.identify(name);
    }
  },
  track: (id, params = {}) => {
    if (isMixPanelAvailable()) {
      mixpanelApi.track(id, params);
    }
  },
  people: {
    set: (params) => {
      if (isMixPanelAvailable()) {
        mixpanelApi.people.set(params);
      }
    },
  },
};

export default mixpanel;

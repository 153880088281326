import React from 'react';
import cx from 'classnames';

import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon } from 'assets/icons/status';
import { AlertTitle } from '@mui/material';
import useStyles from './styles';
import Paragraph from '../Paragraph';

export interface PropsInterface extends Omit<AlertProps, 'severity'> {
  type: 'error' | 'success' | 'warning' | 'info';
  message: React.ReactNode;
  sharp?: boolean;
  fullWidth?: boolean;
  title?: string;
}

const Alert: React.FunctionComponent<PropsInterface> = ({ type, message, fullWidth, sharp, title, ...restProps }) => {
  const classes = useStyles({});

  return (
    <MuiAlert
      variant={restProps.variant || 'filled'}
      {...restProps}
      severity={type}
      iconMapping={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      }}
      {...restProps}
      className={cx(restProps.className, classes.alert, {
        [classes['alert--sharp']]: sharp,
        [classes['alert--full-width']]: fullWidth,
        [classes['alert--success']]: type === 'success',
        [classes['alert--error']]: type === 'error',
        [classes['alert--warning']]: type === 'warning',
        [classes['alert--info']]: type === 'info',
      })}
      classes={{ message: classes['alert__message'], action: classes['alert__action'] }}
    >
      {title && (
        <Paragraph type="size-S-bold" color="primary">
          {title}
        </Paragraph>
      )}
      <Paragraph type="size-S-medium" color="primary">
        {message}
      </Paragraph>
    </MuiAlert>
  );
};

export default Alert;

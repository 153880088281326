import withStyles from '@mui/styles/withStyles';
import Palette from './palette';
import theme from './index';

const styles = () => ({
  '@global': {
    '*': {
      outline: '0',
      '-webkit-appearance': 'none',
      '-webkit-tap-highlight-color': 'transparent',
    },
    textarea: {
      scrollbarColor: `${theme.palette.white.light} ${theme.palette.black.dark}`,
      scrollbarWidth: 'thin',
      paddingRight: '5px!important',
    },

    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    'input::-webkit-scrollbar, textarea::-webkit-scrollbar': {
      width: theme.spacing(1),
      height: theme.spacing(1),
      backgroundColor: theme.palette.blue.dark,
    },
    'input::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb': {
      borderRadius: 0,
      backgroundColor: theme.palette.white.light,
    },
    'input::-webkit-scrollbar-button, textarea::-webkit-scrollbar-button': {
      display: 'none',
    },
    'body, html': {
      color: '#fff',
      margin: '0',
      padding: '0',
      // overflowY: 'scroll',  // disabling mobile browser native scrolling bahavior
      fontFamily: '"Montserrat", sans-serif',
      '-webkit-overflow-scrolling': 'auto',
      fontSize: 14,
      '&': {
        scrollbarColor: `${theme.palette.white.light} ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
      },

      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
        height: theme.spacing(1),
        backgroundColor: theme.palette.blue.dark,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 0,
        backgroundColor: theme.palette.white.light,
      },
      '&::-webkit-scrollbar-button': {
        display: 'none',
      },
    },
    body: {
      fontFamily: 'Montserrat, sans-serif',
      color: Palette.white.main,
      background: Palette.background.default,
    },
    a: {
      color: Palette.white.light,
      fontWeight: 600,
      cursor: 'pointer',
    },
    h1: {
      margin: '0 0 0.3em',
    },
    h2: {
      marginTop: '0',
      marginBottom: '0.3em',
    },
    h3: {
      fontSize: '1.3em',
      lineHeight: '1.3em',
      margin: '0',
    },
    h4: {
      fontSize: '1em',
    },
    h5: {
      fontSize: '1em',
      fontWeight: 600,
      marginTop: '0',
      color: Palette.grey[300],
    },
    h6: {
      fontSize: '1em',
      fontWeight: 400,
      marginTop: '0',
      marginBottom: '0.5',
      color: '#f1f1f1',
    },
    'input, textarea, select, option, button, a': {
      fontFamily: '"Montserrat", sans-serif',
    },
    hr: {
      borderTop: 'none',
      borderColor: theme.palette.grey[600],
    },
    img: {
      '-webkit-user-select': 'none',
      '-webkit-touch-callout': 'none',
      'user-drag': 'none',
      'user-select': 'none',
      '-moz-user-select': 'none',
      '-webkit-user-drag': 'none',
      '-ms-user-select': 'none',
    },
    'input:-webkit-autofill, input:-webkit-autofill:hover,input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus':
      {
        border: 'none',
        '-webkit-text-fill-color': '#fff',
        '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    label: {
      letterSpacing: '0',
      // fontSize: '16px',
    },
    p: {
      fontWeight: 300,
      fontSize: '1em',
      letterSpacing: '0',
      lineHeight: 2.2,
      color: Palette.white.main,
      margin: '0.5em 0',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',
      maxWidth: '60em',
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);

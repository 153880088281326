import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'price-input': {
    position: 'relative',
  },
  'price-input__input': {
    display: 'flex',
    alignItems: 'center',
  },
  'price-input__currency': {
    fontSize: '1.2em',
    marginRight: theme.spacing(2),
  },

  'price-input__currency-icon': {
    fontSize: '0.875em',
    paddingTop: theme.spacing(5),
    marginRight: theme.spacing(2),
  },

  'price-input__price': {
    flex: '2 1 auto',
  },
}));

export default useStyles;

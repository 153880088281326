import React from 'react';
import map from 'lodash/map';

import Icon from '@mui/material/Icon';
import Paragraph from 'components/Paragraph';
import { DropzoneFileRejectType } from '../../Dropzone';
import useStyles from './styles';

interface PropsInterface {
  files: DropzoneFileRejectType[];
}

const DropzoneFileRejectionList: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { files } = props;

  const classes = useStyles({});

  return (
    <aside className={classes['dropzone-files__wrapper']}>
      {map(files, ({ file, errors }: DropzoneFileRejectType, index) => {
        const fileName = file?.name || `'File' ${index + 1}`;
        const secondaryTitle = file?.size ? `${Math.round(file.size / 10000) / 100} mb` : '';
        return (
          <div className={classes['dropzone-files__item']} key={file.lastModified}>
            <div className={classes['dropzone-files__preview']}>
              <div className={classes['dropzone-files__preview-cover']}>
                <Icon color="error">broken_image</Icon>
              </div>
            </div>
            <div className={classes['dropzone-files__file-data']}>
              <Paragraph type="size-S-medium">
                {fileName}
                <br />
                <Paragraph type="size-S-medium" component="span" color="error">
                  {errors.map((value) => value.code)}
                </Paragraph>
              </Paragraph>
              <Paragraph type="size-S-medium">{secondaryTitle}</Paragraph>
            </div>
          </div>
        );
      })}
    </aside>
  );
};

export default DropzoneFileRejectionList;

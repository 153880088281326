import Palette from '../palette';

export default {
  root: {
    borderRadius: '8px',
    transition: 'all 0.3s ease-in-out',
    border: 'solid 1px',

    letterSpacing: 0,
    fontSize: '14px',
    // fontSize: '0.8750em', // '14px',
    fontWeight: '500 !important',
    lineHeight: 1.715, // '24px',
  },
  deleteIcon: {
    color: Palette.black.light,
    '&:hover': {
      color: Palette.black.dark,
    },
  },
};

import React from 'react';

import useStyles from './styles';

interface PropsInterface {
  eventNotification?: {
    isActive: boolean;
    counter: number;
    connectTime: number;
  };
}

const TestStats: React.FunctionComponent<PropsInterface> = ({ eventNotification }) => {
  const classes = useStyles();

  return (
    <div className={classes['test-stats']}>
      <div className={classes['test-stats__row']}>
        <div>event notification socket:</div>
        <div
          className={classes['test-stats__indicator']}
          style={{
            background: eventNotification.isActive ? 'green' : 'red',
          }}
        />
      </div>
      <div className={classes['test-stats__row']}>
        <div>connect time:</div>
        <div>{eventNotification.connectTime}ms</div>
      </div>
      <div className={classes['test-stats__row']}>
        <div>update counter:</div>
        <div>{eventNotification.counter}</div>
      </div>
    </div>
  );
};

export default TestStats;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'chip-select': {
    position: 'relative',
  },

  'chip-select__option': {
    margin: theme.spacing(1),
    textTransform: 'uppercase',
  },
}));

export default useStyles;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { SuccessIcon } from 'assets/icons/status';
import EarningsWidget from 'containers/Widget/EarningsWidget';
import Paragraph from 'components/Paragraph';
import { periodTypes } from 'constants/date';
import Header from 'components/Header';
import Card from 'components/Card';
import { CurrencyType } from 'types';

import useStyles from './styles';

type PropsType = {
  currency: CurrencyType;
  date: string;
};

const EarningsSummary: React.FunctionComponent<PropsType> = ({ currency, date }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <div className={classes['earnings-summary']}>
      <div className={classes['earnings-summary__icon']}>
        <SuccessIcon width={66} height={66} />
      </div>
      <div>
        <Header type="h2" variant="h1">
          {t('eventFinalizationDone.title')}
        </Header>
        <Paragraph type="size-S-medium" alignment="center">
          {t('eventFinalizationDone.subtitle')}
        </Paragraph>
      </div>
      <Card margin={6} fill>
        <EarningsWidget currency={currency} periodType={periodTypes.month} date={date} />
      </Card>
    </div>
  );
};

export default EarningsSummary;

import palette from '../palette';

const injectStyledScrollbar = () => {
  return {
    '-webkit-overflow-scrolling': 'auto',
    '&': {
      scrollbarColor: `${palette.white.light} ${palette.background.default}`,
      scrollbarWidth: 'thin',
    },

    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
      backgroundColor: palette.blue.dark,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 0,
      backgroundColor: palette.white.light,
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
  };
};

export default injectStyledScrollbar;

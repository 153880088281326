import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  'loader--fixed': {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export default useStyles;

const getInitialsFromName = (name: string): string => {
  if (typeof name !== 'string') {
    return null;
  }

  const nameArray = name.split(' ');

  if (nameArray.length === 1) {
    return name[0];
  }
  if (nameArray.length > 1) {
    return nameArray[0][0] + nameArray[1][0];
  }

  return null;
};

export default getInitialsFromName;

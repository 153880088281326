import { ClientFormErrorsType, ClientFormStateType, ClientFormStatusType } from './types';
import { CONTACT_SOURCES } from '../../constants/constants';

export const ClientFormSources = [
  CONTACT_SOURCES[2],
  CONTACT_SOURCES[1],
  CONTACT_SOURCES[4],
  CONTACT_SOURCES[7],
  CONTACT_SOURCES[8],
  CONTACT_SOURCES[9],
  CONTACT_SOURCES[6],
];

export const ClientFormInitialError: ClientFormErrorsType = {
  name: null,
  email: null,
  phone: null,
  contact: null,
};

export const ClientFormInitialStateInterface: ClientFormStateType = {
  name: null,
  email: null,
  phoneNumber: null,
  language: null,
  birthday: null,
  gender: null,
  street: null,
  homeNumber: null,
  postCode: null,
  city: null,
  country: null,
  vat: null,
  additional: null,
  contactSources: null,
  contactSourcesValue: null,
  clientSource: -1,
  tags: null,

  consents: {
    general: 0,
    files: [],
    filesToRemove: [],
  },
};

export const ClientFormInitialStatus: ClientFormStatusType = {
  isFetching: false,
  isSending: false,
};

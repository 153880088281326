import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import take from 'lodash/take';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import { FileRejection, useDropzone } from 'react-dropzone';

import { FormHelperText } from '@mui/material';
import Paragraph from 'components/Paragraph';
import Divider from 'components/Divider';

import DropzoneFileList from './components/DropzoneFileList';
import DropzoneFileRejectionList from './components/DropzoneFileRejectionList';
import useStyles from './styles';

const maxFileCount = 10;

export type DropzoneFileType = {
  id?: string;
  path?: string;
  preview?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name?: string;
  size?: number;
  type?: string;
};
export type DropzoneDropType = (files: DropzoneFileType[]) => void;
export type DropzoneRemoveType = (id: string, path: string) => void;
export type DropzoneFileClickType = (file: DropzoneFileType) => void;
export type DropzoneFileRejectType = FileRejection;

interface PropsInterface {
  multiple?: boolean;
  onDrop?: DropzoneDropType;
  onRemove?: DropzoneRemoveType;
  onFileClick?: DropzoneFileClickType;
  defaultValue?: DropzoneFileType[];
  message?: { type: string; message: string };
  fileNamePrefix?: string;
  maxFileSize?: number;
  acceptFiles?: { [mime: string]: string[] };
}

const Dropzone: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const {
    onDrop = () => {},
    onRemove = () => {},
    multiple = false,
    defaultValue = [],
    message,
    fileNamePrefix,
    onFileClick,
    maxFileSize = 10000000,
    acceptFiles = { 'image/png': ['.png'], 'image/jpeg': ['.jpeg'] },
  } = props;
  const [files, setFiles] = useState<DropzoneFileType[]>(defaultValue || []);
  const classes = useStyles({});
  const { t } = useTranslation('common');

  const handleDrop = React.useCallback(
    (acceptedFiles: DropzoneFileType[]) => {
      const images = take(acceptedFiles, maxFileCount).map((file) =>
        Object.assign(file, {
          id: Math.random().toString(),
          preview: URL.createObjectURL(file),
        }),
      );
      if (multiple) {
        onDrop(take(uniq(images), maxFileCount - files.length));
        setFiles(take(uniq([...files, ...images]), maxFileCount));
      } else {
        onDrop(acceptedFiles);
        setFiles([{ preview: URL.createObjectURL(acceptedFiles[0] as File) }]);
      }
    },
    [files, maxFileCount],
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: acceptFiles,
    maxSize: maxFileSize,
    onDrop: handleDrop,
  });

  const handleClear = (fileToRemove: DropzoneFileType) => {
    onRemove(fileToRemove.id, fileToRemove.path);
    setFiles(filter(files, (file) => file.id !== fileToRemove.id));
  };

  return (
    <div className={classes.dropzone}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classes['dropzone__dropbox']}>
          <Paragraph type="size-S-medium" color="secondary">
            <Paragraph type="size-S-bold" component="span">
              {t('dropzone.cta.grab-bold')}
            </Paragraph>{' '}
            {t('dropzone.cta.grab-secondary')}
          </Paragraph>
          <Paragraph type="size-S-medium" color="secondary">
            {t('dropzone.cta.or')}{' '}
            <Paragraph type="size-S-bold" component="span">
              {t('dropzone.cta.click-bold')}
            </Paragraph>{' '}
            {t('dropzone.cta.click-secondary')}
          </Paragraph>
        </div>
        <Paragraph type="size-XS-medium" color="secondary" alignment="right">
          {t('dropzone.details.accepted-formats')}:{' '}
          {Object.values(acceptFiles).map((value) =>
            value.map((extension) => (
              <span className={classes['dropzone__extension']} key={extension}>
                &apos;{extension}&apos;
              </span>
            )),
          )}
          <br />
          {t('dropzone.details.max-size')}: {maxFileSize / 1000000} mb
        </Paragraph>
      </div>
      <DropzoneFileList
        fileNamePrefix={fileNamePrefix}
        files={files}
        onFileClick={onFileClick}
        onRemove={handleClear}
      />
      {fileRejections.length > 0 && (
        <div className={classes['dropzone__rejections']}>
          <Divider title={t('dropzone.rejected.title')} />
          <DropzoneFileRejectionList files={fileRejections} />
        </div>
      )}

      {message && <FormHelperText error>{message.message}</FormHelperText>}
    </div>
  );
};

export default Dropzone;

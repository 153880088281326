import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import typography from './typography';
import * as overrides from './overrides';
import Palette from './palette';
import layoutValues from './constatns';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    black: Palette['primary'];
    blue: Palette['primary'];
    white: Palette['primary'];
  }

  interface TypeBackground {
    default: string;
    paper: string;
    topbar: string;
    toolbar: string;
    drawer: string;
    card: string;
  }

  interface PaletteOptions {
    black: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
  }
}

// @ts-ignore
const theme = createTheme(
  adaptV4Theme({
    props: {
      MuiTextField: {
        variant: 'filled',
      },
      MuiSelect: {
        variant: 'filled',
      },
      MuiIcon: {
        color: 'inherit',
      },
      MuiFormControl: {
        variant: 'filled',
      },
    },
    breakpoints: {
      values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1600 },
    },
    palette: Palette,
    overrides: overrides as ComponentsOverrides,
    spacing: layoutValues.spacing,
    typography,
    zIndex: {
      appBar: 1200,
      drawer: 1300,
    },
  }),
);

export default theme;

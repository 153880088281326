import client from '../client';
import { CommonApiInterface } from '../types/common';
import authorize from '../authorize';

const commonApi: CommonApiInterface = {
  roles: () => client.get('/commons/get_roles'),
  fa: () => client.post('/2fa/send/verify'),
  autocomplete: {
    languages: (query) => client.get(`/commons/languages_autocomplete?q=${query}`, authorize()),
    categories: (query) => client.get(`/commons/categories_autocomplete?q=${query}`, authorize()),
    countries: (query) => client.get(`/commons/countries_autocomplete?q=${query}`, authorize()),
    cities: (query, countryID) =>
      client.get(`/commons/cities_autocomplete?q=${query}&country_id=${countryID}`, authorize()),
  },
  releaseNotes: {
    list: (params) => client.get(`/release_notes/list?lang=${params.lang}&limit=${params.limit}`),
    details: (params) => client.get(`/release_notes/get?uuid=${params.uuid}`),
    latest: () => client.get('/release_notes/latest'),
  },
};

export default commonApi;

import React from 'react';

type PropsType = {
  size?: number;
};

const Logo: React.FunctionComponent<PropsType> = ({ size = 80 }) => (
  <svg fill="currentColor" viewBox="0 0 702.47 414.58" width={size} xmlns="http://www.w3.org/2000/svg">
    <path d="M121.87 348.11H87.21v12.34h34.66c4 0 7.66 0 7.66-6.17s-3.66-6.17-7.66-6.17zM118.2 336.62c4 0 7.67 0 7.67-6.09s-3.67-6-7.75-6H87.21v12.09h31z" />
    <path d="M692.46 280.42V2.21H9.9v301.27h.1v108.89h682.57V280.42zM125.53 372H72.21v-58.3h51.32c13.25 0 17.33 6.75 17.33 15.33a17.06 17.06 0 01-4.58 11.34 16.46 16.46 0 018.25 14.63c0 9.86-5.75 17-19 17zM222 342.78c0 20.25-11.08 30.08-36.66 30.08s-36.66-9.75-36.66-30.08V313.7h15v29.08c0 8.17 2.25 17.5 21.66 17.5s21.66-9.42 21.66-17.5V313.7h15zm43.59 30.08c-23.92 0-38.16-4.08-38.16-20.91h15c0 6.16 5 9 24 9 15.75 0 20.5-1.75 20.5-6.34 0-4.83-3.34-5.66-22.25-6.58-25.41-1.17-35.5-5.17-35.5-18.25 0-12.83 13.09-16.91 34.92-16.91 22 0 35.32 5.5 35.32 19.91h-15c0-6.41-6.42-8-22-8-14.75 0-18.25 1.42-18.25 5.75 0 4.5 3.5 5.34 20.5 6.34 23 1.25 37.24 2.5 37.24 17.24.02 15.58-14.72 18.75-36.3 18.75zm56.74-.83h-15V313.7h15zm81.82 0h-20.39L344 328.28V372h-15v-58.3h20.33l39.83 43.83V313.7h15zm68.33-46.41h-46.64v11.83h46.66v10.83h-46.66v11.83h46.66V372h-61.66v-58.3h61.66zm42.75 47.24c-23.91 0-38.16-4.08-38.16-20.91h15c0 6.16 5 9 24 9 15.75 0 20.5-1.75 20.5-6.34 0-4.83-3.33-5.66-22.25-6.58-25.41-1.17-35.49-5.17-35.49-18.25 0-12.83 13.08-16.91 34.91-16.91 22 0 35.33 5.5 35.33 19.91h-15c0-6.41-6.42-8-22-8-14.74 0-18.24 1.42-18.24 5.75 0 4.5 3.5 5.34 20.49 6.34 23 1.25 37.25 2.5 37.25 17.24.01 15.58-14.74 18.75-36.32 18.75zm78.57 0c-23.91 0-38.16-4.08-38.16-20.91h15c0 6.16 5 9 24 9 15.75 0 20.49-1.75 20.49-6.34 0-4.83-3.33-5.66-22.24-6.58-25.41-1.17-35.5-5.17-35.5-18.25 0-12.83 13.09-16.91 34.91-16.91 22 0 35.33 5.5 35.33 19.91h-15c0-6.41-6.42-8-22-8-14.75 0-18.25 1.42-18.25 5.75 0 4.5 3.5 5.34 20.5 6.34 23 1.25 37.24 2.5 37.24 17.24.03 15.58-14.72 18.75-36.3 18.75zm75.1-92.92H33.44V25.75h635.48z" />
    <path d="M72.21 56.84h49.37v192.02H72.21zM418.38 212.09l58.98-43.06 84.49 79.82h68.3l-116.3-106.7 116.3-85.31h-74.89l-136.88 99.57V56.84h-49.37v192.01h49.37v-36.76zM319.63 56.84H188.51l131.12 144.28V56.84zM170.96 104.84v144.01H301.8L170.96 104.84zM644.58 73.4c6.35 0 9.48-3.56 9.48-8.52s-3.13-8.53-9.48-8.53-9.49 3.56-9.49 8.53 3.14 8.52 9.49 8.52zm0-14.79c4.4 0 7.22 2 7.22 6.27s-2.82 6.26-7.22 6.26-7.23-2-7.23-6.26 2.8-6.27 7.23-6.27z" />
    <path d="M642.52 66.63h3.1l1.22 2h2.28l-1.38-2.23a2.56 2.56 0 001.61-2.51 2.64 2.64 0 00-2.85-2.74h-5.9v7.48h1.92zm0-3.87H646c.57 0 1.47 0 1.47 1.13S646.53 65 646 65h-3.44z" />
  </svg>
);

export default Logo;

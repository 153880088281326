import makeStyles from '@mui/styles/makeStyles';
import layoutValues from 'theme/constatns';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: layoutValues.sidebar.width.default,
    transition: 'width 0.3s ease-in-out',
    zIndex: 1298,
    height: '100%',
  },
  'sidebar--electron': {
    marginTop: layoutValues.electron.titleBar,
    '& $sidebar__logo': {
      display: 'none',
    },
  },
  'drawer--slim': {
    width: layoutValues.sidebar.width.slim,
  },
  sidebar: {
    position: 'relative',
    backgroundColor: theme.palette.background.drawer,
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px 0',
    transition: 'all 0.5s ease',
  },
  sidebar__content: {
    flex: 1,
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    transition: 'all 0.5s ease',
  },
  'sidebar__content--slim': {
    padding: '0 24px',
  },
  sidebar__logo: {
    marginLeft: '6px',
  },
  sidebar__footer: {
    flex: 0,
    textAlign: 'center',
  },
}));

export default useStyles;

import { VoucherErrorValueState } from './constatns';
import { VoucherFormValueInterface, VoucherFormErrorInterface } from './types';

const translationsKeys = {
  field: {
    notEmpty: 'common:validators.common.notEmpty',
  },
  title: {
    notEmpty: 'common:validators.common.notEmpty',
  },
  code: {
    notEmpty: 'common:validators.common.notEmpty',
    noSpace: 'common:validators.common.noSpace',
  },
  price: {
    notEmpty: 'common:validators.payment.price.notEmpty',
    tooLow: 'common:validators.payment.price.tooSmall',
  },
  percent: {
    tooLow: 'common:validators.payment.price.tooSmall',
    toHigh: 'common:validators.payment.price.tooHigh',
  },
  general: {
    notEmpty: 'common:validators.form.generalError',
  },
} as const;

type ValidationSchemaType = {
  discount: (values: { price: string; percent: string; type: string }) => string;
  title: (title: string) => string;
  paymentMethod: (paymentMethod: string, type: string) => string;
  code: (code: string) => string;
  price: (price: string) => string;
  percent: (percent: string) => string;
};

const validationSchema: ValidationSchemaType = {
  discount: ({ price, percent, type }) => {
    if (!price && !percent) {
      return translationsKeys.price.notEmpty;
    }

    if (type === 'price' && parseInt(price, 10) <= 0) {
      return translationsKeys.price.tooLow;
    }

    if (type === 'percent' && parseInt(percent, 10) <= 0) {
      return translationsKeys.percent.tooLow;
    }

    if (type === 'percent' && parseInt(percent, 10) > 100) {
      return translationsKeys.percent.toHigh;
    }

    return null;
  },
  price: (price) => {
    if (!price || price === '') {
      return translationsKeys.price.notEmpty;
    }

    if (parseInt(price, 10) <= 0) {
      return translationsKeys.price.tooLow;
    }

    return null;
  },
  percent: (percent) => {
    if (!percent || percent === '') {
      return translationsKeys.percent.tooLow;
    }

    if (parseInt(percent, 10) <= 0) {
      return translationsKeys.percent.tooLow;
    }

    if (parseInt(percent, 10) > 100) {
      return translationsKeys.percent.toHigh;
    }

    return null;
  },
  title: (title) => {
    if (!title) {
      return translationsKeys.title.notEmpty;
    }

    return null;
  },
  code: (code) => {
    if (!code) {
      return translationsKeys.code.notEmpty;
    }

    if (code.includes(' ')) {
      return translationsKeys.code.notEmpty;
    }

    return null;
  },
  paymentMethod: (paymentMethod, type) => {
    if (!paymentMethod && type === 'voucher') {
      return translationsKeys.field.notEmpty;
    }

    return null;
  },
};

const validateVoucherForm = {
  single: <T>(name: string, value: T): string => {
    if (!validationSchema[name]) {
      return null;
    }
    return validationSchema[name]?.(value);
  },
  all: (formValue: Partial<VoucherFormValueInterface>): VoucherFormErrorInterface => {
    if (!formValue) {
      return { ...VoucherErrorValueState, general: translationsKeys.general.notEmpty };
    }

    const validationResult = {
      ...VoucherErrorValueState,
      title: validationSchema.title(formValue.title),
      code: validationSchema.code(formValue.code),
      price: validationSchema.price(formValue.price),
      percent: validationSchema.percent(formValue.percent),
      paymentMethod: validationSchema.paymentMethod(formValue.paymentMethod, formValue.type),
    };

    if (formValue.paymentType === 'value') {
      delete validationResult.percent;
    }

    if (formValue.paymentType === 'percent') {
      delete validationResult.price;
    }

    return validationResult;
  },
};

export default validateVoucherForm;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  toast: {
    zIndex: 9999,
    fontWeight: 500,
    maxWidth: 500,
    borderRadius: 8,

    '& > *': {
      flexWrap: 'nowrap!important',
      background: 'linear-gradient(142.17deg, rgba(34, 34, 34, 0.88) 0%, rgba(24, 24, 24, 0.72) 100%) !important',
      boxShadow: '4px 4px 32px rgba(0, 0, 0, 0.32)',
      backdropFilter: 'blur(8px)',
      border: 'solid 1px',
    },

    '& .SnackbarItem-message': {
      alignItems: 'flex-start',
      fontSize: '1.2em',
      '& > *:nth-child(1)': {
        flex: '0 0 auto',
      },

      '& > *:nth-child(2)': {},
    },

    '& .SnackbarItem-action': {
      flex: '0 0 auto',
    },

    [theme.breakpoints.down['sm']]: {
      top: 0,
    },
  },

  toast__icon: {
    marginRight: theme.spacing(2),
    width: '1.2em',
  },

  'toast--success': {
    borderColor: theme.palette.success.main,
  },
  'toast--error': {
    borderColor: theme.palette.error.main,
  },
  'toast--warning': {
    borderColor: theme.palette.warning.main,
  },
  'toast--info': {
    borderColor: theme.palette.info.main,
  },
}));

export default useStyles;

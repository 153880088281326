import Palette from '../palette';

export default {
  root: {},
  listbox: {
    border: 'solid 1px rgba(255,255,255, 0.5)',
    '&': {
      scrollbarColor: `${Palette.white.light} ${Palette.background.default}`,
      scrollbarWidth: 'thin',
    },

    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
      backgroundColor: Palette.blue.dark,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 0,
      backgroundColor: Palette.white.light,
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
  },
};

import { stepLabelClasses } from '@mui/material/StepLabel';

export default {
  label: {
    fontSize: '1.2em',
    [`&.${stepLabelClasses.active}`]: {
      fontWeight: 700,
    },
  },
};

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'menu-item__content-container': {
    display: 'grid',
    gridTemplateColumns: `1fr ${theme.spacing(9)}`,
    alignItems: 'center',
    gap: theme.spacing(3),
    transition: '200ms',
    padding: theme.spacing(3),
    width: '100%',
    '&:hover:not(:disabled)': {
      backgroundColor: 'rgba(255,255,255,0.05)',
    },
  },
  'menu-item__content-container__selected': {
    backgroundColor: 'rgba(255,255,255,0.1)',
    '&:hover:not(:disabled)': {
      backgroundColor: 'rgba(255,255,255,0.15)',
    },
  },
  'menu-item__content-label': {},
  'menu-item__desktop': {
    padding: 0,
    margin: 0,
  },
  'menu-item__mobile': {
    position: 'relative',
    padding: 0,
    width: '100%',
    margin: 0,
    '& > *': {
      width: '100%',
    },
  },
  'menu-item__mobile__disabled': {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  'menu-item__radio__mobile': {
    position: 'absolute',
    opacity: 0,
  },
}));

export default useStyles;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'data-table__empty': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  'data-table__empty-content-wrapper': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'data-table__empty-content': {
    maxWidth: 500,
    textAlign: 'center',
    margin: 'auto',
  },
  'data-table__empty-skeleton': {
    opacity: 0.15,
  },

  'data-table__alert': {
    textAlign: 'left',
    marginTop: theme.spacing(14),
  },
}));

export default useStyles;

import React from 'react';

import useStyles from './styles';

export interface PropsInterface {
  children: React.ReactNode;
}

const DrawerActions: React.FunctionComponent<PropsInterface> = (props: PropsInterface) => {
  const { children } = props;
  const classes = useStyles({});

  return <div className={classes['drawer-actions']}>{children}</div>;
};

export default DrawerActions;

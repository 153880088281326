import React from 'react';
import cx from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import { Card } from '@mui/material';

import Skeleton from 'components/Skeleton';
import LoaderWrapper from 'components/LoaderWrapper';

const useStyles = makeStyles((theme) => ({
  'data-table__row': {
    borderRadius: '8px',
    margin: theme.spacing(2, 0),
    display: 'flex',
    width: '100%',
  },
  'data-table__row--animate': {
    animation: '$appear 300ms ease-in-out',
  },
  'data-table__cell': {
    display: 'flex',
    flex: '0 0 auto',
    padding: theme.spacing(4),
    overflow: 'hidden',
  },

  '@keyframes appear': {
    '100%': {
      opacity: 0.8,
    },
  },
}));

type PropsType = {
  rows: number;
  animate?: boolean;
  isLoading?: boolean;
};

const DataTableSkeleton: React.FunctionComponent<PropsType> = ({ rows, animate, isLoading }) => {
  const classes = useStyles();
  const rowsLength = [...new Array(rows)].length;
  const skeletonArray = [...new Array(rows)].map((item, index) => ({
    opacity: animate && (rowsLength - index) / rowsLength - 0.2,
    animationDuration: animate && `${index * 300}ms`,
  }));

  return (
    <LoaderWrapper loading={isLoading}>
      {skeletonArray.map(({ animationDuration, opacity }) => (
        <Card
          className={cx(classes['data-table__row'], { [classes['data-table__row--animate']]: animate })}
          key={Math.random()}
          style={{ opacity, animationDuration }}
        >
          <div className={classes['data-table__cell']}>
            <Skeleton variant="profile" width="100%" />
          </div>
          <div className={classes['data-table__cell']} style={{ flex: ' 0 1 auto', width: '100%' }}>
            <Skeleton width="100%" />
          </div>
          {/*<div className={classes['data-table__cell']} style={{ flex: ' 1 0 auto', width: '100%' }}>*/}
          {/*  <Skeleton width="100%" />*/}
          {/*</div>*/}
        </Card>
      ))}
    </LoaderWrapper>
  );
};

export default DataTableSkeleton;

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  'earnings-summary': {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    textAlign: 'center',
    alignItems: 'center',
  },

  'earnings-summary__icon': {
    background: 'linear-gradient(90deg, rgba(23, 128, 84, 0.32) 0%, rgba(34, 34, 34, 0.352) 100%)',
    width: 112,
    height: 112,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
}));

export default useStyles;

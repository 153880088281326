import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '0.875em',
    color: '#fff',
    backgroundColor: theme.palette.grey[700],
    border: `1px solid ${theme.palette.background.card}`,
    '& span': {
      fontSize: '1.6em',
    },
  },
  avatar__border: {
    backgroundColor: theme.palette.grey[600],
    border: '2px solid transparent',
    borderRadius: '50%',
    width: 'fit-content',
    height: 'fit-content',
  },
}));

export default useStyles;

import Palette from '../palette';

export default {
  select: {
    paddingBottom: '11px',
    minHeight: 'auto',
    '&:before': {
      borderColor: Palette.white.light,
    },
    '&:after': {
      borderColor: Palette.white.light,
    },
  },
  icon: {
    fill: Palette.white.light,
  },
};

import { alpha } from '@mui/material/styles';
import Palette from '../palette';

export default {
  root: {
    border: 0,
    color: alpha(Palette.white.light, 0.5),
    backgroundColor: alpha(Palette.black.main, 0.6),
    '&$selected': {
      backgroundColor: alpha(Palette.black.main, 0.7),
      color: Palette.white.light,
    },
  },
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import EmptyIllustration from 'assets/illustration/empty.svg?component';
import Paragraph from 'components/Paragraph';
import Header from 'components/Header';
import LinkA from 'components/Link';

import useStyles from './styles';

interface PropsInterface {
  title?: string;
  subtitle?: string | React.ReactNode;
  width?: number;
  cta?: {
    label: string;
    onClick?(): void;
    href?: string;
    type?: 'button' | 'link';
  };
}

const ContentEmptyPlaceholder: React.FunctionComponent<PropsInterface> = ({ title, subtitle, cta, width }) => {
  const { t } = useTranslation('event-details');
  const classes = useStyles({ width });

  return (
    <div className={classes['content-empty-placeholder']}>
      <div>
        <EmptyIllustration />
      </div>
      <div className={classes['content-empty-placeholder__meta']}>
        <Header type="h2" variant="h4" alignment="center" color="inherit">
          {title || t('eventsList.empty.title')}
        </Header>
        <Paragraph type="size-XS-regular" alignment="center" color="inherit">
          {subtitle || t('eventsList.empty.subtitle')}
        </Paragraph>
      </div>

      {cta && cta.href && cta.type !== 'link' && (
        <Button
          className={classes['content-empty-placeholder__cta']}
          component={Link}
          to={cta.href}
          variant="contained"
        >
          {cta.label}
        </Button>
      )}
      {cta && typeof cta.onClick === 'function' && cta.type !== 'link' && (
        <Button className={classes['content-empty-placeholder__cta']} onClick={cta.onClick} variant="contained">
          {cta.label}
        </Button>
      )}
      {cta && cta.type === 'link' && (
        <LinkA href={cta.href} onClick={cta.onClick}>
          {cta.label}
        </LinkA>
      )}
    </div>
  );
};

export default ContentEmptyPlaceholder;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Collapse, Grid } from '@mui/material';
import { useBooleanToggle } from 'helpers/hooks/useToggle';
import { CALENDAR_BOUNDS } from 'constants/calendar';
import { BOOKING_SOURCES, GENDER_OPTIONS } from 'constants/constants';
import ContactSourceSelect, { ContactSourceChangeType, ContactSourceSelectType } from 'components/ContactSourceSelect';
import LanguageAutocomplete from 'components/LanguageAutocomplete';
import RadioButtonsGroup from 'components/RadioButtonsGroup';
import Select, { SelectChangeType } from 'components/Select';
import MenuItem from 'components/MenuItem';
import PhoneInput, { PhoneInputChangeType } from 'components/PhoneInput';
import { AutocompleteChangeType } from 'components/Autocomplete/Autocomplete';
import DatePicker from 'components/DatePicker';
import Divider from 'components/Divider';
import TextField from 'components/TextField';
import Paragraph from 'components/Paragraph';
import ClientTagsAutocomplete from 'components/ClientTagsAutocomplete';

import { ClientFormStateType } from '../../types';
import { ClientFormSources } from '../../constants';

interface PropsInterface {
  formValue: ClientFormStateType;
  errors: {
    [key: string]: string;
  };

  onInputBlur(event: React.FocusEvent<HTMLInputElement>, value?: string): void;
  onInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onLanguageChange: AutocompleteChangeType;
  onPhoneChange: PhoneInputChangeType;
  onClientSourceSelect: SelectChangeType;
  onContactSelectChange: ContactSourceChangeType;
  onContactSourceSelect: ContactSourceSelectType;
  onTagSelect: AutocompleteChangeType;
  onDateChange(date: Date, name: string): void;
}

const ClientFormUserData: React.FunctionComponent<PropsInterface> = ({
  errors,
  onInputBlur,
  onInputChange,
  onLanguageChange,
  onPhoneChange,
  onClientSourceSelect,
  onContactSelectChange,
  onContactSourceSelect,
  onTagSelect,
  formValue,
  onDateChange,
}) => {
  const { t } = useTranslation(['page-client', 'common']);
  const [moreExpanded, toggleMoreExpanded] = useBooleanToggle(false);

  return (
    <>
      <Grid item xs={12}>
        <Divider title={t('clientEdit.section.basic')} />
        <TextField
          label={t('clientEdit.name.label')}
          required
          message={errors.name && { type: 'error', message: errors.name }}
          name="name"
          onBlur={onInputBlur}
          onChange={onInputChange}
          placeholder={t('clientEdit.name.placeholder')}
          startIcon="perm_identity"
          value={formValue.name || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioButtonsGroup
          name="gender"
          onChange={onInputChange}
          options={GENDER_OPTIONS.map((option) => ({ label: t(`clientEdit.gender.${option}`), value: option }))}
          row
          value={formValue.gender}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label={t('clientEdit.mail.label')}
          message={errors.email && { type: 'error', message: errors.email }}
          name="email"
          onBlur={onInputBlur}
          onChange={onInputChange}
          placeholder={t('clientEdit.mail.placeholder')}
          startIcon="email_outlined"
          value={formValue.email || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneInput
          error={errors.phone}
          onBlur={onInputBlur}
          onChange={onPhoneChange}
          translations={{
            phoneLabel: t('clientEdit.phone.label'),
            phonePlaceholder: t('clientEdit.phone.placeholder'),
          }}
          value={formValue?.phoneNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          InputProps={{
            name: 'birthday',
            fullWidth: true,
          }}
          fullWidth
          label={t('clientEdit.birth.label')}
          onChange={onDateChange}
          value={formValue.birthday}
          maxDate={CALENDAR_BOUNDS.birthdayMaxDate}
          views={['year', 'month', 'day']}
        />
      </Grid>
      <Grid item xs={6}>
        <LanguageAutocomplete
          label={t('clientEdit.languages.label')}
          multiple={false}
          onChange={onLanguageChange}
          placeholder={t('clientEdit.languages.placeholder')}
          value={formValue.language}
        />
      </Grid>
      <Grid item xs={12}>
        <Button color="secondary" onClick={() => toggleMoreExpanded()}>
          {moreExpanded ? t('clientEdit.details.hide') : t('clientEdit.details.show')} {t('clientEdit.details.more')}
        </Button>
        <Collapse in={moreExpanded}>
          {moreExpanded && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <TextField
                  label={t('clientEdit.street.label')}
                  name="street"
                  onChange={onInputChange}
                  placeholder={t('clientEdit.street.placeholder')}
                  value={formValue.street || ''}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('clientEdit.homeNumber.label')}
                  name="homeNumber"
                  onChange={onInputChange}
                  placeholder={t('clientEdit.homeNumber.placeholder')}
                  value={formValue.homeNumber || ''}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label={t('clientEdit.postCode.label')}
                  name="postCode"
                  onChange={onInputChange}
                  placeholder={t('clientEdit.postCode.placeholder')}
                  value={formValue.postCode || ''}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label={t('clientEdit.city.label')}
                  name="city"
                  onChange={onInputChange}
                  placeholder={t('clientEdit.city.placeholder')}
                  value={formValue.city || ''}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t('clientEdit.country.label')}
                  name="country"
                  onChange={onInputChange}
                  placeholder={t('clientEdit.country.placeholder')}
                  value={formValue.country || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('clientEdit.vat.label')}
                  name="vat"
                  onChange={onInputChange}
                  placeholder={t('clientEdit.vat.placeholder')}
                  value={formValue.vat || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('clientEdit.additional.label')}
                  maxLength={1000}
                  multiline
                  name="additional"
                  onChange={onInputChange}
                  placeholder={t('clientEdit.additional.placeholder')}
                  value={formValue.additional || ''}
                />
              </Grid>
            </Grid>
          )}
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Divider title={t('clientEdit.section.label')} />
        <Paragraph type="size-S-medium">{t('clientEdit.section.labelSecondary')}</Paragraph>
      </Grid>
      <Grid item xs={12}>
        <ClientTagsAutocomplete
          label={t('clientEdit.tag.label')}
          onChange={onTagSelect}
          placeholder={t('clientEdit.tag.placeholder')}
          value={formValue.tags}
          helperText={t('clientEdit.tag.helper')}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider title={t('clientEdit.section.clientSource')} />
        <Paragraph type="size-S-medium">{t('clientEdit.section.clientSourceSecondary')}</Paragraph>
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          label={{ id: 'clientSource', message: t('clientEdit.clientSource.label') }}
          onChange={onClientSourceSelect}
          value={formValue.clientSource}
        >
          <MenuItem disabled key={-1} value={-1}>
            {t('clientEdit.clientSource.placeholder')}
          </MenuItem>
          {Object.keys(BOOKING_SOURCES).map((key) => (
            <MenuItem selected={String(formValue.clientSource) === key} key={key} value={key}>
              {t(`common:booking-sources.${BOOKING_SOURCES[key].type}`)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Divider title={t('clientEdit.section.contactSources')} />
      </Grid>
      <Grid item xs={12}>
        <ContactSourceSelect
          defaultSourceValue={formValue.contactSources?.reduce(
            (state, { type }) => ({
              ...state,
              [type]: formValue.contactSourcesValue?.[type],
            }),
            { 4: formValue.phoneNumber },
          )}
          multi
          onInputChange={onContactSelectChange}
          onSourceChange={onContactSourceSelect}
          selectedSources={formValue.contactSources?.map(({ type }) => type)}
          sources={ClientFormSources.reduce((state, value) => ({ ...state, [value.id]: value }), {})}
        />
      </Grid>
    </>
  );
};

export default ClientFormUserData;

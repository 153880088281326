import axios from 'axios';

export default axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  timeout: 180000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'max-age=315360000',
    Authorization: `Bearer ${window?.localStorage?.getItem('biz_token')}`,
    'accept-language': window.localStorage?.getItem('customLang'),
  },
  transformResponse: [
    (response) => {
      if (typeof response === 'string') {
        const json = JSON.parse(response);
        return json.errors ? json.errors : json.data;
      }
      return response;
    },
  ],
});
